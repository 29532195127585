<template>
  <div @keyup.esc="escape" tabindex="0" id="modal_holder">

    <div class="modal-mask" @click="shouldClose">

      <div class="modal-wrapper">
        <div class="modal-container" id="modal_container">

        <transition name="sfade">
          <div id="main_question" v-if="!awkAnswered">

            <div v-if="vector && vector.modalQuestion">
              <h1>{{vector.title}}</h1>
              <img :src="'/static/vectors/' + vector.modalQuestion.image">
              <h2>{{vector.modalQuestion.question}}</h2>
              <a @click.prevent.stop="closeAwk()">Mehr erfahren ></a>
            </div>
          </div>
        </transition>

          <div class="modal-body" id="modal_body">
            <slot></slot>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import eventBus from '@/components/shared/EventBus.js'
import attackVectors from '@/components/shared/AttackVectors'
import _ from 'lodash'

export default {
  name: 'Modal',
  data () {
    return {
      vector: {},
      awkAnswered: false
    }
  },
  mounted: function () {
    this.getModalVector()
    const modalHolder = document.getElementById('modal_holder')

    /* istanbul ignore if */
    if (modalHolder) {
      modalHolder.focus()
    }

    const parent = this
    /* istanbul ignore next */
    setTimeout(() => {
      parent.activate = true
    })

    document.body.classList.add('modal-open')
  },
  methods: {
    getModalVector: function () {
      const thisVector = _.find(attackVectors, { id: this.$route.params.id })
      if (!thisVector) {
        return
      }

      this.vector = thisVector
    },
    closeAwk: function () {
      this.awkAnswered = true
    },
    getParents: function (el, parentSelector) {
      // If no parentSelector defined will bubble up all the way to *document*
      if (parentSelector === undefined) {
        parentSelector = document
      }
      var parents = []
      var p = el.parentNode

      try {
        while (p !== parentSelector) {
          var o = p
          parents.push(o)
          p = o.parentNode
        }
      } catch (err) {
        return []
      }

      parents.push(parentSelector) // Push that parentSelector you wanted to stop at

      return parents
    },
    escape: function () {
      return this.toolLP()
    },
    toolLP: function () {
      return this.$router.push({
        name: 'Vectors'
      })
    },
    shouldClose: function (evnt) {
      if (!evnt) {
        return false
      }
      const parents = this.getParents(evnt.target)
      const result = parents.some(function (element) {
        if (!element.classList) {
          return false
        }
        return element.classList.contains('close-btn')
      })

      /* istanbul ignore next */
      if (evnt.target.classList.contains('modal-wrapper') || result) {
        return this.toolLP()
      }
    }
  },
  destroyed: function () {
    document.body.classList.remove('modal-open')
    eventBus.$emit('modalClosing')
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';

.sfade-enter-active, .sfade-leave-active {
  transition: opacity .4s ease-in-out;
}
.sfade-enter, .sfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#main_question {
  @include absoluteMe();
  z-index: 1;
  height: 100%;
  width: 100%;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 80%;
    h1 {
      // width: 75%;
      // margin: auto;
      color: $blueTone;
      font-size: 2.5rem;
      margin-bottom: calc($standardGutter * 2);
    }
    img {
      width: 250px;
    }
    a {
      display: inline-block;
      margin-top: calc($standardGutter * 2);
      font-size: 1.9rem;
      color: $orangeTone;

      i {
        position: relative;
        top: 2px;
        margin-left: 5px;
      }
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: $standardGutter;
  height: 100%;
  // overflow: hidden;
}

.modal-container {
  border-radius: $borderRadius;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition: all .3s ease;
  width: 95%;
  height: 92%;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  position: relative;
  overflow: hidden;
  max-width: 1200px;
}

.modal-container:after {
  position: absolute;
  bottom: -1px;
  height: 8%;
  width: 100%;
  content: "";
  background: linear-gradient(to top,
     rgba(255,255,255, 1) 20%,
     rgba(255,255,255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  position: relative;
  padding: 0;
}


::v-deep .modal-body {
  margin: 20px 0;

  .row {
    max-width: none;
  }
}

.modal-default-button {
  float: right;
}

// Arrows for child views

#modal_body::before {
  @include absoluteMe();

  background-size: 50px;
  background-repeat: no-repeat;
  transition: opacity .5s ease-in;
  // transition: none;
  content: "";
  opacity: 0;
  background-position-y: 60%;
}
#modal_body.active.right::before {
  background-image: url(/public/static/arrows-right.svg);
  background-position-x: right;
  opacity: 1;
}

#modal_body.active.left::before {
  background-image: url(/public/static/arrows-left.svg);
  background-position-x: left;
  opacity: 1;
}

#modal_body.active.first.left::before,
#modal_body.active.last.right::before {
  background-image: none;
}

</style>
