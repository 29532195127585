<template>
  <b-modal v-model="modalShow" :id="id" :dialog-class="`dialog${id}`" :modal-class="['modal-basic', modalClass]" header-class="modal-basic__header" :hide-header="hideHeader" hide-footer size="lg" @hide="handleHide">
    <template #modal-header-close> <Icon name="cross" /> </template>
    <slot />
  </b-modal>
</template>

<script>
import Icon from '@/components/Icon'

  export default {
    components: {
      Icon
    },
    props: {
      id: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      },
      modalClass: {
        type: String
      },
      popupName: {
        type: String
      },
      hideHeader: {
        type: Boolean
      },
      unclosable: {
        type: Boolean
      }
    },
    data() {
      return {
        modalShow: this.value
      }
    },
    watch: {
      modalShow: {
        handler(newValue, oldValue) {
          if (newValue) {
            const modalOuter = document.getElementById(this.id).parentNode;
            this.fixZIndex(modalOuter, 10600)
          } else {
            this.popupName === 'no-offer' ? this.$store.commit('STOP_SHOWING_NO_OFFER_POPUP') : ''
          }
          this.$emit('input', newValue)
        }
      }
    },
    methods: {
      fixZIndex(el, zIndex) {
        el.style.zIndex = zIndex
      },
      handleHide($event) {
        this.unclosable ? $event.preventDefault() : ''
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

  #event-modal___BV_modal_outer_ {
    z-index: 10600 !important;
  }

::v-deep .modal-basic {
    width: 100%;

  .modal-basic__header {
    border-bottom: 0;
    padding: 0;
  }

  .modal-content {
    border-radius: 0;
    padding: 1rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem 2.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 3.5rem 4.25rem;
    }
    @include media-breakpoint-down(sm) {
      border: 0;
    }
  }

  .modal-body {
    padding: 0;
  }
}

::v-deep .close {
  opacity: 1;
  transition: 0.3s;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 5;
}

::v-deep .modal.show .modal-dialog {
  top: 50%;
    transform: translateY(-50%);

  @include media-breakpoint-up(sm)  {
    transform: translateY(calc(-50% - 34px));
  }
}
</style>