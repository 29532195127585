<template>
  <div class="secondary-page">
    <div class="container">
      <div class="row page-title">
        <div class="col">
          <h1>
            <div class="mb-3">
              Support nötig?
            </div>
            <div>
              Schnell & einfach Kontakt aufnehmen
            </div>
          </h1>
        </div>

        <div class="col-12 col-md-2 back-btn">
          <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
        </div>
      </div>
    </div>

    <BannerSection :content="bannerSectionContent" class="banner-section"></BannerSection>

    <div class="container">
      <ContactForm class="contact-form-section"></ContactForm>
    </div>

    <BackToTop />
  </div>
</template>

<script>
import BackToTop from '@/components/reusable/BackToTop.vue'
import BannerSection from '@/components/BannerSection'
import ContactForm from './contact-form.vue'

const bannerSectionContent = {
  "background": "primaryWaveV3",
  "h2": "Sie haben eine Frage?",
  "buttons": [
    {
      "style": "whiteBorder",
      "behavior": "externalLink",
      "iconPosition": "left",
      "openNewTab": false,
      "icon": {
        "name": "call3",
        "position": "left",
        "width": 20
      },
      "title": " 030 403 660 36",
      "link": "tel:03040366036"
    },
    {
      "style": "accent",
      "behavior": "external",
      "openNewTab": true,
      "title": "Beratungstermin buchen",
      "link": "https://calendar.app.google/4zx33hpfbaJ6mbav5"
    }
  ],
  "text": "<p>Wir beraten Sie gerne.</p>",
}

export default {
  name: 'Support',
  metaInfo: {
    title: 'Support',
  },

  components: {
    BackToTop,
    BannerSection,
    ContactForm
  },

  data() {
    return {
      bannerSectionContent
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.contact-form-section {
  position: relative;
  margin-top: -1.75rem;
}

::v-deep .section-heading {
  font-size: 1.75rem;
}

</style>
