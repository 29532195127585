<template>
    <div class="training-nav">
        <tabs :options="{ useUrlFragment: false }"
              ref="tabs">
            <tab name="Kursmaterialien"
                 id="training-module">
                <div class="training-nav__course-item flex flex--v-center"
                     :tabindex="0"
                     v-for="(courseMaterial, index) in courseMaterials"
                     :key="index"
                     @keypress.enter.space="() => setActiveModule(index, !!courseMaterial.steps)"
                     @click="() => setActiveModule(index, !!courseMaterial.steps)"
                     :class="{ 'training-nav__course-item--active': activeCourseMaterialIndex === index, 'training-nav__course-item--disabled': isSlideDisabled(index) }">
                    <div class="training-nav__course-play">
                        <icon :name="activeCourseMaterialIndex === index ? 'pause' : 'play'"></icon>
                    </div>
                    <div class="training-nav__course-content">
                        <h3 class="training-nav__course-subtitle">{{ index + 1 }}. {{ courseMaterial.sidebarTitle
                            }}</h3>
                        <!--<span class="training-nav__course-time">{{ courseMaterial.time }}</span>-->
                    </div>
                </div>
            </tab>
            <tab name="Quiz"
                 :is-disabled="$route.meta.module"
                 id="training-quiz">
                <div class="training-nav__course-item flex flex--v-center"
                     v-for="(quizQuestion, index) in quizQuestions"
                     :key="index"
                     :class="{ 'training-nav__course-item--active': activeQuizQuestionIndex === index }">
                    <div class="training-nav__course-play">
                        <icon :name="activeQuizQuestionIndex === index ? 'pause' : 'play'"></icon>
                    </div>
                    <div class="training-nav__course-content">
                        <h3 class="training-nav__course-subtitle">{{ index + 1 }}. {{ quizQuestion.question }}</h3>
                    </div>
                </div>
            </tab>
        </tabs>
    </div>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue';

  export default {
    name: 'training-nav',
    data() {
      return {
        currentTab: this.$route.meta.quiz ? 'training-quiz' : 'training-module'
      };
    },
    components: {Icon},
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse;
      },
      selectedCourseSlides() {
        return this.selectedCourse ? this.selectedCourse.slides : [];
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      savedSlideIndex() {
        const slide = this.selectedCourseProgress.slideSlug.match(/slide-(\d+)/);

        return slide ? slide[1] - 1 : -1;
      },
      isQuizStarted() {
        this.selectedCourseProgress.slideSlug.match(/^quiz/);
      },
      isCourseComplete() {
        return this.selectedCourseProgress.isDone;
      },
      courseMaterials() {
        return this.selectedCourseSlides.filter(slide => slide.group === 'intro' || slide.group === 'module');
      },
      quizQuestions() {
        return this.selectedCourseSlides.filter(slide => slide.group === 'quiz');
      },
      activeCourseMaterialIndex() {
        return Math.max(this.$route.params.slide - 1, -1);
      },
      activeQuizQuestionIndex() {
        return Math.max(this.$route.params.step - 1, -1);
      }
    },
    mounted() {
      if (this.$route.meta.quiz) {
        this.$refs.tabs.selectTab('#training-quiz');
      }

      this.$refs.tabs.$on('changed', this.tabChanged);
    },
    watch: {
      '$route.path': {
        handler(url) {
          if (url.indexOf('/quiz/') > -1 && this.$refs.tabs) {
            this.$refs.tabs.selectTab('#training-quiz');
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      setActiveModule(index, haveSteps) {
        if (this.isSlideDisabled(index)) return;

        this.$router.push({
          name: 'training-module',
          params: {
            slide: index + 1,
            step: haveSteps ? 1 : null
          }
        });
      },
      setActiveQuiz(index) {
        this.$router.push({
          name: 'training-quiz',
          params: {
            step: index + 1
          }
        });
      },
      isSlideDisabled(index) {
        return !this.isQuizStarted &&
          !this.isCourseComplete &&
          ((this.activeCourseMaterialIndex > -1 || this.savedSlideIndex > 0) ? index - 1 > this.savedSlideIndex : index !== 0);
      },
      async tabChanged({id}) {
        if (this.currentTab !== id) {
          this.currentTab = id;
        }
      }
    }
  }
</script>

<style lang="scss">
    @import '../../scss/trainings-variables';
    @import '../../scss/tabs-component';

    .training-nav {
        &__course-item {
            position: relative;
            padding: 7px 15px 7px 63px;
            min-height: 52px;
            margin-left: -1px;
            border-left: 2px solid transparent;
            cursor: pointer;

            & + & {
                margin-top: 8px;
            }

            &:focus {
                outline: 0;
            }

            &--active,
            &:not(&--disabled):focus,
            &:not(&--disabled):hover {
                border-left-color: $primary;

                .training-nav__course-play .icon {
                    fill: $primary;
                }

                .training-nav__course-subtitle {
                    color: $primary;
                }
            }

            &--active {
                cursor: default;
            }

            &--disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &__course-play {
            position: absolute;
            top: 7px;
            left: 15px;
            padding: 6px;
            border: 1px solid $table-border-color;
            border-radius: 50%;
            height: 38px;
            width: 38px;
        }

        &__course-subtitle {
            font-size: 0.935rem;
            line-height: 1.25rem;
            margin: 0 0 1px;
        }

        &__course-time {
            font-size: 0.8rem;
            line-height: 1rem;
        }
    }
</style>
