<template>
  <div class="loader-full-screen">
    <LoaderComponent></LoaderComponent>
  </div>
</template>

<script>
import LoaderComponent from '@/components/reusable/LoaderComponent.vue';

  export default {
    components: {LoaderComponent}
  }
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.loader-full-screen {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.1);
  z-index: 805;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>