<template>
    <div class="contact-tool"
         :class="{'contact-tool--modal-in': showContactModal, 'contact-tool--mobile': isMobile, 'contact-tool--in': isOpen}"
         ref="contactTool">
        <div class="contact-tool__container d-flex flex-column" :class="{'contact-tool__container--modal': isOpen}">
            <div class="contact-tool__head1 d-none" :class="{'d-block': isOpen}" >
                <button class="contact-tool__head1__close btn-icon"
                        type="button"
                        v-if="isMobile"
                        @click="isOpen = false">
                    <icon name="cancel"
                          width="24"
                          color="secondary">
                    </icon>
                </button>
            </div>
            <p class="d-none d-md-block">Unverbindliches Telefonat mit einem Fachberater für Cyber-Versicherungen
            </p>
            <div class="contact-tool__body">
                <button class="contact-tool__button btn2 btn2--secondary contact-tool__button" @click="openLink()">
                    <icon class="btn2__icon" name="calendar" color="white" width="18"></icon>
                    <span>Beratung vereinbaren</span>
                </button>
                <button class="contact-tool__button btn2 btn2--grey" @click="openModal('getOffer')">
                    <icon class="btn2__icon" name="email" color="grey" width="18"></icon>
                    <span>Vergleich zusenden</span>
                </button>
            </div>
        </div>
        <button class="contact-tool__mobile-button btn btn-primary btn-lg"
                type="button"
                v-if="isMobile"
                @click="isOpen = true">
            Beratung
        </button>
        <modal class="contact-modal" :size="modalSize" :closeable="true" v-if="showContactModal"
               @close="showContactModal = false">
            <h3 slot="header">
                {{title}}
            </h3>
            <div slot="body">
                <iframe :src="termin2golink"
                        frameBorder="0"
                        height="590"
                        width="100%"
                        scrolling="yes" v-if="modalMode === 'appointment'"></iframe>
                <form v-if="modalMode !== 'appointment' && submissionSuccess === false">
                    <div class="form-group" :class="getFieldClass('name')">
                        <label for="name">Name</label>
                        <input class="form-control"
                               type="text"
                               id="name"
                               name="name"
                               v-model="formData.name"
                               v-validate="{ required: true }">
                    </div>
                    <div class="form-group" :class="getFieldClass('email')" v-if="modalMode === 'getOffer'">
                        <label for="email">E-Mail *</label>
                        <input class="form-control"
                               type="email"
                               id="email"
                               name="email"
                               v-model="formData.email"
                               v-validate="{ required: true }">
                    </div>
                    <PhoneForm
                      v-else-if="modalMode === 'recall'"
                      name="Mobiltelefon"
                      v-model='formData.phone'
                      @numberChange="updatePhoneNumber"
                    />
                </form>
                <div v-if="modalMode !== 'appointment' && submissionSuccess">
                    <div class="col-12">
                        <h2 class="text-center">Vielen Dank für Ihre Anfrage!</h2>
                        <p class="text-center">Sie erhalten in Kürze eine Nachricht.</p>
                    </div>
                    <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-2 offset-lg-5">
                        <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/success_icon.svg">
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="contact-tool__buttons">
                  <button class="btn btn--default"
                          type="button"
                          v-if="this.showAbort"
                          @click="showContactModal= false"
                          slot="footer">Schlie&szlig;en
                  </button>
                  <button class="btn btn-secondary"
                          type="button"
                          v-if="this.showSend && !submissionSuccess"
                          @click="sendSubmission()"
                          slot="footer">Absenden
                  </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
  import Icon from '../Icon'
  import Modal from '../Modal';
  import eventBus from '@/components/shared/EventBus.js'
  import PhoneForm from './shared/PhoneForm'

  const MOBILE_OPEN_CLASS = 'contact-tool--in';

  const MODAL_CONFIG = {
    appointment: {
      showAbort: true,
      showSend: false,
      title: 'Wunschtermin vereinbaren',
      modalSize: 'lg'
    },
    recall: {
      showAbort: true,
      showSend: true,
      title: 'Rückruf',
      modalSize: 'sm'
    },
    getOffer: {
      showAbort: true,
      showSend: true,
      title: '',
      modalSize: 'sm'
    }
  };

  export default {
    name: 'ContactTool',
    data() {
      return {
        showContactModal: false,
        modalMode: 'appointment',
        showAbort: true,
        showSend: true,
        formData: {
          phone: ''
        },
        title: '',
        submissionSuccess: false,
        modalSize: 'lg',
        left: 0,
        isMobile: false,
        isOpen: false,
        contactToolWidth: 0,
        termin2golink: 'https://booking.termin2go.com/?service=611bd6f8a96b132019d8bbdc/#!/cyberdirekt/appointments/'
      }
    },
    components: {
      Icon,
      Modal,
      PhoneForm
    },
    props: ['meta'],
    mounted() {
      this.contactToolWidth = this.$refs.contactTool.clientWidth;
      this.calcContainerPos();
      window.addEventListener('resize', this.calcContainerPos);
    },
    destroyed() {
      window.removeEventListener('resize', this.calcContainerPos);
    },
    watch: {
      isOpen() {
        const $body = document.body;

        if (this.isOpen) {
          $body.classList.add(MOBILE_OPEN_CLASS);
        } else {
          $body.classList.remove(MOBILE_OPEN_CLASS);
        }
      }
    },
    methods: {
      updatePhoneNumber (res) {
        this.formData.phone = res.number;
      },
      calcContainerPos: function () {
        if (!this.$refs.contactTool) return;

        const containerWidth = document.querySelector('.container').clientWidth;
        const browserWidth = window.innerWidth;
        const widthDifference = browserWidth - containerWidth;
        const widthDifferencePerSide = widthDifference / 2;

        if (this.contactToolWidth > widthDifferencePerSide) {
          this.isMobile = true;
        } else {
          this.left = widthDifferencePerSide + containerWidth;
          this.isMobile = false;
        }
      },
      openLink() {
        window.open('https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0St30IG-rpZ14hvh3qzPBM3RkH5I6N7BAG7j82QR-uWVm8QTgteP6Le0T8DVlZSQPJnQIjsSXw', '_blank', 'noopener');
      },
      openModal(modalMode = 'appointment') {
        this.submissionSuccess = false;
        if (this.isMobile && modalMode === 'appointment') {
          window.open(this.termin2golink, '_blank', 'noopener');
          return;
        }

        this.modalMode = modalMode;
        this.showContactModal = true;
        this.showAbort = MODAL_CONFIG[modalMode].showAbort;
        this.showSend = MODAL_CONFIG[modalMode].showSend;
        this.title = MODAL_CONFIG[modalMode].title;
        this.modalSize = MODAL_CONFIG[modalMode].modalSize;
      },
      sendSubmission() {
        let topic = {
          recall: 'marketComparisonRecall',
          getOffer: 'marketComparisonGetOffer'
        }[this.modalMode];
        let data = {
          topic
        };

        if (this.modalMode === 'recall') {
          data.name = this.formData.name;
          data.phone = this.formData.phone;
          data.meta = this.meta;
        } else if (this.modalMode === 'getOffer') {
          data.name = this.formData.name;
          data.email = this.formData.email;
          data.meta = this.meta;
        }

        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              this
                .$store
                .dispatch('INSERT_SUBMISSION', data)
                .then(() => {
                  this.submissionSuccess = true;
                })
                .catch(() => {
                  eventBus.$emit('alertUser', {
                    type: 'error',
                    message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.',
                    customCloseBtnText: 'OK',
                    customCloseBtnClass: 'btn btn-primary ',
                  })
                })
            }
          });
      },
      getFieldClass(fieldName) {
        if (!this.fields[fieldName]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      }
    }
  }
</script>
<style lang="scss">
    .contact-tool--in {
        overflow: hidden;
    }
</style>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '../scss/signup-shared';
    @import '../scss/form';
    @import '../scss/button';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    

    .contact-tool {
        position: absolute;
        right: -230px;
        top: 174px;
        width: 230px;
        padding: 16px;
        border: 2px solid $greyTone;

        p {
            color: $bodyColor;
            font-size: .8rem;
        }

        &__button {
            width: 100%;
            border: 0;
            text-align: left;
            border-radius: 0;

            @include media-breakpoint-up(md) {
                padding: 10px 10px 10px 42px;
                margin-bottom: 8px;
                border-radius: 0.2rem;
            }
        }

        &__container {
            height: 100%;
            width: 100%;
            gap: 1rem;

            &--modal {
              background-color: #fff;
              padding: 1rem;
            }
        }

        &__head1 {
            text-align: center;
            position: relative;
            height: 10px;
            color: $bodyColor;
            font-size: .8rem;
            line-height: 40px;

            &__close {
                position: absolute;
                right: 4px;
                padding: 0;
                background: none;
                border: 0;
            }
        }

        &__head2 {
            padding: 8px 16px;
            color: #fff;
            text-align: center;
            background: $secondary;
        }

        &__body {
            background: #fff;
        }

        &__footer {
            padding: 8px 16px;
            color: #fff;
            text-align: center;
            background: $primary;
        }

        .modal-footer > div {
          width: 100%;
        }

        &__buttons {
          display: flex;
          gap: 1rem;
          width: 100%;

          .btn {
            white-space: normal;
          }
        }

        &__mobile-button {
            position: fixed;
            padding: 8px;
            bottom: 8px;
            right: 8px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        &--mobile {
            display: flex;
            position: fixed;
            z-index: 1000;
            top: 101%;
            left: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: rgba(0, 0, 0, .6);

            .contact-tool__container {
                height: auto;
            }

            .contact-tool__container {
                max-width: 300px;
                box-shadow: 0 18px 35px rgba(50, 50, 93, .3), 0 8px 15px rgba(0, 0, 0, .2);
            }
        }

        &--in {
            top: 0%;
        }
    }

    .contact-modal {
        h3 {
            font-size: 1.4rem;
        }
    }

</style>
