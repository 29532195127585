<template>
    <div class="container">
        <div class="col-12">
            <div class="alert alert-danger text-center" v-if="errorText">
                {{errorText}}
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'SSO',
    metaInfo: {
      title: 'Single Sign On'
    },
    data() {
      return {
        errorText: null
      }
    },
    mounted() {
      let ssoEndpoint = this.$route.query.partner ? this.$route.query.partner.toUpperCase() : 'PMA';

      this.$store
        .dispatch(`LOGIN_SSO_${ssoEndpoint}`, this.$route.query.data)
        .then(() => {
          return this.$router.push({name: 'Main'})
        })
        .catch((error) => {
          if (error) {
            this.errorText = 'Der Login war nicht erfolgreich. Bitte wenden Sie sich an unseren Support.';
          }
        })
    }
  }
</script>

<style lang="scss" scoped>
    @import '../../scss/variables';

    .container {
        margin-top: 16px;
    }

</style>
