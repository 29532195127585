<template>
  <div>
    <router-view :forms="forms" :step="step"></router-view>
  </div>
</template>
<script>

export default {
  name: 'MultiStep',
  props: ['forms', 'step']
}
</script>

<style lang="scss" scoped>

</style>
