<template>
<div>
  <div v-if ="!apiCalled" class="loader-wrapper">
    <LoaderComponent />
  </div>
  <ModalBasic v-if ="apiCalled && !filteredOffers.length && isUserActive && isAVVchecked && showNoOfferPopup && isTermsChecked" v-model="showModal" id="modal-offers-info" popup-name="no-offer">
    Sie haben aktuell noch kein Angebot erstellt. <router-link to="/versicherung/1a/ihr-unternehmen/branche">Kalkulieren Sie Ihr erstes Angebot</router-link> für Ihren Kunden mit nur wenigen Klicks.
  </ModalBasic>

  <div v-else :class="{'enable-filters': enableFilters}">
    <ButtonComponent
      v-if="enableFilters"
      v-b-modal.modal-filter
      class="filter-btn"
      btnStyle="neutral"
      :icon="{name: 'filter', position: 'left'}">
      <span>Filter</span>
    </ButtonComponent>

    <!-- mobile filter is not implemented yet -->
    <ModalFilter v-if="enableFilters" id="modal-filter" />

    <div class="offer-preview" :class="{'show-cut': !isTouchDevice, 'has-loader': isProcessing('one-offer')}">

      <div v-if="isProcessing('one-offer')" class="loader-wrapper">
        <LoaderComponent />
      </div>

      <Icon name="arrow-down" class="offer-preview__icon"  />
      <table class="offer-preview-table">
        <thead class="offer-preview__header" @click="$router.push('/offers')">
          <tr>
            <th v-if="enableFilters" ></th>
            <th>
              <div class="offer-preview-table__title">
                Datum
              </div>
            </th>
            <th class="offer-preview-table__customer-col" >
              <label class="offer-preview__control-label">Kunde</label>
              <input
                v-if="enableFilters"
                class="form-control filter-control"
                type="text"
                v-model="filters.userName.selected">
            </th>
            <th class="offer-preview-table__company-col">
              <label class="offer-preview__control-label">Firma</label>
              <input
                v-if="enableFilters"
                class="form-control filter-control"
                type="text"
                v-model="filters.company.selected">
            </th>
            <th class="offer-preview-table__makler-col">
              <label class="offer-preview__control-label" >Makler:in</label>
              <select
                v-if="enableFilters"
                class="custom-select filter-control"
                v-model="filters.brokerName.selected"
              >
                <option v-for="broker in filters.brokerName.list" :key="broker.id">{{broker}}</option>
              </select>
            </th>
            <th class="offer-preview-table__status-col">
              <label class="offer-preview__control-label">Status</label>
              <select
                v-if="enableFilters"
                class="custom-select filter-control"
                v-model="filters.state.selected"
              >
                <option v-for="state in filters.state.list" :key="state.id">{{state}}</option>
              </select>
            </th>
            <th class="offer-preview-table__notice-col">
              <div class="offer-preview-table__title">
                Interne Notiz
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="offer in filteredOffers"
            :key="offer.id"
            @click="openOffer(offer.id)"
            class="table-row-link"
          >
            <td v-if="enableFilters" >
              <CustomCheckbox />
            </td>
            <td>
              {{
                new Date(offer.created).toLocaleDateString("de-DE",
                {day: '2-digit', month: '2-digit', year: 'numeric'})
              }}
              </td>
            <td class="offer-preview-table__customer-col">{{offer.userName}}</td>
            <td class="offer-preview-table__company-col">{{offer.company}}</td>
            <td class="offer-preview-table__makler-col">{{offer.brokerName}}</td>
            <td class="offer-preview-table__notification-col text-lg">
              <b-badge
                class="custom-badge"
                :class="[
                  {'bg-st-open': getStateLabel(offer).toLowerCase() === 'angebot erstellt'}, //open
                  {'bg-st-sent': getStateLabel(offer).toLowerCase() === 'angebot versandt'}, // sent
                  {'bg-st-accepted': getStateLabel(offer).toLowerCase() === 'angebot akzeptiert'}, // accepted
                  {'bg-st-declined': getStateLabel(offer).toLowerCase() === 'angebot abgelehnt'}, // declined
                  {'bg-st-submitted': getStateLabel(offer).toLowerCase() === 'antrag eingereicht'}, // submitted
                ]"
              >
              {{getStateLabel(offer)}}</b-badge>
            </td>
            <td>{{offer.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import CustomCheckbox from '@/components/shared/CustomCheckbox'
import ButtonComponent from '@/components/reusable/ButtonComponent'
import LoaderComponent from '@/components/reusable/LoaderComponent'
import ModalFilter from '@/components/ModalFilter'
import Icon from '@/components/Icon'
import OfferAPI from '@/api/offer.js';
import ModalBasic from '@/components/reusable/ModalBasic'
import {mapState} from 'vuex'

  export default {
    components: {
      CustomCheckbox,
      ButtonComponent,
      LoaderComponent,
      ModalFilter,
      Icon,
      ModalBasic
    },
    props: {
      limit: {
        type: Number,
        default: 1000
      },
      enableFilters: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        offers: [],
        filteredOffers: [],
        states: {
          open: 'Angebot erstellt', // blau (CyberDirekt colour)
          sent: 'Angebot versandt',  // grau (CyberDirekt colour)
          accepted: 'Angebot akzeptiert', // orange (CyberDirekt colour)
          declined: 'Angebot abgelehnt', // pink or danger
          submitted: 'Antrag eingereicht' // grau (CyberDirekt colour)
        },
        apiCalled: false,
        errorMessage: '',
        showModal: true,
        filters: {
          userName: {
            selected: ''
          },
          company: {
            selected: ''
          },
          brokerName: {
            list: [],
            selected: ''
          },
          state: {
            list: [
              'open',
              'sent',
              'accepted',
              'declined',
              'submitted'
            ],
            selected: ''
          }
        }
      }
    },
    computed: {
      isTouchDevice () {
      return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
      },
      isUserActive() {
        return this.$store.state.user.isActive
      },
      isAVVchecked() {
        return this.$store.state.user.orderProcessingChecked
      },
      isTermsChecked() {
        return this.$store.state.user.termsChecked
      },
      processingState () {
        return this.$store.state.checkout.application.isInitialDataLoading;
      },
      ...mapState(['showNoOfferPopup', 'user'])
    },
    mounted () {
      this.initData();

      if (this.enableFilters) {
        this.$watch('filters', () => {
          this.filterHandler()
        },{deep: true})
      }

      this.preventEndlessLoading(10000)
    },
    methods: {
      async initData () {
        await this.getOffers();
      },
      getOffers() {
        let brokers = new Set();

        OfferAPI
          .list(1000)
          .then((response) => {
            this.offers = (response.data.offers || []).map(offer => {
              brokers.add(offer.brokerName);

              offer.userName = offer.firstname + ' ' + offer.lastname;
              offer.state = this.getState(offer)

              return offer;
            });

            this.filters.brokerName.list = Array.from(brokers);
            this.filteredOffers = this
                .sortBy('accepted-first', [...this.offers])
                .filter(offer => !offer.createdBy || offer.createdBy === this.user.id)
                .slice(0, 3);
          })
          .catch(error => {
            this.errorMessage = 'Failed to load offers. Try to reload page.'
            console.error(error.message, this)
          })
          .finally(() => {
            this.apiCalled = true;
          });
      },
      getStateLabel(offer) {
        let lastLog;

        if (!offer.log || !offer.log.length) {
          lastLog = offer.state;
        } else {
          lastLog = offer.log[offer.log.length - 1].state;
        }
        return this.states[lastLog];
      },

      getState(offer) {
        let lastLog;
        if (!offer.log || !offer.log.length) {
          lastLog = offer.state;
        } else {
          lastLog = offer.log[offer.log.length - 1].state;
        }
        return lastLog;
      },

      async allFilters (array, filters) {
        let arr = array
        for (let i = 0; i < filters.length; i++) {
          arr = await this.filterItems(arr, filters[i], this.filters[filters[i]].selected)
        }
        return arr
      },

      async filterHandler () {
        this.filteredOffers = await this.allFilters(this.offers, ['userName', 'company', 'brokerName', 'state'])
      },

      filterItems (array, prop, by) {
        const filtered = array.filter((item) => {
          return item[prop].toLowerCase().includes(by.toLowerCase())
        })
        return filtered
      },

      openOffer(offerId) {
        this.$store.commit('SET_INIT_STATUS', 'one-offer');
        try {
          this.$router.push({name: 'AngeboteDetail', params: {offerId: offerId}})
        } catch (error) {
          console.error(error);
        }
      },

      sortBy(rule, offers) {
        const self = this
        const rules = {
          'accepted-first'(offersList) {
            return offersList.sort((a,b) => {
              const acceptedState = 'accepted'
              if (self.getState(a) === acceptedState && self.getState(b) !== acceptedState) {
                return -1
              } else if (self.getState(a) !== acceptedState && self.getState(b) === acceptedState) {
                return 1
              }
              return 0
            })
          }
        }
        return rules[rule](offers)
      },

      isProcessing(processName) {
        if (this.processingState === processName) {
          return true
        }
        return false
      },

      preventEndlessLoading(timeout) {
        setTimeout(() => {
          this.$store.commit('SET_INIT_STATUS', null)
        }, timeout)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.offer-preview {
  overflow: auto;
  background: $whiteColor;

  &.has-loader {
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      width: 100%;
      height: 97%;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;

      @include media-breakpoint-down(xs) {
        width: 100vw;
        left: -1rem;
      }
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 55px;
  }

  &__icon {
    display: none;
  }

  &.show-cut {
    @include media-breakpoint-between(md,lg) {
      position: absolute;
      left: -1rem;
      right: -1rem;
      top: -1rem;
      padding: 1rem;
      min-width: 100%;
      border-radius: 0.5rem;

      .offer-preview__icon {
        display: block;
        position: absolute;
        top: 130px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      @include media-breakpoint-down(md) {
        position: static;
        padding: 0;
      }

      &::after {
        @include media-breakpoint-between(md,lg) {
          position: absolute;
          top: 130px;
          content: '';
          width: 90%;
          height: 40px;
          background: $whiteColor;
          filter: blur(13px);
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &__header {
    font-weight: bold;
    background: $greyBlueDark;
    cursor: pointer;
  }

  &__control-label {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

}

.filter-control {
  display: none;
}

.enable-filters {
  .filter-control {
    display: flex;
  }
}

.filter-btn {
  display: none;
  width: 100%;
  margin-bottom: 0.75rem;

  @include media-breakpoint-down(md) {
    display: block;
  }

  span {
    margin-left: 0.5rem;
  }
}

.offer-preview-table {
  border-collapse:separate;
  border-spacing: 0 0.25rem;
  font-size: 0.875rem;
  width: 100%;
  min-height: 120px;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  .form-control,
  .custom-select {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  th {
    padding: 0.875rem 0.5rem;
  }
  td {
    padding: 0.875rem 0.5rem;
    vertical-align: top;
  }
  td:first-child,
  th:first-child {
    padding: 0.875rem 0.5rem 0.875rem 1rem;
  }
  tbody:before {
      content:"@";
      display:block;
      line-height: 0.25rem;
      text-indent:-99999px;

      @include media-breakpoint-down(md) {
        content: '';
        display: none;
      }
  }
  tbody tr:nth-child(odd) {
    background: $greyBlue;
  }
  tbody tr:nth-child(even) {
    background: $greyBlueLight;
  }

  &__title {
    @include media-breakpoint-up(lg) {
      // margin-top: 23px;
    }
  }

  th:first-child {
    width: 20px;
  }


  .table-row-link {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: $greyBlueDark;
      // box-shadow: 0px 0px 16px rgba(88, 89, 91, 0.24);
    }
  }

  &__customer-col {
    width: 18%;
  }

  td.offer-preview-table__customer-col {
    @include media-breakpoint-down(sm) {
      width: 100%;
      white-space: nowrap
    }
  }

  &__company-col {
    width: 22%;
  }

  &__makler-col {
    width: 18%;
  }

  td.offer-preview-table__company-col {
    @include media-breakpoint-down(sm) {
      width: 100%;
      white-space: nowrap
    }
  }

  td.offer-preview-table__makler-col,
  td.offer-preview-table__status-col {
    @include media-breakpoint-down(sm) {
      width: 100%;
      white-space: nowrap
    }
  }

  &__notice-col {
    width: 15%;
    min-width: 160px;
  }
}

table.offer-preview-table th:first-child{
  border-radius: 0.25rem 0 0 0.25rem;
}

table.offer-preview-table th:last-child{
  border-radius: 0 0.25rem 0.25rem 0;
}

table.offer-preview-table td:first-child{
  border-radius: 0.25rem 0 0 0.25rem;
}

table.offer-preview-table td:last-child{
  border-radius: 0 0.25rem 0.25rem 0;
}

.loader-wrapper {
  text-align: center;
}
</style>
