import Logger from './Logger'

export default function (data, staticData, key) {
    // simple comparsion of lengths && elements of hardcoded and dynamic data.
    // with logger outputting error when lengths/elements are different
    // it is just supposed to act as a warning

    if (key === 'overallBenchmark') {
      if (!data) {
        Logger(`'${key}' is not set`,null, 'error');
      }
      return
    }

    const difference = [];
    const isObjects = !Array.isArray(data) && !Array.isArray(staticData);
    if ((Array.isArray(data) && !Array.isArray(staticData)) ||
      (!Array.isArray(data) && Array.isArray(staticData))) {
      Logger(`'${key}' JSON and dynamic data are not consistent`, [data,staticData], 'error');
      return;
    }

    const results = Array.isArray(data) ? data : Object.keys(data);
    const jsonData = Array.isArray(staticData) ? staticData : Object.keys(staticData);

    jsonData.forEach((element) => {
      let match = null;
      if (isObjects) {
        match = results.find(e => e === element);
      } else {
        match = results.find(e => e.key === element.key || e.insurerKey === element.insurerKey);
      }
      if (!match) {
        difference.push(element);
      }
    });

    if (difference.length > 0) {
      Logger(`${key} has different array length (${difference.length}) than the hardcoded one`, null, 'error');
      difference.forEach((element) => {
        Logger(`${element.insurerKey || element.key || element} is missing in ${key} data`, null, 'error');
      });
    }
  };