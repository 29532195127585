<template>
  <div class="acc-child" :class="`acc-child--nested-lvl-${nestedLvl}`">
    <div class="acc-child__header gap-sm" :class="isExpanded ? '' : 'collapsed'">
      <slot name="header">Header</slot>
    </div>
    <b-collapse
      :id="id"
      v-model="isExpanded"
      :visible="isOpenOnInitComputed"
      :accordion="'acc-' + id"
      @show="handleShowAcc($el)"
      role="tabpanel"
      class="acc-subgroup"
    >
      <div class="acc-child__body syncscroll" name="trTable">
        <slot name="body">Body</slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/components/shared/EventBus.js'

export default {
  name: 'ChildAccordion',
  props: {
    id: String,
    nestedLvl: {
      type: Number,
      default() {
        return 0
      }
    },
    isOpenOnInit: {
      type: Boolean
    },
    isForceOpenOnInit: {
      type: Boolean,
      default: false
    },
    mode: String
  },
  computed: {
    ...mapGetters([
      'style'
    ]),
    isOpenOnInitComputed () {
      const isClosedConditions = (
        // this.props.isOpenOnInit ||
        this.mode === 'broker' ||
        this.mode === 'www' ||
        this.mode === 'offer'
      ) && this.nestedLvl < 1 && !this.isForceOpenOnInit

      return !isClosedConditions;
    }
  },
  data() {
    return {
      isExpanded: this.isForceOpenOnInit
    }
  },
  methods: {
    handleShowAcc ($el) {
      if (this.mode === 'offer') {
        const elements = $el.querySelectorAll('.syncscroll')
        for (let i = 0; i < elements.length; i++) {
          eventBus.$emit('showAccordionSyncScroll', elements[i])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../../scss/print/childAccordion.scss';

.acc-child {
  &__header {
    display: flex;
    gap: 0.5rem;

    button {
      font-size: 1rem;

      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
      }
    }

    .arrow .icon {
      @include media-breakpoint-down(sm) {
        color: $primary;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }

  &--nested-lvl-3 {
    .acc-child__header {
      position: sticky;
      left: 0;
      max-width: calc(100vw - 1rem);
    }

    .trigger-wrap.w-full {
      position: sticky;
      left: 0;
      max-width: calc(100vw - 1.25rem);

      @include media-breakpoint-down(xs) {
        max-width: calc(100vw - 2.85rem);
      }
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: #fff;
  box-shadow: 0px 36px 14px rgba(0, 0, 0, 0.01),
    0px 20px 12px rgba(0, 0, 0, 0.05);
  border-radius: 20rem;
  margin-right: 1rem;

  @include media-breakpoint-down(sm) {
    background: none;
    box-shadow: none;
    padding: 0;
    margin-right: 1rem;
    width: auto;
  }

  .icon {
    transition: transform 0.4s;
    color: $primary;

    @include media-breakpoint-down(sm) {
      color: #fff;
    }
  }
}

.info-icon {
  margin-top: -12px;
}
</style>
