var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container secondary-page"},[_c('div',{staticClass:"row page-title"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-2 back-btn"},[_c('router-link',{staticClass:"btn-style",attrs:{"to":'/'}},[_vm._v("←Zurück")])],1)]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-info text-center affiliate-link-box"},[_c('a',{staticClass:"affiliate-link",attrs:{"href":_vm.affiliateLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.affiliateLink))]),_c('div',[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.onCopyClicked}},[_vm._v("Link kopieren")])])])])]),_c('div',{staticClass:"row question"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"question__title h1"},[_vm._v(" Wie funktioniert der Affiliate Link? ")]),_c('div',{staticClass:"question__text"},[_c('p',[_vm._v(" Wenn ein Kunde auf den Link klickt, gelangt er zu einer vereinfachten Version des Marktvergleichs, wo er ein Angebot erst rechnen und dann bei Ihnen anfordern kann. Sie als Vermittler erhalten darüber eine Nachricht und können direkt in Kontakt treten. Der Kunde kann das Angebot annehmen, aber nicht ohne Ihre Beteiligung einreichen. Die Angebote können Sie wie gewohnt unter der Kachel "),_c('router-link',{attrs:{"to":"/offers"}},[_vm._v("“Angebote”")]),_vm._v(" einsehen und abschließend bearbeiten. ")],1)])])]),_vm._m(3),_c('div',{staticClass:"text-center mt-4 mb-4"},[_c('icon',{attrs:{"name":"arrow-down","color":"primary","width":32}})],1),_vm._m(4),_c('div',{staticClass:"text-center mt-4 mb-4"},[_c('icon',{attrs:{"name":"arrow-down","color":"primary","width":32}})],1),_vm._m(5),_c('div',{staticClass:"text-center mt-4 mb-4"},[_c('icon',{attrs:{"name":"arrow-down","color":"primary","width":32}})],1),_vm._m(6)]),_c('BackToTop')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h1',[_vm._v("Was ist ein Affiliate Link?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row question"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"question__text"},[_c('p',[_vm._v("Affiliate-Links sind ein wesentlicher Bestandteil des modernen Marketings, insbesondere wenn es darum geht, neue Kunden zu gewinnen. Dieser Prozess ermöglicht es Ihnen, Leads schnell und effektiv zu generieren.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row question"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"question__title h1"},[_vm._v(" Wo platziere ich den Affiliate Link? ")]),_c('div',{staticClass:"question__text"},[_c('p',[_vm._v(" Indem Sie den Affiliate-Link innerhalb Ihrer Webseite platzieren, ermöglichen Sie es Ihren Kunden, einen Vergleich von Cyber-Versicherungen durchzuführen. Der speziell für sie erstellte Link kann auch in Ihren Social-Media-Beiträgen (z.B. LinkedIn) oder Newslettern verwendet werden, um die Reichweite zu erhöhen und potenzielle Kunden anzusprechen. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"step__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/static/affiliate-1.jpg"}})]),_c('div',{staticClass:"step__text"},[_c('p',[_vm._v(" Dies ist die Ansicht Ihres Kunden.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"step__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/static/affiliate-2.jpg"}})]),_c('div',{staticClass:"step__text"},[_c('p',[_vm._v("Nachdem Ihr Kunde “Angebot anfordern” klickt, öffnet sich dieses Fenster. Hier gibt Ihr Kunde seine/ihre Daten ein.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"step__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/static/affiliate-3.png"}})]),_c('div',{staticClass:"step__text"},[_c('p',[_vm._v("Ihr Kunde erhält daraufhin eine Bestätigungs-Email. Gleichzeitig erhalten Sie als Makler eine Benachrichtigung über die Anfrage des Kunden und können dies im Maklerportal einsehen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"step__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/static/affiliate-4.png"}})]),_c('div',{staticClass:"step__text"},[_c('p',[_vm._v("Die Folgeschritte der Angebotsfunktion stehen ab diesem Punkt zur Verfügung. Sie können dem Kunden das Angebot über einen personalisierten Link (in Email kopieren) zur Verfügung stellen. Der Kunde kann über den Link die Risikofragen beantworten und bestätigen, dass er/sie die Versicherung abschließen möchte. Jetzt erhalten Sie wieder eine Benachrichtigung per Email Im Anschluss können Sie als Makler den Abschluss der Versicherung im Auftrag ihres Kunden durchführen. Sie erhalten (und auf Wunsch auch Ihr Kunde) die Antragsbestätigung sowie Versicherungsdokumente per Email.")])])])
}]

export { render, staticRenderFns }