// list is the list if insurers to be sortedIndex
// criteria is the criteria to sort by
// alternativeData is to distinguish between twoi different data structures
// EXPORT
export default {

  getSortedInsurers(list, criteria, alternativeData = false) {
    return list.sort((a, b) => {
      // insurer data come in two different forms, we have to treat both cases
      // so we remap values based on whats available
      const aKey = alternativeData ? String(a.insurer.label).toLowerCase() : String(a.label).toLowerCase()
      const bKey = alternativeData ? String(b.insurer.label).toLowerCase() : String(b.label).toLowerCase()
      const aScore = alternativeData ? a.sumScore : a.score
      const bScore = alternativeData ? b.sumScore : b.score
      const aPrice = alternativeData ? a.grossPrice : a.prices.gross
      const bPrice = alternativeData ? b.grossPrice : b.prices.gross

      switch (criteria) {
        case 'abc':
          if(!aKey || !bKey) {
            // console.error('missing key for ', aKey ? bKey : aKey)
          }
          if (aKey < bKey) {
            return -1
          }
          if (aKey > bKey) {
            return 1
          }
          return 0
        case 'score':
          if(!aScore || !bScore) {
            // console.error('score is missing for ', aScore ? bScore : aScore, 'insurer key: ', aKey ? bKey : aKey)
            // console.error('a', a)
            // console.error('b', b)
          }
          if (aScore > bScore) {
            return -1
          } else if (aScore < bScore) {
            return 1
          }
          return 0
        case 'price':
          if(!aPrice || !bPrice) {
            console.error('price is missing for ', aPrice ? bPrice : aPrice, 'insurer key: ', aKey ? bKey : aKey)
          }
          if (aPrice < bPrice) {
            return -1
          } else if (aPrice > bPrice) {
            return 1
          }
          return 0
        case 'price-score-ratio':
          if(!aPrice || !bPrice) {
            console.error('price is missing for ', aPrice ? bPrice : aPrice, 'insurer key: ', aKey ? bKey : aKey)
          }
          if(!aScore || !bScore) {
            // console.error('score is missing for ', aScore ? bScore : aScore, 'insurer key: ', aKey ? bKey : aKey)
            // console.error('a', a)
            // console.error('b', b)
          }

          const aRatio = aPrice / aScore
          const bRatio = bPrice / bScore

          if (aRatio < bRatio) {
            return -1
          } else if (aRatio > bRatio) {
            return 1
          }
          return 0
        case 'questions-amount':
          if (a.questionsCount < b.questionsCount) {
            return -1
          } else if (a.questionsCount > b.questionsCount) {
            return 1
          } else if(!a.questionsCount || !b.questionsCount) {
            console.error('questionsCount is missing for ', aKey)
            console.log(a)
            return 0
          }

          return 0
      }
    })
  },
}
