<template>
    <v-touch @swipeleft="next"
             @swiperight="back"
             :swipe-options="{ direction: 'horizontal' }"
             class="course-base-template iphone-update">
        <h2 class="course-base-template__title">{{ content.title }}</h2>
        <div class="iphone-update__image-wrapper"
             ref="container">
            <div class="iphone-update__image-holder">
                <img class="iphone-update__image"
                     v-for="(image, index) in images"
                     :class="{ 'iphone-update__image--active': index === currentIndex }"
                     :key="index"
                     alt="image"
                     :src="require(`@/pages/trainings/assets/courses/5-golden-rules/iphone-update/${image.name}`)">
            </div>
            <div class="custom-template__highlight-area"
                 @click="nextSlide"
                 :style="currentSlide.style">
                <icon name="arrow-click"
                      color="secondary"
                      class="custom-template__highlight-arrow"
                      :size="40">
                </icon>
            </div>
            <div class="custom-template__tooltip"
                 v-if="currentSlide.tooltipText"
                 :style="currentSlide.tooltipStyle"
                 v-tooltip="{
                   content: currentSlide.tooltipText,
                   classes: 'custom-template__tooltip-content',
                   trigger: 'hover click',
                 }">
                <icon name="info"
                      :size="26"
                      color="white"></icon>
            </div>
        </div>

        <div class="row mb-15">
            <div class="col-6">
                <button class="btn btn--secondary btn--border"
                        type="button"
                        name="back"
                        @click="back">Zurück
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn--secondary"
                        type="button"
                        name="next"
                        @click="next">Weiter
                </button>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue'
  import {throttle} from 'lodash';

  function calc() {
    let width = this.$refs.container && this.$refs.container.clientWidth || this.maxWidth;
    width = Math.min(width, window.innerWidth, this.maxWidth);

    this.currentWidth = width;
  }

  export default {
    name: 'iphone-update',
    components: {
      Icon
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        maxWidth: 414,
        currentWidth: 414,
        images: [
          {
            name: '01.png',
            highlightArea: [90, 102, 7.2, 74.2],
            tooltipText: 'Klicken Sie auf "Einstellungen"',
            tooltipPosition: [25, 85]
          },
          {
            name: '02.png',
            highlightArea: [390, 38, 81.5, 3.5],
            tooltipText: 'Klicken Sie auf "iTunes & App Store"',
            tooltipPosition: [75, 82]
          },
          {
            name: '03.png',
            highlightArea: [390, 39, 33.2, 3.5],
            tooltipText: 'Aktivieren Sie "Automatische Downloads" für "Updates" in dem Sie auf den Regler klicken. ' +
              'Von nun an werden alle Apps automatisch aktualisiert.',
            tooltipPosition: [39.5, 84]
          },
          {
            name: '04.png',
            highlightArea: [414, 896, 0, 0],
            tooltipText: '',
            tooltipPosition: [0, 0]
          }
        ],
        currentIndex: 0
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.calc();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    computed: {
      scaleRatio() {
        return `scale(${this.currentWidth / this.maxWidth})`;
      },
      slideNumber() {
        return +this.$route.params.slide;
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      },
      currentSlide() {
        const {
          name,
          highlightArea: [w, h, t, l],
          tooltipText,
          tooltipPosition: [tooltipTop, tooltipLeft]
        } = this.images[this.currentIndex];

        return {
          name,
          style: {
            width: w + 'px',
            height: h + 'px',
            top: t + '%',
            left: l + '%',
            transform: this.scaleRatio
          },
          tooltipText,
          tooltipStyle: {
            top: tooltipTop + '%',
            left: tooltipLeft + '%',
            transform: this.scaleRatio
          }
        };
      }
    },
    methods: {
      calc,
      onResize: throttle(calc, 100),
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      next() {
        this.$router.push({
          params: { ...this.$route.params, slide: this.nextSlideNumber }
        });
      },
      nextSlide() {
        this.currentIndex === (this.images.length - 1) ? this.next() : this.currentIndex++;
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .iphone-update {
        &__image-wrapper {
            width: 100%;
            max-width: 414px;
            position: relative;
            margin: 0 auto 30px;
        }

        &__image-holder {
            padding-top: 216.42%;
        }

        &__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            vertical-align: top;
            max-width: 100%;
            opacity: 0;

            &--active {
                opacity: 1;
            }
        }
    }
</style>
