<template>
  <form @submit.prevent.stop="submitAvv()">
    <Modal2 v-if="modalData.termOpen" @close="modalData.termOpen = false" :closeable="this.orderProcessingChecked">
      <h1
        slot="header"
        class="MsoNormal-bold"
        align="center"
        style="text-align: center; line-height: normal; font-weight: bold">
        Auftragsdatenverarbeitungsvertrag CyberDirekt GmbH Version 5.40</h1>
      <template slot="body">
        <BodyAvv :avvData="{ avvAccepted, avvAcceptedOn }"/>
      </template>
      <template slot="footer">
        <!-- <button type="submit" class="btn-style primary" :disabled="!avvAccepted">Fenster schließen</button> -->
        <div class="submition-row">
          <div class="submit-btn">
            <ButtonComponent
            btnStyle="accent"
            btnSize="sm"
            behavior="button"
            type="submit"
            :disabled="!avvAccepted || orderProcessingChecked"
          >
            Fenster schließen
          </ButtonComponent>
          </div>
          <div>
            <b-form-checkbox
              id="checkbox-avv"
              name="avvAccepted"
              v-model="avvAccepted"
              required
              :disabled="orderProcessingChecked"
            >
              Ich akzeptiere den Auftragsverarbeitungsvertrag.
            </b-form-checkbox>
          </div>
        </div>

        <ButtonComponent
        @click.native="dwnld()"
        btnStyle="link-primary"
        btnSize="sm"
        behavior="button"
        type="button"
        class="print-btn"
      >
        Jetzt herunterladen
      </ButtonComponent>
      </template>
    </Modal2>
  </form>
</template>

<script>
import Modal2 from '@/components/Modal2'
import ButtonComponent from '@/components/reusable/ButtonComponent'
import BodyAvv from '@/components/BodyAvv'
import brokerApi from '../api/broker';
import { mapGetters } from 'vuex'
import Axios from 'axios'

export default {
  components: {
    Modal2,
    ButtonComponent,
    BodyAvv
  },
  data() {
    return {
      modalData: {
        isCourtageVisible: true,
        termOpen: true,
        closeable: false
      },
      avvAccepted: false,
      avvAcceptedOn: null
    }
  },
  computed: {
    orderProcessingChecked () {
      return this.user.orderProcessingChecked
    },
    ...mapGetters(['user']),
  },
  methods: {
    print () {
      window.print()
    },
    dwnld() {
      const url = 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/AVV_Cyberdirekt_Gmbh_V_5_40.pdf'
      const label = 'AVV_Cyberdirekt_GmbH_V_5_40'
      Axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(e => {
          console.error(e)
        })
    },
    submitAvv() {
      const self = this;

      this.$store
        .dispatch('UPDATE_USER', {
          orderProcessingChecked: this.avvAccepted,
          orderProcessingCheckedOn: Date.now()
        })
        .then(() => {
          self.modalData.termOpen = false
          self.$emit('accept')
        })
      return
    }
  },
  async mounted () {
      if(this.user) {
        const userAvvData =  await brokerApi.getAVVState()
        if (userAvvData.hasAgreed) {
          this.avvAccepted = userAvvData.orderProcessingChecked
          this.avvAcceptedOn = userAvvData.orderProcessingCheckedOn
        }
      }
    },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
    word-break: break-word;

  }
}
.table {
  overflow: auto;
}
::v-deep .modal2-wrapper {
  max-width: 100%;
}
::v-deep .modal2-container {
  max-width: 1140px;
}
::v-deep .modal2-footer,
::v-deep .submition-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 1rem;

  @include media-breakpoint-down(xs) {
    flex-direction: column-reverse;
  }
}

::v-deep .modal2-footer {
  margin-top: 2rem;
  flex-wrap: wrap;

  @include media-breakpoint-down(xs) {
    gap: 0.5rem;
  }
}

::v-deep .submition-row {
}

::v-deep .print-btn {
  min-width: auto;

  @include media-breakpoint-down(xs) {
    order: -1;
  }
}
</style>
