<template>
  <div>
    <div class="row webinar my-md-4">
      <div class="col-md-8">
        <VideoPlayer
          v-if="videoOptions.sources[0].src && videoOptions.sources[0].src !== 'null'"
          :options="videoOptions"
          :poster="getImageUrl()"
        />
        <img v-else :src="webinarData.cover_image.url" alt="Webinar cover" class="webinar__cover">
      </div>
      <div class="col-md-4">
        <div class="webinar__content h-100">
          <div class="row h-100">
            <h2 class="webinar__section--title col-6 align-self-baseline">{{webinarData.upper_text || ''}}</h2>
            <div class="webinar__date-holder col-6 align-self-baseline">
              <span class="webinar__date"> {{webinarData.date_of_creation || ''}}</span>
            </div>
            <div class="col-12">
              <h4 class="webinar__title--sub align-self-baseline">{{webinarData.text_1 || ''}}</h4>
              <div v-if="webinarData.text_2" v-html="webinarData.text_2" class="webinar__text"></div>
              <p class="webinar__text">{{webinarData.text_3 || ''}}</p>
            </div>
            <div class="webinar__link col-12 mt-3 align-self-end">
              <ButtonComponent
                v-if="webinarData.button"
                :btnStyle="webinarData.button.style"
                :behavior="webinarData.button.behavior"
                :link="webinarData.button.link"
              >
                {{ webinarData.button.title }}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue'
import formats from '../helpers/formats'
import ButtonComponent from '@/components/reusable/ButtonComponent'

export default {
  name: 'webinar',
  components:{
    VideoPlayer,
    ButtonComponent
  },
  props: {
    webinarData:{
      type:Object,
      required: true
    }
  },
  data() {
    return {
      placeholder: '/static/vectors/placeholder.png',
      videoOptions: {
        autoplay: true,
        controls: true,
        autoplay: false,
        sources: [
          {
            /* 
            for historical reasons we need to consider that the resource for the video
            can come from two different sources. if the first one is missing, then take the second one 'video_url'. 
            this should be removed after we transfer all the neccessary files to the new resource
            */
            src: this.webinarData.video_file ? this.webinarData.video_file.url : this.webinarData.video_url ? this.webinarData.video_url : 'null',
            type: 'video/mp4',
          },
        ],
      },
    }
  },
  methods: {
    getImageUrl() {
      const url = formats(this.webinarData.cover_image, 'small') 
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.webinar {
  $corner-radius: 7px;
  $table-border-color: $greyTone;

  border-radius: $corner-radius;
  margin-bottom: 40px;

  &:not(&--inactive):hover {
    transition: all 0.2s;
  }

  &__cover {
    width: 100%;
  }

  &__content {
    padding: 17px 13px 24px;
    border-radius: 0 0 $corner-radius $corner-radius;
    @include media-breakpoint-down(sm) {
      border: solid $table-border-color;
      border-width: 0 1px 1px;
    }
  }

  &__title--sub {
    font-size: 1.025rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 0 0 15px;
  }

  &__text + &__text {
    margin-top: 1rem;
  }

  &__section {
    font-size: 0.715rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: $secondary;
    text-transform: uppercase;
  }

  &__section--title {
    font-size: 1.115rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: $secondary;
    text-transform: uppercase;
  }

  &__date-holder {
    font-size: 0.8rem;
    line-height: 1.125rem;
    margin-bottom: 15px;
    text-align: right;
  }

  &__date {
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
  }
}
</style>
