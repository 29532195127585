<template>
    <course-base-template :title="progress + slide.title"
                          :subtitle="slide.subtitle"
                          :course-slug="courseSlug"
                          :icon="slideIcon"
                          :text="slideText"
                          @back="back"
                          @next="next"></course-base-template>
</template>

<script>
  import CourseBaseTemplate from './CourseBaseTemplate.vue';
  import {get as getValue, isEqual} from 'lodash';

  export default {
    name: 'multi-step-template',
    components: {
      CourseBaseTemplate
    },
    props: {
      slide: {
        type: Object,
        required: true
      },
      courseSlug: String
    },
    data() {
      return {
        isEnd: false
      };
    },
    created() {
      this.setInitialStep(this.$route, this.$routeFrom);
    },
    watch: {
      '$route'(to, from) {
        this.setInitialStep(to, from);
      }
    },
    computed: {
      slideNumber() {
        return +this.$route.params.slide;
      },
      stepNumber() {
        return +this.$route.params.step || 1;
      },
      progress() {
        return `${this.stepNumber}/${this.slide.steps.length} `;
      },
      stepIndex() {
        return this.stepNumber - 1;
      },
      currectStep() {
        return this.slide.steps[this.stepIndex] || {};
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      },
      slideIcon() {
        return this.currectStep.icon;
      },
      slideText() {
        return this.slide.steps.reduce((html, cur, index) => {
          const classes = [];

          if (this.isEnd || index === this.stepIndex) {
            classes.push('multi-step-template__step--active');
          } else if (index > this.stepIndex) {
            classes.push('multi-step-template__step--hidden');
          }

          return html + `<p class="multi-step-template__step ${classes.join(' ')}">${cur.text}</p>`
        }, '');
      },
      $routeFrom() {
        return this.$store.state.$routeFrom;
      }
    },
    methods: {
      setInitialStep(to, from) {
        if (!to.params.step) {
          const defaultStep = to.params.slide < getValue(from, 'params.slide', 0) ? this.slide.steps.length : 1;

          this.$router.replace({
            params: {
              ...to.params,
              step: defaultStep
            }
          });
        }
      },
      back() {
        if (this.isEnd) {
          this.isEnd = false;
          return;
        }

        const prevStep = this.stepIndex > 0 ? this.stepIndex : null;
        const prevSlide = prevStep ? this.slideNumber : (this.prevSlideNumber > 0 ? this.prevSlideNumber : null);
        const params = {
          ...this.$route.params,
          slide: prevSlide,
          step: prevStep
        };

        this.$router.push({params});
      },
      next() {
        const nextStep = this.stepNumber < this.slide.steps.length ? this.stepNumber + 1 : this.isEnd ? null : this.stepNumber;
        const nextSlide = nextStep ? this.slideNumber : this.nextSlideNumber;
        const params = {
          ...this.$route.params,
          slide: nextSlide,
          step: nextStep
        };

        if (!isEqual(params, this.$route.params)) {
          this.$router.push({params});
        }

        this.isEnd = nextStep && this.stepNumber >= this.slide.steps.length;
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .multi-step-template {
        &__step {
            margin: 0 0 10px;
            color: $disabled-color;
            position: relative;

            &:before {
                content: '';
                border-color: transparent transparent transparent $disabled-color;
                border-width: 0.45rem 0 0.45rem 0.8rem;
                border-style: solid;
                position: absolute;
                top: 0.65rem;
                right: 100%;
                margin-right: 10px;
                transform: translateY(-50%);
            }

            &--hidden {
                visibility: hidden;
            }

            &--active {
                color: $dark-blue;

                &:before {
                    border-left-color: $secondary;
                }
            }
        }
    }
</style>
