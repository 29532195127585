<template>
    <v-touch @swipeleft="next"
             @swiperight="back"
             :swipe-options="{ direction: 'horizontal' }"
             class="course-base-template">
        <h2 class="course-base-template__title">{{ title }}</h2>
        <div class="course-base-template__mid-icon text--center" v-if="midIcon">
            <course-icon :name="midIcon"
                         :colors="['primary', 'dark']"></course-icon>
        </div>
        <h3 class="course-base-template__subtitle"
            :class="{'course-base-template__subtitle--fill-empty': midIcon}">{{ subtitle }}</h3>
        <div class="course-base-template__content flex flex--v-center between-sm"
             v-if="icon || text">
            <div class="course-base-template__icon">
                <course-icon :name="icon"
                             :course-slug="courseSlug"
                             :size="193"
                             :colors="['primary', 'dark']"></course-icon>
            </div>
            <div class="course-base-template__text"
                 v-html="text"></div>
        </div>
        <div class="row mb-15">
            <div class="col-6">
                <button class="btn btn--secondary btn--border"
                        type="button"
                        name="back"
                        @click="back">{{ backText }}
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn--secondary"
                        type="button"
                        name="next"
                        @click="next">{{ nextText }}
                </button>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import CourseIcon from './CourseIcon.vue';

  export default {
    name: 'course-base-template',
    components: {
      CourseIcon
    },
    props: {
      title: String,
      midIcon: String,
      subtitle: String,
      courseSlug: String,
      icon: String,
      text: String,
      backText: {
        type: String,
        default() {
          return 'Zurück';
        }
      },
      nextText: {
        type: String,
        default() {
          return 'Weiter';
        }
      }
    },
    methods: {
      back() {
        this.$emit('back');
      },
      next() {
        this.$emit('next');
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/base';
    @import '../../../scss/trainings-variables';

    .course-base-template {
        &__title {
            font-size: 1.5rem;
            line-height: 1.75rem;
            font-weight: 500;
            margin: 0 0 32px;
            color: $dark-blue;
            text-align: center;
        }

        &__mid-icon {
            padding: 22px 0;
        }

        &__subtitle {
            font-size: 1.125rem;
            line-height: 1.25rem;
            min-height: 1.25rem;
            font-weight: 500;
            margin: 0 0 35px;
            color: $primary;
            text-align: center;

            &--fill-empty {
                min-height: 2.5rem;
                margin-bottom: 65px;
            }
        }

        &__content {
            padding: 0 0 0 28px;
            min-height: 237px;
            margin-bottom: 57px;
        }

        &__icon {
            width: 193px;
        }

        &__text {
            width: calc(100% - 285px);
            font-size: 1rem;
            line-height: 1.25rem;
            color: $dark-blue;
        }

        .btn {
            font-size: 1rem;
            padding: 9px;
            min-width: 207px;
            border-radius: 8px;
        }

        @media screen and (min-width: 768px) {
            &__title,
            &__subtitle,
            &__text {
                user-select: text;
            }
        }

        @media screen and (max-width: 767px) {
            .btn {
                font-size: 1rem;
                padding: 7px;
                min-width: 120px;
                border-radius: 8px;
            }

            &__title {
                margin: 0 0 15px;
            }

            &__subtitle {
                margin: 0 0 20px;
            }

            &__content {
                padding: 0 15px;
                margin-bottom: 15px;
                min-height: 50vh;
            }

            &__icon {
                width: 150px;
                margin: 15px auto;
                align-self: start;

                .course-icon > svg {
                    height: 150px;
                    width: 150px;
                }
            }

            &__text {
                width: 100%;
            }
        }
    }

    p {
        font-size: .975rem;
        line-height: 1.425rem;
        color: #667075;
        margin: 0 0 14px;
    }
</style>
