<template>
  <div class="logo">
    <img :src="logo" v-if="logo && logo !== 'default'" alt="logo" class="img-fluid" />
    <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_blue.svg" v-else-if="logo && logo === 'default'" alt="CyberDirekt logo" class="default-logo"/>
  </div>
</template>

<script>

export default {
  name:"OfferLogo",
  props: {
    offer: {
      type:Object,
      default: null
    }
  },
  computed:{
    logo() {
      if (this.offer && this.offer.logo) {
        if (this.offer.logo === 'no-logo') return null
        return this.offer.logo;
      }
      return 'default';
    },
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 340px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
