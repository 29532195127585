<template>
  <div class="banners-print mt-4 mb-4">
    <div v-for="(insurer, iIndex) in innerInsurers" :key="`print-${iIndex}`" class="insurer-banners">
      <div v-if="getBanner(insurer).length" v-for="bnr in getBanner(insurer)" :key="bnr.text" class="mb-4">
        <div class="table-heading" v-if="bnr.long && bnr.long !== '<p></p>'">
          <div class="table-heading__title">
            Sonstige Informationen -&nbsp;<b>{{ insurer.label }}</b>
          </div>

          <div
              class="custom-badge offer-badge insurer__badge table-heading__badge"
              :class="[
              getBannerClass(insurer),
              getLabelStyle(bnr.style),
              getLabelColor(bnr)
            ]"
          >
            {{ bnr.text }}
          </div>
        </div>

        <div v-if="bnr.long && bnr.long !== '<p></p>'" class="insurer__badge-box" v-html="bnr.long" />
      </div>
    </div>
  </div>
</template>

<script>
import { get as getValue } from 'lodash'
import bannersLogic from '../../../mixins/banners.js'

export default {
  mixins: [bannersLogic],
  props: {
    insurers: {
      type: Array,
      default() {
        return []
      }
    },
    synaxon: Object,
    checkoutButtonIsVisible: Boolean,
    affiliateBroker: Object,
    order1: String,
    order2: String,
    isBroker: Boolean,
    isOffer: Boolean,
    mode: String,
    offerSelection: Array,
    maxOffers: Number
  },
  data() {
    return {
      innerInsurers: this.insurers,
      offerSelectionInner: this.offerSelection
    }
  },
  watch: {
    offerSelectionInner(newValue) {
      this.$emit('updateOfferSelection', newValue)
    },
    insurers: {
      handler(newvalue) {
        this.innerInsurers = newvalue
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    getLabelColor(banner) {
      return banner.color === 'red'
        ? 'insurer__badge--red'
        : banner.color === 'yellow'
          ? 'insurer__badge--yellow'
          : banner.color === 'gray'
            ? 'insurer__badge--gray'
            : null
    },
    getInsurerKey(key) {
      return key.indexOf('victor') > -1 ? 'victor' : key
    },

    shouldShowArtusBanner(insurer) {
      const isArtus =
        this.$store.state.user &&
        this.$store.state.user.brokerAgency &&
        this.$store.state.user.brokerAgency.key &&
        this.$store.state.user.brokerAgency.key.includes('artus')
      const offerKey = getValue(this.$store.state, 'offer.offer.key')
      const isArtusCampaign = offerKey && offerKey.includes('artus')

      return (
        (isArtus || isArtusCampaign) &&
        ['aig', 'hiscox'].indexOf(insurer.key) > -1
      )
    },

    getInsurerLogoPath(insurer) {
      return insurer.logoUrl
    },

    getLabelStyle(data) {
      if (
        data != null &&
        this.affiliateBroker &&
        this.affiliateBroker.agency == data
      ) {
        return `insurer__label--${data}`
      }
    },

    selectInsurer(insurer) {
      this.$emit('selectInsurer', insurer)
    },

    forceUpdateInsurers() {
      this.innerInsurers = this.insurers
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/tooltip';
@import '../../../scss/form';
@import '../../../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../scss/print/insurersPrint.scss';

.banners-print {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  ul {
    list-style-type: none !important;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    background: $orangeTone;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .insurer__badge-box {
    ul {
      list-style-type: none !important;
      padding: 0;
      margin: 0;
    }

    li {
      position: relative;
      padding-left: 16px;
    }

    li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
      background: $orangeTone;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  table {
    width: 100%;
    border-spacing: 0.2cm;
    border-collapse: separate;
  }

  .table-heading {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    display: flex !important;
    height: 48px;
    align-items: stretch !important;
  }

  .table-heading__title,
  .table-heading__badge {
    font-size: 16px !important;
    padding: 0.3rem 1.25rem;
    border-radius: 0.25rem;
    height: 100%;
    display: flex !important;
    align-items: center !important;
  }

  .table-heading__title {
    background: #31a9e0;
    margin-right: 8px;
    width: 100%;
    flex-grow: 1;
  }

  .table-heading__badge {
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-align: center;
  }

  .first-col {
    min-width: 340px;
    max-width: 340px;
    width: 340px;
  }

  .tb-cell {
    // width: 17.5%;
    min-width: 155px;
    max-width: 155px;
    background: $primary-lighter;
    border-radius: 0.25rem;
    padding: 1rem 0.75rem;
    font-size: 0.85rem;
    text-align: center;
  }

  //

  .insurers {
    display: flex;
    gap: 1rem;

    @include media-breakpoint-down(sm) {
      gap: 0.5rem;
    }
  }

  .insurers-space {
    @include media-breakpoint-down(sm) {
      margin-left: 298px;
    }
  }


  .logo img {
    // min-height: 65px;
    max-width: 120px;
  }

  .badge {
    margin-bottom: 10px;
  }

  .insurer-banners {
    & + & {
      margin-top: 1.25rem;
    }
  }

  .insurer {
    position: relative;

    &__label--vodafone {
      background: #e60000 !important;
    }

    &__head {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &__banner {
      padding: 2px;
      width: 100%;
      background: $primary;
      color: #fff;
      font-weight: bold;
      font-size: 0.8rem;
      text-align: center;

      &--warning {
        background: $redTone;
      }

      &--green {
        background: $greenTone;
      }

      &--artus {
        background: #ffb200;
        position: absolute;
        top: -20px;
      }

      &--synaxon {
        background: $secondary;
      }
    }

    &__image {
      height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: auto;

      @include media-breakpoint-down(sm) {
        margin-bottom: 1.5rem;
      }

      img {
        margin: 0 auto;
        display: block;
        // width: 100%;
        width: auto;
        max-width: 85px;
        max-height: 100%;

        @include media-breakpoint-up(md) {
          max-width: 100px;
          max-height: 65px;
        }
      }

      &--aig {
        img {
          width: 100px;
        }
      }

      &--markel {
        img {
          max-height: 60px;
          width: auto;
        }
      }

      &--hdi {
        img {
          height: 40px;
        }
      }

      &--hdi-vodafone {
        img {
          height: 40px;
        }
      }

      &--axa {
        img {
          height: 40px;
        }
      }

      &--allianz {
        img {
          min-width: 120px;
        }
      }

      &--victor {
        img {
          height: 60px;
        }
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__badge-box {
      background: $primary-lightest;
      padding: 1rem 1.5rem;
      border-radius: 0.25rem;

      & + & {
        margin-top: 0.75rem;
      }
    }

    &__badge-title {
      font-weight: 600;
    }

    &__badge {
      display: inline-block;
      background: $success;
      color: #fff;
      font-size: 0.8rem;
      padding: 0.25em 0.4em;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
    }

    &__select {
      height: 50px;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      &__top {
        height: 50%;
      }

      &__bottom {
        height: 50%;
      }
    }

    &__offer-check {
      height: 24px;
    }

    .custom-checkbox {
      margin-bottom: 4rem;

      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

    &--disabled {
      & > *:not(.insurer__bottom) {
        opacity: 0.5;
        filter: grayscale(100%);
      }

      .insurer__badge {
        position: static;
        background: $redTone;
      }

      .action {
        opacity: 0.5;
        filter: grayscale(100%);
      }
    }
  }

  .insurer__badge {
    //position: static;
    //display: inline-block;
    //bottom: auto;
    //border-radius: 0.25rem;
    //text-align: center;

    &--red {
      background: rgb(220, 53, 69);
    }

    &--yellow {
      background: rgb(232, 175, 8);
    }

    &--gray {
      background: rgb(134, 142, 150);
    }
  }
}
</style>
