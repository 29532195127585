<template>
    <div class="secondary-page">
        <div class="container">
            <div class="row page-title">
                <div class="col-12 col-md-10">
                    <h1>Angebote</h1>
                </div>
                <div class="col-12 col-md-2 back-btn">
                    <a class="btn-style" href="" @click.prevent.stop="takeMeBack()">&larr;Zurück</a>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-secondary mb-3"
                            v-if="isFilterSet"
                            type="button"
                            @click="resetFilter()">
                        <icon name="cross" color="white"></icon>
                        Filter zurücksetzen
                    </button>
                    <div class="alert alert-danger d-flex flex-row align-items-center justify-content-between"
                         v-if="deleteSelection.length > 0"
                         role="alert">
                        <div>{{deletionText}}</div>
                        <button class="btn-icon" @click="deleteOffers()">
                            <icon name="delete" color="dark-grey"></icon>
                        </button>
                    </div>
                    <div class="table-wrapper">
                      <table class="table table-hover">
                        <thead class="thead-light">
                          <tr>
                              <th class="vertical-middle">
                                  <!--<div class="custom-control custom-checkbox">-->
                                  <!--<input type="checkbox"-->
                                  <!--class="custom-control-input"-->
                                  <!--id="delete-all"-->
                                  <!--name="delete-all">-->
                                  <!--<label class="custom-control-label" for="delete-all"></label>-->
                                  <!--</div>-->
                              </th>
                              <th scope="col">
                                  <div>Datum</div>
                              </th>
                              <th scope="col">
                                  <div>Kunde</div>
                                  <div class="position-relative search">
                                    <input class="form-control"
                                           type="text"
                                           id="customerFilter"
                                           name="customerFilter"
                                           v-model="filter.customer.selected"
                                           placeholder="Suche"
                                    >
                                    <Icon name="search" color="secondary" :width="28" :height="28" />
                                  </div>
                              </th>
                              <th scope="col">
                                  <div>Firma</div>
                                  <div class="position-relative search">
                                    <input class="form-control"
                                           type="text"
                                           id="companyFilter"
                                           name="companyFilter"
                                           v-model="filter.company.selected"
                                           placeholder="Suche"
                                    >
                                    <Icon name="search" color="secondary" :width="28" :height="28" />
                                  </div>
                              </th>
                              <th scope="col" v-if="isAgencyColumnVisible">
                                  <div>Agentur</div>
                                  <select class="form-control"
                                          name="agencyFilter"
                                          id="agencyFilter"
                                          v-model="filter.agencies.selected">
                                      <option v-for="agency in filter.agencies.list">{{agency}}</option>
                                  </select>
                              </th>
                              <th scope="col" v-if="isBrokerColumnVisible">
                                  <div>Makler:in</div>
                                  <vue-multiselect
                                      v-model="filter.brokers.selected"
                                      :options="filter.brokers.list"
                                      name="brokerFilter"
                                      id="brokerFilter"
                                      selectLabel=""
                                      placeholder=""
                                  />
                              </th>
                              <th scope="col">
                                  <div>Status</div>
                                  <vue-multiselect
                                      name="stateFilter"
                                      id="stateFilter"
                                      v-model="filter.states.selected"
                                      :options="filter.states.list.map(item => states[item])"
                                      selectLabel=""
                                      placeholder=""
                                 />
                              </th>
                              <th scope="col">
                                  <div>Interne Notiz</div>
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr class="offer-row" v-for="(offer, index) in filteredOffers"
                              :key="index">
                              <td>
                                  <div class="custom-control custom-checkbox">
                                      <input type="checkbox"
                                            class="custom-control-input"
                                            :id="`delete-${offer.id}`"
                                            :value="offer.id"
                                            v-model="deleteSelection">
                                      <label class="custom-control-label"
                                            :for="`delete-${offer.id}`"></label>
                                  </div>
                              </td>
                              <td @click="openOffer(offer.id)">{{ offer.created | date }}</td>
                              <td @click="openOffer(offer.id)">{{ displayName(offer) }}</td>
                              <td @click="openOffer(offer.id)">{{ offer.company || 'N/A' }}</td>
                              <td @click="openOffer(offer.id)" v-if="isAgencyColumnVisible">{{ offer.agencyName }}
                              <td @click="openOffer(offer.id)" v-if="isBrokerColumnVisible">{{ offer.brokerName }}
                              </td>
                              <td @click="openOffer(offer.id)">{{ getState(offer) }}</td>
                              <td @click="openOffer(offer.id)">{{ offer.description }}</td>
                          </tr>
                          </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
      <BackToTop />
    </div>
</template>

<script>
  import Icon from '@/components/Icon';
  import BackToTop from '@/components/reusable/BackToTop.vue'

  import eventBus from '@/components/shared/EventBus.js'
  import OfferAPI from '@/api/offer.js';

  export default {
    name: 'Offers',
    metaInfo: {
      title: 'Angebote'
    },
    data() {
      return {
        offers: [],
        filteredOffers: [],
        deleteSelection: [],
        filter: {
          brokers: {
            list: [],
            selected: undefined
          },
          agencies: {
            list: [],
            selected: undefined
          },
          states: {
            list: [
              'open',
              'sent',
              'accepted',
              'declined',
              'submitted'
            ],
            selected: undefined
          },
          company: {
            selected: undefined
          },
          customer: {
            selected: undefined
          }
        },
        states: {
          open: 'Angebot erstellt',
          sent: 'Angebot versandt',
          accepted: 'Angebot akzeptiert',
          declined: 'Angebot abgelehnt',
          submitted: 'Antrag eingereicht'
        }
      }
    },
    computed: {
      isBrokerColumnVisible() {
        return this.user.role === 'agencyOwner' || (this.user.brokerAgency && this.user.brokerAgency.offerVisibility === 'all');
      },
      isAgencyColumnVisible() {
        return this.user.brokerAgency &&
          this.user.brokerAgency.offerVisibility === 'all' &&
          this.user.brokerAgency.mainAgency === true;
      },
      deletionText() {
        if (this.deleteSelection.length > 1) {
          return `${this.deleteSelection.length} Angebote wurde ausgewählt`;
        } else if (this.deleteSelection.length === 1) {
          return `1 Angebot wurde ausgewählt`;
        }
      },
      user() {
        return this.$store.state.user;
      },
      isFilterSet() {
        return Object.keys(this.filter).some(filter => this.filter[filter].selected);
      },
      processingState () {
        return this.$store.state.checkout.application.isInitialDataLoading;
      }
    },
    head: {
      title: function() {
        return {
          inner: 'Angebote'
        }
      }
    },
    components: {
      Icon,
      BackToTop
    },
    mounted() {
      this.getOffers();
      this.preventEndlessLoading(10000)
    },
    watch: {
      'filter.brokers.selected'() {
        this.filterOffers();
      },
      'filter.agencies.selected'() {
        this.filterOffers();
      },
      'filter.states.selected'() {
        this.filterOffers();
      },
      'filter.company.selected'() {
        this.filterOffers();
      },
      'filter.customer.selected'() {
        this.filterOffers();
      },
      'filter.date.selected'() {
        this.filterOffers();
      },
      processingState(value) {
        if (value === 'one-offer') {
          this.$store.commit('SET_FULL_SCREEN_LOADER', true)
        } else {
          this.$store.commit('SET_FULL_SCREEN_LOADER', false)
        }
      }
    },
    methods: {
      deleteOffers() {
        const self = this;

        this.$Simplert.open({
          title: 'Achtung',
          message: 'Sollen alle ausgewählen Angebote gelöscht werden.',
          type: 'warning',
          useConfirmBtn: true,
          customConfirmBtnText: 'Ja',
          customConfirmBtnClass: 'btn btn-secondary',
          customCloseBtnText: 'Nein',
          customCloseBtnClass: 'btn',
          onConfirm() {
            let deletePromises = [];

            self.deleteSelection.forEach((offerId) => {
              deletePromises.push(OfferAPI.remove(offerId));
            });

            Promise
              .all(deletePromises)
              .then(() => {
                self.offers = self.offers.filter((offer) => {
                  return self.deleteSelection.indexOf(offer.id) === -1;
                });
                self.filteredOffers = self.offers;
                self.deleteSelection = [];
              })
              .catch(() => {
                eventBus.$emit('alertUser', {
                  type: 'error',
                  message: 'Die Angebote konnten nicht gelöscht werden.',
                  customCloseBtnText: 'OK'
                });
              });
          }
        })
      },
      filterOffers() {
        let filters = Object.keys(this.filter).filter(filter => this.filter[filter].selected);

        if (filters.length) {
          this.filteredOffers = this.offers.filter(offer => filters.every((filter) => {
            switch (filter) {
              case 'company':
                return RegExp(`${this.filter.company.selected}`, 'i').test(offer.company);
              case 'agencies':
                return offer.agencyName === this.filter.agencies.selected;
              case 'brokers':
                return offer.brokerName === this.filter.brokers.selected;
              case 'states':
                return this.getState(offer) === this.filter.states.selected;
              case 'customer':
                return RegExp(`${this.filter.customer.selected}`, 'i').test(`${offer.firstname} ${offer.lastname}`);
            }
          }));

          this.filteredOffers = this.sortBy('accepted-first', this.filteredOffers);
        } else {
          this.resetFilter();
        }
      },
      takeMeBack() {
        this.$router.push('/');
      },
      openOffer(offerId) {
        try {
          this.$router.push({name: 'AngeboteDetail', params: {offerId: offerId}})
        } catch (error) {
          console.error(error);
        }
      },
      displayName(checkoutObj) {
        return `${checkoutObj.firstname} ${checkoutObj.lastname}`
      },
      getOffers() {
        let brokers = new Set();
        let agencies = new Set();

        OfferAPI
          .list(1000)
          .then((response) => {
            this.user.brokerAgency.offerVisibility === 'personal' ?
                this.offers = response.data.offers.filter(offer => !offer.createdBy || offer.createdBy === this.user.id) :
                this.offers = response.data.offers;

            this.filteredOffers = this.sortBy('accepted-first', [...this.offers])

            this.offers.forEach((offer) => {
              brokers.add(offer.brokerName);
              agencies.add(offer.agencyName);

              this.filter.brokers.list = Array.from(brokers);
              this.filter.agencies.list = Array.from(agencies);
            });
          })
      },
      getState(offer) {
        let lastLog;

        if (!offer.log || !offer.log.length) {
          lastLog = offer.state;
        } else {
          lastLog = offer.log[offer.log.length - 1].state;
        }

        return this.states[lastLog];
      },
      resetFilter() {
        Object.keys(this.filter).forEach((filter) => {
          this.filter[filter].selected = undefined;
        });

        this.filteredOffers = this.sortBy('accepted-first', [...this.offers]);
      },
      sortBy(rule, offers) {
        const self = this
        const rules = {
          'accepted-first'(offersList) {
            return offersList.sort((a,b) => {
              const acceptedState = 'Angebot akzeptiert'
              if (self.getState(a) === acceptedState && self.getState(b) !== acceptedState) {
                return -1
              } else if (self.getState(a) !== acceptedState && self.getState(b) === acceptedState) {
                return 1
              }
              return 0
            })
          }
        }
        return rules[rule](offers)
      },
      preventEndlessLoading(timeout) {
        setTimeout(() => {
          this.$store.commit('SET_INIT_STATUS', null)
        }, timeout)
      }
    }
  }
</script>

<style lang="scss" scoped>
    .offer-row {
        cursor: pointer;
    }

    table {
        > thead {
            th {
                vertical-align: top;
            }
        }
    }

    .search {
        .icon {
            position: absolute;
            top: calc(50% - 14px);
            right: 6px;
        }
    }

    .vertical-middle {
        vertical-align: middle;
    }

    .btn-icon {
        border: 0;
        background: transparent;
        cursor: pointer;
    }

    .no-filter-button {
        padding-top: 55px;
    }

</style>
