<template>
  <div>
    <!-- <cd-hero :header="header"></cd-hero> -->
    <div id="videos_lp" class="secondary-page">
      <div class="container">
        <div class="row page-title">
          <div class="col">
            <h1>Erklärvideos</h1>
          </div>

          <div class="col-12 col-md-2 back-btn">
            <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
          </div>
        </div>


        <div id="video_roll">

          <div class="row">
            <div class="col-12 cd-video"
              v-for="(video, index) in videos"
              :class="getClassLength(index)"
              :key="index">
              <youtube :video-id="video.id" :player-vars="playerVars"></youtube>
            </div>
          </div>
        </div>

      </div> <!-- end container -->

    </div>

    <BackToTop />
  </div>
</template>

<script>
import BackToTop from '@/components/reusable/BackToTop.vue'

export default {
  name: 'Videos',
  components: {
    BackToTop
  },
  metaInfo: {
    title: 'Videos'
  },
  data () {
    return {
      playerVars: {
        width: 200
      },
      videos: [
        {
          id: 'ECm-Cm2jv3A'
        },
        {
          id: '54ARTezPL4s'
        },
        {
          id: '8MAIOQUnXJc'
        },
        {
          id: 'SfFc9jHqd7w'
        },
        {
          id: 'TTI3GPK8rH0'
        },
        {
          id: '-lId6VNZaXM'
        },
        {
          id: '5qD7ztXlAtA'
        }
      ]
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getClassLength (index) {
      return index === 0
        ? 'col-md-12'
        : 'col-md-4'
    }
  }
}
</script>

<style lang="scss">

@import '../scss/variables';
@import '../scss/mixins';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#videos_lp {
  iframe {
    // width: 100px!important;
  }
  #video_roll {
    .cd-video {
      margin-bottom: 20px;
      iframe {
        width: 100%!important;
      }
    }

    .col-md-12.cd-video {
      iframe {
        height: 480px;
      }
    }

    .col-md-4.cd-video {
      iframe {
        height: 280px;
      }
    }
  }
}


</style>
