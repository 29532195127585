<template>
  <div id="beratungstool_start_page" class="page">
    <div class="container">
      <div class="row section-title">
        <div class="col-12 columns">
          <h1>CyberEasy</h1>
          <!-- <h1 class="subhead"></h1> -->
        </div>
      </div>
  
      <div class="row section-title" id="intro_copy">
        <div class="col-12 columns">
          <h3>Verstehen Sie ihr persönliches Cyber-Risiko</h3>
        </div>
      </div>
  
      <div class="row overview-bucket" id="overview_list">
  
        <div class="col-12 col-md-6 columns">
        </div>
  
        <div class="col-12 col-md-6 columns">
          <ul>
            
            <li>
              <span>Kaum ein deutsches Unternehmen ist ausreichend auf Hackerangriffe vorbereitet.</span>
            </li>
  
             <li>
              <span>Kleine Firmen werden als leichtes und daher besonders attraktives Ziel wahrgenommen.</span>
            </li>
  
             <li>
              <span>Oft fehlt es den Entscheidern an den nötigen Ressourcen, um Ihr Unternehmen wirksam vor Cyberangriffen zu schützen.</span>
            </li>
  
            </ul>
        </div>
      </div>
  
  
      <div class="row section-title">
        <div class="col-12 columns">
          <h3>Die Gefahren der digitalen Welt in Zahlen</h3>
        </div>
      </div>
  
      <div class="row plate" id="digital_dangers">
        
        <div class="col-12 col-md-4 columns">
          <h4>57%</h4>
          <p>Anteil der Unternehmen mit weniger als 100 Mitarbeitenden, die in den letzten 2 Jahren Ziel einer Cyberattacke waren</p>
        </div>
  
        <div class="col-12 col-md-4 columns">
          <h4>€174</h4>
          <p>Kosten pro betroffenen Datensatz bei einer Datenpanne</p>
        </div>
  
        <div class="col-12 col-md-4 columns">
          <h4>71%</h4>
          <p>Anteil der Kunden, die nach einem Datenmissbrauch den Anbieter wechseln würden</p>
        </div>
  
      </div> <!-- End expert help -->
  
      <div class="row section-title">
        <div class="col-12 columns">
          <h3>Der Schutz der großen Unternehmen auch für Sie – lassen Sie sich jetzt beraten</h3>
        </div>
      </div>
  
      <div class="row plate" id="protection">
  
        <div class="col-12 col-md-4 columns">
          <img src="/static/icons_beratungstool/analysieren.svg">
          <h4>Cyber-Risiken analysieren</h4>
          <p>Analyse auf welchen Wegen Cyberkriminelle Ihr Unternehmen angreifen können.</p>
        </div>
        
        <div class="col-12 col-md-4 columns">
          <img src="/static/icons_beratungstool/verstehen.svg">
          <h4>Cyber-Risiken verstehen</h4>
          <p>Verstehen Sie, mit welchen raffinierten Techniken Cyber-Kriminelle versuchen Ihnen zu schaden.</p>
        </div>
  
  
        <div class="col-12 col-md-4 columns">
          <img src="/static/icons_beratungstool/absichern.svg">
          <h4>Cyber-Risiken absichern</h4>
          <p>Sichern Sie sich gegen die vielfältigen Risiken der digitalen Welt ab und erhalten Sie den optimalen Schutz für Ihr Unternehmen.</p>
        </div>
  
      </div>
</div> <!-- End check list -->

    <BackToTop />
  </div>
</template>

<script>
import BackToTop from '@/components/reusable/BackToTop.vue'

export default {
  name: 'Beratungstool',
  components: {
    BackToTop
  },
  data () {
    return {
    }
  },
  mounted: function () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#beratungstool_start_page {
  #intro_copy {
    padding-top: 0;
  }
  .overview-bucket {
    > div:first-child {
      background-image: url(/public/static/guys_on_laptop.jpg);
    }

  }
}

#overview_list.overview-bucket {
 > div {
   ul {
    li {
      color: $orangeTone;
      margin: 0 $standardGutter ($standardGutter * 2);
      font-size: 1.3rem;
      span {
        color: $bodyColor;
      }

      @include media-breakpoint-down(sm) {
        margin: 0 1.5rem 1.5rem;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }

 }
}

#digital_dangers.plate {
  text-align: center;
  margin-bottom: $standardGutter;
  .columns {
    h4 {
      color: $blueTone;
      font-size: 3rem;
    }

    p {
      // font-size: 1.15rem;
    }
  }
}

#protection {
  .columns {
    text-align: center;
    img {
      height: 145px;
    }
  }
}

</style>
