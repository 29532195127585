<template>
  <div class="go-bottom-wrap d-print-none">
    <button class="btn btn--scroll-bottom" :class="{ 'show-btn': showBtn }" @click="scrollToBottom">
      <Icon name="arrow-down" class="icon-down" />
    </button>
  </div>
</template>

<script>
import Icon from "@/components/Icon"
export default {
  components: {
    Icon
  },
  props: ['scrollContainer'],
  data() {
    return {
      showBtn: true
    }
  },

  methods: {
    scrollToBottom() {
      let scrollContainer = window
      let scrollDistance = 10000
      if (this.scrollContainer) {
        scrollContainer = document.querySelector(this.scrollContainer)
        scrollDistance = scrollContainer.children[0].offsetHeight
      }
      
      scrollContainer.scrollTo({
        top: scrollDistance,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.go-bottom-wrap {
  position: absolute;
  right: -20px;
}

.icon-down {
  fill: $whiteColor;
}

.btn--scroll-bottom {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  min-width: auto;

  @include media-breakpoint-down(sm) {
    bottom: 1rem;
    right: 1rem;
  }

  z-index: 25;
  position: fixed;
  bottom: 2rem;
  right: initial;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: $primary;
  color: $whiteColor;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0px 0px 15px rgba($primary, 0.5);
  }

  .icon svg {
    position: absolute;
  }
}

.show-btn {
  opacity: 1;
  visibility: visible;
}
</style>
