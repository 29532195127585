<template>
    <ul class="file-list">
        <li v-for="item in items" :key="item.link">
            <a :href="item.link" target="_blank" rel="noopener noreferrer">
                <icon :name="getIconName(item.icon)" width="40" color="secondary"></icon>
                {{item.name}}
            </a>
        </li>
    </ul>
</template>

<script>
  import Icon from './Icon';

  export default {
    name: 'fileList',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    components: {
      Icon
    },
    methods: {
      getIconName(iconName) {
        return iconName || 'file-pdf';
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import './scss/variables';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .file-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 4px 0;
        }

        a {
            color: $secondary;
        }
        
        @include media-breakpoint-down(xs) {
          ::v-deep svg {
            width: 1.5rem;
            transform: translateY(-4px);
          }
        }
    }
</style>
