<template>
    <v-touch @swipeleft="next"
             @swiperight="back"
             :swipe-options="{ direction: 'horizontal' }"
             class="course-base-template facebook-friends-settings">
        <h2 class="course-base-template__title">{{ content.title }}</h2>
        <div class="facebook-friends-settings__image-wrapper" ref="container">
            <div v-for="(image, index) in images" :key="index">
                <img class="facebook-friends-settings__image"
                     :class="{ 'facebook-friends-settings__image--active': index === currentIndex }"
                     alt="image"
                     :src="require(`@/pages/trainings/assets/courses/social-media/facebook-friends-settings/${image.name}`)">
            </div>
            <div class="custom-template__highlight-area"
                 @click="nextSlide"
                 :style="currentSlide.style">
                <icon name="arrow-click"
                      color="secondary"
                      class="custom-template__highlight-arrow"
                      :size="40">
                </icon>
            </div>
            <div class="custom-template__tooltip"
                 :style="currentSlide.tooltipStyle"
                 v-tooltip="{
                   content: currentSlide.tooltipText,
                   classes: 'custom-template__tooltip-content',
                   trigger: 'hover click',
                 }">
                <icon name="info"
                      :size="26"
                      color="white"></icon>
            </div>
        </div>

        <div class="row mb-15">
            <div class="col-6">
                <button class="btn btn--secondary btn--border"
                        type="button"
                        name="back"
                        @click="back">Zurück
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn--secondary"
                        type="button"
                        name="next"
                        @click="next">Weiter
                </button>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue'
  import {throttle} from 'lodash';

  function calc() {
    let width = this.$refs.container && this.$refs.container.clientWidth || this.maxWidth;
    width = Math.min(width, window.innerWidth, this.maxWidth);

    this.currentWidth = width;
  }

  export default {
    name: 'facebook-friends-settings',
    components: {
      Icon
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        maxWidth: 1024,
        currentWidth: 1014,
        images: [
          {
            name: '01.png',
            highlightArea: [38, 34, 0, 82.4],
            tooltipText: 'Klicken Sie rechts auf das Symbol für Freundschaftsanfragen.',
            tooltipPosition: [0.2, 76]
          },
          {
            name: '02.png',
            highlightArea: [100, 26, 7.3, 82.7],
            tooltipText: 'Klicken Sie oben auf "Einstellungen"',
            tooltipPosition: [22, 82]
          },
          {
            name: '03.png',
            highlightArea: [82, 32, 52, 62],
            tooltipText: 'Aktuell kann Ihnen jeder auf Facebook eine Freundschaftsanfrage senden. Klicken Sie auf "Alle" um dies zu ändern.',
            tooltipPosition: [51, 53]
          },
          {
            name: '04.png',
            highlightArea: [196, 34, 61.3, 62],
            tooltipText: 'Wählen Sie "Freunde von Freunden" aus.<br> Durch diese Einstellungen werden Sie deutlich weniger Anfragen von Fake-Profilen erhalten. Achten Sie aber weiterhin darauf, Freunschaftsanfragen von Freunden abzulehnen, mit denen Sie bereits befreundet sind. Betrüger ändern oft einzelne Buchstaben im Namen und geben sich dann als Ihre Freunde aus. Klicken Sie auf "Fertig" , um das Beispiel zu beenden.',
            tooltipPosition: [51, 53]
          }
        ],
        currentIndex: 0
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.calc();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    computed: {
      scaleRatio() {
        return `scale(${this.currentWidth / this.maxWidth})`;
      },
      slideNumber() {
        return +this.$route.params.slide;
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      },
      currentSlide() {
        const {
          name,
          highlightArea: [w, h, t, l],
          tooltipText,
          tooltipPosition: [tooltipTop, tooltipLeft]
        } = this.images[this.currentIndex];

        return {
          name,
          style: {
            width: w + 'px',
            height: h + 'px',
            top: t + '%',
            left: l + '%',
            transform: this.scaleRatio
          },
          tooltipText,
          tooltipStyle: {
            top: tooltipTop + '%',
            left: tooltipLeft + '%',
            transform: this.scaleRatio
          }
        };
      }
    },
    methods: {
      calc,
      onResize: throttle(calc, 100),
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      next() {
        this.$router.push({
          params: { ...this.$route.params, slide: this.nextSlideNumber }
        });
      },
      nextSlide() {
        this.currentIndex === (this.images.length - 1) ? this.next() : this.currentIndex++;
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .facebook-friends-settings {
        position: relative;

        &__image-wrapper {
            width: 100%;
            position: relative;
            padding-top: 56%;
            margin: 0 auto 30px;
        }

        &__image {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            opacity: 0;

            &--active {
                opacity: 1;
            }
        }
    }
</style>
