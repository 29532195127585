<template>
  <div class="email-preview">
    <div class="d-flex justify-content-center mb-3">
      <img
        src="@/assets/CyberDirekt_no-subs.svg"
        alt="CyberDirekt"
        class="email-preview__logo"
      />
    </div>
    <p>
      {{ salutation }}
    </p>
    <p>
      {{ mainText.reason }}
    </p>
    <p>
      {{ mainText.comment }}
    </p>
    <div class="d-flex justify-content-center email-preview__btn">
      <ButtonComponent
        btnStyle="accent"
        btnSize="sm"
        behavior="externalLink"
        :link="offerLink"
        class="mb-4"
      >
        Angebot einsehen
      </ButtonComponent>
    </div>
    <p>
      Mit freundlichen Grüßen
    </p>
    <p>
      {{ broker.firstname }} {{ broker.lastname }} <br />
      {{ broker.brokerAgency.company }}
    </p>
  </div>
</template>

<script>
import ButtonComponent from '@/components/reusable/ButtonComponent'

export default {
  props: ['salutation', 'main-text', 'offer-link'],
  components: {
    ButtonComponent
  },
  computed: {
    broker() {
      return this.$store.state.user
    }
  }
}
</script>

<style lang="scss" scoped>
.email-preview {
  border-top: 1px solid #ebf0f2;
  border-bottom: 1px solid #ebf0f2;
  padding: 1rem 0 0;
  margin-bottom: 1rem;

  &__logo {
    max-width: 175px;
  }

  &__btn {
    ::v-deep .btn {
      min-width: 200px;
    }
  }
}
</style>
