function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    return Promise.resolve(successful);
  } catch (err) {
    return Promise.resolve(false);
  } finally {
    document.body.removeChild(textArea);
  }
}

export default {
  copyTextToClipboard(text) {
    if (!navigator.clipboard || !window.isSecureContext) {
      return fallbackCopyTextToClipboard(text);
    }
    navigator.clipboard.writeText(text);
    // since readText will always return something, 
    // the error catching has to be on the components side
    return navigator.clipboard.readText();
  }
};
