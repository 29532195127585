<template>
    <div>
        <div class="container secondary-page">
            <div class="row page-title">
                <div class="col">
                    <h1>Was ist ein Affiliate Link?</h1>
                </div>
                <div class="col-12 col-md-2 back-btn">
                    <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
                </div>
            </div>

            <div class="row question">
                <div class="col-12">
                    <div class="question__text">
                    <p>Affiliate-Links sind ein wesentlicher Bestandteil des modernen Marketings, insbesondere wenn es darum geht, neue Kunden zu gewinnen. 
Dieser Prozess ermöglicht es Ihnen, Leads schnell und effektiv zu generieren.</p>
                    </div>
                </div>
            </div>
            
            <div class="row question">
                <div class="col-12">
                    <div class="question__title h1">
                        Wo platziere ich den Affiliate Link?
                    </div>
                    <div class="question__text">
                        <p>
                            Indem Sie den Affiliate-Link innerhalb Ihrer Webseite platzieren, ermöglichen Sie es Ihren Kunden, einen Vergleich von Cyber-Versicherungen durchzuführen. 
            Der speziell für sie erstellte Link kann auch in Ihren Social-Media-Beiträgen (z.B. LinkedIn) oder Newslettern verwendet werden, um die Reichweite zu erhöhen und potenzielle Kunden anzusprechen.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="alert alert-info text-center affiliate-link-box">
                        <a :href="affiliateLink" target="_blank" class="affiliate-link">{{ affiliateLink }}</a>
                        <div>
                            <button class="btn btn-primary" @click="onCopyClicked">Link kopieren</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row question">
                <div class="col-12">
                    <div class="question__title h1">
                        Wie funktioniert der Affiliate Link?
                    </div>
                    <div class="question__text">
                        <p>
                            Wenn ein Kunde auf den Link klickt, gelangt er zu einer vereinfachten Version des Marktvergleichs, wo er ein Angebot erst rechnen und dann bei Ihnen anfordern kann. Sie als Vermittler erhalten darüber eine Nachricht und können direkt in Kontakt treten. Der Kunde kann das Angebot annehmen, aber nicht ohne Ihre Beteiligung einreichen. Die Angebote können Sie wie gewohnt unter der Kachel <router-link to="/offers">“Angebote”</router-link> einsehen und abschließend bearbeiten. 
                        </p>
                    </div>
                </div>
            </div>

            <div class="step">
                <div class="step__image">
                    <img class="img-fluid" src="/static/affiliate-1.jpg">
                </div>
                <div class="step__text">
                    <p> Dies ist die Ansicht Ihres Kunden.</p>
                </div>
            </div>
            <div class="text-center mt-4 mb-4">
                <icon name="arrow-down" color="primary" :width="32"></icon>
            </div>
            <div class="step">
                <div class="step__image">
                    <img class="img-fluid" src="/static/affiliate-2.jpg">
                </div>
                <div class="step__text">
                    <p>Nachdem Ihr Kunde “Angebot anfordern” klickt, öffnet sich dieses Fenster. Hier gibt Ihr Kunde seine/ihre Daten ein.</p>
                </div>
            </div>
            <div class="text-center mt-4 mb-4">
                <icon name="arrow-down" color="primary" :width="32"></icon>
            </div>
            <div class="step">
                <div class="step__image">
                    <img class="img-fluid" src="/static/affiliate-3.png">
                </div>
                <div class="step__text">
                    <p>Ihr Kunde erhält daraufhin eine Bestätigungs-Email. Gleichzeitig erhalten Sie als Makler eine Benachrichtigung über die Anfrage des Kunden und können dies im Maklerportal einsehen.</p>
                </div>
            </div>
            <div class="text-center mt-4 mb-4">
                <icon name="arrow-down" color="primary" :width="32"></icon>
            </div>
            <div class="step">
                <div class="step__image">
                    <img class="img-fluid" src="/static/affiliate-4.png">
                </div>
                <div class="step__text">
                    <p>Die Folgeschritte der Angebotsfunktion stehen ab diesem Punkt zur Verfügung. Sie können dem Kunden das Angebot über einen personalisierten Link (in Email kopieren) zur Verfügung stellen. Der Kunde kann über den Link die Risikofragen beantworten und bestätigen, dass er/sie die Versicherung abschließen möchte. Jetzt erhalten Sie wieder eine Benachrichtigung per Email Im Anschluss können Sie als Makler den Abschluss der Versicherung im Auftrag ihres Kunden durchführen. Sie erhalten (und auf Wunsch auch Ihr Kunde) die Antragsbestätigung sowie Versicherungsdokumente per Email.</p>
                </div>
            </div>
        </div>
      <BackToTop />
    </div>
</template>

<script>
  import copy from 'clipboard-copy';
  import Icon from '../components/Icon';
  import BackToTop from '@/components/reusable/BackToTop.vue'

  export default {
    name: 'Affiliate',
    metaInfo: {
      title: 'Affiliate'
    },
    components: {
      Icon,
      BackToTop
    },
    data() {
      return {
        affiliateLink: ''
      }
    },
    mounted() {
      this.$http.get('/brokers/affiliate')
        .then((response) => {
          this.affiliateLink = response.data.link;
        })
    },
    methods: {
      onCopyClicked() {
        copy(this.affiliateLink)
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    @import '../scss/base';
    @import '../scss/form';

    .step {
        display: flex;
        flex-direction: column;
        box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        &__image {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        &__text {
            padding: 16px;
            width: 100%;
            color: #444 !important;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    .affiliate-link-box {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        margin-bottom: 2.5rem;
    }

    .affiliate-link {
        display: inline-flex;
        margin-bottom: 0.75rem;
    }

    .question {
        margin-bottom: 1.5rem;

        &__title {
            margin-bottom: 1rem;
        }
    }
</style>
