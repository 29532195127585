<template>
  <div>
    <video ref="videoPlayer" width="auto" height="auto" :poster="poster" class="video-js vjs-fluid"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    poster: {
      type: String,
      required: false,
      default: '/static/vectors/placeholder.png'
    },
  },
  data() {
    return {
      player: null,
    }
  },
  computed:{
    updatedOptions() {
      const newOptions = this.options
      return newOptions
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {})
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
<style scoped></style>
