<template>
    <div class="quiz-base-template"
         :style="{ 'background-image': `url(${background})` }">
        <div class="quiz-base-template__holder"
             :class="[`quiz-base-template__holder--${position}`, resultStatus ? 'quiz-base-template__holder--hide': null]">
            <slot></slot>
        </div>

        <transition :name="'slide-' + position">
            <div v-if="resultStatus"
                 class="quiz-base-template__result"
                 :class="`quiz-base-template__result--${resultStatus} quiz-base-template__result--${position}`">
                <h3 class="quiz-base-template__result-title">{{ answerTexts[resultStatus] }}</h3>
                <div class="quiz-base-template__result-hr"></div>
                <div class="quiz-base-template__result-text"
                     v-html="resultHtml"></div>
                <div class="quiz-base-template__result-action-holder">
                    <button class="btn quiz-base-template__result-button"
                            @click="next"
                            type="button">Nächste Frage
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
  const MINIMUM_PASS_SCORE = 80;

  export default {
    name: 'quiz-base-template',
    props: {
      background: {
        type: String,
        required: true
      },
      position: {
        type: String,
        default: 'full'
      },
      resultStatus: {
        type: String
      },
      resultHtml: {
        type: String,
        required: true
      }
    },
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse || {};
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      progressAnswers() {
        return this.selectedCourseProgress.answers || {};
      },
      currentStep() {
        return +this.$route.params.step;
      },
      selectedCourseSlides() {
        return this.selectedCourse.slides || [];
      },
      quizQuestions() {
        return this.selectedCourseSlides.filter(slide => slide.group === 'quiz');
      },
      isLastStep() {
        return this.currentStep === this.quizQuestions.length;
      }
    },
    data() {
      return {
        answerTexts: {
          success: 'Das ist richtig',
          failure: 'Das ist falsch'
        }
      }
    },
    methods: {
      async saveFinalResult() {
        const passed = Object.values(this.progressAnswers);
        const progress = Math.round(passed.reduce((sum, cur) => sum + cur * 100 / passed.length, 0));

        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          result: String(progress),
          isDone: progress >= MINIMUM_PASS_SCORE || undefined
        });

        // if (progress < MINIMUM_PASS_SCORE) {
        //   this.$store.commit('INCREASE_QUIZ_REPEAT_COUNT');
        // }
      },
      async next() {
        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          answers: {
            ...this.progressAnswers,
            ['answer-' + this.$route.params.step]: this.resultStatus === 'success'
          },
          slideSlug: 'quiz-' + (+this.$route.params.step + 1)
        });

        if (this.isLastStep) {
          await this.saveFinalResult();
        }

        this.$router.replace({
          params: {
            step: this.currentStep + 1
          }
        });
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .quiz-base-template {
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        &__holder {
            background: rgba($quiz-bg-overlay, 0.89);
            padding: 20px 25px;
            color: #fff;
            min-height: 500px;
            min-width: 360px;

            &--left {
                width: 50%;
            }

            &--right {
                width: 50%;
                margin-left: auto;
            }

            &--hide {
                visibility: hidden;
            }
        }

        &__result {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 50%;
            padding: 20px 25px;
            min-width: 360px;
            color: #fff;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }

            &--success {
                background-color: rgba($green, 0.84);
            }

            &--failure {
                background-color: rgba($red, 0.84);
            }

            &--full.quiz-base-template__result--success {
                right: 0;
            }

            &--full.quiz-base-template__result--failure {
                left: 0;
            }

            &-title {
                text-align: center;
                font-size: 1.75rem;
                line-height: 2.15rem;
                font-weight: 500;
                margin: 25px 0;
                color: #fff;
                text-transform: uppercase;
            }

            &-hr {
                width: 120px;
                margin: 0 auto 25px;
                height: 3px;
                background-color: #fff;
                border-radius: 3px;
            }

            &-text {
                font-size: 1.075rem;
                line-height: 1.35rem;
                min-height: 260px
            }

            &-action-holder {
                text-align: center;
                padding: 25px 0;
            }

            &-button.btn {
                color: #fff;
                border: 2px solid #fff;
                padding: 9px;
                font-size: 1.125rem;
                line-height: 1.5rem;
                font-weight: 500;
                width: 200px;
                border-radius: 8px;

                &:hover {
                  color: #fff;
                }
            }
        }

        @media screen and (max-width: 767px) {
            &__result,
            &__holder {
                padding: 15px;
                min-width: 0;

                &,
                &--left,
                &--right {
                    width: 100%;
                }
            }
        }
    }

    .slide-left-enter-active,
    .slide-right-enter-active,
    .slide-full-enter-active {
        transition: all 0.3s ease-in-out;
    }

    .slide-left-enter,
    .slide-full-enter.quiz-base-template__result--failure {
        transform: translateX(-20%);
        opacity: 0;
    }

    .slide-right-enter,
    .slide-full-enter.quiz-base-template__result--success {
        transform: translateX(20%);
        opacity: 0;
    }
</style>
