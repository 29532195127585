<template>
    <div class="row">
        <div class="col-xs-12 col-sm-8">
            <course-start :image="require(`@/pages/trainings/assets/courses/${selectedCourse.slug}/img/${selectedCourse.image}`)"
                          :title="selectedCourse.name"
                          :on-start="startCourse"
                          v-if="!$route.params.slide"></course-start>
            <div v-else-if="currentSlide">
                <simple-template :slide="currentSlide"
                                 :course-slug="selectedCourse.slug"
                                 v-if="currentSlide.template === 'simple'"></simple-template>
                <multi-step-template :slide="currentSlide"
                                     :course-slug="selectedCourse.slug"
                                     v-else-if="currentSlide.template === 'multi-step'"></multi-step-template>
                <custom-template :slide="currentSlide"
                                 v-else-if="currentSlide.template === 'custom'"></custom-template>
            </div>
            <div v-else-if="$route.params.slide > 0">
                <quiz-start :title="'Überprüfen Sie Ihr Wissen in 5 Fragen'"
                            :icon="'quiz-intro'"
                            :subtitle="'Es können beliebig viele Antworten richtig oder falsch sein!'"></quiz-start>
            </div>
        </div>
        <div class="col-xs-12 col-sm-4">
            <training-nav></training-nav>
        </div>
    </div>
</template>

<script>
  import TrainingNav from '@/pages/trainings/TrainingNav.vue';
  import CourseStart from '@/pages/trainings/course-templates/CourseStart.vue';
  import SimpleTemplate from '@/pages/trainings/course-templates/SimpleTemplate.vue';
  import MultiStepTemplate from '@/pages/trainings/course-templates/MultiStepTemplate.vue';
  import QuizStart from '@/pages/trainings/course-templates/QuizStart.vue';
  import CustomTemplate from '@/pages/trainings/course-templates/CustomTemplate.vue';

  export default {
    name: 'my-training-module',
    components: {
      TrainingNav,
      CourseStart,
      SimpleTemplate,
      MultiStepTemplate,
      QuizStart,
      CustomTemplate
    },
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse || {};
      },
      selectedCourseSlides() {
        return this.selectedCourse.slides || [];
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      savedSlideIndex() {
        const slide = this.selectedCourseProgress.slideSlug.match(/slide-(\d+)/);

        return slide ? slide[1] - 1 : -1;
      },
      isQuizStarted() {
        this.selectedCourseProgress.slideSlug.match(/^quiz/);
      },
      isCourseComplete() {
        return this.selectedCourseProgress.isDone;
      },
      currentSlideIndex() {
        return this.$route.params.slide ? this.$route.params.slide - 1 : -1;
      },
      moduleSlides() {
        return this.selectedCourseSlides.filter(slide => slide.group === 'intro' || slide.group === 'module');
      },
      currentSlide() {
        return this.moduleSlides[this.currentSlideIndex];
      }
    },
    async beforeRouteUpdate(to, from, next) {
      if (this.isCourseComplete) return next();

      const slideTo = +to.params.slide;

      if ((slideTo - this.savedSlideIndex) > 2 && !this.isQuizStarted) return;

      if ((slideTo - this.savedSlideIndex) === 2) {
        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          slideSlug: 'slide-' + Math.min(slideTo, this.moduleSlides.length)
        });
      }

      next();
    },
    methods: {
      async startCourse() {
        if (this.savedSlideIndex === -1 && !this.isQuizStarted && !this.isCourseComplete) {
          await this.$store.dispatch('SAVE_PROGRESS', {
            trainingSlug: this.selectedCourseProgress.trainingSlug,
            slideSlug: 'slide-1'
          });
        }

        this.$router.push({params: {slide: '1'}});
      }
    }
  }
</script>

<style lang="scss">

</style>
