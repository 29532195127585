import { mapGetters } from 'vuex'

export default {
  data: () => ({
    // just right after the scan, the vuex connection to the offer object is sometimes
    // not correctly updated in copmuted \offerScanStatus\, so we need to store the scan result temporarily
    temporaryOfferScanStatus: null, // used to store the scan result temporarily
    // hardcoded tariffs that have scans.
    corvusScanTariffs: [
      'corvus-rc1-sideletter',
      'corvus-rc2-sideletter',
      'corvus-rc3-sideletter',
      'corvus-rc4-sideletter',
      'corvus-rc5-sideletter',
    ],
    baobabScanTariffs: [
      'baobab-sideletter-tg1',
      'baobab-sideletter-tg2',
      'baobab-sideletter-tg3',
      // 'baobab-standard-tg1',
      // 'baobab-standard-tg2',
      // 'baobab-standard-tg3'
    ]
  }),
  // TODO
  // all the scan related fcs should be moved here from the offer component
  computed: {
    ...mapGetters({
      localMixinOffer: 'offer',
    }),
    offerScanStatusMixin() {
      // the computed prop has problems to detect changes in nested objects
      // therefore we use the temp data, that will be erased once the user updates
      // this cover the time section between the submission and the user refresh.
      return {
        corvus: this.localMixinOffer?.corvusQuoteStatus,
        baobab: this.localMixinOffer?.baobabQuoteStatus,
        temporary: this.temporaryOfferScanStatus
      }
      // return this.localMixinOffer?.corvusQuoteStatus || this.localMixinOffer?.baobabQuoteStatus || this.temporaryOfferScanStatus
    },
    isEditingBlockedByScanMixin() {
       return {
         corvus: this.offerScanStatusMixin.corvus === 'QUOTES_SHARED' || this.offerScanStatusMixin.corvus === 'SUBMITTED',
         baobab: this.offerScanStatusMixin.baobab === 'offer' || this.offerScanStatusMixin.baobab === 'in_progress'
       }
    },
    hasPositiveScanStatusMixin() {
      return {
        corvus: this.offerScanStatusMixin.corvus === 'QUOTES_SHARED',
        baobab: this.offerScanStatusMixin.baobab === 'offer'
      }
    },
  },
  methods: {
    isQuoteExpiredMixin(insurerKey) {
      switch (true) {
        case insurerKey.includes('corvus'): return new Date() > new Date(this.localMixinOffer.corvusQuoteExpireDate)
        case insurerKey.includes('baobab'): return new Date() > new Date(this.localMixinOffer.baobabQuoteExpireDate)
      }
    },
    hasOfferScanStatusMixin() {
      return Boolean(this.offerScanStatusMixin.corvus|| this.offerScanStatusMixin.baobab || this.offerScanStatusMixin.temporary)
    },
    tariffHasScanMixin(tariffKey) {
      return this.corvusScanTariffs.some((t) => t === tariffKey) || this.baobabScanTariffs.some((t) => t === tariffKey)
    },
    updateScanResultMixin(update) {
      this.temporaryOfferScanStatus = update
    },
    isQuoteCheckAvailableMixin(insurerKey) {
      return this.tariffHasScanMixin(insurerKey) && (
          insurerKey.includes('corvus') && this.offerScanStatusMixin.corvus === 'SUBMITTED' ||
          insurerKey.includes('baobab') && this.offerScanStatusMixin.baobab === 'in_progress'
      )
    },
    isQuoteCheckDoneMixin(insurerKey) {
      return (this.tariffHasScanMixin(insurerKey) &&
          this.localMixinOffer?.insurer === insurerKey && this.offerScanStatusMixin.corvus === 'QUOTE_SHARED'
      )
    },
    isRecommendationDeclinedMixin(insurerKey) {
      return this.tariffHasScanMixin(insurerKey) && (
          insurerKey.includes('corvus') && this.localMixinOffer?.corvusQuoteStatus === 'DECLINED' ||
          insurerKey.includes('baobab') && this.localMixinOffer?.baobabQuoteStatus === 'declined'
      )
    },
    isRecommendationAcceptedMixin(insurerKey) {
      return this.tariffHasScanMixin(insurerKey) && (
          insurerKey.includes('corvus') && this.localMixinOffer?.corvusQuoteStatus === 'QUOTES_SHARED' ||
          insurerKey.includes('baobab') && this.localMixinOffer?.baobabQuoteStatus === 'offer'
      )
    },
    isResubmitMixin(insurerKey) {
      // temporarily disabled toto update
      return false
      return (
        (this.tariffHasScanMixin(insurerKey) && this.localMixinOffer?.corvusQuoteStatus === 'DECLINED') || this.isQuoteExpiredMixin(insurerKey)
      )
    },
  },
}
