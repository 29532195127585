<template>
  <b-tooltip :show.sync="showTooltip" target="notification-link" triggers="hover" trigerred custom-class="notification-tt">
    <div class="notification-tt__header">Benachrichtigungen</div>
    <div class="notification-tt__main">
      <slot>
      </slot>
    </div>
  </b-tooltip>
</template>

<script>
  export default {
    data() {
      return {
        showTooltip: false
      }
    },
    methods: {
      hideTooltip() {
        setTimeout(() => {
          this.showTooltip = false
        }, 100)
      }
    },
  }
</script>

<style lang="scss" >
@import "../scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

div.notification-tt {
  font-size: 1rem;
  color: $blackColor;
  z-index: 10600;
  // transform: translateX(0) !important;

  &.tooltip {
    position: relative;
    opacity: 1;
    max-width: 300px;

    &::after {
      content: '';
      position: absolute;
      top: -0.75rem;
      left: 0;
      height: 1rem;
      width: 100%;
      z-index: 9;
      opacity: 0;
    }

    @include media-breakpoint-up(sm) {
      max-width: 390px;
    }

    .tooltip-inner {
      max-height: 90vh;
      padding: 0;
      background: $whiteColor;
      color: $blackColor;
      box-shadow: 0px 0px 16px rgba(88, 89, 91, 0.24);
      border-radius: 0.5rem;
      transform: translateX(30px);
      overflow: auto;
    }

    .arrow {
      z-index: 5;
      &:before {
        border-bottom-color: white
      }
    }
  }
  
  &__body {
    padding: 1.4rem 1rem;
    font-weight: 500;
    font-size:1rem;
    font-size: 1.125rem;
    border-bottom: 1px solid $greyBlueDark
  }
  &__body:last-child {
    border-bottom: 0px
  }

  &__header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    font-size: 1.125rem;
    border-bottom: 1px solid $greyBlueDark
  }

  &__main {
    padding: 1rem 1.5rem 2rem 1.5rem;
    font-size: 1rem;
  }

  &__top {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__date {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 1.5rem;
  }

  &__buttons {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      a + button {
        margin-top: 1rem;
      }

      .link-primary {
        justify-content: center;
      }
    }
  }
}
</style>
