<template>
  <div>
    <div class="cd-hero" id="cd_hero" :class="header.style" :style="setHeaderImage()">
        <div class="container">
          <div class="row">
          <div class="col-12" :class="getHeroLength(header.style)">
            <div class="cd-hero__box">
              <h1 v-html="$sanitizeHtml(header.title)"></h1>
              <div v-html="$sanitizeHtml(header.subhead)" v-if="header.subhead"></div>
              <router-link :to="header.link.url" class="btn-style" v-if="header.link">{{header.link.title}}</router-link>
            </div>
          </div>
          <!-- <div class="col-12 col-md-5">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'

  export default {
    name: 'CdHero',
    props: ['header'],
    data () {
      return {
        playVideo: false,
        videoSrc: ''
      }
    },
    mounted: function () {
      // load viewport specific video
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      const vm = this;

      setTimeout(() => {
        eventBus.$emit('hasHdrVideo', vm.header.type === 'video')
      });

      // unfortunately video srcset / src[media] is not working anymore in chrome. So we need this little workaround
      if (width > 1024) {
        this.videoSrc = '/static/Vid-HDR-Loop.mp4'
      } else if (width >= 760 && width < 1024) {
        this.videoSrc = '/static/Vid-HDR-Loop-800.mp4'
      } else {
        this.videoSrc = '/static/Vid-HDR-Loop-500.mp4'
      }
    },
    methods: {
      getHeroLength: function (style) {
        return style !== 'no-box'
          ? 'col-md-12'
          : ''
      },
      videoStart: function (action) {
        window.scrollTo(0, 0)

        if (action) {
          setTimeout(() => {
            const videoEl = document.getElementById('main_video')
            videoEl.play()
          }, 500)
        }

        this.playVideo = action
        eventBus.$emit('playVideo', action)
      },
      setHeaderImage: function () {
        return (this.header.image)
          ? {'background-image': 'url(' + this.header.image + ')'}
          : ''
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';
  @import '../../scss/mixins';

  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';


    .cd-hero {

        height: 370px;
        position: relative;
        background-size: cover;
        position: relative;

        .row {

            height: 100%;
            position: relative;
            z-index: 10;
            color: #fff;

            > div {

                height: 100%;
                display: flex;
                align-items: center;

                h1, h2 {
                    font-weight: bold;
                }

                h1 {
                    font-size: 1.2em;

                    @include media-breakpoint-up(md) {
                        font-size: 1.8em;
                    }
                }

                h1, p {
                    color: #fff;
                }

                p {
                    font-size: 1.2em;
                }

                h2 {
                    color: #fff;
                    font-size: 1.5em;
                }

                img {
                    width: 49%;
                }

                $hdr-btn-style-bg-c: rgba(0, 0, 0, .8);
                a.btn-style {
                    background: $hdr-btn-style-bg-c;
                    font-weight: bold;
                    font-size: 1.2em;
                    color: #fff;
                }

                a.btn-style:hover {
                    background: lighten($hdr-btn-style-bg-c, 50%);
                }

                ul {
                    li {
                        font-size: 1.2em;
                    }
                }
            }

            > div:first-child {
                > div {
                    padding: 2em;
                    background: rgba(0, 0, 0, 0.6);
                }
            }

            > div:last-child {
                // justify-content: flex-end;
            }
        }

    }

    .cd-hero::before {
        @include absoluteMe();
        content: ' ';
        background: $violetOverlayTone;
        z-index: 1;
    }

    .cd-hero.short {
        height: auto;
        padding: 44px 0;
        margin-bottom: 10px;

        .row {
            color: #fff;

            > div:first-child {
                display: block;

                > div {
                    h1 {
                        color: #fff;
                        margin: 0;
                        font-size: 2em;

                        @include media-breakpoint-up(md) {
                            font-size: 2.5em;
                        }
                    }

                    padding: 0;
                    background: none;
                }
            }
        }

    }

    .cd-hero.short::before {
        background: $darkStartGradient;
        background: $darkLinearGradient;
    }

    @media screen and (max-width: 455px) {

        .cd-hero {
            height: auto;
            padding: $standardGutter 0;

        }

    }

  .cd-hero {
    .vid-overlay {
      display: none;
    }
    .container {
      height: 100%;
      position: relative;        
      z-index: 30;
      &__box {

        width: 100%;
        text-align: center;
        @include media-breakpoint-up(md) {
          width: auto;
          text-align: left;
        }
      }
      > div:last-child {
        // background: red;
        // visibility: hidden;
      }
    }
  }

  .cd-hero.no-box {
    background-position: 50%;
    .vid-overlay {
      display: block;
    }
    .container {
      .row {
        .cd-hero__box {
          background: none;
          width: 100%;
          max-width: 840px;
          margin: auto;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          h1 {
            font-size: 2.6rem;
            margin-bottom: 0;
          }
        }
        @include media-breakpoint-down(md) {
          h1 {
            word-break: normal;
            word-wrap: break-word;
          }
        }        
      }
    }
  }  

  #cd_video_hero {
    height: 100vh;
    margin-bottom: 40px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }

    video {
      @include transition(opacity .5s);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .blau {
      color: $blueTone;
    }

    .naranga {
      color: $orangeTone;
    }

    .hdr-content {
      @include transition(opacity .3s);
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 22;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        padding: 0 12px;
        font-size: 2.3rem;
        line-height: 1.8;
        color: #fff;
        
        @media screen and (min-width: $mediumOverwrite) {
          font-size: 3.4rem;
          line-height: 1.5;
        }
      }

      p {
        font-size: 2rem;
      }

      a {
        font-size: 2rem;
        color: #fff;

        i {
          top: 4px;
          position: relative;
        }
      }

      a:hover {
        border-bottom: 1px solid;
        text-shadow: 0px 0px 12px rgba(0, 0, 0, 1);

      }

      img {
        max-width: 145px;
      }
    }

    #hdr_control {
      align-items: flex-end;

      > div {
        margin-bottom: $standardGutter;
      }
    }
  }

</style>
