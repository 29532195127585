<template>
  <Transition name="custom-toasted">
    <div v-if="showToasted" class="custom-toasted">
      <div class="custom-toasted__msg" :class="`msg-${options.type}`">
        {{options.message}}
      </div>
  </div>
  </Transition>
</template>

<script>
export default {
  data () {
    return {
      showToasted: false,
      options: {}
    }
  },

  methods: {
    show(options) {
      this.showToasted = true
      this.options = options

      setTimeout(() => {
        this.showToasted = false
        this.options = {}
      }, options.duration || 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-toasted {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 68px !important;

  &__msg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    min-height: 48px;
    line-height: 1.1em;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 300;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);

    &.msg-error {
      background-color: #f44336;
      color: #fff;
    }

    &.msg-success {
      background-color: #4caf50;
      color: #fff;
      height: 68px !important;
    }
  }
}

.custom-toasted-enter-active,
.custom-toasted-leave-active {
  transition: all 0.4s;
}

.custom-toasted-enter-from {
  opacity: 0;
  transform: translateY(200px);
}

.custom-toasted-enter,
.custom-toasted-leave-to {
  opacity: 0;
}


.custom-toasted-leave,
.custom-toasted-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

</style>
