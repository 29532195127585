<template>
  <div class="cyb-card">
    <slot></slot>
  </div>
</template>

<script>
export default { 
   
}
</script>

<style lang="scss" scoped>
.cyb-card {
  padding: 2rem 1.5rem;
  background: #ebf0f2;
  border-radius: 0.25rem;
}
</style>
