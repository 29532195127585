<template>
    <v-touch @swipeleft="next"
             @swiperight="back"
             :swipe-options="{ direction: 'horizontal' }"
             class="course-base-template create-windows-user">
        <div class="create-windows-user__image-wrapper" ref="container">
            <div v-for="(image, index) in images" :key="index">
                <img class="create-windows-user__image"
                     :class="{ 'create-windows-user__image--active': index === currentIndex }"
                     alt="image"
                     :src="require(`@/pages/trainings/assets/courses/5-golden-rules/create-windows-user/${image.name}`)">
            </div>
            <div class="custom-template__highlight-area"
                 @click="nextSlide"
                 :style="currentSlide.style">
                <icon name="arrow-click"
                      color="secondary"
                      class="custom-template__highlight-arrow"
                      :size="40">
                </icon>
            </div>
            <div class="custom-template__tooltip"
                 :style="currentSlide.tooltipStyle"
                 v-tooltip="{
                   content: currentSlide.tooltipText,
                   classes: 'custom-template__tooltip-content',
                   trigger: 'hover click',
                 }">
                <icon name="info"
                      :size="26"
                      color="white"></icon>
            </div>
        </div>

        <div class="row mb-15">
            <div class="col-6">
                <button class="btn btn--secondary btn--border"
                        type="button"
                        name="back"
                        @click="back">Zurück
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn--secondary"
                        type="button"
                        name="next"
                        @click="next">Weiter
                </button>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue'
  import {throttle} from 'lodash';

  function calc() {
    let width = this.$refs.container && this.$refs.container.clientWidth || this.maxWidth;
    width = Math.min(width, window.innerWidth, this.maxWidth);

    this.currentWidth = width;
  }

  export default {
    name: 'create-windows-user',
    components: {
      Icon
    },
    props: {
      content: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        maxWidth: 1024,
        currentWidth: 1014,
        images: [
          {
            name: '01.png',
            highlightArea: [40, 40, 95, 0.5],
            tooltipText: 'Dieses Beispiel basiert auf Windows 10. Bei älteren Windows-Versionen funktioniert der Vorgang ' +
              'analog, nur einige Symbole sehen anders aus. Klicken Sie unten links auf das Windows-Symbol.',
            tooltipPosition: [84, 7]
          },
          {
            name: '02.png',
            highlightArea: [40, 40, 80.5, 1.1],
            tooltipText: 'Klicken Sie links auf Einstellungen (Zahnrad).',
            tooltipPosition: [80.5, 8]
          },
          {
            name: '03.png',
            highlightArea: [294, 92, 84, 18.5],
            tooltipText: 'Klicken Sie auf "Konten".',
            tooltipPosition: [76, 12.5]
          },
          {
            name: '04.png',
            highlightArea: [290, 40, 64.8, 1.2],
            tooltipText: 'Klicken Sie auf "Familie & weitere Konten".',
            tooltipPosition: [49, 37.8]
          },
          {
            name: '05.png',
            highlightArea: [438, 62, 60.7, 42.4],
            tooltipText: 'Klicken Sie auf "Diesem PC eine andere Person hinzufügen".',
            tooltipPosition: [49, 37.8]
          },
          {
            name: '06.png',
            highlightArea: [514, 40, 70.4, 12.7],
            tooltipText: 'Um einen lokalen Benutzer ohne Windows Konto zu erstellen klicken Sie auf "Ich kenne ' +
              'die Anmeldeinformationen für diese Person nicht".',
            tooltipPosition: [60.5, 9.5]
          },
          {
            name: '07.png',
            highlightArea: [390, 40, 75.2, 13.2],
            tooltipText: 'Nach Klicken auf "Benutzer ohne Microsoft-Konto hinzufügen" lässt sich ein lokales ' +
              'Benutzerkonto ohne Bindung an ein Microsoft-Konto anlegen.',
            tooltipPosition: [71, 52]
          },
          {
            name: '08.png',
            highlightArea: [166, 52, 82.6, 55],
            tooltipText: 'Nachdem Sie ein Benutzername, ein sicheres Passwort und drei Sicherheitsfragen gewählt ' +
              'haben, haben Sie einen neuen lokalen Benutzer ohne Adminrechte erstellt.',
            tooltipPosition: [50, 50]
          }
        ],
        currentIndex: 0
      }
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.calc();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    computed: {
      scaleRatio() {
        return `scale(${this.currentWidth / this.maxWidth})`;
      },
      slideNumber() {
        return +this.$route.params.slide;
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      },
      currentSlide() {
        const {
          name,
          highlightArea: [w, h, t, l],
          tooltipText,
          tooltipPosition: [tooltipTop, tooltipLeft]
        } = this.images[this.currentIndex];

        return {
          name,
          style: {
            width: w + 'px',
            height: h + 'px',
            top: t + '%',
            left: l + '%',
            transform: this.scaleRatio
          },
          tooltipText,
          tooltipStyle: {
            top: tooltipTop + '%',
            left: tooltipLeft + '%',
            transform: this.scaleRatio
          }
        };
      }
    },
    methods: {
      calc,
      onResize: throttle(calc, 100),
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      next() {
        this.$router.push({
          params: { ...this.$route.params, slide: this.nextSlideNumber }
        });
      },
      nextSlide() {
        this.currentIndex === (this.images.length - 1) ? this.next() : this.currentIndex++;
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .create-windows-user {
        position: relative;

        &__image-wrapper {
            width: 100%;
            position: relative;
            padding-top: 75%;
            margin: 0 auto;
            margin-bottom: 40px;
        }

        &__image {
            position: absolute;
            display: block;
            width: 100%;
            height: auto;
            left: 0;
            top: 0;
            opacity: 0;

            &--active {
                opacity: 1;
            }
        }
    }
</style>
