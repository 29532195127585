<template>
  <ModalBasic :id="id" modalClass="modal-event">
    <h4 class="modal-heading">
      {{ content.big_window_header }}
    </h4>

    <div class="modal-event__top">
      <b-row class="modal-event__top-text">
        <b-col :lg="content.big_window_small_left_side_text_optional ? 6 : 12" v-if="content.event_start_date_optional && content.event_end_date_optional">
          <div class="modal-event__title">Datum und Uhrzeit</div>
          <table class="modal-event__date">
              <tr>
                <td class="modal-event__date-item">{{ getDay(content.event_start_date_optional) }},</td>
                <td class="modal-event__date-item">{{ getDate(content.event_start_date_optional) }}</td>
                <td class="modal-event__date-item">{{ getTime(content.event_start_date_optional) }} Uhr MESZ</td>
              </tr>
              <tr>
                <td class="modal-event__date-item">{{ getDay(content.event_end_date_optional) }},</td>
                <td class="modal-event__date-item">{{ getDate(content.event_end_date_optional) }}</td>
                <td class="modal-event__date-item">{{ getTime(content.event_end_date_optional) }} Uhr MESZ</td>
              </tr>
          </table>
        </b-col>
        <b-col :lg="content.event_start_date_optional && content.event_end_date_optional ? 6 : 12">
          <div class="notification-tt__text" v-if="content.big_window_small_left_side_text_optional" v-html="$sanitizeHtml(content.big_window_small_left_side_text_optional)"></div>
        </b-col>
      </b-row>

      <ButtonComponent
        v-if="content.big_window_middle_button_text_optional && content.big_window_middle_button_link_optional"
          class="modal-event__link"
          btnStyle="link-primary"
          behavior="externalLink"
          :link="content.big_window_middle_button_link_optional"
          :icon="{
            name: 'arrow-full-right',
            position: 'right'
          }"
      >
        <span class="mr-2">{{ content.big_window_middle_button_text_optional}} </span>
      </ButtonComponent>
    </div>

    <div class="modal-event__components"
      v-for="component in content.big_window_main_components_optional" :key="component.id">
       <!-- basic text  -->
      <div v-if="component.__typename === 'ComponentRepeatableCopyItem'">
        <h4 v-if="component.title" class="mb-2">
          {{ component.title }}
        </h4>
        <h6 v-if="component.subtitle" class="mb-2">
          {{ component.subtitle }}
        </h6>
        <div v-if="component.copy" class="mb-3" v-html="$sanitizeHtml(component.copy)"></div>
      </div>
        <!-- list -->
      <div v-if="component.__typename === 'ComponentContentBasicList'" class="mb-2">
        <h4 v-if="component.header"> {{ component.header }}</h4>
        <ListComponent v-if="component.__typename === 'ComponentContentBasicList'" list-style="advantages" class="mb-3">
          <li v-for="(element, index) in component.list" :key="index">
            {{ element.item }}
          </li>
        </ListComponent>
      </div>
    </div>
    <div class="mb-5"></div>

    <div class="modal-event__bottom">
      <ButtonComponent
        v-if="content.button_internal_redirect_optional"
        btnStyle="accent"
        btnSize="md"
        :link="content.big_window_button_link"
        @click.native.stop="closeModal()"
      >
        {{ content.big_window_button_text || 'Anmeldung'}}
      </ButtonComponent>
      <ButtonComponent
        v-else
        btnStyle="accent"
        btnSize="md"
        behavior="externalLink"
        :link="content.big_window_button_link"
      >
        {{ content.big_window_button_text || 'Anmeldung'}}
      </ButtonComponent>
      <span v-if="content.big_window_price" class="modal-event__price">€ {{ content.big_window_price }}</span>
    </div>
  </ModalBasic>
</template>

<script>
  import ModalBasic from '@/components/reusable/ModalBasic';
  import ButtonComponent from '@/components/reusable/ButtonComponent';
  import ListComponent from '@/components/reusable/ListComponent';
  import { mapGetters } from 'vuex'
  import moment from 'moment';

  export default {
    components: {
      ModalBasic,
      ButtonComponent,
      ListComponent
    },
    props: {
      id: {
        type: String,
        required: true,
      }
    },
    methods: {
      closeModal () {
        this.$bvModal.hide('event-modal')
      },
      getDay (t) {
        return moment(t).format("dd")
      },
      getDate (t) {
        return moment(t).format("DD.MM.YY")
      },
      getTime (t) {
        return moment(t).format("h:mm")
      },
    },
    computed: {
      ...mapGetters(['notifications', 'eventModalId']),
      content() {
        const id = this.eventModalId ? this.eventModalId : null
        const notification = this.notifications.find(n => n.notification.id === id)
        // fix linting error
        return notification && notification.notification
          ? notification.notification
          : {}
      },
    }
  }
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.modal-heading {
  text-align: left;
  margin-bottom: 2rem;
  font-weight: bold;
}

.modal-event {
  &__top {
    margin-bottom: 1.5rem;
  }
  &__top-text {
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  &__date {
    margin-bottom: 1rem;
  }

  &__date-item {
    &:first-child {
      width: 40px;
    }

    &:nth-child(2) {
      width: 90px;
    }
  }

  &__components {
    margin-bottom: 1.5rem;
  }

  &__bottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__price {
    font-size: 1.5rem;
    font-weight: bold;
    color: $primary;
    text-align: center;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.875rem;
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
}
</style>
