<template>
  <section class="contacts-section">
    <div>
      <div>
        <b
          >Sie konnten Ihre Branche nicht finden?</b
        >
      </div>
      <div>
        Wir helfen Ihnen persönlich weiter!
      </div>
    </div>

    <ButtonComponent
      class="submit-btn"
      btn-style="accent-border"
      behavior="internalLink"
      link="/kontakt"
    >
      Kontaktieren Sie uns
    </ButtonComponent>
  </section>
</template>

<script>
import ButtonComponent from './calculator-v2.0/ui/ButtonComponent.vue'
export default {
  components: { ButtonComponent }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.contacts-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebf0f2;
  padding: 2.5rem 0;
  gap: 2rem;

  @include media-breakpoint-down(lg) {
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 2rem;
  }

  .submit-btn {
    max-width: 285px;
    width: 100%;
  }
}
</style>
