import get from 'lodash/get';

const ConditionCheck = {
  check(inputs, conditions, checkoutStore, agencyKey = null, options = {}) {

    if (conditions && conditions[0] && conditions[0].property) {
      if (conditions[0].property === 'agencyKey' && agencyKey) {
        // add agencyKey object to inputs to allow the condition to be checked
        inputs.agencyKey = agencyKey
      }
    }

    const selectedModules = inputs.selectedModules && inputs.selectedModules[inputs.insurer];
    if (!conditions || !conditions.length) {
      return true;
    }
    
    return conditions.every((condition) => {
      if(condition.property.includes('answers') && options.passFollowUps) {
        return true
      }

      if (condition.property === 'env') {
        let mode;

        if (checkoutStore.mode === 'www') {
          mode = 'www';
        } else if (checkoutStore.mode === 'broker' && checkoutStore.style === 'affiliate') {
          mode = 'affiliate';
        } else if (checkoutStore.mode === 'broker') {
          mode = 'broker';
        }

        return condition.value === mode;
      } else if (condition.check === 'indexOf') {
        if (condition.property === 'selectedModules') {
          return selectedModules && selectedModules.indexOf(condition.value) > -1;
        } else {
          return get(inputs, condition.property)
            .indexOf(condition.value) > -1;
        }
      } else if (condition.check === 'gtlte') {
        return get(inputs, condition.property) > condition.value[0] && get(inputs, condition.property) <= condition.value[1];
      } else if (condition.check === 'gte') {
        return get(inputs, condition.property) >= condition.value;
      } else if (condition.check === 'gt') {
        return get(inputs, condition.property) > condition.value;
      } else if (condition.check === 'lte') {
        return get(inputs, condition.property) <= condition.value;
      } else if (condition.check === 'lt') {
        return get(inputs, condition.property) < condition.value;
      } else if (condition.check === 'in') {
        return condition.value.indexOf(get(inputs, condition.property)) > -1;
      } else if (condition.check === 'neq') {
        return get(inputs, condition.property) !== condition.value;
      }else if (condition.check === 'eq') {
        return get(inputs, condition.property) === condition.value;
      }
    });
  }
};

export default ConditionCheck;
