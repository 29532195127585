<template>
  <div class="product-details__text">
    <div class="product-details__industry detail-field">
      <div class="label d-print-none">
        Branche
      </div>
      <div class="value">
        {{ inputs.industry.l }}
      </div>
    </div>
    <div class="detail-field d-print-none">
      <div class="label">
        Jahresumsatz
      </div>
      <div class="value"
        ><span class="range-start">{{ revenueRange.start | currency(0) }}</span> -
        <span class="range-end">{{ revenueRange.end | currency(0) }}</span></div
      >
    </div>
    <div v-if="isOffer" class="d-print-none details-group">
      <div class="detail-field">
        <div class="label">
          Selbstbehalt 
        </div>
        <div class="value">{{ inputs.deductible | currency(0) }}</div>
      </div>
      <div class="detail-field">
        <div class="label">
          Versicherungssumme
        </div>
        <div class="value">{{ inputs.insuredSum | currency(0) }}</div>
      </div>
      <div
        v-if="$store.getters.insuredSumOptionsAllowed"
        class="filter-option form-group filter-option--checkbox filter-option--checkbox-offer"
      >
        <b-form-checkbox
          class="cd-control checkbox-accent control"
          name="insured-sum-options"
          v-model="$store.state.offer.showInsuredSumOptions"
        >
          <template v-if="!$store.state.offer.showInsuredSumOptions">Alternativen einblenden</template>
          <template v-else>
            Alternativen ausblenden
          </template>
            <button :id="isMobile ? 'alt-info-btn-mob' : isPrint ? 'alt-info-btn-print' : 'alt-info-btn'"
              type="button" class="info-btn btn btn-icon"
            >
              <icon name="info" color="white" width="18" height="18"></icon>
            </button>

            <b-tooltip
              :target="isMobile ? 'alt-info-btn-mob' : isPrint ? 'alt-info-btn-print' : 'alt-info-btn'"
              triggers="hover"
            >
            <div>
              Hier können Sie alternative Versicherungssummen und die dazugehörigen Jahresbruttoprämien sehen.
            </div>
            </b-tooltip>
        </b-form-checkbox>
      </div>
      <div class="d-print-none mr-4">
        <slot>
        </slot>
      </div>
    </div>
    <div class="d-none d-print-block">
      <div>
        Jahresumsatz
        <b>{{ revenueRange.start | currency(0) }} -
        {{ revenueRange.end | currency(0) }}</b>
      </div>
      <div>
        Versicherungssumme
        <b>{{ inputs.insuredSum | currency(0) }}</b>
      </div>
      <div>
        Selbstbehalt <b>{{ inputs.deductible | currency(0) }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../Icon.vue'

export default {
  components: {Icon},

  props: ['inputs', 'revenueRange', 'isOffer', 'allowRatingsSwitch', 'isScoreVisible', 'sortByOptions', 'sortBy', 'insurers', 'isMobile', 'isPrint']
}
</script>

<style lang="scss" scoped>
.detail-field {
  @media screen {
    display: flex;
    justify-content: space-between;

    .label {
      font-size: 1rem;
      font-weight: 800;
    }

    .value {
      width: 153px;
      word-break: break-word;
      hyphens: auto;

      .range-start, .range-end {
        display: inline-flex;
      }
    }
  }
}

.filter-option--checkbox {
  margin-top: 1rem;

  .custom-checkbox.custom-control ::v-deep {
    width: 100%;
    max-width: 100%;

    .custom-control-input ~ .custom-control-label::before {
      border-color: #fff;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: transparent;
    }

    .custom-control-label {
      font-weight: 800;
    }
    
  }

}

.info-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.5rem;
  padding: 0;
  min-width: fit-content;
}
</style>
