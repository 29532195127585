import holiday from 'holiday-de';
import moment from 'moment';

holiday.setState('be');

export default {
  isHoliday(date) {
    return holiday.isHoliday(date);
  },
  customPredictor: (date = new Date()) => {
    date = new Date(date)
    const now = moment();
    const yesterDay = moment(date).subtract(1, 'd');
    const timeThreshold = moment().hour(14).minute(59);

    if (now.isAfter(moment(date), 'day') || now.isSame(moment(date), 'day')) {
      return true;
    }

    // exception for 1st of january
    if (date.getMonth() === 0 && date.getDate() === 1) {
      return false;
    }

    if (date.getDay() === 6 || date.getDay() === 0) {
      return true;
    }

    if (now.isSame(yesterDay, 'd')) {
      if (now.isAfter(timeThreshold)) {
        return true;
      }
    }

    return holiday.isHoliday(date);
  }
}
