<template>
  <div id="container_block">

    <div id="tab_content" class="tab-content row">

    <div class="">
      <!-- <div class="row">
        <div class="medium-6 large-6 columns">
          <h4>Vorgang des Angriffs</h4>
          <ul>
            <li v-for="(val, key) in tab.attacks"><span>{{val}}</span></li>
          </ul>
        </div>
        <div class="medium-6 large-6 columns vector-icon">
          <img :src="'/static/vectors/' + tab.icon">
        </div>
      </div> -->

    <div class="expanded-bucket clearfix" :style="'background-image: url(' + tab.example.image + ')'">
       <!--  -->
      <div class="row">
        <div class="col-12 col-md-6 columns image-bkg">
          <div class="content-front">
            <h4>Ein Praxisbeispiel</h4>
          </div>
        </div>
        <div class="col-12 col-md-6 columns">
          <h4>{{tab.example.title}}</h4>        
          <p v-for="(val, key) in tab.example.content" v-html="val"></p>
        </div>
      </div>

    </div>

      <div class="row damage-list">
        <div class="col-12 col-md-6 columns">
          <h4>Konsequenzen</h4>
          <ul>
            <li v-for="(val, key) in tab.damage.content"><span>{{val}}</span></li>
          </ul>
          <p v-if="tab.damage.costs"><img src="/static/ausrufezeichen_orange.svg"><span>{{tab.damage.costs}}</span></p>
        </div>

        <div class="col-12 col-md-6 columns">
          <h4>Deckung der Cyber-Versicherung</h4>
          <ul class="">
            <li v-for="(val, key) in tab.coverage.content"><span>{{val}}</span></li>            
          </ul>
        </div>
      </div>


    </div>
  </div>

  </div>
</template>

<script>
import eventBus from '@/components/shared/EventBus.js'

export default {
  name: 'BeginnenSP',
  props: ['tab'],
  data () {
    return {
      content: {}
    }
  },
  computed: {
  },
  mounted: function () {
    eventBus.$emit('rebindSwipe')
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";
@import "../scss/mixins";

#tab_content {
  // margin-top: $standardGutter * 2;
  width: auto!important;
  h4, h5 {
    font-size: 1.5em;
  }
  h4 {
    // color: $blueTone;
    font-weight: 500;
    margin-bottom: 20px;
  }

  ul {
    li {
      margin-bottom: .5em;
      font-size: 1.3em;
      color: $orangeTone;
      span {
        color: $bodyColor;
      }
    }
  }

  p {
    font-size: 1.3em;
  }
  div.row {
   margin-bottom: $standardGutter;
  }
  .row:not(.expanded-bucket) {
    padding-left: calc($standardGutter * 2);
    padding-right: calc($standardGutter * 2);
  }
  .section-title {
    text-align: center;
  }

  .expanded-bucket {
    $paddingSize: 2rem;
    margin-bottom: $standardGutter;
    background-size: cover;
    // overflow: hidden;
    .row {
      padding:0;
    .columns {
      padding-left: 0;
      padding-right: 0;
      
      p {
        // background: #fff;
        padding-left: $paddingSize;
        padding-right: $paddingSize;
      }
    }
    .columns:last-child {
      background: rgba(255, 255, 255, 0.94);
      min-height: 460px;
    }
    h4 {
      width: 100%;
      padding: .5rem $paddingSize;
      background: rgba(0, 0, 0, 0.14);
      color : #000;
      margin-bottom: 1.5rem;
      font-size: 1.2em;
    }
    .image-bkg {
      position: relative;
     
    .content-front {
      @include absoluteMe();
      z-index: 20;
      text-align: right;
      
      h4 {
        color: #fff;
      }
     }

     > div:last-child {
      //  background: red;
     }
    }
  }
  }

  .damage-list {

    h4 {
      margin-bottom: $standardGutter;
    }
    p:last-child {
      font-weight: bold;
      margin-top: 10px;
      color: $orangeTone;
      // display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.5rem;
      > * {
        height: 50px;
        display: inline-block;
      }
      span {
        // line-height: 1;
        position: relative;
        top: 5px;
        margin: 0 0 0 10px;
      }
      img {
        // height: 50px;
        // display: inline-block;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      li {
        background: url(/public/static/checkmark-blue.svg) no-repeat 0 0;
        padding-bottom: 20px;
        background-size: 40px;
        padding-left: calc($standardGutter * 2);
        span {
          margin-top: 4px;
          display: inline-block;
        }
      }
    }

    > div:first-child {
      ul {
        li {
          background-image: url(/public/static/arrow-orange.svg);
          background-size: 19px;
          background-position: 0 0;
          padding-left: $standardGutter;
        }
      }
    }

    > div:last-child {
      ul {
        li {
          margin-bottom: $standardGutter ;
        }
      }
    }    
// arrow-orange.svg

  }

  .damage-list:last-child {
    margin-bottom: 0;
    padding-bottom: calc($standardGutter * 2);
  }

  .vector-icon {
    text-align: center;
    img {
      // height: 144px;
      height: 128px;
      margin-top: 62px;
    }
  }
}

</style>
