<template>
  <div>
    <div class="banner-section-outer">
      <section
        class="banner-section section-pd-y"
        :class="{
          'banner-section--v2': content.background === 'primaryWaveV2',
          'banner-section--v3': content.background === 'primaryWaveV3',
          'banner-section--trustpilot': content.background === 'primaryWaveTrustPilot'
        }"
      >
        <div class="container position-relative z-index-3">
          <div class="banner-section__inner">
            <div class="banner-section__heading-container">
              <h2 v-if="content.h2" class="section-heading">
                {{ content.h2 }}
                <div v-if="content.heading2" class="section-heading mt-1">
                  {{ content.heading2 }}
                </div>
              </h2>
              <RichText
                v-if="content.text"
                class="banner-section__text"
                :content="content.text"
              />
            </div>
            <div class="banner-section__btn-container">
              <div v-if="content.buttons.length" class="banner-section__action">
                <div
                    v-for="(button, i) in content.buttons"
                    :key="button.id"
                    class="banner-section__btn-item">
                  <ButtonComponent
                    :button="button"
                    :icon="button.icon"
                    rel="noopener"
                    >{{ button.title }}</ButtonComponent
                  >
                  <div
                    v-if="i === 0 && content.buttonSubtitle"
                    class="banner-section__btn-subtitle"
                  >
                    {{ content.buttonSubtitle }}
                  </div>
                  <div
                    v-else-if="i === 1 && content.buttonSubtitle2"
                    class="banner-section__btn-subtitle"
                  >
                    {{ content.buttonSubtitle2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img
        v-if="content.background === 'primaryWave'"
        class="curve-form"
        src="@/assets/banner-section/curve-form.svg"
        alt=""
      />
      <img
        v-else-if="content.background === 'primaryWaveV2'"
        class="curve-form curve-form--v2"
        src="@/assets/banner-section/curve-form-v2.svg"
        alt=""
      />
      <img
        v-else-if="content.background === 'primaryWaveV3'"
        class="curve-form curve-form--v3"
        src="@/assets/banner-section/curve-form-v3.svg"
        alt=""
      />
      <img
        v-else-if="content.background === 'primaryWaveTrustPilot'"
        class="curve-form curve-form--tp"
        src="@/assets/banner-section/curve-form-trustpilot.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/reusable/ButtonComponent'
import RichText from '@/components/reusable/RichText'

export default {
  components: {ButtonComponent, RichText},

  props: {
    content: {
      type: Object,
      required: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
@import '../scss/variables';
@import '../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.banner-section-outer {
  position: relative;
  background: #31a9e0;
  overflow-x: hidden;
}

.banner-section {
  color: #fff;
  background:
    linear-gradient(
      0deg,
      #84cced 0%,
      rgba(49, 169, 224, 0) 100%
    ); //v1 gradioent

  .section-heading {
    color: #fff;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.25rem;
    }
  }

  &--v2 {
    background:
      linear-gradient(
        180deg,
        #84cced 0%,
        rgba(49, 169, 224, 0) 68.46%
      ); //v2 gradient

    .section-heading {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  &--v3 {
    background:
      linear-gradient(
        180deg,
        #84cced 0%,
        rgba(49, 169, 224, 0) 70%
      ); //v3 gradient

    .section-heading {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      // z-index: 2;
    }
  }

  &--trustpilot {
    background: #829ca9;

    .section-heading {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__heading-container {
    z-index: 1;

    @include media-breakpoint-up(md) {
      max-width: 65%;
    }
  }

  &__text {
    @include media-breakpoint-down(sm) {
      margin-bottom: 1.25rem;
    }
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    max-width: 75%;

    @include media-breakpoint-down(lg) {
      max-width: 310px;

      .btn-wrapper,
      .btn-basic {
        width: 100%;
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: 310px;

      .btn-wrapper,
      .btn-basic {
        width: 100%;
      }
    }
  }

  &__btn-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      gap: 0.5rem 1rem;
      flex-direction: row;
      flex-wrap: wrap;
    }

    button {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }

  &__heading-subtitle {
    margin-top: 0.75rem;
  }

  &__btn-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }

  &__btn-subtitle {
    // margin-top: 0.5rem;

    // @include media-breakpoint-down(sm) {
    //   margin-top: 0;
    // }
  }
}

.curve-form {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 145px;

  &--v2 {
    top: 0;
    left: 0;
    height: 181px;
    width: 1072px;
    z-index: 0;
  }

  &--v3 {
    top: 0;
    left: 0;
    width: 907px;
    height: 170px;
    z-index: 0;
  }

  &--tp {
    top: 0;
    right: 0;
    width: 1236px;
    height: 211px;
    z-index: 0;
  }
}

p {
  margin-bottom: 0;
}

p + p {
  margin-top: 1rem;
}
</style>
