<template>
  <div
    class="read-more"
    :class="{'read-more--collapsed': !isOpened}"
    :style="cssVars">
    <slot />
    <div class="read-more__trigger" @click="toggleCollapse">
      <slot v-if="!isOpened" name="trigger-open"/>
      <slot v-else name="trigger-close"/>
    </div>
  </div>
</template>

<script>
export default { 
  props: {
    content: {
      type: Object,
      default () { 
        return {
          collapsedHeight: '48px',
          bg: '#fff'
        } 
      }
    }
  },
  data () {
    return {
      cssVars: {
        '--collapsed-height': this.content.collapsedHeight || '48px',
        '--bg': this.content.bg || '#fff'
      },
      isOpened: false
    }
  },
  methods: {
    toggleCollapse () {
      this.isOpened = !this.isOpened
    }
  }
}
</script>

<style lang="scss" scoped>
.read-more {
  position: relative;

  &__trigger {
    width: 100%;
  }

  &--collapsed {
    overflow: hidden;
    height: var(--collapsed-height);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3rem;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255,0,0,0), var(--bg) 78%);
    }

    .read-more__trigger {
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }
}
</style>
