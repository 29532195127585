<template>
    <div class="qiuz-results">
        <h3 class="qiuz-results__title">Ergebnis</h3>
        <vue-progress :value="progress"
                      class="qiuz-results__progress"
                      :class="{ 'qiuz-results__progress--fail': !passed }"></vue-progress>
        <h4 class="qiuz-results__final-score">Sie haben {{progress}}% der benötigten 80% erreicht</h4>
        <div class="text--center">
            <icon :name="passed ? 'done' : 'close'"
                  :size="80"
                  class="qiuz-results__pass-icon"
                  :class="{ 'qiuz-results__pass-icon--fail': !passed }"
                  color="white"></icon>
        </div>
        <p class="qiuz-results__pass-text"
           :class="{ 'qiuz-results__pass-text--fail': !passed }">
            {{ passed ? 'Super, Sie haben das Modul erfolgreich absolviert!' : 'Sie haben leider nicht bestanden.' }}
        </p>
        <div>
            <button class="btn btn--secondary"
                    @click="completeCourse()"
                    v-if="passed">Modul beenden
            </button>
            <button class="btn btn--secondary"
                    @click="repeatWholeCourse()"
                    v-else-if="isCourseRepeatNeeded">Modul wiederholen
            </button>
            <button class="btn btn--secondary"
                    @click="backToStart()"
                    v-else>Quiz wiederholen
            </button>
        </div>
    </div>
</template>

<script>
  import VueProgress from '@/components/vue-progress/VueProgress.vue';
  import Icon from '@/pages/trainings/Icon.vue';
  const MINIMUM_PASS_SCORE = 80;

  export default {
    name: 'quiz-results',
    components: {
      VueProgress,
      Icon
    },
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse || {};
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      progress() {
        return +this.selectedCourseProgress.result || 0;
      },
      passed() {
        return this.progress >= MINIMUM_PASS_SCORE;
      },
      quizRepeatCount() {
        return this.selectedCourseProgress.quizRepeatCount || 0;
      },
      isCourseRepeatNeeded() {
        return this.quizRepeatCount > 0 && this.quizRepeatCount % 3 === 0;
      }
    },
    methods: {
      async backToStart() {
        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          slideSlug: 'quiz-1'
        });

        this.$router.replace({
          name: 'training-quiz',
          params: {
            step: '1'
          }
        });
      },
      async repeatWholeCourse() {
        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          slideSlug: 'slide-1'
        });

        this.$router.replace({
          name: 'training-module',
          params: {}
        });
      },
      completeCourse() {
        localStorage.removeItem('answers');
        this.$router.replace('/mitarbeiterschulung');
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .qiuz-results {
        text-align: center;

        &__title {
            margin: 0 0 30px;
            font-size: 2rem;
            line-height: 2.5rem;
            font-weight: 500;
            color: $dark-blue;
        }

        &__final-score {
            margin: 0 0 30px;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 500;
            color: $dark-blue;
        }

        &__progress {
            max-width: 80%;
            margin: 0 auto 25px;

            .vue-progress__label {
                display: none;
            }

            .vue-progress__holder {
                width: 100%;
                background-color: $quiz-progress-bg-color;
            }

            .vue-progress__holder,
            .vue-progress__line {
                height: 30px;
                border-radius: 6px;
            }

            &--fail .vue-progress__line {
                background-color: $red;
            }
        }

        &__pass-icon {
            border-radius: 10px;
            background-color: $green;
            margin-bottom: 15px;

            path:not([stroke]) {
                stroke: #fff;
                stroke-width: 1px;
            }

            &--fail {
                background-color: $red;
            }
        }

        &__pass-text {
            color: $green;
            font-size: 1.35rem;
            line-height: 1.75rem;
            margin: 0 0 25px;

            &--fail {
                color: $red;
            }
        }
    }
</style>
