<template>
    <div class="container">
        <form v-on:submit.prevent.stop="submitForm()" name="step3" id="step_form">
            <div class="question">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <div class="row" id="question_header">
                            <h2 class="question__title">
                                Frage 1 von 2
                            </h2>
                        </div>
                        <p class="question__text">
                            Bestanden in den letzten 5 Jahren eine oder mehrere Vorversicherungen (Rechtsschutz) für Sie oder
                            für mitversicherte Personen?
                        </p>
                    </div>
                    <div class="col-12 col-sm-4 d-flex align-items-end justify-content-end"
                         id="desktop_navigator">
                        <div class="question__answers text-center">
                            <button type="button"
                                    @click="answerQuestion('no')"
                                    :class="{'btn-primary': isSelected('no')}"
                                    class="btn btn-default mr-2">Nein
                            </button>
                            <button type="button"
                                    @click="answerQuestion('yes')"
                                    :class="{'btn-primary': isSelected('yes')}"
                                    class="btn btn-default">Ja
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="inputs.answers['q-roland-1'] === 'yes'">
                <div class="col-12 col-sm-6 form-group" :class="getFieldClass('ceding')">
                    <label for="ceding">Bei Gesellschaft</label>
                    <input class="form-control"
                           type="text"
                           id="ceding"
                           name="ceding"
                           v-validate="'required'"
                           v-model="inputs.legal.ceding">
                </div>
                <div class="col-12 col-sm-6 form-group" :class="getFieldClass('policyNumber')">
                    <label for="policyNumber">Versicherungsschein-Nummer</label>
                    <input class="form-control"
                           type="text"
                           id="policyNumber"
                           name="policyNumber"
                           v-validate="'required'"
                           v-model="inputs.legal.policyNumber">
                </div>
                <div class="col-12 col-sm-6 form-group" :class="getFieldClass('harmCount')">
                    <label for="harmCount">Anzahl Schäden in den letzten 5 Jahren</label>
                    <input class="form-control"
                           type="text"
                           id="harmCount"
                           name="harmCount"
                           v-validate="'required'"
                           v-model="inputs.legal.harmCount">
                </div>
                <div class="col-12 form-group">
                    <label>Wurde die Versicherung gekündigt?</label>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="alreadyCancelledNo"
                               v-model="inputs.legal.alreadyCancelled"
                               value="no"
                               name="alreadyCancelled">
                        <label class="custom-control-label" for="alreadyCancelledNo">Nein</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="alreadyCancelledYes"
                               v-model="inputs.legal.alreadyCancelled"
                               value="yes"
                               name="alreadyCancelled">
                        <label class="custom-control-label" for="alreadyCancelledYes">Ja</label>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group"
                     v-if="inputs.legal.alreadyCancelled === 'yes'">
                    <label>Zu wann gekündigt?</label>
                    <datepicker placeholder="heute"
                                format="dd.MM.yyyy"
                                input-class="form-control"
                                :language="de"
                                :monday-first="true"
                                v-model="inputs.legal.cancellationDate"></datepicker>
                </div>
                <div class="col-12 col-sm-6 form-group"
                     :class="getFieldClass('whoCancelled')"
                     v-if="inputs.legal.alreadyCancelled === 'yes'">
                    <label>Wer hat die Kündigung ausgesprochen?</label>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="whoCancelledCustomer"
                               v-model="inputs.legal.whoCancelled"
                               value="customer"
                               name="whoCancelled">
                        <label class="custom-control-label" for="whoCancelledCustomer">Kunde und/oder mitversicherte
                            Person</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="whoCancelledInsurer"
                               v-model="inputs.legal.whoCancelled"
                               value="insurer"
                               name="whoCancelled">
                        <label class="custom-control-label" for="whoCancelledInsurer">Gesellschaft/ -en</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <button type="button"
                            class="btn-style"
                            @click="goBack()">
                        <icon name="chevron-left" color="secondary"></icon>
                        Zurück
                    </button>
                </div>
                <div class="col-6 text-right">
                    <button type="button"
                            class="btn-style primary"
                            @click="goForward()"
                            v-if="isForwardVisible()">
                        Weiter
                        <icon name="chevron-right" color="white"></icon>
                    </button>
                </div>
            </div>
            <input type="submit" id="step_submit">
        </form>
        <div id="navigator" style="margin-top: 30px;">
            <div class="question__answers text-center">
                <button type="button"
                        @click="answerQuestion('no')"
                        :class="{'btn-primary': isSelected('no')}"
                        class="btn btn-default">Nein
                </button>
                <button type="button"
                        @click="answerQuestion('yes')"
                        :class="{'btn-primary': isSelected('yes')}"
                        class="btn btn-default btn-primary">Ja
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'
  import Icon from '../Icon.vue';
  import Datepicker from 'vuejs-datepicker';
  import {de} from 'vuejs-datepicker/dist/locale';

  export default {
    name: 'StepLegalQ1',
    data() {
      return {
        de: de
      }
    },
    computed: {
      inputs() {
        return this.$store.state.checkout.application.inputs;
      }
    },
    components: {
      Icon,
      Datepicker
    },
    props: ['step'],
    mounted() {
      eventBus.$emit('stepChangeCompleted');
      eventBus.$emit('disableHdrNext', true);

      if (!this.inputs.legal.whoCancelled) {
        this.$set(this.inputs.legal, 'whoCancelled', 'customer');
      }

      if (!this.inputs.legal.cancellationDate) {
        this.$set(this.inputs.legal, 'cancellationDate', new Date());
      }

      if (!this.inputs.legal.alreadyCancelled) {
        this.$set(this.inputs.legal, 'alreadyCancelled', 'no');
      }
    },
    methods: {
      getFieldClass(fieldName) {
        if (!this.fields[fieldName]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      },
      goBack() {
        eventBus.$emit('goBack');
      },
      goForward() {
        this.$set(this.step, 'completed', true);
        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              eventBus.$emit('changeStepForward', this.step);
            }
          });
      },
      answerQuestion(answer) {
        this.$set(this.inputs.answers, 'q-roland-1', answer);
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        if (answer === 'no') {
          this.goForward();
        }
      },
      isSelected(answer) {
        return this.inputs.answers['q-roland-1'] === answer;
      },
      isForwardVisible() {
        return ['yes', 'no'].indexOf(this.inputs.answers['q-roland-1']) > -1;
      }
    },
    destroyed: function () {
      eventBus.$off('submitStep');
    }
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '../scss/button';
    @import '../scss/form';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .question {
        display: inline-block;
        margin-right: 10px;
        min-height: 220px;
        width: 100%;

        .button {
            margin-right: 0;
        }

        &__text {
            position: relative;
        }

        &__answers {
            .btn {
                margin-bottom: 8px;
                width: 107px;
                border-radius: 8px;
                box-shadow: none;
            }
            .btn:not(.btn-primary) {
                background: #ddd;
                outline-color: #ddd;
            }

            .btn.btn-primary {
                border: 0;
            }
            .btn:active {
                $thisColor: #efe9e9;
                background: $thisColor;
            }
        }
    }

    #navigator {
        position: fixed;
        bottom: 0;
        z-index: 10000;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        display: none;
    }

    @include media-breakpoint-down(xs) {
        #navigator {
            display: block;
        }

        #desktop_navigator {
            display: none !important;
        }

        .question {
            &__answers {
                display: flex;
                height: 50px;
                .btn {
                    margin: 0;
                    width: 50%;
                    border-radius: 0;
                }
            }
        }
    }

</style>
