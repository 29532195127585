<template>
  <div  v-if="url">
    <div class="pdf-viewport-container">
      <div class="pdf-viewport">
        <pdf
          ref="pdf"
          :src="url"
          :rotate="rotate"
          @num-pages="numPages = $event"
          :page="pageNum"
          @progress="loadedRatio = $event"
          style="width: 95%;margin: auto;"
        />
			<div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white;margin:auto; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>        
      </div>
    
      <div class="controls">
        <div>
          <!--<span class="control control-icon ion-android-print" @click="onPrint"></span>-->
          <a :href="url" class="control control-icon ion-android-download" @click="onDownload" download="preview"></a>
          <a class="control control-icon" @click="rotate += 90"><i class="ion-ios-refresh-empty"></i></a>
          <!-- <span class="control control-icon ion-email" @click="onMail"></span> -->
        </div>
        <div>
          <span>Page: <span>{{ pageNum }}</span> / <span>{{ numPages }}</span></span>
        </div>
        <div>
          <span class="control control-prev" @click="onPrevPage" :class="{'disabled': pageNum === 1}">&lt; Zurück</span>
          <button class="control btn-style primary" @click="onNextPage" :class="{'disabled': pageNum === numPages}">Weiter &gt;</button>
        </div>
      </div>
      <span class="control-close" @click="closePDF">&times;</span>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import eventBus from '@/components/shared/EventBus.js'
import { KEY_CODES } from '@/components/shared/KeyboardCodes';

export default {
  name: 'BPdf',
  data () {
    return {
      numPages: 0,
      pageNum: 1,
      url: '',
      loadedRatio: 0,
      rotate: 0
    }
  },
  mounted () {
    eventBus.$on('previewPDF', (url) => {
      // console.log('url here', url)
      if (url) {
        this.$router.push(window.location.pathname + window.location.search + '?pdf=' + url)
      }
      this.url = url
      // window.history.pushState({ url: '?pdf=' + url }, 'Previewing', 'lalal.html?pdf=' + url)
    })
  },
  computed: {
  },
  components: {
    pdf
  },
  methods: {
    closePDF() {
      this.url = '';
      this._toggleScroll(true);
      this.pageNum = 1;
    },
    _toggleScroll(isVisible) {
      document.body.style.overflow = isVisible ? 'scroll' : 'hidden';
    },
    onNextPage() {
      if (this.pageNum >= this.numPages) {
        return;
      }
      this.pageNum++;
    },
    onPrevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
    },
    onPrint() {
      this.$refs.pdf.print();
    },
    onDownload() {

    },
    onMail() {

    },
    _onKeyUp(keyCode) {
      if (keyCode === KEY_CODES.Esc) {
        this.closePDF();
      } else if (keyCode === KEY_CODES.RightArrow) {
        this.onNextPage();
      } else if (keyCode === KEY_CODES.LeftArrow) {
        this.onPrevPage();
      }
    }
  },
  created() {
    eventBus.$on('keyUp', this._onKeyUp);
  },
  beforeDestroy() {
    eventBus.$off('keyUp');
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

.pdf-viewport-container{
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.7);

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 111;
    left: 0;
    height: 60px;
    padding: 15px 30px;
    border-top: 1px solid #ccc;

    .control {
      cursor: pointer;
      margin-right: 20px;

      &-prev {
        color: $secondary;
      }

      &-icon {
        font-size: 2rem;
        color: $topDownColor;
        &:hover {
          color: $greyTone;
        }
      }

      .ion-ios-refresh-empty {
        font-size: 2.3rem;
        position: relative;
        top: 3px;
      }
    }
  }

  .control-close {
    position: fixed;
    top: 1%;
    right: 30px;
    font-size: 2.5rem;
    cursor: pointer;
    position: fixed;
    top: 1%;
    right: 30px;
    font-size: 2.5rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 35px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    line-height: 0;
    justify-content: center;
    padding-top: 5px;

    &:hover {
      color: #797979;
    }
  }
}
.pdf-viewport{
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);

  >div {
    width: 100%;
  }
}

</style>
