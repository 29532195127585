<template>
  <span class="course-icon"
        :class="iconClass"
        aria-hidden="true">
    <svg :height="size"
         class="fade-enter-active"
         :class="{ 'fade-enter': showPending && !iconPaths }"
         :width="size"
         viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M0 0h400v400H0z" fill="none" stroke="none"></path>
        <path v-for="(iconPath, index) in iconPaths"
              :class="getClass(index)"
              :key="iconPath"
              :d="iconPath">
        </path>
      </g>
    </svg>
    <transition name="fade">
      <icon v-if="showPending && !iconPaths"
            class="course-icon__pending"
            name="sync"
            :size="75"
            :rotate="true"></icon>
    </transition>
  </span>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue';

  export default {
    name: 'course-icon',
    components: {Icon},
    props: {
      name: {
        type: String,
        required: true
      },
      courseSlug: {
        type: String,
        default: 'common'
      },
      color: {
        type: String,
        default: 'default'
      },
      colors: {
        type: Array,
        default() {
          return [];
        }
      },
      size: {
        type: Number,
        default: 200
      }
    },
    data() {
      return {
        showPending: false
      }
    },
    mounted() {
      setTimeout(() => {
        this.showPending = true;
      }, 33);
    },
    computed: {
      iconClass() {
        return this.color ? `course-icon--${this.color}` : null;
      }
    },
    asyncComputed: {
      iconPaths() {
        return this.getIconPaths(this.name);
      }
    },
    methods: {
      getClass(index) {
        return this.colors[index] ? `course-icon--${this.colors[index]}` : null;
      },
      async getIconPaths(name) {
        const {getIconPaths} = await import(/* webpackChunkName: "course-icons_[request]" */ `./icons/${this.courseSlug}.js`);

        return getIconPaths(name);
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .course-icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        line-height: 0;
        position: relative;

        &__pending {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
        }

        &--default {
            fill: $default-icon-color;
        }

        &--white {
            fill: #ffffff;
        }

        &--primary {
            fill: $primary;
        }

        &--blue {
            fill: $blue-2;
        }

        &--secondary {
            fill: $secondary;
        }

        &--orange {
            fill: $orange;
        }

        &--success {
            fill: $green;
        }

        &--dark {
            fill: $dark-blue;
        }
    }
</style>
