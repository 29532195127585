module.exports = [
  {
    id: '1',
    slug: 'email',
    title: 'E-Mail',
    image: 'email.svg',
    description: 'Nutzen Sie beruflich E-Mails?',
    answer: null,
    modalQuestion: {
      image: 'email_kontozugriff.svg',
      question: 'Durch eine unverdächtige E-Mail wird per Anhang oder Link eine Schadsoftware aktiviert, die dem Angreifer Zugriff auf Ihre Daten ermöglicht.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'email_erpressung.svg',
        attacks: [
          'Sie erhalten eine unverdächtige E-Mail',
          'Durch Öffnen des Anhangs aktivieren Sie einen Trojaner',
          'Die Schadsoftware verschlüsselt Ihre Daten',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Ein Virus in der Arztpraxis',
          image: '/static/vectors/email_erpressung.jpg',
          content: [
            'Am Morgen des 13. Mai ließ sich auf den Rechnern einer Bonner Arztpraxis <strong>keine Datei mehr öffnen</strong>. In den Tagen zuvor hatte die Arztpraxis zahlreiche <strong>Bewerbungen für die ausgeschriebene Stelle</strong> als “Medizinische/r Fachangestellte/r” erhalten.',
            'Eine Bewerbung enthielt einen als PDF <strong>angehängten Lebenslauf und eine Excel Tabelle</strong>. Beim Öffnen der Dokumente installierte sich unbemerkt eine <strong>Schadsoftware, die alle Daten verschlüsselte.</strong> Auch die Telefonanlage war von dem Angriff betroffen und Patienten konnten die Praxis nicht mehr erreichen.'
          ]
        },
        damage: {
          title: 'Konsequenzen',
          content: [
            'Die Arztpraxis war für 4 Tage geschlossen',
            'Alle Computer mussten bereinigt und neu aufgesetzt werden',
            'Vertrauliche Patientendaten gingen verloren'
          ],
          costs: 'Schaden: 69.000 €'
        },
        coverage: {
          title: 'Deckung der Cyberversicherung',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'email_spionage.svg',
        attacks: [
          'Sie erhalten eine E-Mail mit einem schadhaften Link',
          'Mit nur einem Klick haben Sie Ihren Computer infiziert',
          'Es installiert sich unbemerkt eine Spionagesoftware',
          'Ihre vertraulichen Daten werden entwendet'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Information und ggf. Abwanderung Ihrer Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Die Quittung kommt zum Schluss',
          image: '/static/vectors/email_spionage.jpg',
          content: [
            'Im März erfuhr der Besitzer eines beliebten Restaurants in Ostdeutschland, dass die <strong>Kreditkartendaten von mehr als 450 Kunden</strong> gestohlen wurden. Einer der Mitarbeitende hatte auf einen <strong>schadhaften Link in einer E-Mail</strong> geklickt. Dadurch konnten Hacker das computergestützte Kassensystem infiltrieren und über einen <strong>Zeitraum von mehr als zwei Jahren</strong> die Kreditkartendaten abgreifen. Die Daten wurden anschließend für <strong>Einkäufe in den USA</strong> missbraucht.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Alle betroffenen Kunden mussten über den Vorfall informiert werden',
            'Visa hat das Restaurant in Regress genommen'
          ],
          costs: 'Schaden: 1.200.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Umsatzausfall',
            'Kosten für die Kundenkommunikation',
            'Kosten für zu leistenden Schadensersatz'
          ]
        }
      },
      {
        id: '3',
        label: 'Kontozugriff',
        icon: 'email_kontozugriff.svg',
        attacks: [
          'Sie erhalten eine E-Mail im Namen Ihrer Hausbank',
          'Auf der angegebenen Interseite loggen Sie sich ein',
          'Ihre Login-Daten werden gestohlen',
          'Hohe Geldbeträge werden von Ihrem Konto abgebucht'
        ],
        consequences: [
          'Abbuchung hoher Geldbeträge',
          'Manipulation von Kontoinformationen',
          'Sperrung des Bankkontos'
        ],
        example: {
          title: 'Der betrogene Handwerker',
          image: '/static/vectors/email_kontozugriff.jpg',
          content: [
            'Als der Installateur- und Heizungsbaumeister Fabian Koch (Name geändert) die Kontoauszüge des Firmenkontos prüft, fällt ihm eine <strong>Überweisung über 13.000 Euro auf ein ausländisches Konto</strong> auf. Diese kann er keinem Buchungsvorgang zuordnen. Auf Nachfrage erfährt er, dass sein Buchhalter vor kurzem eine <strong>E-Mail der Hausbank</strong> erhalten hatte. In der Mail wurde er aufgefordert, seine Kontoumsätze und Kontodaten zu überprüfen. Der Buchhalter kam der Aufforderung nach und wurde auf eine <strong>Phishing-Seite</strong> geleitet. Dort gab er wie gewohnt die Zugangsdaten ein.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Das Konto wurde vorsorglich gesperrt, um alle Umsätze der letzten Monate detailliert zu überprüfen',
            'Der Zahlungsverkehr war 7 Tage erheblich gestört',
            'Der Schaden musste selbst getragen werden'
          ],
          costs: 'Schaden: 13.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung erstattet den durch den Cyber-Betrug entstanden Schaden.',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Kosten des Cyber-Betrugs'
          ]
        }
      }
    ]
  },
  {
    id: '2',
    slug: 'internet',
    title: 'Surfen im Internet',
    image: 'internet_surfen.svg',
    description: 'Ist die Nutzung des Internets für Mitarbeitende möglich?',
    answer: null,
    modalQuestion: {
      image: 'surfen_internet_spionage.svg',
      question: 'Webseiten und Onlineshops werden immer wieder gehackt, um sensible Kundeninformationen zu entwenden.'
    },
    tabs: [
      {
        id: '1',
        label: 'Kreditkartenmissbrauch',
        icon: 'surfen_internet_kreditkartenzugriff.svg',
        attacks: [
          'Ein Onlineshop wird von einem Hacker angegriffen',
          'Sie geben eine Bestellung auf und zahlen mit Ihrer Kreditkarte',
          'Die Eingabe der sensiblen Daten wird mitgelesen',
          'Ihre Kreditkarte wird für Einkäufe im Ausland missbraucht'
        ],
        consequences: [
          'Abbuchung hoher Geldbeträge',
          'Sperrung der Kreditkarte',
          'Zeitweiser Verlust der Kreditwürdigkeit'
        ],
        example: {
          title: 'Der gehackte Online-Shop',
          image: '/static/vectors/surfen_kreditkartenzugriff.jpg',
          content: [
            'Im März bestellte der Anlageberater Timo Becker (Name geändert) <strong>bei einem Online-Händler</strong> eine neue Kaffeemaschine für das Büro. Die Zahlung wurde über die Kreditkarte des Unternehmens geleistet.',
            'Allerdings war die <strong>Webseite des Online-Shops manipuliert worden</strong>, um <strong>Kreditkartendaten abzugreifen</strong>. Die abgegriffenen Daten wurden für Einkäufe in den USA missbraucht.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Aufgrund der Insolvenz des Online-Shops wurde der Schaden nicht erstattet'
          ],
          costs: 'Schaden: 9.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung erstattet den durch den Cyber-Betrug entstanden Schaden.',
          content: [
            'Kosten des Cyber-Betrugs'
          ]
        }
      },
      {
        id: '2',
        label: 'Erpressung',
        icon: 'surfen_internet_erpressung.svg',
        attacks: [
          'Auf einer seriösen Webseite wird ein schadhafter Link versteckt',
          'Mit nur einem Klick aktivieren Sie einen Trojaner',
          'Die Schadsoftware verschlüsselt Ihre Daten',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Der schadhafte Link',
          image: '/static/vectors/surfen_erpressung.jpg',
          content: [
            'Im September wurde ein Hamburger Makler Opfer von Cyberkriminellen. Als er morgens den Computer startet, sind <strong>alle Daten</strong> - sowohl auf seinem Computer als auch in den IT-Systemen des Unternehmens - <strong>verschlüsselt</strong>. Er erhält die Nachricht, dass gegen eine Zahlung von 16.000 Euro die Daten wiederhergestellt werden können.',
            'Der beauftragte IT-Experte stellt fest, dass der Makler auf den Link einer mit <strong>Schadsoftware infizierten Webseite</strong> geklickt hatte. Hierdurch wurde unbemerkt die Verschlüsselungssoftware installiert. Zudem wurden <strong>auch personenbezogene Daten ausgelesen</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Der Makler konnte 9 Tage nicht auf seine Daten zugreifen',
            'Über 100 betroffene Kunden mussten über den Vorfall informiert werden',
            'Mehr als 30 Kunden haben den Makler gewechselt'
          ],
          costs: 'Schaden: 31.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten der Betriebsunterbrechung',
            'Kosten für die Kundenkommunikation',
            'Kosten für zu leistenden Schadensersatz'
          ]
        }
      },
      {
        id: '3',
        label: 'Spionage',
        icon: 'surfen_internet_spionage.svg',
        attacks: [
          'Auf einer seriösen Webseite wird ein schadhafter Link versteckt',
          'Mit nur einem Klick haben Sie Ihren Computer infiziert',
          'Es installiert sich unbemerkt eine Spionagesoftware',
          'Ihre vertraulichen Daten werden entwendet'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für Mitarbeiter',
          'Information und ggf. Abwanderung Ihrer Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Infiziert durch die eigene Bank',
          image: '/static/vectors/surfen_spionage.jpg',
          content: [
            'Im November besuchte der Hotelier Thorsten Renneke (Name geändert) die <strong>Webseite seiner Hausbank</strong>, um Überweisungen durchzuführen. Wie die Bank später mitteilte, war in die Webseite allerdings über mehrere Stunden <strong>Schadsoftware eingebettet und unbemerkt aktiv</strong>. Diese durchsuchte die Systeme der Webseitenbesucher systematisch nach bekannten Sicherheitslücken.',
            'Auf diesem Weg gelang es den Hackern, auf dem Rechner von Thorsten Renneke unbemerkt eine Spionagesoftware zu installieren und <strong>sensible Kundendaten abzugreifen</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Der Geschäftsbetrieb war für mehrere Tage erheblich gestört',
            'Alle betroffenen Kunden mussten über den Vorfall informiert werden'
          ],
          costs: 'Schaden: 46.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für die Kundenkommunikation',
            'Kosten für den Umsatzausfall',
            'Kosten für zu leistenden Schadensersatz'
          ]
        }
      }
    ]
  },
  {
    id: '3',
    slug: 'wlan',
    title: 'Öffentliches WLAN',
    image: 'wlan.svg',
    description: 'Nutzen Sie öffentliche WLAN-Netzwerke außerhalb des Büros?',
    answer: null,
    modalQuestion: {
      image: 'wlan_kontozugriff.svg',
      question: 'In öffentlichen WLANs suchen Hacker gezielt nach Geräten mit Schwachstellen, um diese mit Schadsoftware zu infizieren.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'wlan_erpressung.svg',
        attacks: [
          'Ein Hacker nutzt ein offenes WLAN für einen gezielten Angriff',
          'Auf Ihrem Notebook ist veraltete Software installiert',
          'Die Sicherheitslücke wird genutzt, um Ihre Daten zu verschlüsseln',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Die teure Geschäftsreise',
          image: '/static/vectors/wlan_erpressung.jpg',
          content: [
            'Am 05. April infizierte sich der selbstständige Unternehmensberater Jörg Husemann (Name geändert) <strong>in einem Café mit einer Schadsoftware</strong>. Diese verschlüsselte in wenigen Sekunden sein <strong>gesamtes Computersystem</strong>.',
            'Der beauftragte IT-Spezialist erklärte Jörg Husemann später, dass sich wahrscheinlich Hacker in das WLAN-Netzwerk des Cafés eingewählt hatten. Im Netzwerk suchten diese gezielt nach Geräten mit veralteter Software. Ein <strong>nicht vorhandenes Softwareupdate</strong> wurde dem Unternehmensberater zum Verhängnis.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Der Computer mussten bereinigt und neu aufgesetzt werden',
            'Seit der letzten Datensicherung erstellte Dokumente konnten nicht wiederhergestellt werden',
            'Der Computer konnte für 2 Tage nicht genutzt werden'
          ],
          costs: 'Schaden: 7.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'wlan_spionage.svg',
        attacks: [
          'Ein Hacker hat ein öffentliches WLAN gekapert',
          'Der  Angreifer sucht gezielt nach Schwachstellen',
          'Die Sicherheitslücke wird genutzt, um unbemerkt eine Spionagesoftware auf Ihrem Notebook zu installieren',
          'Ihre vertraulichen Daten werden entwendet'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für Mitarbeiter',
          'Information und ggf. Abwanderung Ihrer Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Das unsichere Hotel WLAN',
          image: '/static/vectors/wlan_spionage.jpg',
          content: [
            'Immer wieder versuchen Hacker über die <strong>WLAN-Netze von Hotels</strong> vertrauliche Daten auszuspähen.',
            'Opfer eines solchen Angriffs wurde auch ein Anwalt aus Köln. Die Cyberkriminellen verleiteten das Opfer dazu, ein <strong>Update für eine Standardsoftware herunterzuladen und zu installieren</strong>. Dabei <strong>infizierte sich der Jurist mit einem Virus</strong>. Die Hacker erhielten Zugriff auf gespeicherte Passwörter, Klientendaten und das Bankkonto.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Alle betroffenen Klienten mussten über den Vorfall informiert werden',
            'Von dem Bankkonto wurden mehrere tausend Euro abgebucht'
          ],
          costs: 'Schaden: 76.000 €.'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten des Cyber-Betrugs'
          ]
        }
      },
      {
        id: '3',
        label: 'Kontozugriff',
        icon: 'wlan_kontozugriff.svg',
        attacks: [
          'Sie loggen sich in ein öffentliches WLAN ein',
          'Ihr Datenverkehr wird von einem Hacker mitgelesen',
          'Die Anmeldedaten für Ihr Online Banking werden aufgezeichnet',
          'Von Ihrem Konto werden hohe Geldbeträge abgebucht'
        ],
        consequences: [
          'Abbuchung hoher Geldbeträge',
          'Manipulation von Kontoinformationen',
          'Sperrung des Bankkontos'
        ],
        example: {
          title: 'Nie wieder Online Banking im öffentlichen WLAN',
          image: '/static/vectors/wlan_kontozugriff.jpg',
          content: [
            'Als der Einzelhändler Christian Thiele (Name geändert) vor seinem Abflug über das <strong>öffentliche WLAN</strong> des Flughafens seine E-Mails liest, sieht er die <strong>Mahnung eines Lieferanten</strong>. Daraufhin <strong>loggt er sich in sein Online Banking ein</strong> und überweist das Geld.',
            'Allerdings werden im öffentlichen <strong>WLAN Daten unverschlüsselt übertragen</strong>. Dadurch konnten Hacker unbemerkt die Kontodaten ausspähen und anschließend <strong>hohe Geldbeträg vom Konto abbuchen</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Das Konto wurde vorsorglich gesperrt, um den Grund für die fehlerhaften Abbuchungen zu finden',
            'Der Zahlungsverkehr für mehrere Tage erheblich gestört',
            'Der Schaden musste selbst getragen werden'
          ],
          costs: 'Schaden: 189.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung erstattet den durch den Cyber-Betrug entstandenen Schaden.',
          content: [
            'Kosten für den Umsatzausfall',
            'Kosten des Cyber-Betrugs',
            'Kosten für den Mehraufwand der Mitarbeiter'
          ]
        }
      }
    ]
  },
  {
    id: '4',
    slug: 'telefon',
    title: 'Telefon',
    image: 'telefon.svg',
    description: 'Nutzen Sie das Telefon zur Erteilung oder Annahme von Aufträgen?',
    answer: null,
    modalQuestion: {
      image: 'telefon_spionage.svg',
      question: 'Am Telefon geben sich Cyberkriminelle als vertrauenswürdige Person aus, um die Opfer dazu zu verleiten, die Installation von Schadsoftware zu ermöglichen.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'telefon_erpressung.svg',
        attacks: [
          'Sie erhalten einen Anruf von Ihrem vorgeblichen Internetprovider',
          'Durch eine gezielte Täuschung gewähren Sie einen Fernzugriff auf Ihre IT-Systeme',
          'Daraufhin werden Ihre Daten mittels Schadsoftware verschlüsselt',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Der falsche IT-Spezialist',
          image: '/static/vectors/telefon_erpressung.jpg',
          content: [
            'Im August war der <strong>Internetanschluss</strong> der Unternehmerin Saskia Kiesewetter (Name geändert) plötzlich <strong>gestört</strong>. Daraufhin erhielt sie einen <strong>Anruf von ihrem vorgeblichen Internetprovider</strong>. Zur Behebung des Fehlers sollte Frau Kiesewetter ihm einen temporären Zugriff auf das Netzwerk ermöglichen. Unter Anleitung des IT-Spezialisten <strong>richtete die Unternehmerin den Fernzugriff ein</strong>.',
            'Allerdings war der Anrufer ein Cyberkrimineller, der den Internetanschluss vorsätzlich gestört hatte. Durch den Fernzugriff wurde eine <strong>Schadsoftware installiert, die alle Daten verschlüsselte</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Die Unternehmerin konnte 14 Tage nicht auf ihre Systeme zugreifen',
            'Wichtige Daten konnten nicht wiederhergestellt werden'
          ],
          costs: 'Schaden: 63.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'telefon_spionage.svg',
        attacks: [
          'Sie erhalten einen Anruf von einem angeblichen Geschäftspartner',
          'In guter Absicht geben Sie vertrauliche Informationen weiter',
          'Der Kriminelle erhält Zugang zu Informationen, die unter das Datenschutzgesetz fallen'
        ],
        consequences: [
          'Sie müssen alle betroffenen Kunden und Geschäftspartner sowie die Datenschutzbehörde informieren',
          'Bußgelder und Regressforderungen',
          'Kosten für PR-Spezialisten und Rechtsberatung'
        ],
        example: {
          title: 'Der erfundene Notfall',
          image: '/static/vectors/telefon_spionage.jpg',
          content: [
            'Am Morgen des 19. September erhält die Sachbearbeiterin Angelika Fischer (Name geändert) den <strong>Anruf eines langjährigen Kunden</strong>. Dieser erklärte verzweifelt, dass Hacker das gesamte IT-System lahmgelegt hätten. Nun müssten alle wichtigen Dokumente rekonstruiert und wiederbeschafft werden. Aus diesem Grund <strong>bittet er um Zusendung des Schriftverkehrs</strong> zwischen den Unternehmen.',
            'Frau Fischer möchte unkompliziert helfen und <strong>versendet die Dokumente umgehend per E-Mail</strong>. Als sie zwei Wochen später bei dem Kunden anruft, um zu fragen, ob alles angekommen sei weiß dieser nichts von dem besagten Vorfall.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Das erschütterte Kundenvertrauen führte zum Ende der Geschäftsbeziehung',
            'Es kam zu einer Schadensersatzklage'
          ],
          costs: 'Schaden: 59.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung unterstützt dabei Schadensersatzklagen abzuwenden',
          content: [
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für zu leistenden Schadensersatz'
          ]
        }
      },
      {
        id: '3',
        label: 'Kontozugriff',
        icon: 'telefon_kontozugriff.svg',
        attacks: [
          'Sie erhalten einen Anruf von Ihrem vorgeblichen IT-Dienstleister',
          'Unter seiner Anleitung nehmen Sie Änderungen am Mailserver vor',
          'Ausgehende Rechnungen werden manipuliert',
          'Ihre Kunden überweisen den Rechnungsbetrag auf das falsche Konto'
        ],
        consequences: [
          'Kosten für den Zahlungsausfall',
          'Kosten für IT-Spezialisten',
          'Eingeschränkte Erreichbarkeit per Mail',
          'Information der Kunden',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Echte Rechnung falsches Konto',
          image: '/static/vectors/telefon_kontozugriff.jpg',
          content: [
            'Am Morgen des 27. August ruft die Unternehmerin Simone Winkler (Name geändert) bei einem Ihrer langjährigen Kunden an, um sich zu informieren, <strong>warum auch die zweite Mahnung nicht gezahlt wurde</strong>. Der Kunde teilte ihr mit, dass der <strong>Rechnungsbetrag auf das angegebene Konto überwiesen</strong> wurde. Allerdings war dies <strong>nicht das Konto von Simone Winkler</strong>.',
            'Ein beauftragter IT-Spezialist stellte später fest, dass der <strong>E-Mailserver manipuliert worden war</strong>. Von Ihrem Sekretär erfährt Simone Winkler, dass vor einigen Wochen das <strong>IT-Unternehmen anrief</strong>, welches den Mailserver eingerichtet hatte. Es <strong>gäbe ein neues Sicherheitsupdate</strong>, dass umgehend installiert werden müsse. Der <strong>Sekretär nahm die Installation unter Anleitung des Anrufers</strong> vor. Anstatt der Installation eines Sicherheitsupdates wurde allerdings der Mailserver manipuliert.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Der Mailserver musste neu aufgesetzt werden',
            'Bei der Überprüfung offener Rechnungen wurden weitere Manipulationen entdeckt',
            'Das Unternehmen blieb auf den offenen Rechnungen sitzen'
          ],
          costs: 'Schaden: 112.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Kosten des Cyber-Betrugs'
          ]
        }
      }
    ]
  },
  {
    id: '5',
    slug: 'eigene-webseite',
    title: 'Eigene Webseite',
    image: 'website.svg',
    description: 'Haben Sie eine eigene Webseite?',
    answer: null,
    modalQuestion: {
      image: 'website_infizierung.svg',
      question: 'Hacker durchsuchen das Netz nach Internetseiten mit Sicherheitslücken, um dort Schadsoftware zu platzieren, mit der die Computer der Webseitenbesucher infiziert werden.'
    },
    tabs: [
      {
        id: '1',
        label: 'Infizierung',
        icon: 'website_infizierung.svg',
        attacks: [
          'Ein Hacker entdeckt eine Sicherheitslücke in Ihrer Internetseite',
          'Die Sicherheitslücke wird zur Platzierung von Schadsoftware genutzt',
          'Ein Schadprogramm wird auf den PCs der Webseitenbesucher installiert',
          'Ihre Internetseite ist nicht mehr über Google auffindbar  und Betroffene verlangen Schadensersatz'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Regressforderungen',
          'Kosten für den Umsatzausfall',
          'Kosten für die Krisenkommunikation'
        ],
        example: {
          title: 'Diese Seite wurde möglicherweise gehackt',
          image: '/static/vectors/website_infiziert.jpg',
          content: [
            'Im Juli wurde ein Unternehmen aus Baden-Württemberg von einem Kunden darüber informiert, dass bei Google für die Unternehmenswebseite die Meldung erscheint <strong>“Diese Seite wurde möglicherweise gehackt”</strong>.',
            'Hackern war es gelungen, die <strong>Webseite mit Schadsoftware zu infiltrieren</strong>. Diese Software <strong>durchsuchte die Systeme der Webseitenbesucher</strong> systematisch <strong>nach Sicherheitslücken</strong> und <strong>infizierte zahlreiche Computer</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Verschlechtertes Google Ranking und Imageverlust',
            'Schadenersatzforderungen der infizierten Webseitenbesucher',
            'Die Internetseite war für 7 Tage nicht erreichbar'
          ],
          costs: 'Schaden: 133.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten für',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Erpressung',
        icon: 'website_erpressung.svg',
        attacks: [
          'Ihre Webseite wird durch massenhafte Anfragen überlastet',
          'Die Internetseite ist dadurch zeitweise nicht mehr erreichbar',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Umsatzausfall',
          'Imageverlust bei Kunden und Geschäftspartner',
          'Kosten für IT-Spezialisten'
        ],
        example: {
          title: 'Kein Anschluss unter dieser Webseite',
          image: '/static/vectors/website_erpressung.jpg',
          content: [
            'Im Mai <strong>attackierten Hacker deutsche Onlineshops mit einer großen Zahl an Anfragen</strong>, bis die jeweiligen <strong>Server und IT-Systeme unter der Last zusammen brachen</strong>.',
            'Opfer wurde unter anderem ein Händler für Telekommunikationsanlagen und -geräte, der seine Produkte auch über seinen eigenen Webshop vertreibt.',
            'Die Täter <strong>forderten ein Schutzgeld in 5-stelliger Höhe</strong>, ansonsten würden sie den Webshop weiterhin attackieren. Auf Anraten der Behörden weigerte sich der Betreiber zu zahlen.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Über mehrere Wochen wurde der Shop immer wieder attackiert',
            'Der Webshop war für insgesamt 12 Tage nicht erreichbar',
            'Es kam zu existenzbedrohenden Umsatzeinbußen'
          ],
          costs: 'Schaden: 123.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten für den Umsatzausfall.',
          content: [
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für den Umsatzausfall'
          ]
        }
      }
    ]
  },
  {
    id: '6',
    slug: 'hardware',
    title: 'Hardware',
    image: 'hardware.svg',
    description: 'Nutzen Sie oder Ihre Mitarbeitenden Geräte wie USB-Stick, Funkmaus oder Laptop?',
    answer: null,
    modalQuestion: {
      image: 'hardware_spionage.svg',
      question: 'Hacker verteilen - z.B. als Werbegeschenk getarnt - schadhaftes Computerzubehör wie Funkmäuse oder USB-Sticks, die einen Computer mit Schadsoftware infizieren, sobald die Geräte verbunden werden.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'hardware_erpressung.svg',
        attacks: [
          'Sie erhalten einen USB-Stick als Werbegeschenk',
          'Durch die Verwendung des USB-Sticks wird ein Trojaner aktiviert',
          'Die Schadsoftware verschlüsselt alle Ihre Daten',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Das vergiftete Werbegeschenk',
          image: '/static/vectors/hardware_erpressung.jpg',
          content: [
            'Im Dezember wird der Steuerberater Michael Figge (Name geändert) von einem Geschäftspartner informiert, dass im Zuge einer <strong>Werbeaktion möglicherweise USB-Sticks mit Schadsoftware</strong> verteilt wurden.',
            'Da Michael Figge den USB Stick bereits verwendet hatte, wandte er sich an einen IT-Spezialisten. Dieser stellte fest, dass die <strong>IT-Systeme mit einem Virus infiziert waren</strong>. Mit Hilfe des Virus hatten Cyberkriminelle direkten <strong>Zugriff auf die gesamten Kundendaten</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Alle Computer mussten bereinigt und neu aufgesetzt werden',
            'Der Steuerberater konnte 6 Tage nicht auf seine Systeme zugreifen',
            'Über 120 betroffene Klienten mussten über den Vorfall informiert werden'
          ],
          costs: 'Schaden: 98.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'hardware_spionage.svg',
        attacks: [
          'Sie sind auf einer Geschäftsreise',
          'Ihr Laptop wird gestohlen',
          'Über den Laptop erhalten Hacker Zugang zu vertraulichen Daten'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für Mitarbeiter',
          'Information und ggf. Abwanderung der Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Der gestohlene Laptop',
          image: '/static/vectors/hardware_spionage.jpg',
          content: [
            'Am 12. Juni wurde dem Geschäftsführer einer Münchner Beteiligungsgesellschaft auf einer Geschäftsreise der <strong>Laptop gestohlen</strong>.',
            'Er meldete den Diebstahl umgehend der Bundespolizei. Da der Laptop <strong>direkten Zugang zum Firmennetzwerk</strong> ermöglicht, empfahl der aufnehmende Beamte einen IT Spezialisten einzuschalten. Bei der Sicherung des Netzwerks stellte der beauftragte IT-Dienstleister fest, dass von dem gestohlenen Laptop bereits <strong>auf sensible Firmendaten zugegriffen wurde</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Der Laptop konnte nicht wiederbeschafft werden',
            'Die IT-Systeme mussten abgesichert werden und waren für 3 Tage nicht nutzbar',
            'Über 70 Geschäftspartner mussten über den Vorfall informiert werden'
          ],
          costs: 'Schaden: 98.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten des Umsatzausfalls'
          ]
        }
      }
    ]
  },
  {
    id: '7',
    slug: 'social-media',
    title: 'Social Media',
    image: 'social_media.svg',
    description: 'Können Facebook oder Twitter in Ihrem Unternehmensnetzwerk aufgerufen werden?',
    answer: null,
    modalQuestion: {
      image: 'social_media_spionage.svg',
      question: 'Über gekaperte Facebook-Konten verbreiten Hacker schadhafte Dateien, um die Computersysteme von Facebook-Nutzern mit Schadsoftware zu infizieren.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'social_media_erpressung.svg',
        attacks: [
          'Ein Facebook-Kontakt sendet Ihnen eine Bilddatei ',
          'Durch Öffnen der Datei aktivieren Sie einen Trojaner',
          'Die Schadsoftware verschlüsselt Ihre Daten',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Ausgeschlossen in den eigenen vier Wänden',
          image: '/static/vectors/social_media_erpressung.jpg',
          content: [
            'Im Oktober 2016 funktionierte plötzlich das elektronische Schließsystem eines bayrischen Landgasthofes nicht mehr.',
            'Der Juniorchef hatte über Facebook unbemerkt eine Schadsoftware heruntergeladen. Einer seiner Kontakte wurde gezielt gehackt und über sein <strong>Facebook Konto ein Bild verschickt</strong>. Als der Juniorchef das <strong>Bild öffnete</strong>, installierte sich ein <strong>Schadprogramm und infizierte</strong> über das lokale Netzwerk auch den <strong>Hauptrechner</strong> des Hotels, über den das <strong>elektronische Schließsystem</strong> gesteuert wird. Die Hacker konnten über einen Fernzugriff die <strong>Schließanlage manipulieren</strong> und forderten ein Lösegeld im fünfstelligen Bereich.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die Kunden konnten ihre Zimmer für mehrere Stunden nicht betreten',
            'Erheblicher Imageverlust',
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden'
          ],
          costs: 'Schaden: 47.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für zu leistenden Schadensersatz'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'social_media_spionage.svg',
        attacks: [
          'Ein Facebook-Kontakt sendet Ihnen eine Nachricht mit einem schadhaften Link',
          'Mit nur einem Klick haben Sie Ihren Computer infiziert',
          'Es installiert sich unbemerkt eine Spionagesoftware',
          'Ihre vertraulichen Daten werden entwendet'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für Mitarbeiter',
          'Information und ggf. Abwanderung der Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Der falsche Facebook-Freund',
          image: '/static/vectors/social_media_spionage.jpg',
          content: [
            'Der Personalberater Michael Wilde (Name geändert) nutzt seinen Laptop sowohl für berufliche als auch für private Zwecke. Dies wurde ihm im letzten August zum Verhängnis. Er erhielt über Facebook eine private <strong>Nachricht von einem Bekannten, die einen Link</strong> zu einem Video enthielt. Als Michael Wilde auf den Link klickte, <strong>installierte sich eine Schadsoftware</strong>, mit der <strong>private Informationen, Passwörter, E-Mail-Konten und Browser-Verläufe ausspioniert</strong> wurden. Zudem wurde der <strong>schädliche Link</strong> in seinem Namen <strong>automatisch an seine E-Mail-Kontakte weitergeleitet</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Der Computer musste durch einen IT-Spezialisten bereinigt und neu aufgesetzt werden',
            'Über 250 betroffene Kunden mussten über den Vorfall informiert werden'
          ],
          costs: 'Schaden: 36.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation'
          ]
        }
      }
    ]
  },
  {
    id: '8',
    slug: 'datenbank',
    title: 'Datenbank',
    image: 'datenbank.svg',
    description: 'Sind Ihre Kundendaten in einer zentralen Datenbank gespeichert?',
    answer: null,
    modalQuestion: {
      image: 'datenbank_spionage.svg',
      question: 'Cyberkriminelle suchen im Internet nach Datenbankservern mit Sicherheitslücken, um diese mit Schadsoftware zu infizieren.'
    },
    tabs: [
      {
        id: '1',
        label: 'Erpressung',
        icon: 'datenbank_erpressung.svg',
        attacks: [
          'Ein Hacker entdeckt eine Sicherheitslücke in Ihrem Datenbankserver',
          'Diese wird genutzt, um einen Trojaner zu installieren',
          'Eine Schadsoftware verschlüsselt Ihre Daten',
          'Sie werden zur Zahlung von Lösegeld aufgefordert'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für die Mitarbeiter',
          'Imageverlust bei Geschäftspartnern und Kunden'
        ],
        example: {
          title: 'Sicherheitsrisiko veraltete Software',
          image: '/static/vectors/datenbank_erpressung.jpg',
          content: [
            'Im letzten Dezember wurde ein mittelständischer Handwerksbetrieb aus Nordrhein-Westfalen Opfer von Cyberkriminellen. Durch <strong>fehlende Softwareupdates</strong> war der Betrieb für die Hacker ein leichtes Ziel. Die Cyberkriminellen hatten zunächst eine Software installiert, mit der sie <strong>aus der Ferne auf die Unternehmensserver zugreifen</strong> konnten. Nachdem sie Zugang zu den IT-Systemen hatten, <strong>verschlüsselten Sie den zentralen Datenbankserver</strong>. In der Folge konnte keiner der Mitarbeitenden mehr auf Auftrags-, Kunden- oder Rechnungsdaten zugreifen.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Der Datenbankserver musste durch einen externen IT-Spezialisten neu aufgesetzt werden',
            'Nicht gesicherte Daten gingen verloren',
            'Der Handwerksbetriebs war eine Woche nur eingeschränkt arbeitsfähig'
          ],
          costs: 'Schaden: 31.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Kosten für den Umsatzausfall'
          ]
        }
      },
      {
        id: '2',
        label: 'Spionage',
        icon: 'datenbank_spionage.svg',
        attacks: [
          'Cyberkriminelle suchen im Internet nach Datenbankservern mit Sicherheitslücken',
          'Auf Ihrem Server ist veraltete Software installiert',
          'Die Sicherheitslücke wird genutzt, um unbemerkt Spionagesoftware zu installieren',
          'Ihre vertraulichen Daten werden entwendet'
        ],
        consequences: [
          'Kosten für IT-Spezialisten',
          'Betriebsunterbrechung',
          'Mehraufwand für Mitarbeiter',
          'Information und ggf. Abwanderung der Kunden',
          'Bußgelder und Regressforderungen'
        ],
        example: {
          title: 'Die unsichere Kundendatenbank',
          image: '/static/vectors/datenbank_spionage.jpg',
          content: [
            'Im letzten März wurde der Hotelier Jochen Schumann (Name geändert) Opfer von Cyberkriminellen. Zwei Jahre zuvor hatte er sich eine elektronische Kundendatenbank und einen Server zur zentralen Datenspeicherung angeschafft. Allerdings war die <strong>Server- und Datenbanksoftware nicht auf dem neuesten Stand</strong> und hatte Sicherheitslücken. <strong>Hacker suchen im Internet</strong> gezielt nach solchen <strong>unzureichend gesicherten Systemen</strong>, da diese leicht mit Schadsoftware infiziert werden können. Den Hackern gelang es auf diese weise <strong>hunderte Kundendatensätze, Kreditkartendaten und Rechnungen abzugreifen</strong>.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Die IT-Systeme mussten bereinigt und neu aufgesetzt werden',
            'Alle betroffenen Kunden mussten über den Vorfall informiert werden'
          ],
          costs: 'Schaden: 56.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Unterstützung bei der Kundenkommunikation'
          ]
        }
      },
      {
        id: '3',
        label: 'Bedienungsfehler',
        icon: 'datenbank_bedienungsfehler.svg',
        attacks: [
          'Sie nutzen eine zentrale Kundendatenbank',
          'Einer Ihrer Mitarbeitenden löscht versehentlich wichtige Dateien',
          'Die verlorenen Daten können nicht wiederhergestellt werden',
          'Sie erleiden einen erheblichen Wettbewerbsnachteil'
        ],
        consequences: [
          'Kosten für die Datenrekonstruktion',
          'Mehraufwand für das Nachpflegen von Daten',
          'Betriebsunterbrechung'
        ],
        example: {
          title: 'Der gut gemeinte Rat',
          image: '/static/vectors/datenbank_bedienungsfehler.jpg',
          content: [
            'Aufgrund eines <strong>Anwenderfehlers</strong> funktionierte im Frühjahr das Warenwirtschaftssystem eines Baustoffhandels nicht mehr. Aus diesem Grund konnten <strong>Bestellungen nur noch manuell bearbeitet werden</strong>, wodurch nur ein <strong>Bruchteil der üblichen Bestellmengen verarbeitet</strong> wurde.'
          ]
        },
        damage: {
          title: 'Schaden',
          content: [
            'Das Warenwirtschaftssystem musste durch einen IT-Spezialisten wiederhergestellt werden',
            'Der Baustoffhandel war 4 Tage nur eingeschränkt arbeitsfähig',
            'Es kam zu hohen Umsatzeinbußen'
          ],
          costs: 'Schaden: 15.000 €'
        },
        coverage: {
          title: 'Die Cyber Versicherung übernimmt die Kosten',
          content: [
            'Kosten für IT-Spezialisten',
            'Kosten für den Mehraufwand der Mitarbeiter',
            'Unterstützung bei der Kundenkommunikation',
            'Kosten für den Umsatzausfall'
          ]
        }
      }
    ]
  }
]
