module.exports = [
  {
    id: '1',
    slug: 'mobile-network',
    title: 'mobile Geräte',
    image: 'mobile_geraete.svg',
    description: 'Verwenden Sie oder Ihre Mitarbeitenden mobile Geräte (wie z.B. Laptop / Smartphone) mit Zugriff auf Ihr Firmennetzwerk?',
    answer: null
  },
  {
    id: '2',
    slug: 'cloud-services',
    title: 'Cloud-Services',
    image: 'cloud_services.svg',
    description: 'Nutzen Sie Cloud-Services für betriebliche Zwecke?',
    answer: null
  },
  {
    id: '3',
    slug: 'byod',
    title: 'Bring your own Device',
    image: 'private_geraete.svg',
    description: 'Ist die Nutzung privater Geräte („Bring your own Device“) mit Zugriff auf das Firmennetzwerk in Ihrem Unternehmen erlaubt?',
    answer: null
  },
  {
    id: '4',
    slug: 'creditcards',
    title: 'Kreditkarten',
    image: 'kreditkartenzahlung.svg',
    description: 'Bieten Sie Ihren Kunden die Möglichkeit, mit Kreditkarte zu bezahlen?',
    answer: null
  },
  {
    id: '5',
    slug: 'social-engineering',
    title: 'Social Engineering',
    image: 'social_engineering.svg',
    description: 'Halten Sie es für realistisch, dass Ihre Mitarbeitenden durch gezielte Manipulation (Social Engineering) zu unerwünschten Handlungen angeleitet werden?',
    answer: null
  },
  {
    id: '6',
    slug: 'schadenersatzansprueche',
    title: 'Schadenersatzansprüche',
    image: 'schadensersatzansprueche.svg',
    description: 'Müssen Sie mit vertraglichen Schadenersatzansprüchen rechnen, falls Sie infolge einer Cyber-Attacke Ihre Leistung gegenüber Kunden nicht oder nur verzögert erfüllen könnten?',
    answer: null
  }
];
