<template>
  <transition name="modal">
    <div class="modal2-mask">
      <div class="modal2-wrapper" @mousedown="closeModal()" :class="{'modal2-wrapper--small': small}">
        <div class="modal2-container" @mousedown="$event.stopPropagation()">
          <div class="modal2-header">
            <slot name="header"></slot>
            <span class="modal2-cancel"
                  @click="closeModal()"
                  v-if="closeable">
              <icon name="cancel" :width="30"></icon>
            </span>
          </div>
          <div class="modal2-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal2-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Icon from './Icon';

  export default {
    name: 'modal',
    components: {
      Icon
    },
    data() {
      return {}
    },
    props: [
      'closeable',
      'small'
    ],
    computed: {},
    methods: {
      closeModal() {
        if (this.closeable) {
          this.$emit('close');
        }
      }
    },
    mounted() {
      document.body.classList.add('modal2-open');
    },
    destroyed() {
      document.body.classList.remove('modal2-open');
    }
  }
</script>

<style lang="scss">
  @import '../scss/variables';
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';
  @import "../scss/print/modal2";


  .modal2-open {
    overflow: hidden;
    padding-right: 17px;

    .fixed-header & header {
      z-index: 12;
    }
  }

  .modal2-footer {
    text-align: right;
  }

  .modal2-mask {
    position: fixed;
    z-index: $zIndexModal;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    overflow-x: hidden;
    overflow-y: auto;

    .modal2-container {
      border-radius: $borderRadius * 2;
      padding: 48px 24px;

      @include media-breakpoint-down(sm) {
        padding: 48px 12px;
      }

      .modal2-footer,
      .modal2-header {
        border: 0;
      }

      .modal2-cancel {
        top: 21px;
        right: 21px;
        z-index: $zIndex-100;
      }

      .modal2-cancel:hover {
        svg {
          path:first-of-type {
            fill: $orangeTone;
          }
        }
      }
    }
  }

  .modal2-wrapper {
    max-width: 800px;
    margin: 1.75rem auto;
    padding: 0 16px;

    &--small {
      max-width: 500px;
    }
  }

  .modal2-container {
    position: relative;
    max-width: 900px;
    border: 1px solid $placeHolderColor;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal2-cancel {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .modal2-header h3 {
    margin-top: 0;
    color: #000;
  }

  .modal2-default-button {
    float: right;
  }

  .modal2-enter {
    opacity: 0;
  }

  .modal2-leave-active {
    opacity: 0;
  }

  .modal2-enter .modal2-container,
  .modal2-leave-active .modal2-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  p {
    font-size: 1rem;
  }

  body.modal2-open {
    padding: 0;

    #app {
      padding: 0;
    }
  }
</style>
