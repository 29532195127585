<template>
  <transition name="feature">
    <div v-if="enabled && show" class="new-feature" id="feature-block">
      <div class="new-feature__content">
        <slot>
          <div class="new-feature__title">
            Jetzt neu:
          </div>
          <div class="new-feature__text">
            Sortierung nach <u>Preis-Leistung</u>
          </div>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { getCookie, setCookie } from '@/helpers/cookie.js'

export default {
  name: 'NewFeature',
  props: {
    showUntil: {
      type: Date
    },
    featureId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      enabled: true,
      show: false
    }
  },

  methods: {
    listenClicks(e) {
      let featureBlock = document.getElementById('feature-block')
      let targetEl = e.target // clicked element

      do {
        if (targetEl == featureBlock) {
          // This is a click inside, does nothing, just return.
          return
        }
        // Go up the DOM
        targetEl = targetEl.parentNode
      } while (targetEl)

      // This is a click outside.
      this.show = false

      if (document) {
        document.removeEventListener('click', this.listenClicks)
      }
    }
  },

  beforeMount() {
    if (getCookie(this.featureId || 'price-score-notification-shown')) {
      this.enabled = false
      return
    }

    const now = new Date()

    if (now <= this.showUntil) {
      this.enabled = true
    } else {
      this.enabled = false
    }
  },

  mounted() {
    setTimeout(() => {
      this.show = true

      setCookie(this.featureId || 'price-score-notification-shown', true)

      if (document) {
        document.addEventListener('click', this.listenClicks)
      }
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.new-feature {
  background: #fff;
  position: absolute;
  top: 248px;
  right: -230px;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
  border: 2px solid $primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @include media-breakpoint-down(sm) {
    left: 0;
    right: auto;
    top: 280px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -100px;
    width: 6px;
    height: 6px;
    border-radius: 20rem;
    background: $primary;
    margin-top: 6px;

    @include media-breakpoint-down(sm) {
      left: 7px;
      bottom: -60px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: -90px;
    width: 92px;
    border-top: 1px dashed $primary;
    margin-top: 10px;
    transform: rotate(14deg);
    transform-origin: left top;

    @include media-breakpoint-down(sm) {
      width: 42px;
      left: 10px;
      bottom: -8px;
      transform: rotate(90deg);
    }
  }

  &__content {
    max-width: 100px;
  }

  &__title {
    font-weight: 600;
  }
}

.feature-leave,
.feature-enter-to {
  opacity: 1;
}

.feature-enter-active,
.feature-leave-active {
  transition: 0.3s;
}

.feature-enter,
.feature-leave-to {
  opacity: 0;
}
</style>
