<script>
// Richtext with Short Codes
// insert component in richtext editor with syntax like this: #[[content.tooltip, triggerText=="hover me//" text=="Tooltip text//"]]
// 1) content.tooltip - path to component in strapi and ComponentMap.json
// 2) triggerText - component property
// for now shortCode works for one level nesting params (params must be of type string)
import Vue from 'vue'
import sanitizeHtml from 'sanitize-html'
import IdAnchor from '@/components/strapi/IdAnchor'

const COMPONENT_MAP = {
  "content.idAnchor": "IdAnchor"
}

Vue.component('IdAnchor', IdAnchor)

export default {

  props: {
    content: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      html: ''
    }
  },

  async mounted () {
    let source = await this.sanitizeLocally(this.content)

    if (source.includes('#[[')) {
      source = await this.parseShortCodes(source)
    }

    // IMPORTANT: do not remove any of theese "regex"es
    // We need this to keep all spaces typed in the text-editor
    // These are two different "space characters" and we have both in our articles and text pages
    source = source.replace(/> <\//g, '>&nbsp;</')
    source = source.replace(/> <\//g, '>&nbsp;</')
    // IMPORTANT: do not remove any of theese "regex"es
    this.html = `<span>${source}</span>`
  },

  render (h) {
    return h({
      template: `<span class="d-inline-block rich-text">${this.html}</span>`
    })
  },

  methods: {
    // sanitizing locally because there are specific rules for this view that do not need to be allowed for the rest
    // of the app
    sanitizeLocally (dirty) {
      return sanitizeHtml(dirty, {
        allowedTags: [
          'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
          'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
          'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span','img',
          'figure','figcaption',
        ],
        allowedAttributes: {
          a: ['href', 'name', 'target','class'],
          img: ['src'],
          figure: ['class'],
          div: ['class'],
          '*': ['style']
        },
        transformTags: {
          'figure': 'div',
          'figcaption': 'span',
        }
      })
    },

    getShortCodes (source, pattern = new RegExp('#\\[\\[(.*?)]]', 'g')) {
      const isCurrentNested = false

      const shortcodeStrings = source.match(pattern)
      const shortcodes = []
      if (shortcodeStrings) {
        shortcodeStrings.forEach((string) => {
          const shortcode = {}
          const componentPath = string.substring(0, string.indexOf(',')).replace(/#\[\[/, '')
          let paramsString = string.substring(string.indexOf(',') + 1)
          const params = {}
          if (paramsString.includes('#{{')) {
            const key = paramsString.match(/\s(\S*)==#{{/)[1]
            const parsedValue = this.getShortCodes(paramsString, new RegExp('#{{(.*?)}}', 'g'))
            params[key] = parsedValue[0]
            params[key] = params[key].params
            params[key].nestedComponent = true
            const componentRegex = new RegExp(`.${key}==#(.*)}}`, '')
            paramsString = paramsString.replace(componentRegex, '')
          }
          const p = paramsString.match(/(.*?)=="(.*?)\/\/"/g)
          if (p && !params.nestedComponent) {
            p.forEach((el) => {
              const paramKey = el.substring(0, el.indexOf('==')).trim()
              params[paramKey] = el.substring(el.indexOf('==') + 2).slice(1, -3)
            })
          }
          shortcode.componentPath = componentPath
          shortcode.params = params
          shortcode.string = string
          shortcodes.push(shortcode)
        })
      }
      return shortcodes
    },

    renderShortcode (shortcode) {
      const componentName = this.getComponentName(shortcode.componentPath)
      const propName = componentName === 'ButtonComponent' ? 'button' : 'content'
      const result = `<${componentName} :${propName}='${JSON.stringify(shortcode.params)}'></${componentName}>`
      return result
    },

    parseShortCodes (source) {
      let parsed = source
      const shortcodes = this.getShortCodes(source, new RegExp('#\\[\\[(.*?)]]', 'g'))
      if (shortcodes) {
        shortcodes.forEach((sc) => {
          const html = this.renderShortcode(sc)
          parsed = parsed.replace(sc.string, html)
        })
      }
      return parsed
    },

    getComponentName (cmsComponentName) {
      if (!COMPONENT_MAP[cmsComponentName]) {
        // eslint-disable-next-line no-console
        console.error(`COMPONENT NOT FOUND: ${cmsComponentName}`)
      }
      return COMPONENT_MAP[cmsComponentName]
    },

    checkIfNestedComponents (source) {

    }
  }
}
// parsed_: <p><span style="color:#333333;">Bei der Auswahl unserer Kooperationspartner werden wir auf der Grundlage der &nbsp; und der Benutzerfreundlichkeit als auch auf einem guten Preis-Leistungsverhältnis. Test</span></p><p><span style="color:#333333;">#[[content.download-cta, text=="text for donwload banner //" background=="black //" backgroundOpacity=="0.075//" cornersRadius=="sm//" button==#[[buttons.button, title=="Download//" style=="primary//" behavior=="button//"]] ]]</span></p>

// sc.string: #[[content.download-cta, text=="text for donwload banner //" background=="black //" backgroundOpacity=="0.075//" cornersRadius=="sm//" button==#[[buttons.button, title=="Download//" style=="primary//" behavior=="button//"]]

// html: <DownloadBanner :content='{"button":{"title":"Download","style":"primary","behavior":"button","nestedComponent":true},"text":"text for donwload banner ","background":"black ","backgroundOpacity":"0.075","cornersRadius":"sm"}'></DownloadBanner>
</script>

<style lang="scss">
@import "../../scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.rich-text {
  ul {
    padding-left: 1.25rem;

    li {
      position: relative;
    }

    li + li {
      margin-top: 1rem;
    }

    li::marker {
      content: '';
      color: $primary;
      font-size: 1.5rem;
    }

    li::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: -1.25rem;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: $primary;
    }
  }

  p {
    color: inherit;
  }

  p + * {
    margin-top: 1rem;
  }
  img {
    max-width: 100%;
  }
  .image-style-side {
    float: right;
    max-width: 50%;
    margin-left: 1rem;
  }

  h1, h2, h3, h4, h5, h6, p {
    min-height: 1.5em; // a way to keep line-breaks
  }

  a {
    color: $primary;
    transition: 0.2s;

    &:hover {
      color: $primary;
      filter: brightness(120%) !important;
      text-decoration: none;
    }
  }
}
</style>
