// add the mixin in route/component where we need to check if there is enough store data
export default {

  methods: {
    findMatchingModel(prices, map, key, ) {
      let ei = null
      map.some((groupModule) => {
        if (prices.modules[groupModule].modules.indexOf(key) > -1) {
          ei = groupModule
          // console.log('groupModule', groupModule)
          return true
        }

      })
      return ei
    }
  }
}

