export default {
  methods: {
    preventInputHandler (event, field) {
      if (event.data) {
        if (field.numeric) {
          this.preventNonNumeric(event)
        }
      }
    },
    preventNonNumeric (event) {
      const isNumber = /\d/.test(event.data)
      if (!isNumber) {
        event.preventDefault()
      }
    }
  }
}
