<template>
    <div>
        <div id="sales_materials_lp" class="secondary-page">
            <div class="container">
                <div class="row page-title">
                    <div class="col">
                        <h1>Bedingungen und Formulare</h1>
                    </div>
                    <div class="col-12 col-md-2">
                        <div class="back-btn" style="text-align:right;margin-bottom:20px;">
                            <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
                        </div>
                        <!-- <div class="tab-controls cyberdirekt">
                            <button
                                    class="btn-style"
                                    @click="visit(tab)"
                                    :class="{primary: getCurrentTab(tab)}"
                                    v-for="(tab, index) in tabs"
                                    :key="index">{{tab.label}}
                            </button>
                        </div> -->
                    </div>
                </div>
                <template v-for="(insurer, index) in currentContent" >
                  <div class="row insurers" id="" v-if="insurer.files.length" :key="index">
                    <div class="col-12 col-md-3 insurer"
                         :class="$slugify(insurer.label)"
                         :key="index">
                        <div class="image-area">
                            <img :src="insurer.logoUrl" alt="Logo">
                        </div>
                    </div>
                    <div class="col-12 col-md-9 file-area">
                        <file-list class="mb-3" :items="insurer.files"></file-list>
                    </div>
                    <hr>
                  </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
  import FileList from '@/checkout/src/FileList'
  import { mapGetters } from 'vuex'
  import unionBy from 'lodash/unionBy'

  export default {
    name: 'Versicherungsbedingungen',
    metaInfo: {
      title: 'Versicherungsbedingungen'
    },
    created () {
      if (this.user && this.user.brokerAgency && this.user.brokerAgency.key === 'teasscon') {
        this.additionalFiles.victor.push({
          link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Architekten_Ingenieure.pdf',
          name: 'Victor Allgemeine Versicherungsbedingungen für Architekten & Ingenieure'
        })
      }
    },
    components: {
      FileList,
    },
    data() {
      return {
        tabs: [
          {
            id: 'bedingungen',
            label: 'Bedingungen',
            key: 'insurers'
          }
        ],
        additionalFiles: {
          victor: [
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_VVG.pdf',
              name: 'Victor Vorvertragliche Anzeigepflichten'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Kundeninformationen_VVG.pdf',
              name: 'Victor Kundeninformation gemäß § 7 VVG'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Datenschutzerklaerung.pdf',
              name: 'Victor Datenschutzerklärung'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Bauwirtschaft.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Bauwirtschaft & Handwerksbetriebe'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Gastronomie.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Gastronomie & Hotels'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Gesundheit.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Gesundheitswesen & Sozialwesen'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Handel.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Handelsunternehmen'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_ITK.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Informations- & Kommunikationstechnologie'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Immobilien.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Immobilienwirtschaft'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Kammerberufe.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Beratungs- & Kammerberufe'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Kultur_Soziales.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Kultur, Soziales und kreative Tätigkeiten'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Transport_Logistik.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Logistik & Verkehr'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Vereine.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Vereine, Stiftungen & Verbände'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Vermittler.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Vermittler (Versicherungen, Finanzen, Immobilien)'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Sonstige.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Sonstige Branche'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Gesundheit_MCSS.pdf',
              name: 'Victor Allgemeine Versicherungsbedingungen für Arztpraxen'
            }
          ],
          continentale: [
            {
              "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/continentale/Continentale_Mitteilung_nach_19_Abs_5_VVG.pdf",
              "name" : "Continentale Mitteilung nach §19 Abs. 5 VVG"
            },
            {
              "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/continentale/Continentale_Versicherungsbedingungen.pdf",
              "name" : "Continentale Versicherungsbedingungen"
            },
            {
              "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/continentale/Continentale_Produktinformation_Cyberversicherung.pdf",
              "name" : "Continentale Produktinformation Cyberversicherung"
            }
          ],
          'gothaer-10-50-rev-austria': [
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer-10-50-rev+AT/Gothaer_AT_10_bis_50_Mio_Bedingungswerk_Cyber-Versicherung.pdf",
                "name" : "Gothaer Bedingungswerk Cyber-Versicherung"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer-10-50-rev+AT/Gothaer_AT_Datenschutz_Informationsblatt.pdf",
                "name" : "Gothaer Datenschutz Informationsblatt"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer-10-50-rev+AT/Gothaer_AT_Informationsbroschuere_Cyber_Versicherung.pdf",
                "name" : "Gothaer Produktblatt Cyber"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer-10-50-rev+AT/Gothaer_AT_Vorvertragliche_Anzeigepflicht.pdf",
                "name" : "Gothaer Vorvertragliche Anzeigepflicht",
                "vvg" : true
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer-10-50-rev+AT/Gothaer_AT_Widerrufsbelehrung.pdf",
                "name" : "Gothaer Widerrufsbelehrung"
            }
          ],
          'gothaer-austria': [
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer+AT/Gothaer_AT_Bedingungswerk_Cyber-Versicherung.pdf",
                "name" : "Gothaer Bedingungswerk Cyber-Versicherung"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer+AT/Gothaer_AT_Datenschutz_Informationsblatt.pdf",
                "name" : "Gothaer Datenschutz Informationsblatt"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer+AT/Gothaer_AT_Produktblatt_Cyber.pdf",
                "name" : "Gothaer Produktblatt Cyber"
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer+AT/Gothaer_AT_Vorvertragliche_Anzeigepflicht.pdf",
                "name" : "Gothaer Vorvertragliche Anzeigepflicht",
                "vvg" : true
            },
            {
                "link" : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/gothaer+AT/Gothaer_AT_Widerrufsbelehrung.pdf",
                "name" : "Gothaer Widerrufsbelehrung"
            }
          ],
          'hiscox-austria': [
            {
              link: 'https://s3.eu-central-1.amazonaws.com/de-cyberdirekt-uploads-public/insurers/hiscox/Hiscox_Bedingungen_Cyberclear_Versicherung.pdf',
              name: 'Hiscox Bedingungen Cyberclear Versicherung'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox+AT/Hiscox_Mitteilung_nach_Paragraph_16_ff_VersVG.pdf',
              name: 'Hiscox Mitteilung nach §§16 ff VersVG',
              vvg: true
            },
            {
              link: 'https://s3.eu-central-1.amazonaws.com/de-cyberdirekt-uploads-public/insurers/hiscox/Hiscox_Allgemeine_Versicherungsinformationen.pdf',
              name: 'Hiscox Allgemeine Versicherungsinformationen'
            },
            {
              link: 'https://s3.eu-central-1.amazonaws.com/de-cyberdirekt-uploads-public/insurers/hiscox/Hiscox_Bedingungen_Allgemeine_Regelungen.pdf',
              name: 'Hiscox Bedingungen Allgemeine Regelungen'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox/Hiscox_Cyberclear_Zielgruppenantrag_Handel_Highlightsheet.pdf',
              name: 'Hiscox Zielgruppenantrag Handel Highlightsheet'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox/Hiscox_Cyberclear_Zielgruppenantrag_Hersteller_Highlightsheet.pdf',
              name: 'Hiscox Zielgruppenantrag Hersteller Highlightsheet'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox/Hiscox_Cyberclear_Zielgruppenantrag_Baubranche_Highlightsheet.pdf',
              name: 'Hiscox Zielgruppenantrag Baugewerbe Highlightsheet'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox/Hiscox_Sideletter_Tagessatz_BU_On-Premises.pdf',
              name: 'Hiscox Sideletter Tagessatz BU On-Premises'
            },
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/hiscox/Hiscox_Sideletter_Tagessatz_BU_Cloud-Ausfall.pdf',
              name: 'Hiscox Sideletter Tagessatz BU Cloud-Ausfall'
            }
          ],
        }
      }
    },
    computed: {
      ...mapGetters(['isAon','agencyKey']),
      currentContent() {
        const currentTab = this.tabs.find(tab => tab.id === this.$route.params.id);
        return this[currentTab.key]
      },
      user() {
        return this.$store.state.user
      },
      insurers() {
        let insurers = this.$store.state.checkout.application.insurers
          .filter((insurer) => {
            //filter austrian/aon results if not austrian.
            return !(!this.isAon && insurer?.key?.includes('austria'));
          });

        insurers.push({
          label: 'o2',
          key: 'o2',
          logoUrl: "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurer-logos/o2.svg",
          files: [
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/o2/o2+Cyber-Versicherung+Produktinformationsblatt.pdf',
              name: 'o2 Cyber-Versicherung Produktinformationsblatt'
            },            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/o2/o2+Cyber+Versicherungsbedingungen.pdf',
              name: 'o2 Cyber-Versicherungsbedingungen'
            },            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/o2/o2+Cyber-Versicherung+Datenschutzinformationen.pdf',
              name: 'o2 Cyber-Versicherung Datenschutzinformationen'
            }
          ]
        });

        insurers.push({
          label: 'Victor',
          key: 'victor',
          logo: 'victor.svg',
          logoUrl: "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurer-logos/victor.svg",
          files: [
            {
              link: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/victor/Victor_Widerrufsbelehrung.pdf',
              name: 'Victor Widerrufsbelehrung'
            }
          ]
        });

        insurers.push({
          label: 'Continentale',
          key: 'continentale',
          logo: 'continentale.png',
          logoUrl : "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurer-logos/continentale.png",
          files: []
        });

        // specific agency rules AON austria, filter only the austrian insurers
        if (this.isAon) {
          // austria
          insurers.push({
            label: 'Hiscox',
            key: 'hiscox-austria',
            logoUrl: "https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurer-logos/hiscox.png",
            files: [
            ]
          });

          insurers = insurers.filter((insurer) => {
            return insurer.key.includes('austria') || insurer.key === 'berkley'
          });
        }

        Object.keys(this.additionalFiles).forEach((insurerKey) => { // get all additional files
          let insurer = insurers.find(insurer => insurer.key === insurerKey); // find a particular insurer

          let fileInsurerKey = insurerKey

          this.additionalFiles[fileInsurerKey].forEach((additionalFile) => { // look at all files within the insurer
            if (!insurer?.files?.some(file => file?.link === additionalFile?.link)) { // check if there isn't duplicate etc
              insurer?.files?.push(additionalFile);
            }
          });
        });

        /// EXCEPTIONS FOR aon
        // remove file from berkley
        if (this.isAon) {
          const berkleyIndex = insurers.findIndex(insurer => insurer.key === 'berkley');
          if(berkleyIndex > -1) {
            const berkleyFileIndex = insurers[berkleyIndex].files.findIndex(file => file.link === 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/insurers/Berkley/Berkley_Sideletter_CyberDirekt_072023.pdf');
            if (berkleyFileIndex > -1) {
              insurers[berkleyIndex].files.splice(berkleyFileIndex, 1);
            }
          }
        }

        // filter whitelist, blacklist, exclusive list
        insurers = insurers.filter(insurer => {
          const status = this.getInsurerStatus(insurer.key)

          if (status === 'exclusive') {
            if (this.insurerBlackList.length) {
              return !this.insurerBlackList.includes(insurer.key) && this.insurerExclusiveList.includes(insurer.key)
            }

            // check is agency has it in exclusive list
            return this.insurerExclusiveList.includes(insurer.key)
          } else {

            if (this.insurerWhiteList.length) {
              return this.insurerWhiteList.includes(insurer.key)
            }

            if (this.insurerBlackList.length) {
              return !this.insurerBlackList.includes(insurer.key)
            }

            return true
          }
        })

        // filter files based on admin settings
        insurers.forEach(ins => {
          ins.files = ins.files.filter(file => {
            return file.allowedLocations && file.allowedLocations.showInBrokerPortalDownloads
          })

        })

        // Merging files with same labels in one group
        function mergeInsurersWithSameLabel(array) {
          let mergedObjects = {};

          for (let i = 0; i < array.length; i++) {
              let currentObject = array[i];
              let key = currentObject.label;

              if (!mergedObjects[key]) {
                  mergedObjects[key] = currentObject;
              } else {
                  // Merge objects if they have the "files" property
                  if (currentObject.files) {
                      mergedObjects[key].files = mergedObjects[key].files || [];
                      mergedObjects[key].files = unionBy(mergedObjects[key].files, currentObject.files, "name")
                  }
              }
          }

          // Convert objects back to an array
          let result = Object.values(mergedObjects);

          return result;
        }

        insurers = mergeInsurersWithSameLabel(insurers)

        return insurers.sort((a, b) => a.label.localeCompare(b.label));
      },
      insurerWhiteList() {
        return this.$store.getters.user.brokerAgency.insurerWhiteList || []
      },
      insurerBlackList() {
        return this.$store.getters.user.brokerAgency.insurerBlackList || []
      },
      insurerExclusiveList() {
        return this.$store.getters.user.brokerAgency.insurerExclusiveList || []
      }
    },
    mounted: function () {

    },
    methods: {
      visit(tab) {
        this.$router.push({
          name: 'Bedingungen und Formulare',
          params: {
            id: tab.id
          }
        })
      },
      getCurrentTab(tab) {
        return this.$route.params.id === tab.id
      },
      getInsurerStatus(insurerKey) {
        let status
        try {
          if (this.$store.state.checkout.application.prices[insurerKey]) {
            status = this.$store.state.checkout.application.prices[insurerKey].status
          }
        } catch(err) {
          console.error('Cannot find status for insurer. Error:', err)
        }
        return status
      }
    },
  }
</script>

<style lang="scss" scoped>

    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    #sales_materials_lp {

        .insurers {
            margin-top: calc($standardGutter * 2);
            .insurer {

                .image-area {
                    height: 135px;
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 120px;
                    }

                }

                &:first-child {
                    .file-area {
                        border: 0;
                    }
                }
            }

            .insurer.markel {
                img {
                    width: 83px;
                    position: relative;
                    left: 8px;
                }
            }
            .insurer.hdi {
                img {
                    width: 135px;
                    margin-top: 10px;
                }
            }

            .insurer.aig {
                img {
                    width: 135px;
                    margin-top: 10px;
                }
            }

            hr {
                width: 80%;
            }
        }

        .insurers:last-child {
            hr {
                display: none;
            }
        }

    }


</style>

<style lang="scss">
    #sales_materials_lp {
        .icon.icon--secondary {
            stroke: none !important;
            margin-right: 10px;
        }
    }
</style>
