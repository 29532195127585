import accounting from 'accounting';

const currencyFilter = function (value, fractals = 2, symbol = '€') {
  let currencyString = symbol ? symbol + ' ' : '';

  if (value === -1) {
    return '---';
  }

  return accounting.formatMoney(value, currencyString, fractals, '.', ',');
};

export default currencyFilter;
