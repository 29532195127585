<template>
  <div>
    <div class="secondary-page">
      <div class="container">

        <div class="row page-title">
          <div class="col">
            <h1>Gesprächsleitfäden</h1>
          </div>

           <div class="col-12 col-md-2 back-btn">
            <a class="btn-style" href="" @click.prevent.stop="takeMeBack()">&larr;Zurück</a>
          </div>
        </div>

        <div class="container">
          <div class="row" v-for="(qa, index) in guidelines" :key="index">
            <div class="col-12 qa-mod" :class="{ active: qa.expanded, last: (index + 1) === guidelines.length }">
              <a class="cd-question" href="" @click.prevent.stop="openDrawer(qa, index)">
                <span>{{(qa.expanded) ? '-' : '+'}}</span>
                <span>{{qa.question}}</span>
              </a>
              <transition name="fadeHeight">
                <div class="cd-answer" v-html="$sanitizeHtml(qa.answer)" v-show="qa.expanded"></div>
              </transition>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CDFAQContainer',
  metaInfo: {
    title: 'Gesprächsleitfäden'
  },
  data() {
    return {}
  },
  methods: {
    takeMeBack () {
      this.$router.push('/vertriebsmaterialien')
    },
    openDrawer (qa, index) {
      qa.expanded = !(qa.expanded === true)
      this.content.qa_list.splice(index, 1, qa)
    }
  },
  computed:{
    ...mapGetters(['guidelines'])
  },
  components: {
  },
  destroyed() {
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/variables';

  $qaModBorderColor: #ccc;
  $contentMargin: 10px;

  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all 0.2s;
    max-height: 600px;
  }
  .fadeHeight-enter,
  .fadeHeight-leave-to
  {
    opacity: 0;
    max-height: 0px;
  }

  .main-title {
    padding: 0;
  }

  .qa-mod {
    border: 1px solid $qaModBorderColor;
    border-bottom: 0;

    a.cd-question {
      font-size: 1.2rem;
      margin-bottom: 10px;
      margin-top: 10px;
      display: flex;
      font-weight: 550;

      > span:first-child {
        margin-right: $contentMargin;
        display: none;
      }

      &:hover {
        color: #1a7dab;
        text-decoration: none;
      }

    }
    .cd-answer {
      margin-left: $contentMargin * 2;
    }
  }

  .qa-mod.active {
    a.cd-question {

      > span:first-child {
        font-size: 1.7rem;
        line-height: 1;
        display: none;
      }

    }
  }

  .qa-mod.last {
    border-bottom: 1px solid $qaModBorderColor;
    margin-bottom: calc($standardGutter * 2);
  }

</style>
