<template>
  <section class="section-form">
    <h2 class="section-heading">{{ content.h2 }}</h2>

    <form @submit.prevent="submit">
      <div
        class="loading-overlay"
        v-if="loading"
        :class="{ 'loading-overlay--in': loading }"
      >
        <LoaderComponent></LoaderComponent>
      </div>

      <div class="row">
        <div
          v-if="content.form.control1"
          class="col-12 col-sm-6 col-lg-4 form-group"
        >
          <label :for="`name`"
            >{{ content.form.control1 }}*</label
          >
          <input
            class="form-control form-control--v2"
            type="text"
            :id="`name`"
            name="name"
            v-model="form.name"
            :data-vv-as="content.form.control1"
            v-validate="'required'"
          />
          <small v-show="errors.has('name')" class="error-note">
            {{ errors.first('name') }}
          </small>
        </div>

        <div
          v-if="content.form.control2"
          class="col-12 col-sm-6 col-lg-4 form-group"
        >
          <label :for="`email`"
            >{{ content.form.control2 }}*</label
          >
          <input
            class="form-control form-control--v2"
            type="email"
            :id="`email`"
            name="email"
            v-model="form.email"
            :data-vv-as="content.form.control2"
            v-validate="'required'"
          />
          <small v-show="errors.has('email')" class="error-note">
            {{ errors.first('email') }}
          </small>
        </div>

        <div
          v-if="content.form.control3"
          class="col-12 col-sm-6 col-lg-4 form-group"
        >
          <label :for="`phone`"
            >{{ content.form.control3 }}*</label
          >
          <input
            class="form-control form-control--v2"
            type="text"
            :id="`phone`"
            name="phone"
            v-model="form.phone"
            :data-vv-as="content.form.control3"
            v-validate="'required'"
          />
          <small v-show="errors.has('phone')" class="error-note">
            {{ errors.first('phone') }}
          </small>
        </div>

        <div
          v-if="content.form.control4"
          class="col-12 col-sm-6 col-lg-4 form-group"
        >
          <label>{{
            content.form.control4
          }}</label>

          <div class="custom-control custom-radio radio-accent">
            <input
              class="custom-control-input"
              type="radio"
              :id="`radio-reason-question`"
              :name="`radio-reason`"
              value="Frage / Support nötig"
              v-model="form.reason"
              v-validate="'required'"
            />
            <label class="custom-control-label" :for="`radio-reason-question`"
              >Frage / Support nötig</label
            >
          </div>
          <div class="custom-control custom-radio radio-accent">
            <input
              class="custom-control-input"
              type="radio"
              :id="`radio-reason-idea`"
              :name="`radio-reason`"
              value="Idee / Verbesserungsvorschlag für uns"
              v-model="form.reason"
              v-validate="'required'"
            />
            <label class="custom-control-label" :for="`radio-reason-idea`"
              >Idee / Verbesserungsvorschlag für uns</label
            >
          </div>

          <small v-show="errors.has('reason')" class="error-note">
            {{ errors.first('reason') }}
          </small>
        </div>
      </div>

      <div v-if="content.form.control7" class="row">
        <div class="col-12 form-group">
          <label :for="`text`">{{ content.form.control7 }}</label>
          <textarea
            class="form-control form-control--v2"
            name="text"
            :id="`text`"
            cols="30"
            rows="5"
            v-model="form.message"
            :data-vv-as="content.form.control7"
          >
          </textarea>
          <small v-show="errors.has('text')" class="error-note">
            {{ errors.first('text') }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="form-group custom-control custom-checkbox checkbox-accent"
          >
            <input
              class="custom-control-input"
              type="checkbox"
              :id="`privacy-policy`"
              name="privacy-policy"
              v-model="form.privacyPolicy"
              :data-vv-as="'Datenschutzhinweisen'"
              required
            />
            <label
              :for="`privacy-policy`"
              v-html="content.form.checkboxText"
              class="custom-control-label"
            />
            <small v-show="errors.has('privacy-policy')" class="error-note">
              {{ errors.first('privacy-policy') }}
            </small>
          </div>
        </div>
      </div>

      <div class="contact-form__buttons">
        <ButtonComponent
          v-if="formSent"
          behavior="button"
          type="button"
          class="contact-form__submit contact-form__submit--submited"
        >
          <Icon name="check-circle" :width="24" />
          <span>Gesendet</span>
        </ButtonComponent>

        <ButtonComponent
          v-else-if="content.form.button"
          behavior="button"
          :button="content.form.button"
          type="submit"
          class="contact-form__submit"
        >
        {{ content.form.button.title }}
        </ButtonComponent>
      </div>
    </form>
  </section>
</template>

<script>
import ButtonComponent from '@/components/reusable/ButtonComponent'
import Icon from '@/components/Icon'
import LoaderComponent from '@/components/reusable/LoaderComponent.vue'

const content = {
  h2: 'Kontaktformular',
  form: {
    source: 'contactForm',
    template: 'contactForm',
    control1: 'Name',
    control2: 'E-Mail',
    control3: 'Telefonnummer',
    control4: 'Was ist ihr Anliegen?',
    control7: 'Worum geht es?',
    button: {
      style: 'accent',
      behavior: 'button',
      openNewTab: true,
      size: 'md',
      title: 'Senden'
    },
    checkboxText:
      '<span>Ich bin mit den <a class="link-primary" target="_blank" rel="noopener noreferrer" href="https://www.cyberdirekt.de/datenschutzhinweise"><strong>Datenschutzhinweisen </strong></a> einverstanden.</span>'
  }
}

export default {
  components: { ButtonComponent, Icon, LoaderComponent },

  data() {
    return {
      content,
      loading: false,
      form: this.getInitialFormData(),
      formSent: false
    }
  },

  methods: {
    getInitialFormData() {
      return {
        source: this.content?.form.source || 'contactForm',
        template: this.content?.form.template || 'contactForm',
        urlFrom: this.window ? this.window.location.href : '',
        name: '',
        email: '',
        phone: '',
        reason: 'Frage / Support nötig',
        message: ' ',
        privacyPolicy: false
      }
    },

    clearForm() {
      this.form = this.getInitialFormData()
      this.$validator.reset()
    },

    submit() {
      this.$validator.validateAll().then((result) => {
        if (this.$validator.errors.items[0]) {
          const firstErrorField = document.getElementById(
            this.$validator.errors.items[0].field
          )

          const y =
            firstErrorField.getBoundingClientRect().top + window.scrollY - 100
          window.scroll({
            top: y - 200,
            behavior: 'smooth'
          })
        }
        if (result) {
          this.form.urlFrom =
            this.content.form.urlFrom || window ? window.location.href : ''

          this.loading = true
          this.$store.dispatch('SEND_EMAIL', this.form).then(() => {
            this.clearForm()
            this.loading = false
            this.formSent = true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/scss/form';

.section-form {
  padding: 3.5rem 4.25rem;
  background: #fff;
  box-shadow: 0 147px 59px rgba(14, 45, 92, 0.01),
    0 83px 50px rgba(14, 45, 92, 0.05), 0 37px 37px rgba(14, 45, 92, 0.09),
    0 9px 20px rgba(14, 45, 92, 0.1), 0 0 0 rgba(14, 45, 92, 0.1);

  @include media-breakpoint-down(md) {
    padding: 3rem 2rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 3rem 2.5rem;
  }

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }
}

.contact-form {
  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    min-width: 293px;
  }

  &__submit--submited,
  &__submit--submited:focus {
    font-weight: 600;
    gap: 0.75rem;
    background: #51BC7E;
    border: 2px solid #51BC7E;
    box-shadow: none;
    pointer-events: none;
    max-width: 585px;
    width: 100%;
  }
}

.form-group {
  margin-bottom: 2rem;

  label:not(.custom-control-label) {
    margin-bottom: 1rem;
  }

  &.custom-checkbox {
    label {
      font-weight: normal;
      line-height: 1;
    }
  }

  .custom-radio {
    .custom-control-label {
      font-weight: normal;
    }

    & + .custom-radio {
      margin-top: 0.5rem;
    }
  }
}
</style>
