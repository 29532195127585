var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-touch',{staticClass:"emotet-email course-base-template",attrs:{"swipe-options":{ direction: 'horizontal' }},on:{"swipeleft":_vm.next,"swiperight":_vm.back}},[_c('h2',{staticClass:"course-base-template__title"},[_vm._v(_vm._s(_vm.content.title))]),_c('div',{ref:"container",staticClass:"emotet-email__holder"},[_c('img',{staticClass:"emotet-email__image",attrs:{"src":require('@/pages/trainings/assets/courses/email-security/emotet-email.png'),"alt":"Bild: CERT-Bund/BSI"}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
         content: 'Herr Müller erhält eine Email von Frau Meier, welche eine Antwort auf eine vorherige Email zu sein scheint',
         classes: 'custom-template__tooltip-content',
         trigger: 'hover click',
       }),expression:"{\n         content: 'Herr Müller erhält eine Email von Frau Meier, welche eine Antwort auf eine vorherige Email zu sein scheint',\n         classes: 'custom-template__tooltip-content',\n         trigger: 'hover click',\n       }"}],staticClass:"emotet-email__tooltip",staticStyle:{"top":"3.5%","left":"0%"},style:({ transform: _vm.iconTransform })},[_vm._v(" 1 ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
         content: 'In seinen Text fügt der Computervirus einen Link ein. Doch der muss nicht zu der Adresse führen, die lesbar ist (also im Beispiel musterfirma.de...). Wenn Sie den Mauszeiger auf den Link halten (ohne ihn anzuklicken!) zeigt Ihnen das E-Mail-Programm, dass der Klick auf eine ganz andere Adresse führt',
         classes: 'custom-template__tooltip-content',
         trigger: 'hover click',
       }),expression:"{\n         content: 'In seinen Text fügt der Computervirus einen Link ein. Doch der muss nicht zu der Adresse führen, die lesbar ist (also im Beispiel musterfirma.de...). Wenn Sie den Mauszeiger auf den Link halten (ohne ihn anzuklicken!) zeigt Ihnen das E-Mail-Programm, dass der Klick auf eine ganz andere Adresse führt',\n         classes: 'custom-template__tooltip-content',\n         trigger: 'hover click',\n       }"}],staticClass:"emotet-email__tooltip emotet-email__show-fake-url",staticStyle:{"top":"24%","left":"73%"},style:({ transform: _vm.iconTransform })},[_vm._v(" 2 ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
         content: 'Der Computervirus späht aus Outlook-Postfächern nicht nur Email-Kontakte aus, die zur weiteren Verbreitung genutzt werden. Darüber hinaus werden auch die ersten 16kB jeder E-Mail ausgelesen und in der automatisch generierten Nachricht eingefügt. Besonders tückisch ist, wenn der Absender der E-Mail offenbar jemand ist, dem Sie kürzlich einen Schriftwechsel hatten.',
         classes: 'custom-template__tooltip-content',
         trigger: 'hover click',
       }),expression:"{\n         content: 'Der Computervirus späht aus Outlook-Postfächern nicht nur Email-Kontakte aus, die zur weiteren Verbreitung genutzt werden. Darüber hinaus werden auch die ersten 16kB jeder E-Mail ausgelesen und in der automatisch generierten Nachricht eingefügt. Besonders tückisch ist, wenn der Absender der E-Mail offenbar jemand ist, dem Sie kürzlich einen Schriftwechsel hatten.',\n         classes: 'custom-template__tooltip-content',\n         trigger: 'hover click',\n       }"}],staticClass:"emotet-email__tooltip emotet-email__show-highlight",staticStyle:{"top":"62%","left":"92%"},style:({ transform: _vm.iconTransform })},[_vm._v(" 3 ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
         content: 'Oftmals können Sie die gefälschte Email nur anhand der Wortwahl und eventueller Fehler in der Grammatik erkennen. In diesem Fall ist die Nachricht in nahezu perfektem Deutsch formuliert. Nur aufgrund des Wechsels in der Anrede von „Sie“ auf „Du“ konnte Herr Müller erahnen, dass mit der Email etwas nicht stimmt.',
         classes: 'custom-template__tooltip-content',
         trigger: 'hover click',
       }),expression:"{\n         content: 'Oftmals können Sie die gefälschte Email nur anhand der Wortwahl und eventueller Fehler in der Grammatik erkennen. In diesem Fall ist die Nachricht in nahezu perfektem Deutsch formuliert. Nur aufgrund des Wechsels in der Anrede von „Sie“ auf „Du“ konnte Herr Müller erahnen, dass mit der Email etwas nicht stimmt.',\n         classes: 'custom-template__tooltip-content',\n         trigger: 'hover click',\n       }"}],staticClass:"emotet-email__tooltip",staticStyle:{"top":"18%","left":"0"},style:({ transform: _vm.iconTransform })},[_vm._v(" 4 ")]),_c('div',{staticClass:"emotet-email__btn-mask emotet-email__show-fake-url",style:({ transform: _vm.iconTransform })}),_c('div',{staticClass:"emotet-email__fake-url",style:({ transform: _vm.scaleRatio })},[_vm._v(" http://super-plus.pl/css/oo6a-atf3y-frzom/ ")]),_c('div',{staticClass:"emotet-email__highlight",style:({ transform: _vm.scaleRatio })},[_vm._v(" Von Emotet zuvor auf infizierten System ausgespähte authentische E-Mail ")]),_c('div',{staticClass:"emotet-email__image-from"},[_vm._v("Bild: CERT-Bund/BSI")])]),_c('div',{staticClass:"row mb-15"},[_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"btn btn--secondary btn--border",attrs:{"type":"button","name":"back"},on:{"click":_vm.back}},[_vm._v("Zurück ")])]),_c('div',{staticClass:"col-6 text-right"},[_c('button',{staticClass:"btn btn--secondary",attrs:{"type":"button","name":"next"},on:{"click":_vm.next}},[_vm._v("Weiter ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }