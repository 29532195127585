<template>
  <ul :class="`list-${listStyle}`">
    <slot />
  </ul>
</template>

<script>
  export default {
    props: {
      listStyle: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  word-wrap: break-word;
}
.list-advantages {
  li {
    position: relative;

    &::before {
      content: url('~@/assets/icons/advantage.svg');
      position: absolute;
      left: calc(-1.5rem - 1rem);
      top: 0;
    }

    & + li {
      margin-top: 0.5rem;
    }
}
}
</style>