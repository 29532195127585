<template>
  <div class="cyb-table gap-sm" :class="{'cyb-table--offer': mode === 'offer'}">
    <div class="statistics-row gap-sm" :class="{'statistics-row--price-perform-winner': isPricePerformWinner()}">
      <div class="sticky-col title-col">
        <div class="th-cell"><b class="mr-2">Jahresprämie</b> (inkl. {{insTaxRate}}% VSt.)</div>
        <transition name="scores">
          <ScoresInfoMakler v-if="(application_name === 'portal-2' || isOffer) && isScoreVisible" :isOffer="isOffer" :isAffiliate="isAffiliate" />
          <ScoresInfo v-else-if="isScoreVisible" :isOffer="isOffer" :isAffiliate="isAffiliate" :isScoreNotVisible="isScoreNotVisible" />
        </transition>
      </div>
      <div class="prices">
        <div
          class="price"
          :class="[
              `price--${insurers.length}`,
              {'price--disabled': insurer.disabled,
              'insurer-recommended': insurer.key === recommendedInsurer},
              isRecommendationDeclinedMixin(insurer.key) && isOffer ? 'insurer--recommendation-declined' : ''
          ]"
          v-for="(insurer, insIndex) in innerInsurers"
          v-tooltip.bottom="getPriceToolTip(insurer)"
          :key="insurer.key"
        >
          <div class="recommendation-frame"></div>
          <div
            class="insurer-price th-cell text-center"
            :class="{'price-perform-winner': isInsurerPricePerformWinner(insurer.key)}"
            :style="[isRecommendationDeclinedMixin(insurer.key) && mode === 'offer' ? {
              color: 'transparent'
            } : '']"
          >
            <div v-if="!isLoading">
              <div v-if="mode === 'offer'" class="insurer-price__title">
                <b>Jahresprämie</b>
              </div>
              <span class="font-weight-bold">
                {{ $store.state.offer?.offer?.priceOverwrite?.[insurer.insurerKey]?.gross || insurer.prices?.gross || '' | currency(2) }}
                <span v-if="getPriceToolTip(insurer)">*</span>
              </span>
              <span v-if="mode === 'offer'" class="insurer-price__subtitle">
                (inkl. {{insTaxRate}}% VSt.)
              </span>
            </div>
            <span v-else>
              <icon name="loading" color="white" :spinning="true"></icon>
            </span>
          </div>

          <transition name="scores">
            <div
              v-if="isScoreVisible"
              class="tb-cell"
              :class="{'tb-cell--winner': insurersWithScores[insIndex] && insurersWithScores[insIndex].precentageScore === 'winner'}"
            >
              <ProgressRing
                class="enlarge-circle"
                :radius="70"
                :progress="insurersWithScores[insIndex] ? insurersWithScores[insIndex].precentageScore : null"
                :stroke="12"
                :standard-score="insurersWithScores[insIndex] ? insurersWithScores[insIndex].standardScore : null"
                >
              </ProgressRing>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoresInfo from './ScoresInfo.vue';
import ScoresInfoMakler from './ScoresInfoMakler.vue';
import ProgressRing from '../../block/ProgressRing.vue'
import Icon from '../../../../Icon.vue'
import agencyKey from '../../../../mixins/agencyKey';
import tariffsWithScanMixin from '../../../../mixins/tariffsWithScanMixin.js'

import {mapGetters} from 'vuex'


export default {
  components: {
    ScoresInfo,
    ScoresInfoMakler,
    ProgressRing,
    Icon
  },
  mixins: [agencyKey, tariffsWithScanMixin],

  props: {
    insurers: Array,
    isOffer: {
      type: Boolean,
      default: false,
      required: false
    },
    isAffiliate: Boolean,
    getPriceToolTip: Function,
    isPricePerformWinner: Function,
    isInsurerPricePerformWinner: Function,
    isLoading: Boolean,
    mode: String
  },

  data() {
    return {
      innerInsurers: this.insurers,
      recommendedInsurer: this.$store.state.offer?.offer?.insurer
    }
  },
  watch:{
    insurers: {
      handler(newValue) {
        this.innerInsurers = newValue
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'isBroker'
    ]),
    application_name () {
      return this.$store.state.application_name
    },
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      }
    },
    isScoreNotVisible: {
      get() {
        return !this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', !newValue)
      }
    },
    insurersWithScores() {
      return this.$store.getters.getInsurersWithScoresSorted
    }
  },

  methods: {
    generateRandomScore(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    forceUpdateInsurers () {
      this.innerInsurers = this.insurers
    }
  },

}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '../../../../scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '../../../../scss/print/tableStatistics.scss';

.statistics-row {
  display: flex;
  gap: 0.5rem;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }

  &--price-perform-winner {
    margin-top: 2rem;
  }

  .sticky-col {
    @include media-breakpoint-down(sm) {
      min-width: 290px;
      width: 290px;
    }

    .th-cell {
      // margin-bottom: 0.5rem;
      & + .cyb-card {
        margin-top: 0.5rem;
      }
    }
  }
}
.prices {
  display: flex;
  height: 100%;
  gap: 0.5rem;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }
}

.price {
  width: 290px;

  &--disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.tb-col {
  display: flex;
  flex-direction: column;
}

.th-cell {
  padding: 0.75rem 1.5rem;
  background: #12658b;
  color: #fff;
  border-radius: 0.25rem;
}

.tb-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 141%;
}

.row {
  height: 100%;
}

.scores-info {
  hyphens: manual;
}

.cyb-table--offer {
  .tb-cell--winner {
    padding-top: 1rem;

    @include media-breakpoint-down(xs) {
      padding-top: 0;
    }
  }

  .insurer-price {
    &__title {
      display: none;
      font-size: 0.85rem;
    }

    &__subtitle {
      display: none;
      font-size: 0.85rem;
      margin-left: 0.5rem;
    }
  }
}

.scores-leave,
.scores-enter-to {
  opacity: 1;
}
.scores-enter-active,
.scores-leave-active {
  transition: opacity 0.4s;
}
.scores-enter,
.scores-leave-to {
  opacity: 0;
}

.price-perform-winner {
  position: relative;
  background: #417E9B;

  &::before {
    content: 'Preis-Leistungs-Sieger';
    color: #000;
    text-align: center;
    font-size: 0.9rem;
    position: absolute;
    top: -1.5rem;
    left: 0;
    right: 0;

    @include media-breakpoint-down(md) {
      font-size: 0.85rem;
    }
  }
}

.enlarge-circle {
  transform: scale(1.3);
}
</style>
