<template>
  <div id="last_page" class="page">

    <div class="container">
      <div class="row section-title">
        <div class="col-12 columns">
          <h1>Cyber-Versicherung</h1>
          <h1 class="subhead">Der perfekte Schutz gegen Ihre Digitalen Risiken</h1>
        </div>
      </div>
  
      <div class="row section-title">
        <div class="col-12 columns">
          <h3>Schon im begr&uuml;ndeten Verdachtsfall erhalten Sie umgehend Sofort-Hilfe</h3>
        </div>
      </div>
  
      <div class="row overview-bucket" id="overview_list">
  
        <div class="col-12 col-md-6 columns">
          <!-- <img src="/static/guy_on_phone.jpg"> -->
        </div>
  
        <div class="col-12 col-md-6 columns">
          <ul>
            
            <li>
              <h4>Sofort-Erreichbarkeit</h4>
              <p>Im Notfall sind Experten 365 Tage im Jahr rund um die Uhr für Sie erreichbar.</p>
            </li>
  
             <li>
              <h4>Sofort-Analyse</h4>
              <p>Die Cyber-Versicherung stellt Ihnen sofort jemand an die Seite, um den Vorfall zu analysieren.</p>
            </li>
  
             <li>
              <h4>Sofort-Maßnahmen</h4>
              <p>Ein IT-Experte leitet umgehend die wichtigen Schritte zur Schadensbegrenzung ein.</p>
            </li>
  
            </ul>
        </div>
      </div>
  
  
      <div class="row section-title">
        <div class="col-12 columns">
          <h3>Experten helfen Ihnen schnell zur Normalität zurückzukehren</h3>
        </div>
      </div>
  
      <div class="row plate" id="expert_help">
        
        <div class="col-12 col-md-6 large-6 columns">
          <img src="/static/icons_produktuebersicht/datenrettung.svg">
          <h4>Wiederherstellung der Daten</h4>
          <p>Wir wollen, dass Ihre wertvollen Daten erhalten bleiben.</p>
          <p>Ein IT-Forensiker wird Ihnen zur Seite gestellt, um Ihre Daten zu retten und die Daten aus der Datensicherung neu einzuspielen.</p>
        </div>
  
  
        <div class="col-12 col-md-6 large-6 columns">
          <img src="/static/icons_produktuebersicht/wiederherstellung_systeme.svg">
          <h4>Wiederherstellung der Systeme</h4>
          <p>Wir wollen, dass Sie schnell wieder arbeiten können.</p>
          <p>IT-Experten helfen Ihnen, dass Sie Ihre IT-Systeme, Software und Netzwerke schnell wieder nutzen können.</p>
        </div>
  
  
        <div class="col-12 col-md-6 large-6 columns">
          <img src="/static/icons_produktuebersicht/pr.svg">
          <h4>Krisen- und PR-Beratung</h4>
          <p>Wir wollen, dass Ihre Kunden Ihnen auch in Zukunft vertrauen.</p>
          <p>PR-Experten helfen Ihnen, Kunden und Geschäftspartner über einen Vorfall zu informieren.</p>
        </div>
  
  
        <div class="col-12 col-md-6 large-6 columns">
          <img src="/static/icons_produktuebersicht/datenschutz.svg">
          <div class="swipe">DSGVO seit Mai 2018</div>
          <h4>Datenschutz Pflichten</h4>
          <p>Sie erhalten Unterst&uuml;tzung bei der Erf&uuml;llung der gesetzlichen Pflichten des Datenschutzes.</p>
          <p>Ein Berater hilft Ihnen bei der Prüfung und Erfüllung der behördlichen Informationspflichten.</p>
        </div>
  
      </div> <!-- End expert help -->
  
      <div class="row plate" id="check_list">
  
        <div class="col-12 col-md-6 columns">
          <img src="/static/checkmark-blue.svg">
          <h4>Sie können schnell wieder arbeiten!</h4>
        </div>
        
        <div class="col-12 col-md-6 columns">
          <img src="/static/checkmark-blue.svg">
          <h4>Alle notwendigen Schritte wurden eingeleitet!</h4>
        </div>
  
      </div> <!-- End check list -->
  
  
      <div class="row section-title">
        <div class="col-12 columns">
          <h3>Wir schützen Sie auch vor den finanziellen Risiken einer Cyber-Attacke</h3>
        </div>
      </div>
  
  
      <div class="row plate" id="financial_risks">
        <div class="col-12 col-md-12 columns">
          <h4>Der Versicherer leistet für die bei Ihnen entstandenen Schäden</h4>
          <p>Der Versicherer ersetzt den finanziellen Schaden, der Ihnen durch eine Betriebsunterbrechung von mehr als 24 Stunden entsteht. Der Schaden setzt sich aus dem entgangenen Betriebsgewinn und den fortlaufenden Kosten im Zeitraum der Betriebsunterbrechung zusammen.</p>
        </div>
  
        <div class="col-12 col-md-12 columns">
          <h4>Der Versicherer übernimmt die Kosten für die Schäden die bei Dritten entstehen</h4>
          <p>Vertragsstrafen und Regressforderungen können sich schnell zu einem Gesamtschaden von mehreren Millionen Euro summieren. Wir lassen nicht zu, dass Ihre Existenz gefährdet wird!</p>
        </div>
  
  
        <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/vertraglich_festgelegt.svg">
          <h5>Vertraglich festgelegte Regressansprüche</h5>
        </div>
  
         <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/persoenlichkeitsrechte.svg">
          <h5>Ansprüche aufgrund der Verletzung von Persönlichkeitsrechten</h5>
        </div>
  
         <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/copyright.svg">
          <h5>Ansprüche aufgrund der Verletzung von Namens-, Urheber-, Marken- und Wettbewerbsrecht</h5>
        </div>
  
  
        <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/abwehr_ansprueche.svg">
          <h5>Die Abwehr von unberechtigten Schadensersatzansprüchen</h5>
        </div>
  
         <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/weitergabe_malware.svg">
          <h5>Schadensersatzansprüche durch die Weitergabe von Schadsoftware</h5>
        </div>
  
         <div class="col-12 col-md-4 columns">
          <img src="/static/icons_produktuebersicht/kreditkartenindustrie.svg">
          <h5>Die vertraglich festgelegte Haftung gegenüber der Kreditkartenindustrie</h5>
        </div>
  
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Finish',
  props: ['attackVectors'],
  data () {
    return {
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

#last_page {
  padding-bottom: $standardGutter * 2;
  $centerImgWidth: 173px;
  background: #f2f2f2;

  #overview_list {

    > div:first-child {
      background-image: url(/public/static/guy_on_phone.jpg);
    }

    ul {
      $thisDimension: 58px;
      width: 100%;
      list-style: none;

      li {
        background: url(/public/static/checkmark-blue.svg) no-repeat 0 35%;
        padding-top: 20px;
        background-size: $thisDimension;
        padding-left: $thisDimension + 30;

        h4 {
          color: $blueTone;     
        }
      }
    }
  } // end overview list

  #expert_help {
    > div {
      text-align: center;
      position: relative;
      max-height: 440px;
      margin-bottom: $standardGutter;

      h4 {
        color: $blueTone;
        margin-top: 0;
      }

      img {
        width: $centerImgWidth - 20;
      }
      p {
        font-size: 1.2rem;
      }
      p:first-of-type {
        color: #6b6a6a;
      }

      .swipe {
        background: #FCDECE;
        transform: rotate(20deg);
        position: absolute;
        right: 5%;
        top: 0;
        color: #F26722;
        padding: .1rem .3rem;
        // font-weight: 500;
        font-size: .9rem;
      }
    }
  }

  #check_list {
    > div {
      text-align: center;
      img {
        width: 40px;
      }
    }
  }

  #financial_risks {
    p {
      font-size: 1.2rem;
    }
    div.col-md-12 {

      h4 {
        color: $blueTone;
      }
    }

    div.col-md-4 {
      text-align: center;
      img {
        width: $centerImgWidth;
      }
    }
  }
  
}

</style>

<style lang="scss">

</style>
