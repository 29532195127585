import { render, staticRenderFns } from "./BFooter.vue?vue&type=template&id=381d7394&scoped=true"
import script from "./BFooter.vue?vue&type=script&lang=js"
export * from "./BFooter.vue?vue&type=script&lang=js"
import style0 from "./BFooter.vue?vue&type=style&index=0&id=381d7394&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381d7394",
  null
  
)

export default component.exports