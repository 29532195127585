<template>
  <div>
    <div class="info-popup" v-if="isBeforeTargetDate && isOpen" :class="[isInit ? 'show' : '']">
      <div class="info-popup__content" id="info-popup__content"><b>Jetzt neu:</b> Vergleichen Sie die Risikofragen.</div>
      <div class="dotted-line"></div>
    </div>
    <div class="overlay" v-if="showOverlay && isBeforeTargetDate" @click="handleOverlayClick"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isInit: false,
    isOpen: true,
    showOverlay: true,
  }),
  mounted() {
    setTimeout(() => {
      this.isInit = true
    }, 1000)
  },
  computed: {
    isBeforeTargetDate() {
      const targetDate = new Date('2024-11-21')
      const currentDate = new Date()
      return currentDate < targetDate && !this.$store.state.checkout?.application?.isRQPopupShown && this.isOpen
    },
  },
  methods: {
    handleOverlayClick() {
      if (!this.isInit) {
        return
      }
      this.showOverlay = false
      this.isOpen = false
      this.$store.commit('SET_RQ_POPUP_STATE')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.info-popup {
  position: relative;
  cursor: pointer;
  z-index: 15;
  opacity: 0;
  transform: translateY(-50%);
  transition:
    opacity 1s ease-in,
    transform 1s ease-in-out;

  &.show {
    transform: translateY(0);
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 16;
    top: -17px;
    left: 120px;
    margin-left: -10px;
    width: 0;
    height: 0;
    border: 5px solid aliceblue;
    border-radius: 60%;
    opacity: 1;
  }

  &__content {
    position: absolute;
    z-index: 16;
    bottom: 17px;
    left: 200px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    background-color: aliceblue;
    white-space: nowrap;
  }

  .dotted-line {
    z-index: 15;
    position: absolute;
    top: -25px;
    left: 111px;
    width: 89px;
    border-top: 1px dotted aliceblue;
    transform: rotate(-17deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 14;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 150px; /* Adjust as needed */
  left: 150px; /* Adjust as needed */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black; /* Arrow color */
}
</style>
