<template>
    <quiz-base-template
            :background="require(`@/pages/trainings/assets/courses/${courseSlug}/img/${quizData.background}`)"
            :result-status="result"
            :result-html="quizData.result"
            :position="quizData.questionPosition">
        <div class="multiple-choice-template__questions">
            <h3 class="multiple-choice-template__question">{{ quizData.question }}</h3>
            <div class="row">
                <div :class="quizData.questionPosition === 'full' ? 'col-sm-6' : 'col-xs-12'"
                     v-for="answer in quizData.answers"
                     :key="answer.id">
                    <div class="multiple-choice-template__answer"
                         :class="{ 'multiple-choice-template__answer--selected': isSelected(answer) }"
                         @click="toggleAnswer(answer)"
                         v-html="answer.text"></div>
                </div>
            </div>
        </div>
        <div class="multiple-choice-template__button-holder">
            <button type="button"
                    @click="check"
                    class="btn multiple-choice-template__confirm-button">Antwort bestätigen
            </button>
        </div>
    </quiz-base-template>
</template>

<script>
  import QuizBaseTemplate from './QuizBaseTemplate.vue';

  export default {
    name: 'multiple-choice-template',
    components: {
      QuizBaseTemplate
    },
    data() {
      return {
        selectedAnswers: [],
        result: ''
      }
    },
    props: {
      quizData: {
        type: Object,
        required: true
      },
      courseSlug: {
        type: String,
        required: true
      }
    },
    watch: {
      quizData() {
        this.selectedAnswers = [];
        this.result = '';
      }
    },
    methods: {
      isSelected(answer) {
        return this.selectedAnswers.indexOf(answer) > -1;
      },
      toggleAnswer(answer) {
        const selectedIndex = this.selectedAnswers.indexOf(answer);

        if (selectedIndex > -1) {
          this.selectedAnswers.splice(selectedIndex, 1);
        } else {
          this.selectedAnswers.push(answer);
        }
      },
      check() {
        const success = this.quizData.answers.every(answer => this.isSelected(answer) === answer.isCorrect);

        this.result = success ? 'success' : 'failure';
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .multiple-choice-template {
        &__question {
            margin: 0 0 15px;
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 500;
            text-align: center;
            color: $primary;
        }

        &__answer {
            padding: 8px 10px;
            margin-bottom: 10px;
            border-radius: 20px;
            cursor: pointer;

            &:not(&--selected):hover,
            &:not(&--selected):focus {
                background-color: rgba($primary, 0.4);
            }

            &--selected {
                background-color: rgba($primary, 0.9);
                box-shadow: 0 0 5px rgba($primary, 0.75);
            }
        }

        &__questions {
            min-height: 374px;
        }

        &__button-holder {
            padding: 25px 0;
            text-align: center;
        }

        &__confirm-button.btn {
            color: #fff;
            border: 2px solid $secondary;
            padding: 9px;
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 500;
            width: 200px;
            border-radius: 8px;

            &:hover {
              color: #fff;
            }
        }

        @media screen and (max-width: 767px) {
            &__answer {
                cursor: default;
            }
        }
    }
</style>
