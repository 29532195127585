import Vue from 'vue';
import MobileDetect from 'mobile-detect';

const SESSION_KEY = 'cd-session-id';
const BROKER_BASE = process.env.BROKER_BASE || process.env.VUE_APP_BROKER_BASE

const getSession = function (userId) {
  let sessionId = sessionStorage.getItem(SESSION_KEY);

  // check if a session io exists, otherwhise create new one
  if (sessionId) {
    return Promise.resolve(sessionId);
  }

  const md = new MobileDetect(window.navigator.userAgent);
  let device;

  if (md.mobile()) {
    device = 'mobile';
  } else if (md.tablet()) {
    device = 'tablet';
  } else {
    device = 'desktop';
  }

  let session = {
    domain: BROKER_BASE,
    userId: userId,
    browser: {
      userAgent: navigator.userAgent
    },
    device: {
      type: device,
      screen: {
        width: window.screen.width,
        height: window.screen.height
      }
    }
  };

  return Vue.http2
    .post('analytics/create-session', session)
    .then((response) => {
      if (response.data && response.data.sessionId) {
        sessionStorage.setItem(SESSION_KEY, response.data.sessionId);
      }

      return response.data.sessionId;
    });
};

export default {
  track(path, action) {
    let data = {
      path: path
    };
    let userSession = sessionStorage.getItem('cd-broker');
    userSession = userSession ? JSON.parse(userSession) : {};

    if (action) {
      data.actions = [ action ]
    }

    if (userSession.auth && userSession.auth.userId) {
      getSession(userSession.auth.userId)
        .then((sessionId) => {
          return Vue.http2.post(`analytics/${sessionId}`, data);
        })
        .catch((error) => {
          console.error('tracking error');
          console.error(error);
        })
    } else {
      console.error('cannot get userId from session')
    }
  }
};
