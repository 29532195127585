<template>
  <div>

    <div class="container">
      <div class="col-12 columns">

        <div class="boxed-questionaire">
          <div class="row">

            <div class="col-12 columns">
              <img :src="'/static/' + vector.image">
              <div class="question-space">
                <p>{{vector.description}}</p>
              </div>
            </div>

            <div class="col-12 columns form-item">

              <div class="mobile-app-toggle">
                <!--  -->
                <button class="button"
                        type="button"
                        v-on:click="selectAnswer(vector, true)"
                        :class="{active : vector.answer}">
                  Ja
                </button>
                <button type="button"
                        class="button"
                        v-on:click="selectAnswer(vector, false)"
                        :class="{active : vector.answer === false}">
                  Nein
                </button>

              </div>
              <div class="alert" v-if="alerted && vector.answer === null">Please answer this question.</div>
            </div>
          </div>
        </div>

        <div style="display:none">
          <!-- Load all images in mem -->
          <div v-for="vector in attackVectors">
            <img :src="'/static/' + vector.image">
          </div>
        </div>

      </div>
    </div>

    <div class="container">
      <div class="row" id="skip_questions">
        <div class="col-12">
          <router-link :to="{ name: 'Vectors' }" v-if="$route.name === 'Risikofragen'">Überspringen</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'
  import _ from 'lodash'
  import md5 from 'md5'

  export default {
    name: 'Risikofragen',
    metaInfo: {
      title: 'Risikofragen'
    },
    props: ['attackVectors'],
    data() {
      return {
        alerted: false,
        vector: {}
      }
    },
    mounted: function () {
      const parent = this
      const vectorId = this.$route.params.id

      this.vector = _.find(this.attackVectors, {id: vectorId.toString()}) || {}
      this.vector.answer = null

      eventBus.$on('nextPageHit', function () {
        parent.alerted = true

        var result = (parent.vector.answer !== null)
          ? parent.getNextRoute(vectorId)
          : false

        eventBus.$emit('nextPage', result)
      })
    },
    methods: {
      selectAnswer: function (vector, answer) {
        vector.answer = answer
        const nextRoute = this.getNextRoute(vector.id).route

        const sessCurrentVectors = this.$session.get('answeredVectors') || {}
        sessCurrentVectors[md5(vector.id + vector.description)] = {
          description: vector.description,
          answer: vector.answer
        }
        this.$session.set('answeredVectors', sessCurrentVectors)

        setTimeout(() => {
          return this.$router.push(nextRoute)
        }, 200)
      },
      getNextRoute: function (vectorId) {
        let route;

        vectorId = parseInt(vectorId) + 1;

        if (this.attackVectors.indexOf(this.vector) + 1 < this.attackVectors.length) {
          route = {
            name: this.$route.name,
            params: {
              id: vectorId.toString()
            }
          };
        } else {
          if (this.$route.name === 'Risikofragen2') {
            const questions = {};

            this.attackVectors.forEach((attackVector) => {
              questions[attackVector.slug] = attackVector.answer;
            });

            this.$store.dispatch('SET_RISK_QUESTIONS2', questions);
            route = {
              path: '/versicherung/1a/ihr-unternehmen/branche'
            }
          } else {
            route = {
              name: 'Vectors'
            }
          }
        }

        return {route};
      }
    },
    computed: {},
    destroyed: function () {
      eventBus.$off('nextPageHit')
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scss/variables';

  #main_content {
    margin-top: 20px;
  }

  div.alert {
    color: red;
    text-align: center;
  }

  .boxed-questionaire {
    margin-top: calc($standardGutter * 2);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;

    > div {
      padding: 1px;
      margin-bottom: calc($standardGutter * 2);
      height: 400px;

      img {
        min-height: 122px;
        height: 122px;
      }

      .question-space {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
      }

      p:not(.note) {
        margin-top: 20px;
        font-weight: 500;
        font-size: 2em;
        line-height: 1.5;
      }
    }
    .form-item {
      $mobile-app-toggle-radius: rem-calc(10);
      $mobile-app-toggle-color: $orangeTone;

      .mobile-app-toggle {
        margin: $standardGutter auto;
        display: flex;
        width: 400px;
        border: 1px solid $mobile-app-toggle-color;
        border-radius: $mobile-app-toggle-radius;
        overflow: hidden;
        margin-bottom: $standardGutter;
        border-radius: $borderRadius;

        .button {
          background: transparent;
          color: $mobile-app-toggle-color;
          margin-bottom: 0;
          flex: 1 0 0;
          font-size: 1.4em;
          border: 0;
          &:last-child {
            border-left: 1px solid $mobile-app-toggle-color;
          }

          &.active {
            background: $mobile-app-toggle-color;
            color: #fff;
          }
          &:focus {
            outline-width: 1px;
            outline-color: $blueTone;
          }
        }
      }
    }

    .form-item.alerted {
      // border: 1px solid $redTone;
      .mobile-app-toggle {
      }
    }
  }

  #skip_questions {
    text-align: center;
    a {
      color: $greyTone;
    }
    a:hover {
      color: darken($greyTone, 20%);
    }
  }

  @media screen and (max-width: $mediumOverwrite) {

    .boxed-questionaire {
      > div {
        margin-bottom: 0;

      }
    }
  }

</style>
