<template>
  <div class="create-account">
    <div v-if="loading" class="loading">
      <img src="../../assets/tail-spin.svg">
    </div>
    <div id="create_account_panel" class="panels">
      <form @submit.prevent.stop="submitForm()" name="create_account" class="is-danger">
        <div class="container">
          <div class="row main-title">
            <div class="col-12">
              <h1>Zugang Maklerportal mit Software zur Beratung und Vertrieb von Cyberversicherungen </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 left-panel d-none d-md-block">
              <div
                  style="background-image: url(https://s3.eu-central-1.amazonaws.com/images.cyberdirekt.de/create_account_makler_portal_663ac77cf5.jpg)">
                <div>
                  <h2>CyberDirekt ist Ihr Partner im Vertrieb von Cyberversicherungen</h2>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <fieldset>
                <div class="form-group">
                  <label for="salutation">Anrede *</label>
                  <select class="form-control"
                          name="salutation"
                          id="salutation"
                          v-model="user.salutation"
                          data-vv-as="Anrede"
                          v-validate="'required'">
                    <option selected disabled hidden value="">W&auml;hlen</option>
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                  </select>
                  <small v-show="errors.has('salutation')" class="error-note">{{ errors.first('salutation') }}</small>
                </div>
                <div class="form-group">
                  <label for="first_name">Vorname *</label>
                  <input class="form-control"
                         type="text"
                         id="first_name"
                         name="first_name"
                         v-model="user.firstname"
                         data-vv-as="Vorname"
                         v-validate="'max:20|required'">
                  <small v-show="errors.has('first_name')"
                         class="error-note">
                    {{ errors.first('first_name') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="last_name">Nachname *</label>
                  <input class="form-control"
                         type="text"
                         id="last_name"
                         name="last_name"
                         v-model="user.lastname"
                         data-vv-as="Nachname"
                         v-validate="'max:30|required'">
                  <small v-show="errors.has('last_name')"
                         class="error-note">
                    {{ errors.first('last_name') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="company">Unternehmen *</label>
                  <input class="form-control"
                         type="text"
                         id="company"
                         name="company"
                         v-model="user.company"
                         data-vv-as="Unternehmen"
                         v-validate="'max:40|required'">
                  <small v-show="errors.has('company')" class="error-note">{{ errors.first('company') }}</small>
                </div>
                <div class="form-group">
                  <label for="street">Stra&szlig;e *</label>
                  <input class="form-control"
                         type="text"
                         id="street"
                         name="street"
                         v-model="user.street"
                         v-validate="'required'"
                         data-vv-as="Stra&szlig;e">
                  <small v-show="errors.has('street')"
                         class="error-note">
                    {{ errors.first('street') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="housenumber">Hausnummer *</label>
                  <input class="form-control"
                         type="text"
                         id="housenumber"
                         name="housenumber"
                         v-model="user.housenumber"
                         data-vv-as="Hausnummer"
                         v-validate="{ required: true, regex: /^[a-z0-9\.\-]+$/i}">
                  <small v-show="errors.has('housenumber')"
                         class="error-note">
                    {{ errors.first('housenumber') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="zipcode">Postleitzahl *</label>
                  <input class="form-control"
                         type="tel"
                         id="zipcode"
                         name="zipcode"
                         v-model="user.zipcode"
                         data-vv-as="Postleitzahl"
                         v-validate="'required|digits:5'">
                  <small v-show="errors.has('zipcode')"
                         class="error-note">
                    {{ errors.first('zipcode') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="city">Stadt *</label>
                  <input class="form-control"
                         type="text"
                         id="city"
                         name="city"
                         v-model="user.city"
                         data-vv-as="Stadt"
                         v-validate="{ required: true, regex: /^[a-zöüäß\-\s]+$/i}">
                  <small v-show="errors.has('city')"
                         class="error-note">
                    {{ errors.first('city') }}
                  </small>
                </div>
                <TelephoneForm
                    :phoneNumber="user.mobile"
                    :emulateTouch="emulateTouch"
                    @numberChange="updatePhoneNumber"
                />
                <div class="form-group">
                  <label for="website">Website</label>
                  <input class="form-control"
                         type="text"
                         id="website"
                         v-model="user.website">
                </div>
                <div class="form-group">
                  <label for="brokerRegistrationId">Vermittler-Register-Nr. *</label>
                  <input class="form-control"
                         type="text"
                         id="brokerRegistrationId"
                         name="brokerRegistrationId"
                         v-model="user.brokerRegistrationId"
                         data-vv-as="Vermittler-Register-Nr."
                         v-validate="'required'">
                  <small v-show="errors.has('brokerRegistrationId')"
                         class="error-note">
                    {{ errors.first('brokerRegistrationId') }}
                  </small>
                </div>
                <div class="form-group" v-if="!hideUnusedInputs">
                  <label for="accountHolder">Kontoinhaber</label>
                  <input class="form-control"
                         type="text"
                         id="accountHolder"
                         name="accountHolder"
                         v-model="user.accountHolder"
                  >
                </div>
                <div class="form-group" v-if="!hideUnusedInputs">
                  <label for="iban">IBAN</label>
                  <input class="form-control"
                         type="text"
                         id="iban"
                         name="iban"
                         v-model="user.IBAN"
                  >
                </div>
                <div class="form-group" v-if="!hideUnusedInputs">
                  <label for="billingEmail">Rechnungs-E-Mail-Adresse</label>
                  <input class="form-control"
                         type="billingEmail"
                         id="billingEmail"
                         name="billingEmail"
                         v-model="user.billingEmail"
                  >
                </div>
                <div class="form-group" v-if="isBlauDirekt || isAllfinanz || isOvBoerse || isSDV || isEfb || isNetfonds || isNVS">
                  <label for="brokerExternalId" v-if="isBlauDirekt">blau direkt Untervermittler-Kennung *</label>
                  <label for="brokerExternalId" v-if="isAllfinanz">allfinanztest.de Vermittler-Kennung *</label>
                  <label for="brokerExternalId" v-if="isOvBoerse">Vermittler-Kennung der Ostfriesischen
                    Versicherungsb&ouml;rse *</label>
                  <label for="brokerExternalId" v-if="isSDV">SDV Vermittlerkennung *</label>
                  <label for="brokerExternalId" v-if="isEfb">Vermittler-Kennung der Eurofinanzberatung Rostock *</label>
                  <label for="brokerExternalId" v-if="isNetfonds">Netfonds Untervermittler-Kennung *</label>
                  <label for="brokerExternalId" v-if="isNVS">NVS Untervermittler-Kennung *</label>
                  <label for="brokerExternalId" v-if="isCharta">Charta Untervermittler-Kennung *</label>
                  <input class="form-control"
                         type="text"
                         id="brokerExternalId"
                         name="brokerExternalId"
                         v-model="user.brokerExternalId"
                         data-vv-as="Vermittlerkennung"
                         v-validate="'required'">
                  <small v-show="errors.has('brokerExternalId')"
                         class="error-note">
                    {{ errors.first('brokerExternalId') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="email">E-Mail *</label>
                  <input class="form-control"
                         type="email"
                         id="email"
                         name="email"
                         v-model="user.email"
                         data-vv-as="E-Mail"
                         v-validate="'required|email'">
                  <small v-show="errors.has('email')"
                         class="error-note">
                    {{ errors.first('email') }}
                  </small>
                </div>
                <div class="form-group password-fixed-height">
                  <label for="password">Passwort *</label>
                  <password defaultClass="form-control"
                            v-model="user.password"
                            id="password"
                            name="password"
                            placeholder=""
                            @score="scoreChanged"
                            :secureLength="6"
                            toggle
                            :badge="false"
                            data-vv-as="Passwort"
                            v-validate="'required'"/>
                  <div class="password-feedback" :class="passwordClass">
                    {{ passwordStrength }}
                  </div>
                  <small v-show="errors.has('password')"
                         class="error-note">
                    {{ errors.first('password') }}
                  </small>
                </div>
                <div class="form-group custom-control custom-checkbox" id="formAgreement">
                  <input class="custom-control-input"
                         type="checkbox"
                         id="agreedTermsConditions"
                         v-model="user.agreedTermsConditions"
                         required>
                  <label for="agreedTermsConditions"
                         class="custom-control-label">
                    Ich bin mit den <a href="https://www.cyberdirekt.de/datenschutzhinweise" target="_blank">Datenschutzhinweisen</a> einverstanden.</label>
                </div>
                <div v-if="isBlauDirekt || isCharta || isNetfonds || isNVS" class="form-group custom-control custom-checkbox" id="formAgreementAVV">
                  <input class="custom-control-input"
                         type="checkbox"
                         id="orderProcessingChecked"
                         v-model="user.orderProcessingChecked"
                         required>
                  <label for="orderProcessingChecked"
                         class="custom-control-label">
                    Ich bin mit der <a href="https://www.cyberdirekt.de/avv/" target="_blank">Auftragsdatenverarbeitung</a> einverstanden.</label>
                </div>
                <button class="btn btn-style primary"
                        :class="{ loading: isCreating }"
                >
                  <span>Anmelden</span>
                </button>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import Password from 'vue-password-strength-meter';
import TelephoneForm from '../../components/shared/TelephoneForm.vue';


export default {
  name: 'CreateAccount',
  metaInfo() {
    return {
    title: this.metaTitle
  }
  },
  components: {
    Password,
    TelephoneForm
  },
  data() {
    return {
      isCreating: false,
      loading: false,
      user: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        salutation: '',
        mobile:''
      },
      passwordScore: 0,
      passwordStrength: '',
      passwordClass: '',
      emulateTouch: false, // binding vee-validate
    }
  },
  computed: {
    isImpuls() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'impuls';
    },
    isBlauDirekt() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'blau-direkt';
    },
    isAllfinanz() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'allfinanztest';
    },
    isOvBoerse() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'ov-boerse';
    },
    isEfb() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'efb';
    },
    isSDV() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'sdv';
    },
    isNetfonds() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'netfonds';
    },
    isNVS() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'nvs-netfonds';
    },
    hideUnusedInputs() {
      return this.isNetfonds || this.isNVS || this.isBlauDirekt
    },
    isCharta() {
      return this.$route.query && this.$route.query.pool && this.$route.query.pool === 'charta';
    },
    metaTitle () {
      return this.isNetfonds ?
        'Registrierung für Makler der Netfonds AG' :
          this.isNVS ?
          'Registrierung für Makler der NVS Netfonds' :
            'Registrierung für Makler der OV-Börse'
    }
  },
  methods: {
    updatePhoneNumber (res) {
      this.user.mobile = res.number;
    },
    submitForm: function () {
      this.emulateTouch = true // simulate the telephone was edited
      this.$validator
          .validateAll()
          .then((result) => {
            if (result && this.passwordScore >= 2) {
              this.loading = true
              this.user.token = this.$route.params.tokenId;

              // TODO: need generic solution here
              if (this.isBlauDirekt || this.isAllfinanz || this.isEfb || this.isOvBoerse || this.isImpuls || this.isSDV || this.isNetfonds || this.isNVS || this.isCharta) {
                this.user.key = this.$route.query.pool;
              }

              if (this.isCharta && this.user.orderProcessingChecked) {
                this.user.orderProcessingCheckedOn = new Date()
              }

              return this.$store.dispatch('CREATE_BROKER', this.user)
                  .then(res => {
                    if (!res.status) {
                      return false
                    }

                    this.$toasted.show('Ihr Zugang wurde erstellt. Bitte loggen  Sie sich ein.', {
                      theme: 'bubble',
                      type:'success',
                      position: 'bottom-left',
                      duration: 4000
                    });
                    return this.$router.push('/login/authenticate')
                  })
                  .catch(err => {
                    console.log(err);
                    return false
                  });
            }
          });
    },
    scoreChanged(score) {
      this.passwordScore = score || 0;
      this.passwordStrength = [
        'Komplexität: sehr schwach',
        'Komplexität: schwach',
        'Komplexität: mittel',
        'Komplexität: stark',
        'Komplexität: sehr stark'
      ][score];
      this.passwordClass = [
        'red',
        'red',
        'yellow',
        'green',
        'green'
      ][score];
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../scss/variables';
@import '../../scss/mixins';
@import '../../scss/base';
@import '../../scss/form';

.main-title {
  padding: $standardGutter;
}

.left-panel {

  > div {
    height: 100%;;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius * 2;
    color: #fff;
    overflow: hidden;
    position: relative;
    // background-position: 30%;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      @include absoluteMe();
      content: ' ';
      background: rgba(32, 29, 90, 0.45);
    }

    > div {
      position: relative;

      h2 {
        text-align: center;
        font-size: 1.6rem;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  img {
    width: 100%;
  }
}

fieldset {
  text-align: left;

  input {
    margin-bottom: 5px;
  }
}

.custom-checkbox {
  label {
    font-weight: 500;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fefefe;
    background-color: #31a9e0 !important;
  }
}

#formAgreement {
  margin-top: $standardGutter;
}

.custom-control-label {
  font-size: 0.94rem;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.error-note {
  position: absolute;
  right: -2px;
  text-align: right;
  top: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;
}

.form-group {
  position: relative;
}

.password-fixed-height {
  min-height: 130px;
}
</style>
