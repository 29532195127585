var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'is-offer': _vm.isOffer, 'no-score': !_vm.isScoreVisible}},_vm._l((_vm.insuranceProperties.groups),function(group,groupIndex){return _c('table',{key:`acc-group-${groupIndex}`,staticClass:"group-table"},[_c('thead',[_c('tr',{staticClass:"table-heading-row"},[_c('th',[_c('div',{staticClass:"table-heading"},[_vm._v(" "+_vm._s(group.label)+" ")])])])]),_c('tbody',{staticClass:"prop-body"},_vm._l((group.subgroups),function(subgroup,subgroupIndex){return _c('table',{key:`acc-group-${groupIndex}__acc-subgroup-${subgroupIndex}`,staticClass:"subgroup-table"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"subgroup-label-col"},[_c('div',{staticClass:"subgroup-label"},[_c('span',{},[_vm._v(_vm._s(subgroup.label))])])]),(_vm.isScoreVisible)?_vm._l((_vm.insurers),function(insurer,insIndex){return _c('td',{key:insurer.key,staticClass:"precentage-col",class:[
                  `precentage-col--${_vm.insurers.length}`,
                  {'precentage-col--disabled': insurer.disabled},
                  _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer ? 'insurer--recommendation-declined' : ''
                ]},[_c('Precentage',{attrs:{"isHidden":_vm.scoringHidden(subgroup.key),"progress":_vm.insurersWithScores && _vm.insurersWithScores.length ? _vm.insurersWithScores[insIndex] && _vm.insurersWithScores[insIndex].groups[groupIndex].subgroups[subgroupIndex].precentageScore : 0,"isPrintMode":true}})],1)}):_vm._e()],2),_vm._l((subgroup.modules),function(module,modIndex){return [(!module.submodules)?_c('tr',{key:`acc-module-${modIndex}`,staticClass:"module-row"},[_c('td',{staticClass:"module-label-col"},[_c('div',{staticClass:"module-label"},[_vm._v(" "+_vm._s(module.label)+" ")])]),_vm._l((_vm.insurers),function(insurer){return _c('td',{key:insurer.key,staticClass:"property-value-col",class:[
                  {'property-value-col--disabled': insurer.disabled},
                  _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer ? 'insurer--recommendation-declined' : ''
                ]},[_c('div',{staticClass:"property-value"},[(_vm.isAvailable(insurer, module.key))?_c('svg',{attrs:{"width":"20","height":"17","viewBox":"0 0 20 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z","fill":"#51BC7E"}})]):(_vm.isOptional(insurer, module.key))?_c('div',{staticClass:"optional-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.selectedModules[insurer.key]),expression:"inputs.selectedModules[insurer.key]"}],staticClass:"optional",attrs:{"type":"checkbox","id":insurer.key + '-' + module.key},domProps:{"value":module.key,"checked":Array.isArray(_vm.inputs.selectedModules[insurer.key])?_vm._i(_vm.inputs.selectedModules[insurer.key],module.key)>-1:(_vm.inputs.selectedModules[insurer.key])},on:{"change":[function($event){var $$a=_vm.inputs.selectedModules[insurer.key],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=module.key,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputs.selectedModules, insurer.key, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputs.selectedModules, insurer.key, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputs.selectedModules, insurer.key, $$c)}},function($event){return _vm.optionalChanged(insurer.key, module.key)}]}}),_c('label',{attrs:{"for":insurer.key + '-' + module.key}},[_c('img',{staticClass:"print-opt-checked",attrs:{"width":"24","src":"https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg","alt":"opt-check"}})]),_c('div',{staticClass:"selected-module-title text-sm"},[(
                          _vm.inputs.selectedModules[insurer.key] && _vm.inputs.selectedModules[insurer.key].includes(
                            module.key
                          )
                        )?[_vm._v("abwählbar")]:[_vm._v("anwählbar")]],2)]):(insurer.properties[module.key] ? insurer.properties[module.key].state === 'info' : '')?void 0:_c('svg',{attrs:{"width":"16","height":"9","viewBox":"0 0 16 4","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16 4H0V0h16","fill":"#31A9E0"}})]),(
                      insurer &&
                      insurer.properties &&
                      insurer.properties[module.key] &&
                      insurer.properties[module.key].note &&
                      !_vm.compressedMode
                    )?_c('div',{staticClass:"note",domProps:{"innerHTML":_vm._s(_vm.getNote(insurer.properties[module.key]))}}):_vm._e()],2)])})],2):[_c('tr',{key:`acc-module-${modIndex}`,staticClass:"module-row acc-child__row acc-child__row-header"},[_c('th',{staticClass:"acc-child__header"},[_c('div',{staticClass:"module-label"},[_vm._v(" "+_vm._s(module.label)+" ")])])]),_vm._l((module.submodules),function(submodule,submodIndex){return _c('tr',{key:`acc-module-${modIndex}-submodule-${submodIndex}`,staticClass:"acc-child__row"},[_c('td',{staticClass:"module-label-col"},[_c('div',{staticClass:"module-label tb-cell"},[_vm._v(" "+_vm._s(submodule.label)+" ")])]),_vm._l((_vm.insurers),function(insurer){return _c('td',{key:insurer.key,staticClass:"property-value-col",class:[
                    {'property-value-col--disabled': insurer.disabled},
                    _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer ? 'insurer--recommendation-declined' : ''
                  ]},[_c('div',{staticClass:"property-value tb-cell"},[(_vm.isAvailable(insurer, submodule.key))?_c('svg',{attrs:{"width":"20","height":"17","viewBox":"0 0 20 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z","fill":"#51BC7E"}})]):(_vm.isOptional(insurer, submodule.key))?_c('div',{staticClass:"optional-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.selectedModules[insurer.key]),expression:"inputs.selectedModules[insurer.key]"}],staticClass:"optional",attrs:{"type":"checkbox","id":insurer.key + '-' + submodule.key},domProps:{"value":submodule.key,"checked":Array.isArray(_vm.inputs.selectedModules[insurer.key])?_vm._i(_vm.inputs.selectedModules[insurer.key],submodule.key)>-1:(_vm.inputs.selectedModules[insurer.key])},on:{"change":[function($event){var $$a=_vm.inputs.selectedModules[insurer.key],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=submodule.key,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputs.selectedModules, insurer.key, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputs.selectedModules, insurer.key, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputs.selectedModules, insurer.key, $$c)}},function($event){return _vm.optionalChanged(insurer.key, submodule.key)}]}}),_c('label',{attrs:{"for":insurer.key + '-' + submodule.key}},[_c('img',{staticClass:"print-opt-checked",attrs:{"width":"24","src":"https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg","alt":"opt-check"}})]),_c('div',{staticClass:"d-md-block text-sm"},[(
                            _vm.inputs.selectedModules[insurer.key] && _vm.inputs.selectedModules[insurer.key].includes(
                              submodule.key
                            )
                          )?[_vm._v("abwählbar")]:[_vm._v("anwählbar")]],2)]):_c('svg',{attrs:{"width":"16","height":"9","viewBox":"0 0 16 4","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16 4H0V0h16","fill":"#31A9E0"}})]),(
                        insurer &&
                        insurer.properties &&
                        insurer.properties[submodule.key] &&
                        insurer.properties[submodule.key].note &&
                        !_vm.compressedMode
                      )?_c('div',{staticClass:"note",domProps:{"innerHTML":_vm._s(_vm.getNote(insurer.properties[submodule.key]))}}):_vm._e()])])})],2)})]]})],2)])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }