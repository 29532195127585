var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"insurers",class:{'insurers-space': !_vm.isOffer}},_vm._l((_vm.innerInsurers),function(insurer,iIndex){return _c('div',{key:('insurer' + insurer.key),staticClass:"company insurer",class:[
        _vm.isOffer ? 'company--offer' : '',
        {'insurer--disabled': insurer.disabled, 'insurer--recommended': insurer.key === _vm.recommendedInsurer},
        `insurer--${_vm.insurers.length}`,
        _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer ? 'insurer--recommendation-declined' : '',
        _vm.isOnlyImages ? 'insurer__smaller-scale company--only-images' : ''
      ]},[(!_vm.isOnlyImages)?[(_vm.synaxon && _vm.synaxon.tooltip)?_c('div',{staticClass:"insurer__top-badge d-print-none"},[_c('div',{staticClass:"insurer__banner insurer__banner--synaxon",attrs:{"id":'synaxon-tooltip' + iIndex}},[_vm._v(" "+_vm._s(_vm.synaxon.tooltip.trigger)+" ")]),_c('b-tooltip',{attrs:{"target":'synaxon-tooltip' + iIndex,"triggers":"hover","custom-class":"custom-tooltip"}},[_c('div',{staticClass:"mb-3"},[_vm._v(" Der im Versicherungsschein vereinbarte Selbstbehalt reduziert sich je Schadenfall um 50%, minimal jedoch auf € 500 unter den nachfolgenden Voraussetzungen im Rahmen der Zusammenarbeit mit der einsnulleins GmbH und der IT-Partner der SYNAXON AG. Durchführung eines Desktop-Managements und Server-Managements mit den folgenden Inhalten: ")]),_c('ul',[_c('li',[_vm._v(" Tägliche Schwachstellen-Analyse betriebskritischer Parameter ")]),_c('li',[_vm._v("Monatlicher Bericht über den Systemstatus")]),_c('li',[_vm._v("Fernwartungssoftware")]),_c('li',[_vm._v(" Fortlaufende Überprüfung der DatensicherungInventarisierung eingesetzter Hard- & Software ")]),_c('li',[_vm._v("Überprüfung der Aktualität der Virensignaturen")]),_c('li',[_vm._v("Installation sicherheitsrelevanter Patches")]),_c('li',[_vm._v("Aktive Alarmierung bei Sicherheitsbedrohungen")]),_c('li',[_vm._v("Bereinigung temporärer Dateien")]),_c('li',[_vm._v("Virenschutz")]),_c('li',[_vm._v("Telefonischem IT-Support Mo-Fr 8:00 - 18:00 Uhr")]),_c('li',[_vm._v("Kostenlosem Remote-Support bei Störungen")]),_c('li',[_vm._v(" Leistungsüberwachung der Server Überprüfung der Windows-Dienste. ")])])])],1):_vm._e(),(_vm.isBroker && _vm.mode !== 'offer')?_c('b-form-checkbox',{staticClass:"cd-control checkbox-accent checkbox--center d-print-none",attrs:{"id":`offerCheck-${insurer.key}`,"name":`offerCheck-${insurer.key}`,"value":insurer.key,"disabled":(_vm.offerSelectionInner.length >= _vm.maxOffers &&
            _vm.offerSelectionInner.indexOf(insurer.key) === -1) ||
            insurer.disabled},model:{value:(_vm.offerSelectionInner),callback:function ($$v) {_vm.offerSelectionInner=$$v},expression:"offerSelectionInner"}}):_vm._e(),_c('div',{staticClass:"insurer__image",class:[
            'insurer__image--' + _vm.getInsurerKey(insurer.key),
            {'insurer__image--has-banner': _vm.hasBanner(insurer)}
          ]},[(_vm.shouldShowArtusBanner(insurer))?_c('div',{staticClass:"insurer__banner insurer__banner--artus d-print-none"},[_vm._v(" ARTUS Sonderbedingungen ")]):_vm._e(),_c('img',{attrs:{"src":_vm.getInsurerLogoPath(insurer),"alt":insurer.label}}),(_vm.isOffer && _vm.hasBanner(insurer))?_c('div',{staticClass:"insurer__badge-box"},_vm._l((_vm.getBanner(insurer)),function(bnr){return _c('b-badge',{key:bnr.text,staticClass:"custom-badge offer-badge insurer__badge",class:[
                _vm.getBannerClass(insurer),
                _vm.getLabelStyleMixin(bnr.style),
                _vm.getLabelColorMixin(bnr),
                _vm.isRecommendationDeclinedMixin(insurer.key) && _vm.isOffer && bnr.text.includes('Indikation') ? 'insurer__banner--hide' : ''
              ],on:{"click":function($event){return _vm.setModalBody(bnr.long, bnr.text === 'Sideletter*')}}},[_vm._v(" "+_vm._s(bnr.text)+" ")])}),1):_vm._e()]),((!_vm.isOffer && _vm.hasBanner(insurer)) || _vm.checkoutButtonIsVisible)?_c('div',{staticClass:"insurer__bottom d-print-none"},[(!_vm.isOffer && _vm.hasBanner(insurer))?_c('div',{staticClass:"insurer__badge-box 2"},_vm._l((_vm.getBanner(insurer)),function(bnr){return _c('b-badge',{key:bnr.text,staticClass:"custom-badge insurer__badge",class:[
                _vm.getBannerClass(insurer),
                _vm.getLabelStyleMixin(bnr.style),
                _vm.getLabelColorMixin(bnr)
              ],on:{"click":function($event){return _vm.setModalBody(bnr.long, bnr.text === 'Sideletter*')}}},[_vm._v(" "+_vm._s(bnr.text)+" ")])}),1):_vm._e(),_c('div',{staticClass:"action d-print-none"},[(!_vm.tariffHasScanMixin(insurer.key))?_c('ButtonComponent',{staticClass:"icon-link",attrs:{"btn-style":"link-primary","icon":_vm.checkoutButtonIsVisible
                  ? {
                      name: 'arrow-full-right',
                      position: 'right'
                    }
                  : null,"behavior":'button',"disabled":insurer.disabled,"type":_vm.isAffiliate ? 'button': 'submit'},nativeOn:{"click":function($event){return _vm.selectInsurer(insurer)}}},[(_vm.checkoutButtonIsVisible)?[_c('span',{staticClass:"d-none d-lg-inline"},[_vm._v(_vm._s(_vm.order1)+" ")]),_c('span',[_vm._v(_vm._s(_vm.order2))])]:_vm._e()],2):_vm._e()],1)]):(!_vm.isOffer)?_c('div',{staticClass:"insurer__bottom insurer__bottom--empty"}):_vm._e()]:[_c('div',{staticClass:"insurer__image insurer__image--smaller",class:[
            'insurer__image--' + _vm.getInsurerKey(insurer.key),
            {'insurer__image--has-banner': _vm.hasBanner(insurer)}
          ]},[_c('img',{attrs:{"src":_vm.getInsurerLogoPath(insurer),"alt":insurer.label}})])]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }