import { render, staticRenderFns } from "./Beginnen.vue?vue&type=template&id=39ce6170&scoped=true"
import script from "./Beginnen.vue?vue&type=script&lang=js"
export * from "./Beginnen.vue?vue&type=script&lang=js"
import style0 from "./Beginnen.vue?vue&type=style&index=0&id=39ce6170&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ce6170",
  null
  
)

export default component.exports