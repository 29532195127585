import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate'
import isValidIBANNumber from './ibanFunctions.js'

Vue.use(VeeValidate, {
    classes: true
})

Validator.extend('iban', {
    getMessage: field => 'IBAN ist nicht korrekt',
    validate: (value) => {
        return isValidIBANNumber(value)
    }
})
