<template>
  <div>
    <footer v-if="!isLoginRequired()" class="py-4 d-print-none">
      <div class="container">
        <ul class="row">
          <li><a href="https://www.cyberdirekt.de/about-us" target="_blank">&Uuml;ber uns</a></li>
          <!-- <li><a href="https://www.cyberdirekt.de/faq">FAQ</a></li> -->
          <li><a href="https://www.cyberdirekt.de/kontakt" target="_blank">Kontakt</a></li>
          <li><a href="" @click.stop.prevent="showTermsModal()">AGB</a></li>
          <li v-if="userRole === 'agencyOwner'"><a href="" @click.stop.prevent="toggleAvvModal()">AVV</a></li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'

  export default {
    name: 'Bfooter',
    components: {
    },
    computed: {
      userRole () {
        return this.$store.state.user.role
      }
    },
    data() {
      return {
        termOpen: false
      }
    },
    methods: {
      toggleAvvModal() {
        eventBus.$emit('toggleAvv', null)
      },
      showTermsModal() {
        eventBus.$emit('showTermsModal')
      },
      isLoginRequired() {
        const noLoginRoutes = [
          'Authenticate',
          'Reset',
          'Forgot',
          'Single Sign On',
          'Login'
        ];

        return noLoginRoutes.some(el => el === this.$route.name)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../scss/variables";

  footer {
    width: 100%;
    background: #14192f;
    // position: fixed;
    // bottom: 0;
    z-index: 2;
    color: $greyTone;
    a {
      color: #fff;
      font-weight: 600;
    }
    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      margin-bottom: 0.5rem;
      li {
        margin-right: 18px;
        a {
          color: #fff;
          font-size: 1.2rem;
          font-weight: 600;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

</style>
