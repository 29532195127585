<template>
  <div class="progress-circle">
    <template v-if="this.progress !== 'winner'">
      <svg :height="radius * 2" :width="radius * 2">
        <circle
          stroke="#dfe7ea"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: bgStrokeDashoffset }"
          stroke-width="10"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          class="progress__active"
          :stroke="progressColor"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset: strokeDashoffset }"
          :stroke-width="stroke"
          fill="transparent"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          v-if="standardScore > -1"
          class="progress__active circle_1"
          :stroke="progressColorBrighter"
          :stroke-dasharray="innerCircumference + ' ' + innerCircumference"
          :style="{ strokeDashoffset: innerStrokeDashoffset }"
          :stroke-width="innerStroke/2"
          fill="transparent"
          :r="normalizedInnerRadius"
          :cx="radius"
          :cy="radius"
        />
        <circle
          v-if="standardScore > -1"
          class="progress__active circle_2"
          :stroke="progressColorBrighter"
          :stroke-dasharray="innerCircumference + ' ' + innerCircumference"
          :style="{ strokeDashoffset: innerStrokeDashoffset }"
          :stroke-width="innerStroke/2"
          fill="transparent"
          :r="normalizedInnerRadius"
          :cx="radius"
          :cy="radius"
        />
      </svg>
      <label class="label"> {{ progressValue }}% </label>
    </template>
    <template v-else>
      <img class="win-icon" src="../../../assets/img/tr2-winner-entire.png" alt="100%">
    </template>
  </div>
</template>

<script>
export default {
  props: {
    radius: Number,
    progress: [Number, String],
    stroke: Number,
    strokeColor: String,
    standardScore: {
      type: Number,
      default: 0,
      required: false
    },
    isPrintMode: {
      type: Boolean,
      default () {return false}
    }
  },

  data() {
    return {
      innerStroke: 8,
      innerRadius: 43,
      progressValue: this.isPrintMode ? Math.round(this.progress) : 0,
      progressValueStandardScore: this.isPrintMode ? Math.round(this.standardScore) : 0,
      progressBreakpoints: [0, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
      progressColors: ['#FFFF64', '#FFF879', '#FFFE88', '#F5FD96', '#EBFAA4', '#E1F6B0', '#D6F3B8', '#CDEFBE', '#C4ECC2', '#ABE8B8', '#89E0AC', '#5ACD9C'],
      progressColorsBrighter: ['#ffff00', '#f0e625', '#e2e135', '#d0dc42', '#bfd74c', '#abd255', '#96cc5b', '#7fc661', '#69c166', '#50bb69', '#38b76d', '#11b36e'],
      // progressC: '#ffff00'
    }
  },

  computed: {
    normalizedInnerRadius() {
      return this.innerRadius - this.innerStroke * 2
    },
    innerCircumference() {
      return this.normalizedInnerRadius * 2 * Math.PI
    },
    innerStrokeDashoffset() {
      return (
        this.innerCircumference - (this.progressValueStandardScore / 100) * this.innerCircumference
      )
    },
    normalizedRadius() {
      return this.radius - this.stroke * 2
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI
    },
    strokeDashoffset() {
      return (
        this.circumference - (this.progressValue / 100) * this.circumference
      )
    },
    bgStrokeDashoffset() {
      return this.circumference - (100 / 100) * this.circumference
    },
    progressColor() {
      return this.getProgressColor(this.progressValue, true)
    },
    progressColorBrighter() {
      return this.getProgressColor(this.progressValueStandardScore, false)
    },
  },

  watch: {
    progress (newVal) {
      this.progressValue = 0
      if (newVal) {
        if (this.isPrintMode) {
          this.progressValue = Math.round(newVal)
        } else {
          this.simulateProgress()
        }
      }
    }
  },

  mounted() {
    this.progress && !this.isPrintMode ? this.simulateProgress() : ''
  },

  methods: {
    simulateProgress () {
      const updateProgress = () => {
        let cycle1Finished = false;
        let cycle2Finished = false;

        if ((this.progressValue + 1 === 100) || this.progressValue === Math.round(this.progress)) {
          cycle1Finished = true;
        } else {
          this.progressValue += 1;
        }

        // update standartScoreProgress if it exists
        if (this.standardScore > 0) {
          if ((this.progressValueStandardScore + 1 === 100) || this.progressValueStandardScore === Math.round(this.standardScore)) {
            cycle2Finished = true;
          } else {
            this.progressValueStandardScore += 1;
          }
        }
        if (cycle1Finished && cycle2Finished) {
          return;
        }
        requestAnimationFrame(updateProgress);
      }
      requestAnimationFrame(updateProgress);
    },
    // tbd: refactor
    getProgressColor(progressValue, brighter = false) {
      let color = ''
      this.progressBreakpoints.forEach((brPoint, i) => {
        if (progressValue >= brPoint && (progressValue < this.progressBreakpoints[i + 1]) || progressValue === 100) {
          color = brighter ? this.progressColorsBrighter[i] : this.progressColors[i]
        }
      })
      return color
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle_1 {
  transform: scale(1.15) rotate(-90deg);
}

.circle_2 {
  transform: scale(1.4) rotate(-90deg);
}

.progress-circle {
  position: relative;
  display: inline-flex;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  margin-bottom: 0;
}

.win-icon {
  width: 100px;
}

</style>
