<template>
  <div class="print-questions__wrapper">
    <div
      class="print-questions__main-category"
      v-for="(main, mainIndex) in categories"
      :key="mainIndex"
    >
      <div :class="{'page-break' : mainIndex < (categories.length - 1)}">
        <div
          class="print-questions__category-label print-questions__category-label--main"
        >
          {{ main.label }}
        </div>
        <div
          class="print-questions__sub-category"
          v-for="(subcategory, subCIndex) in questionsByCategories"
          :class="[subcategory.props.category_key === main.key ? '' : 'd-print-none']"
          :key="subCIndex"
        >
          <template v-if="subcategory.props.category_key === main.key">
            <div
              class="print-questions__category-label print-questions__category-label--sub"
            >
              {{ subcategory.props.label }}
            </div>
            <div
              class="print-questions__insurer-wrap"
              v-for="(insurer, iidx) in Object.keys(subcategory).filter(
                (i) => i !== 'props'
              )"
              :key="iidx"
            >
              <div
                class="print-questions__insurer"
                :class="{ 'print-questions__insurer--odd': iidx % 2 !== 0 }"
              >
                <img
                  :src="getInsurerLogoPath(insurer)"
                  :alt="insurer.label"
                  class="print-questions__insurer-logo"
                />
              </div>
              <div class="print-questions__questions-wrap">
                <div
                  class="print-questions__questions"
                  :class="{ 'print-questions__questions--odd': iidx % 2 !== 0 }"
                  v-for="(q, qidx) in subcategory[insurer]"
                  :key="qidx"
                >
                  <QuestionText
                    :question="q"
                    :isForceOpen="true"
                    :hasHaghlighting="true"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import QuestionText from "./QuestionText.vue";
import ConditionCheck from "../../../../ConditionCheck.js";

export default {
  name: "questionCategories",
  props: {
    mode: {
      type: String,
      default() {
        return this.$store.state.checkout.application.mode;
      },
    },
    insurers: {
      type: Array,
      default() {
        return [];
      },
    },
    inputsData: {
      type: Object,
      default() {
        return {};
      },
    },
    isOffer: {
      type: Boolean,
      default() {
        return false;
      },
    },
    affiliateBroker: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    offerModules: {
      type: Object || null,
      required: false,
      default() {
        return null
      },
    },
  },
  components: {
    QuestionText,
  },
  data() {
    return {
      lenghtLimt: 30,
    };
  },
  // mounted() {
  //   // this.$store.dispatch("checkout/getQuestionCategories");
  //   console.log('t.offermoduels', this.offerModules)
  // },
  computed: {
    questionsByCategories() {
      const res = {};
      // all subcategories
      this.subcategories.forEach((sub) => {
        // all questions
        const qs = this.$store.state.checkout.application.questions || {};
        // insurers
        Object.keys(qs).forEach((insurer) => {
          //if insurer isnt in the selection, let go
          if (
            this.insurers.find((i) => i.insurerKey === insurer) === undefined
          ) {
            return;
          }

          const modifiedInputs = { ...this.inputsData }
          if (!modifiedInputs.selectedModules && this.offerModules) {
            modifiedInputs.selectedModules = { ...this.offerModules }
          }

          modifiedInputs.insurer = insurer;

          const filterQs = qs[insurer].filter((q) => q.categoryKey === sub.key);
          const qsConditionFiltered = filterQs.filter((q) => {
            return ConditionCheck.check(modifiedInputs, q.conditions, null, null, { passFollowUps: true })
          });

          if (qsConditionFiltered.length) {
            if (!res[sub.key]) {
              res[sub.key] = {
                [insurer]: qsConditionFiltered,
                props: sub, // add the data for the subcategory
              };
            } else {
              res[sub.key][insurer] = qsConditionFiltered;
            }
          }
        });
      });
      return res;
    },
    categoriesData() {
      return this.$store.state.checkout.application.questionCategories || [];
    },
    categories() {
      return this.categoriesData.categories || [];
    },
    subcategories() {
      const s = this.categoriesData.subcategories || [];
      return s.sort((a, b) => a.position - b.position);
    },
    subcategoriesByParent() {
      const s = this.categoriesData.subcategories || [];

      const filtered = s.reduce((acc, subcategory) => {
        if (!acc[subcategory.category_key]) {
          acc[subcategory.category_key] = [];
        }
        acc[subcategory.category_key].push(subcategory);
        return acc;
      }, {});

      // sort res
      Object.keys(filtered).forEach((key) => {
        filtered[key] = filtered[key].sort((a, b) => a.position - b.position);
      });
      return filtered;
    },
  },
  methods: {
    getInsurerLogoPath(tariff) {
      const insurer = this.insurers.find((i) => i.insurerKey === tariff);
      return insurer?.logoUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import 'QuestionText' from './QuestionText.vue';
@import "../../../../scss/variables";
@import "../../../../scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// importing shared styles with propertyGrid
@import "../sharedAccordionStyles.scss";

// define a var
$light-gray-1: rgb(231, 231, 231);
$darkblue-1: #417e9b;
$insurersBlue1: rgba(230, 242, 249, 1);
$insurersBlue2: lighten(rgba(230, 242, 249, 1), 2%);

$max-col-w: 40%;
$gap: 0.7rem;
$radius: 0.25rem;

@media print {
  .print-questions {
    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      gap: $gap;
    }

    &__main-category {
      display: flex;
      flex-direction: column;
      gap: $gap;
      margin-bottom: $gap;
    }

    &__sub-category {
      display: flex;
      flex-direction: column;
      gap: $gap;
      page-break-inside: avoid;
      margin-bottom: $gap;

    }

    &__category-label {
      padding: 0.5rem 10px;
      border-radius: $radius;

      &--main {
        background-color: $darkblue-1;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: $gap;
      }

      &--sub {
        background-color: $light-gray-1;
        font-weight: bold;
      }
    }

    &__insurer-wrap {
      display: flex;
      gap: $gap;
      width: 100%;
    }

    &__insurer {
      width: 350px;
      max-width: 350px;
      min-width: 350px;
      background-color: $insurersBlue1;
      border-radius: $radius;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 1rem;
      padding-top: 1rem;

      &--odd {
        background-color: $insurersBlue2;
      }
    }

    &__insurer-logo {
      // width: 355px;
      max-width: 100px;
      max-height: 45px;
    }

    &__questions-wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: $gap;
    }

    &__questions {
      background-color: $insurersBlue1;
      flex: 1;
      flex-direction: column;
      border-radius: $radius;
      padding: 1rem;

      &--odd {
        background-color: $insurersBlue2;
      }
    }
  }
  .page-break {
    page-break-after: always;
  }
  
}
</style>
