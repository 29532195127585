<template>
  <div class="container view-settings">
    <div class="row">
      <div class="col-12">
        <h1>Profil</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-tabs>
          <b-tab title="Profil" active>
            <div class="tab-content">
              <form data-vv-scope="form-profile"
                    @submit.prevent.stop="validateForm(forms.PROFILE_FORM)">
                <div class="form-group">
                  <label for="salutation">
                    Anrede *
                  </label>
                  <select class="form-control"
                          id="salutation"
                          name="salutation"
                          data-vv-as="Anrede"
                          v-validate="'required'"
                          v-model="user.salutation">
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                  </select>
                  <small v-show="errors.has('form-profile.salutation')" class="error-note">{{ errors.first('form-profile.salutation') }}</small>
                </div>
                <div class="form-group">
                  <label for="firstname">
                    Vorname *
                  </label>
                  <input type="text"
                         class="form-control"
                         id="firstname"
                         name="firstname"
                         data-vv-as="Vorname"
                         v-validate="'max:20|required'"
                         key="firstname"
                         v-model="user.firstname">
                  <small v-show="errors.has('form-profile.firstname')" class="error-note">
                    {{ errors.first('form-profile.firstname') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="lastname">
                    Nachname *
                  </label>
                  <input type="text"
                         class="form-control"
                         id="lastname"
                         name="lastname"
                         data-vv-as="Nachname"
                         v-validate="'max:30|required'"
                         v-model="user.lastname">
                  <small v-show="errors.has('form-profile.lastname')"
                         class="error-note">
                    {{ errors.first('form-profile.lastname') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="email">
                    E-Mail *
                  </label>
                  <input type="email"
                         class="form-control"
                         id="email"
                         name="email"
                         data-vv-as="E-Mail"
                         v-validate="'required|email'"
                         v-model="user.email">
                  <small v-show="errors.has('form-profile.email')"
                         class="error-note">
                    {{ errors.first('form-profile.email') }}
                  </small>
                </div>
                <TelephoneForm 
                  formId='userPhoneNumber'
                  :phoneNumber='user.mobile'
                  :emulateTouch='emulateTouch.userPhone'
                  @numberChange="updatePhoneNumber"
                />
                <div class="form-group">
                  <label for="brokerRegistrationId">
                    Registernummer *
                  </label>
                  <input type="text"
                         class="form-control"
                         id="brokerRegistrationId"
                         name="brokerRegistrationId"
                         data-vv-as="Vermittlerkennung"
                         v-validate="{ required: isRegistrationRequired }"
                         v-model="user.brokerRegistrationId">
                  <small v-show="errors.has('form-profile.brokerRegistrationId')"
                         class="error-note">
                    {{ errors.first('form-profile.brokerRegistrationId') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="gutBeratenId">
                    gut beraten-ID
                  </label>
                  <input type="text"
                         class="form-control"
                         id="gutBeratenId"
                         name="gutBeratenId"
                         data-vv-as="gut beraten-ID"
                         v-validate="{ required: false, regex: /(^(|\d{8}-\d{6}-\d{2})$)/ }"
                         v-model="user.gutBeratenId">
                  <small v-show="errors.has('form-profile.gutBeratenId')"
                         class="error-note">
                    {{ errors.first('form-profile.gutBeratenId') }}
                  </small>
                </div>

                <div class="form-check mb-4">
                  <label class="form-check-label">
                    <input type="checkbox"
                           name="hideOffers"
                           v-model="user.hideOffers"
                           class="form-check-input">
                    alle Angebote ausblenden
                  </label>
                </div>


                <button class="btn btn-secondary mt-3" :disabled="errors.items.length > 0">
                  Speichern
                </button>
              </form>
            </div>
          </b-tab>
          <b-tab title="Unternehmen" v-if="agency && agency.company">
            <div class="tab-content">
              <form
                    data-vv-scope="form-company"
                    @submit.prevent="validateForm(forms.COMPANY_FORM)">
                <div class="form-group">
                  <label for="company">
                    Firma
                  </label>
                  <input type="text"
                         class="form-control"
                         id="company"
                         name="company"
                         data-vv-as="Unternehmen"
                         v-validate="'max:40|required'"
                         v-model="agency.company">
                  <small v-show="errors.has('form-company.company')" class="error-note">{{ errors.first('form-company.company') }}</small>
                </div>
                <div class="form-group">
                  <label for="street">
                    Stra&szlig;e
                  </label>
                  <input type="text"
                         class="form-control"
                         id="street"
                         name="street"
                         v-validate="'required'"
                         data-vv-as="Straße"
                         v-model="agency.street">
                  <small v-show="errors.has('form-company.street')"
                         class="error-note">
                    {{ errors.first('form-company.street') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="housenumber">
                    Hausnummer
                  </label>
                  <input type="text"
                         class="form-control"
                         id="housenumber"
                         name="housenumber"
                         data-vv-as="Hausnummer"
                         v-validate="{ required: true, regex: /^[a-z0-9\.\-]+$/i}"
                         v-model="agency.housenumber">
                  <small v-show="errors.has('form-company.housenumber')"
                         class="error-note">
                    {{ errors.first('form-company.housenumber') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="zipcode">
                    PLZ
                  </label>
                  <input type="text"
                         class="form-control"
                         id="zipcode"
                         name="zipcode"
                         data-vv-as="Postleitzahl"
                         v-validate="postalValidation()"
                         v-model="agency.zipcode"
                        @beforeinput="preventInputHandler($event, {numeric: true})">
                  <small v-show="errors.has('form-company.zipcode')"
                         class="error-note">
                    {{ errors.first('form-company.zipcode') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="city">
                    Stadt
                  </label>
                  <input type="text"
                         class="form-control"
                         id="city"
                         name="city"
                         data-vv-as="Stadt"
                         v-validate="{ required: true, regex: /^[a-z\-\s]+$/i}"
                         v-model="agency.city">
                  <small v-show="errors.has('form-company.city')"
                         class="error-note">
                    {{ errors.first('form-company.city') }}
                  </small>
                </div>
                <TelephoneForm 
                  formId="agencyPhoneNumber"
                  :phoneNumber='agency.mobile'
                  :emulateTouch='emulateTouch.agencyPhone'
                  @numberChange="updatePhoneNumber"
                />
                <div class="form-group">
                  <label for="company">
                    Webseite
                  </label>
                  <input type="text"
                         class="form-control"
                         id="website"
                         name="website"
                         v-validate
                         data-vv-rules=""
                         v-model="agency.website">
                </div>
                <button class="btn btn-secondary mt-3">
                  Speichern
                </button>
              </form>
            </div>
          </b-tab>

          <b-tab title="2-Faktor-Authentifizierung">
            <div class="tab-content">
              <form novalidate
                    data-vv-scope="form-twoFactor"
                    @submit.prevent="validateForm(forms.TWO_FACTOR_FORM)">
                <div class="form-check mb-4">
                  <label class="form-check-label">
                    <input type="checkbox"
                           name="twoFactorSMS"
                           v-model="user.twoFactorSMS"
                           class="form-check-input">
                    2-Faktor-Authentifizierung (SMS) aktivieren
                  </label>
                </div>
                 <TelephoneForm 
                  v-if="user.twoFactorSMS"
                  formId="userPhoneNumber"
                  :phoneNumber='user.mobile'
                  :emulateTouch='emulateTouch.userPhone'
                  @numberChange="updatePhoneNumber"
                />
                <div class="form-group"
                     v-if="showTokenInput">
                  <label>
                    SMS Bestätigungs-Code
                    <input type="tel"
                           class="form-control"
                           placeholder=""
                           id="tokenInput"
                           name="verificationToken"
                           v-model="verificationTokenInput">
                    <small class="form-text text-muted">
                      Wir haben Ihnen eine SMS mit einem Bestätigungs-Code zugesandt. Bitte geben
                      Sie diesen hier ein.
                    </small>
                  </label>
                </div>
                <button class="btn btn-secondary mt-3">
                  Speichern
                </button>
              </form>
            </div>
          </b-tab>

          <b-tab title="Passwort ändern">
            <div class="tab-content">
              <form novalidate
                    data-vv-scope="form-change-password"
                    @submit.prevent="validateForm(forms.CHANGE_PASSWORD_FORM)">
                <div class="form-group">
                  <label for="oldPassword">Altes Passwort</label>
                  <input type="password"
                         name="oldPassword"
                         id="oldPassword"
                         v-model="pwdModel.oldPassword"
                         data-vv-as="Passwort"
                         v-validate="'required'"
                         class="form-control">
                  <small v-show="errors.has('form-change-password.oldPassword')"
                         class="error-note">
                    {{ errors.first('form-change-password.oldPassword') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="newPassword">Neues Passwort</label>
                  <password name="newPassword"
                            defaultClass="form-control"
                            v-model="pwdModel.newPassword"
                            id="newPassword"
                            placeholder=""
                            @score="scoreChanged"
                            :secureLength="6"
                            toggle
                            :badge="false"
                            data-vv-as="Passwort"
                            v-validate="'required'"/>
                  <div class="password-feedback" :class="passwordClass">
                    {{ passwordStrength }}
                  </div>
                  <small v-show="errors.has('form-change-password.password')"
                         class="error-note">
                    {{ errors.first('form-change-password.password') }}
                  </small>
                </div>
                <button class="btn btn-secondary mt-3">
                  Speichern
                </button>
              </form>
            </div>
          </b-tab>
          <b-tab title="Bank Details">
            <div class="tab-content">
              <form novalidate
                    data-vv-scope="form-bank-details"
                    @submit.prevent="validateForm(forms.BANK_DETAILS_FORM)">
                <div class="form-group">
                  <label for="accountHolder">Kontoinhaber</label>
                  <input type="text"
                         name="accountHolder"
                         id="accountHolder"
                         v-model="user.accountHolder"
                         v-validate=""
                         data-vv-as="Kontoinhaber"
                         class="form-control"/>
                  <small v-show="errors.has('form-bank-details.accountHolder')"
                         class="error-note">
                    {{ errors.first('form-bank-details.accountHolder') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="billingEmail">Rechnungs-E-Mail-Adresse</label>
                  <input type="text"
                         name="billingEmail"
                         id="billingEmail"
                         v-model="user.billingEmail"
                         v-validate="'email'"
                         data-vv-as="Rechnungs-E-Mail-Adresse"
                         class="form-control"/>
                  <small v-show="errors.has('form-bank-details.billingEmail')"
                         class="error-note">
                    {{ errors.first('form-bank-details.billingEmail') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="IBAN">IBAN</label>
                  <the-mask
                      name="IBAN"
                      id="IBAN"
                      v-validate="ibanValidation()"
                      data-vv-as="IBAN"
                      v-model="user.IBAN"
                      class="form-control"
                      :mask="ibanMask()"
                      :placeholder="ibanPlaceholder()"
                  />
                  <small v-show="errors.has('form-bank-details.IBAN')"
                         class="error-note">
                    {{ errors.first('form-bank-details.IBAN') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="BIC">BIC</label>
                  <input type="text"
                         name="BIC"
                         id="BIC"
                         v-model="user.BIC"
                         v-validate=""
                         data-vv-as="BIC"
                         class="form-control"/>
                  <small v-show="errors.has('form-bank-details.BIC')"
                         class="error-note">
                    {{ errors.first('form-bank-details.BIC') }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="bank">Bank</label>
                  <input type="text"
                         name="bank"
                         id="bank"
                         v-model="user.bank"
                         v-validate=""
                         data-vv-as="Bank"
                         class="form-control"/>
                  <small v-show="errors.has('form-bank-details.bank')"
                         class="error-note">
                    {{ errors.first('form-bank-details.bank') }}
                  </small>
                </div>
                <button class="btn btn-secondary mt-3">
                  Speichern
                </button>
              </form>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter';
import {BTab, BTabs} from 'bootstrap-vue'
import TelephoneForm from '../components/shared/TelephoneForm.vue';
import preventInput from '@/mixins/validation/preventInput'

export default {
  name: 'Settings',
  metaInfo: {
    title: 'Einstellungen'
  },
  components: {
    BTab,
    BTabs,
    Password,
    TelephoneForm
  },
  mixins: [preventInput],
  data() {
    return {
      emulateTouch: {
        userPhone: false ,
        agencyPhone: false 
      },
      pwdModel: {
        oldPassword: '',
        newPassword: ''
      },
      forms: {
        CHANGE_PASSWORD_FORM: 'CHANGE_PASSWORD_FORM',
        TWO_FACTOR_FORM: 'TWO_FACTOR_FORM',
        PROFILE_FORM: 'form-profile',
        COMPANY_FORM: 'form-company',
        BANK_DETAILS_FORM: 'form-bank-details'
      },
      passwordScore: 0,
      passwordStrength: '',
      passwordClass: '',
      agency: {},
      oldUser: {},
      verificationToken: undefined,
      showTokenInput: false,
      verificationTokenInput: undefined
    }
  },
  computed: {
    countryInterface() {
      return this.$store.state.checkout.application.countryInterface.value
    },
    user() {
      return this.$store.state.user;
    },
    isRegistrationRequired() {
      if (this.user.role === 'broker') {
        return false
      } else if (this.user.role === 'agencyOwner') {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.verificationToken = (Math.floor(1000 + Math.random() * 9000)).toString();

    this.$store.dispatch('GET_CURRENT_USER')
        .then(() => {
          this.getAgency().then(() => {
            // initial check when the page loads.
            this.initialValidate('form-profile');
            this.initialValidate('form-company');
          });
          this.oldUser.mobile = this.user.mobile;
          this.oldUser.twoFactorSMS = this.user.twoFactorSMS;
        });
  // initial check when the page loads.
   this.initialValidate('form-profile');
  },
  methods: {
    postalValidation () {
      const map = {
        "DE": "required|numeric|min:5|max:5",
        "AT": "required|numeric|min:4|max:4|1st-char-not-0"
      }
      return map[this.countryInterface]
    },
    ibanValidation () {
      const map = {
        "DE": "required|iban:DE",
        "AT": "required|iban:AT"
      }
      return map[this.countryInterface]
    },
    updatePhoneNumber (res) {
      if(res.id === 'agencyPhoneNumber'){ 
        this.agency.mobile = res.number;
      } else if (res.id==='userPhoneNumber') { 
        this.user.mobile = res.number;
      }
    },
    getAgency() {
      return new Promise((resolve, reject) => {
        if (this.$store.state.user.brokerAgencyId && this.user.role === 'agencyOwner') {
          this.$store
            .dispatch('GET_USERS_AGENCY_FROM_VUEX', this.$store.state.user.brokerAgencyId)
            .then((res) => {
              this.agency = res
              resolve(res)
            })
            .catch(error => {
              console.error(error);
              reject(error)
            });
        }
      })
    },
    scoreChanged(score) {
      this.passwordScore = score || 0;
      this.passwordStrength = [
        'Komplexität: sehr schwach',
        'Komplexität: schwach',
        'Komplexität: mittel',
        'Komplexität: stark',
        'Komplexität: sehr stark'
      ][score];
      this.passwordClass = [
        'red',
        'red',
        'yellow',
        'green',
        'green'
      ][score];
    },
    saveUser() {
      if (!this.user.gutBeratenId) {
        this.user.gutBeratenId = null;
      }

      return this.$store
          .dispatch('UPDATE_USER', this.user)
          .then(() => {
            this.$toasted.show('Einstellungen gespeichert', {
              theme: 'bubble',
              type:'success',
              position: 'bottom-left',
              duration: 4000
            });
          });
    },
    saveBrokerAgency() {
      const {company, street, housenumber, zipcode, city, mobile} = this.agency;
      return this.$store
          .dispatch('UPDATE_AGENCY', {
            agencyId: this.agency.id,
            newData: {
              company,
              street,
              housenumber,
              zipcode,
              city,
              mobile
            }
          })
          .then(() => {
            this.$toasted.show('Einstellungen gespeichert', {
              theme: 'bubble',
              type:'success',
              position: 'bottom-left',
              duration: 4000
            });
          });
    },
    _changePassword() {
      return this.$store
          .dispatch('CHANGE_PASSWORD', this.pwdModel)
          .then(() => {
            this.pwdModel.newPassword = this.pwdModel.oldPassword = '';
            this.$toasted.show('Einstellungen gespeichert', {
              theme: 'bubble',
              type:'success',
              position: 'bottom-left',
              duration: 4000
            });
            this.$nextTick(() => {
              this.errors.clear();
              this.$nextTick(() => {
                this.$validator.reset();
              });
            });
            this.$router.push('/')
          });
    },
    initialValidate(form) {
      this.$validator.validateAll(form)
    },
    validateForm(scope) {
      this.$validator
          .validateAll(scope)
          .then((isValid) => {
            if (isValid) {
              switch (scope) {
                case this.forms.TWO_FACTOR_FORM:
                  if (this.showTokenInput) {
                    if (this.verificationToken === this.verificationTokenInput) {
                      this.saveUser();
                    } else {
                      this.$toasted.show('Bitte überprüfen Sie den Bestätigungs-Code.', {
                        theme: 'bubble',
                        position: 'bottom-left',
                        duration: 4000
                      });
                    }
                  } else if (this.user.twoFactorSMS &&
                      (this.oldUser.mobile !== this.user.mobile || this.oldUser.twoFactorSMS !== this.user.twoFactorSMS)) {
                    this.showTokenInput = true;
                    this.$http.get(`/brokers/send-verification-sms?number=${this.user.mobile.replace('+', '%2B')}&token=${this.verificationToken}`);
                    setTimeout(function () {
                      document.getElementById('tokenInput').focus()
                    }.bind(this), 300)
                  } else {
                    this.saveUser();
                  }
                  break;
                case this.forms.PROFILE_FORM:
                  this.saveUser();
                  break;
                case this.forms.BANK_DETAILS_FORM:
                  this.saveUser();
                  break;
                case this.forms.COMPANY_FORM:
                  this.saveBrokerAgency();
                  break;
                case this.forms.CHANGE_PASSWORD_FORM:
                  if (this.passwordScore >= 2) {
                    this._changePassword();
                  }
                  break;
                default:
                  break;
              }
            }
          })
          .catch(() => {
            this.$toasted.show('Einstellungen konnte nicht gespeichert werden', {
              theme: 'bubble',
              position: 'bottom-left',
              duration: 4000
            });
          });
    },
    ibanMask() {
      const map = {
        "DE": "AA## #### #### #### #### ##",
        "AT": "AA## #### #### #### ####"
      }
      return map[this.countryInterface]
    }, 
    ibanPlaceholder() {
      const map = {
        "DE": "DE12 5001 0517 0648 4898 90",
        "AT": "AT48 3200 0000 1234 5864"
      }
      return map[this.countryInterface]
    }
  },
  destroyed: function () {
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../scss/base';
@import '../scss/form';

label {
  margin-bottom: 2px;
}

.view-settings {
  padding-top: 60px;
}

.tab-content {
  padding-top: 24px;
  max-width: 600px;
}

#oldPassword {
  max-width: 400px;
}


</style>
