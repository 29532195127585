<template>
    <form v-on:submit.prevent.stop="submitForm()" name="step1" id="step_form" novalidate>
        <div class="row mb-3">
            <div class="col-12 col-md-6 offset-md-3 text-center">
                <h2>Wie viele Mitarbeiter hat Ihr Unternehmen?</h2>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-6 offset-md-3 d-flex flex-row">
                <button type="button"
                        class="select-button"
                        @click="inputs.employees = employees"
                        :class="{'select-button--active': isEmployeeSelected(employees)}"
                        v-for="(employees, idx) in employeesOpts"
                        :key="'employees-' + idx">
                    {{employees}}
                </button>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 text-center">
                <a href="#" v-on:click.prevent="showLeaveModal = true">Ihr Unternehmen ist größer?</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 text-center">
                <h2>Bitte wählen Sie einen Selbstbehalt.</h2>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 col-md-6 offset-md-3 d-flex flex-row">
                <button type="button"
                        class="select-button"
                        @click="inputs.deductible = deductible"
                        :class="{'select-button--active': isDeductibleSelected(deductible)}"
                        v-for="(deductible, idx) in deductibleOpts"
                        :key="'deductible-' + idx">
                    {{deductible | currency(0)}}
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 text-center">
                <div class="price">
                    <div>Preis</div>
                    <strong>{{prices.gross | currency}}</strong>
                    <div>
                        <small>jährlich, inkl. 19% Versicherungssteuer</small>
                    </div>
                </div>
            </div>
        </div>
        <modal v-if="showLeaveModal" @close="showLeaveModal = false" modal-style="mobile">
            <h3 slot="header" class="mobile-method-selector__modal-title">
                Aufgrund der Größe Ihres Unternehmens ist eine individuelle Beratung erforderlich.
            </h3>
            <div slot="body">
                <div v-if="!modalSuccess">
                    <div class="form-group" :class="getFieldClass('modal_name')">
                        <label for="modal_name">Name</label>
                        <input class="form-control"
                               type="text"
                               id="modal_name"
                               name="modal_name"
                               v-validate="{ required: true }"
                               v-model="contactFormData.name">
                    </div>
                    <!-- <div class="form-group" :class="getFieldClass('modal_phone')">
                        <label for="modal_phone">Telefonnummer</label>
                        <phone-resolver class="phone-resolver-container"
                          id="modal_phone"
                          name="modal_phone"
                          v-model="contactFormData.phone"
                          required>
                        </phone-resolver>
                    </div> -->
                     <PhoneForm
                      name="Mobiltelefon"
                      v-model='contactFormData.phone'
                      @numberChange="updatePhoneNumber"
                      v-validate="{ required: true }"
                    />
                    <div class="form-group" :class="getFieldClass('modal_mail')">
                        <label for="modal_mail">E-Mail</label>
                        <input class="form-control"
                               type="text"
                               id="modal_mail"
                               name="modal_mail"
                               v-validate="{ required: true, email: true }"
                               v-model="contactFormData.email">
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox"
                               class="custom-control-input"
                               id="customCheck1"
                               v-model="contactFormData.legalCheck">
                        <label class="custom-control-label" for="customCheck1">
                            Ich stimme den
                            <router-link :to="'/datenschutzhinweise'" target="_blank">Datenschutzhinweisen
                            </router-link>
                            zu
                        </label>
                    </div>
                </div>
                <div v-else>
                    <p class="text-center">Vielen Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen melden.</p>
                </div>
            </div>
            <button class="btn btn-block btn-secondary"
                    type="button"
                    @click="sendSubmission()"
                    :disabled="!contactFormData.legalCheck || modalSuccess"
                    slot="footer">Ich möchte ein Angebot erhalten
            </button>
        </modal>
        <input type="submit" id="step_submit">
    </form>
</template>

<script>
  import {mapActions} from 'vuex';
  import eventBus from '@/components/shared/EventBus.js'
  import Modal from '../Modal';
  import store from '@/store/index';
  import PhoneForm from './shared/PhoneForm'
  import isStepAllowed from '../mixins/isStepAllowed.js'

  export default {
    name: 'Step1c',
    components: {
      Modal,
      PhoneForm
    },
    data() {
      return {
        telOptions: {
          placeholder: 'Telefonnummer',
          allowedTelCountries: ['DE', 'AT', 'CH']
        },
        employeesOpts: ['1-10', '11-20', '21-30', '31-40'],
        deductibleOpts: [0, 150, 250, 500],
        showLeaveModal: false,
        contactFormData: {
          name: '',
          phone: '',
          email: '',
          legalCheck: false
        },
        modalSuccess: false,
        prices: {
          gross: 0,
          net: 0
        }
      }
    },
    computed: {
      inputs() {
        return this.$store.state.checkout.application.inputs;
      }
    },
    props: ['step'],
    watch: {
      'inputs.employees'() {
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        this.$store.dispatch('LEGAL_SET_PRICE');
        this.prices = this.$store.getters.legalPrices();
      },
      'inputs.deductible'() {
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        this.$store.dispatch('LEGAL_SET_PRICE');
        this.prices = this.$store.getters.legalPrices();
      }
    },
    mounted() {
      eventBus.$on('submitStep', () => {
        document.getElementById('step_submit').click();
      });

      this.prices = this.$store.getters.legalPrices();
    },
    beforeRouteEnter(to, from, next) {
      const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
      storeInstance.dispatch('LEGAL_SET_PRICE');
      next();
    },
    beforeRouteUpdate(to, from, next) {
      const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
      storeInstance.dispatch('LEGAL_SET_PRICE');
      next();
    },
    methods: {
      ...mapActions({
        updateInputs: 'UPDATE_LEGAL_INPUTS',
        setPrice: 'LEGAL_SET_PRICE'
      }),
      updatePhoneNumber (res) {
        this.contactFormData.phone = res.number;
      },
      submitForm() {
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        if (this.inputs.gross) {
          this.step.completed = true;
          return eventBus.$emit('changeStepForward', this.step);
        }
      },
      isEmployeeSelected(employees) {
        return this.inputs.employees === employees;
      },
      isDeductibleSelected(deductible) {
        return this.inputs.deductible === deductible;
      },
      getFieldClass(fieldName) {
        if (!this.fields[fieldName]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      },
      sendSubmission() {
        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              this
                .$store
                .dispatch('INSERT_SUBMISSION', {
                  topic: 'moreThen40Employees',
                  name: this.contactFormData.name,
                  phone: this.contactFormData.phone,
                  email: this.contactFormData.email,
                  meta: {
                    industry: this.inputs.industry
                  }
                })
                .then(() => {
                  this.modalSuccess = true;
                })
                .catch(() => {
                  eventBus.$emit('alertUser', {
                    type: 'error',
                    message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.',
                    customCloseBtnText: 'OK',
                    customCloseBtnClass: 'btn btn-primary'
                  })
                })
            }
          });
      }
    },
    destroyed: function () {
      eventBus.$off('submitStep');
    },
    mixins: [isStepAllowed]
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/form';
    @import '../scss/mixins';
    @import '../scss/button';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .phone-resolver-container {
      border: none;
      padding-top: 0;
      padding-left: 0;
    }

    .color_blue {
        color: #38a7dd
    }

    .price {
        padding: 32px;
        background: #efefef;
        color: $darkBlueTone;
        border: 2px solid #C8C8C8;

        div {
            margin-bottom: 0;
            font-size: 1.1rem !important;
        }

        strong {
            font-size: 1.4rem;
        }
    }

    @include media-breakpoint-up(md) {
        .select-button + .select-button {
            margin-left: 8px;
        }
    }

    .select-button {
        flex: 1 auto;

        + .select-button {
            margin-left: 2px;
        }

        padding: 12px 8px;
        border: 2px solid $darkBlueTone;
        background: #fff;
        color: $darkBlueTone;
        border-radius: 8px;

        &:active,
        &:focus {
            outline: 0;
        }

        &--active {
            color: #fff;
            background: $darkBlueTone;
        }
    }

    .price {
        background: $lightGrey;
    }
</style>

