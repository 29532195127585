<template>
    <component :is="slide.component"
               :content="slide"></component>
</template>

<script>
  // import AmazonPhishingMail from './AmazonPhishingMail.vue';
  // import RealFakeAddress from './RealFakeAddress.vue';
  // import PasswordCrackingTime from './PasswordCrackingTime.vue';
  import EmotetEmail from './EmotetEmail.vue';
  // import DeleteFirefoxCookies from './DeleteFirefoxCookies.vue';
  import FacebookFriendsSettings from './FacebookFriendsSettings.vue';
  // import WindowsUpdateSettings from './WindowsUpdateSettings.vue';
  import CreateWindowsUser from './CreateWindowsUser.vue';
  // import ChromeUpdate from './ChromeUpdate.vue';
  import iPhoneUpdate from './iPhoneUpdate.vue';

  export default {
    name: 'custom-template',
    components: {
      // AmazonPhishingMail,
      // RealFakeAddress,
      // PasswordCrackingTime,
      EmotetEmail,
      // DeleteFirefoxCookies,
      FacebookFriendsSettings,
      // WindowsUpdateSettings,
      // ChromeUpdate,
      CreateWindowsUser,
      iPhoneUpdate
    },
    props: {
      slide: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .custom-template {
        &__highlight-area {
            position: absolute;
            z-index: 1;
            border: 3px solid $secondary;
            border-radius: 8px;
            cursor: pointer;
            transform-origin: top left;
            animation: fadein 2s infinite linear;
        }

        @keyframes fadein {
            0% {
                opacity: 0.5;
            }
            50% {
                opacity: 0.7;
            }
            60% {
                opacity: 0.8;
            }
            100% {
                opacity: 0.5;
            }
        }

        &__highlight-arrow {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-100%, -20%);
        }

        &__tooltip {
            cursor: pointer;
            position: absolute;
            transform-origin: top left;
            padding: 7px;
            border: 1px solid darken($primary, 15%);
            background: $primary;
            border-radius: 50%;

            .icon {
                border-radius: 50%;
                box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.35);

                path {
                    transform-origin: center;
                    transform: scale(1.15);
                }
            }
        }

        &__tooltip-content {
            .tooltip-inner {
                background: $primary;
                padding: 15px;
                width: 380px;
            }

            .tooltip-arrow {
                border-color: $primary;
            }

            h3 {
                margin: 0 0 15px;
                color: #fff;
            }

            p {
                margin: 0;
                color: #fff;
            }
        }
    }
</style>
