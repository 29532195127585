<template>
  <div class="">
    <ModalBasic :id="id" modalClass="modal-filter">
      <h4 class="modal-heading">
        Filter
      </h4>
      <div class="control-wrapper">
        <label class="control-label">Kunde</label>
        <input class="form-control form-control--border" type="text">
      </div>
      <div class="control-wrapper">
        <label class="control-label">Firma</label>
        <input class="form-control form-control--border" type="text">
      </div>
      <div class="control-wrapper">
        <label class="control-label" >Makler</label>
        <select class="custom-select custom-select--border" id="makler-select-mob">
          <option selected>Choose...</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div class="control-wrapper">
        <label class="control-label">Status</label>
        <select class="custom-select custom-select--border" id="status-select-mob">
          <option selected>Choose...</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
      </div>
      <div class="d-flex justify-content-center">
        <ButtonComponent
        class="apply-btn"
        btnStyle="accent"
        behavior="button">
        <span>Anwenden</span>
      </ButtonComponent>
      </div>
    </ModalBasic>
  </div>
</template>

<script>
  import ModalBasic from '@/components/reusable/ModalBasic';
  import ButtonComponent from '@/components/reusable/ButtonComponent';

  export default {
    components: {      
      ModalBasic,
      ButtonComponent
    },
    props: {
      id: {
        type: String,
        required: true,
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


.modal-filter {
  .modal-heading {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  .control-wrapper + .control-wrapper {
    margin-top:1rem
  }

  .apply-btn {
    margin-top: 1.5rem;

    @include media-breakpoint-down(sm) {
      width: 100%
    }
  }
}
</style>