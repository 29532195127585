<template>
  <div class="d-print-none">
    <button class="btn btn--scroll-top" :class="{ 'show-btn': showBtn }" @click="scrollToTop">      
      <Icon name="arrow-up" class="icon-up" />
    </button>
  </div>
</template>

<script>
import Icon from "@/components/Icon"
export default {
  components: {
    Icon
  },
  data() {
    return {
      showBtn: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleUserScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleUserScroll)
  },
  methods: {
    handleUserScroll() {
      const distance = window.pageYOffset
      if (distance > 600) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


.icon-up {
  fill: $whiteColor;
}

.btn--scroll-top {
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  min-width: auto;

  @include media-breakpoint-down(sm) {
    bottom: 4rem;
    right: 1rem;
  }

  z-index: 25;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: $primary;
  color: $whiteColor;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0px 0px 15px rgba($primary, 0.5);
  }

  .icon svg {
    position: absolute;
  }
}

.show-btn {
  opacity: 1;
  visibility: visible;
}
</style>
