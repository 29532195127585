<template>
    <course-base-template :title="title"
                          :mid-icon="icon"
                          :subtitle="subtitle"
                          back-text="Zurück zum Modul"
                          next-text="Quiz starten"
                          @back="back"
                          @next="next"></course-base-template>
</template>

<script>
  import CourseBaseTemplate from './CourseBaseTemplate.vue';

  export default {
    name: 'quiz-start-template',
    components: {
      CourseBaseTemplate
    },
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      slideNumber() {
        return +this.$route.params.slide;
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      }
    },
    methods: {
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      async next() {
        await this.$store.dispatch('SAVE_PROGRESS', {
          trainingSlug: this.selectedCourseProgress.trainingSlug,
          slideSlug: 'quiz-1'
        });

        this.$router.replace({
          name: 'training-quiz',
          params: {
            step: '1'
          }
        });
      }
    }
  }
</script>

<style lang="scss">

</style>
