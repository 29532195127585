<template>
  <div class="container">
    <div class="row" v-if="inputs.type === 'contract'">
      <div class="col-12">
        <h2 class="text-center">Vielen Dank!  </h2>
        <p class="text-center maxw">Wir haben Ihren Antrag entgegengenommen und werden diesen beim Versicherer einreichen. Sie erhalten umgehend die Antragsunterlagen inklusive Zugang zur digitalen Mitarbeiterschulung.</p>
      </div>
    </div>
    <div class="row" v-if="inputs.type === 'offer'">
      <div class="col-12">
        <h2 class="text-center">Vielen Dank!</h2>
        <p class="text-center">Sie erhalten umgehend per E-Mail ein unverbindliches Angebot, das Ihren individuellen Anforderungen entspricht.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-2 offset-lg-5">
        <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/success_icon.svg">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Success',
    computed: {
      inputs () {
        return this.$store.state.checkout.application.inputs;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scss/variables';

  h2 {
    font-weight: 700;
  }
  .maxw {
    max-width: 600px;
    margin: auto;
  }
</style>
