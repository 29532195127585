<template>
    <div>
        <p class="p2"><span class="s1"><strong>A. Allgemeine Bestimmungen</strong></span></p>
                <ol class="ol1">
                    <li class="li3"><span class="s1"><strong>Anbieter und Geltungsbereich</strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Die CyberDirekt GmbH, K&ouml;penicker Str. 154A, 10997 Berlin, (im Folgenden: &bdquo;<strong>CyberDirekt</strong>&ldquo;, &bdquo;<strong>wir</strong>&ldquo; oder &bdquo;<strong>uns</strong>&ldquo;) vermittelt als Versicherungsmakler ihren Kunden bestimmte Versicherungsprodukte (im Folgenden: &bdquo;<strong>CyberDirekt Insurance Services</strong>&ldquo;) und bietet diesen Dienstleistungen im Bereich der Risikopr&auml;vention (im Folgenden: &bdquo;<strong>CyberDirekt Pr&auml;vention-Services</strong>&ldquo;) an.</span>
                        </li>
                        <li class="li3"><span class="s1">Zus&auml;tzlich bieten wir, als Versicherungsmakler der in Ziffer 2.2 genannten Versicherer, Vermittlern (im Folgenden: &bdquo;<strong>Sie</strong>&ldquo;, &bdquo;<strong>Ihnen</strong>&ldquo;) an, den von CyberDirekt auf www.cyberdirekt.de und www.cyberdirekt.com (im Folgenden: &bdquo;<strong>Website</strong>&ldquo;) angebotenen Versicherungsschutz f&uuml;r Ihre Kunden als Versicherungsnehmer in deren Namen (im Folgenden: &bdquo;<strong>Endkunden</strong>&ldquo;) abzuschlie&szlig;en.</span>
                        </li>
                        <li class="li3"><span
                                class="s1">Diese Gesch&auml;ftsbedingungen (im Folgenden: &bdquo;<strong>AGB</strong>&ldquo;) finden Anwendung zwischen Ihnen und uns f&uuml;r Ihre T&auml;tigkeit als Vermittler von Endkunden &uuml;ber uns an die in Ziffer 2.2 genannten Versicherer f&uuml;r CyberDirekt Insurance Services und unsere Leistungen in diesem Zusammenhang unmittelbar Ihnen gegen&uuml;ber. Daf&uuml;r gelten neben den Allgemeinen Bestimmungen des Teils A auch die Besonderen Vermittlerbestimmungen des Teils B und die Besonderen Bedingungen der CyberDirekt Insurance Services des Teils C, letztere soweit diese f&uuml;r Ihre T&auml;tigkeit als Vermittler relevant sind.</span>
                        </li>
                        <li class="li3"><span class="s1">Gleichzeitig akzeptieren Sie diese AGB auch im Namen Ihrer Endkunden, weil diese AGB Regelungen für das Zustandekommen und die Verwaltung des Versicherungsvertragsverhältnisses enthalten. Dafür gelten die Allgemeinen Bestimmungen des Teils A sowie die Besonderen Bedingungen der CyberDirekt Insurance Services des Teils C.</span>
                        </li>
                        <li class="li3"><span class="s1">Den Endkunden stellen wir ferner unsere CyberDirekt Pr&auml;vention-Services unmittelbar &uuml;ber einen separaten Log-In zum Kundenportal zur Verf&uuml;gung. F&uuml;r deren Nutzung durch die Endkunden gelten die daf&uuml;r einschl&auml;gigen Gesch&auml;ftsbedingungen</span><span
                                class="s2"></span><span class="s1">.</span></li>
                        <li class="li4"><span class="s1">Die Nutzung unserer Website durch Sie als Vermittler ist nur zul&auml;ssig, wenn Sie sich bei uns unter wahrheitsgem&auml;&szlig;er Angabe aller erbetenen Informationen als Partner registriert haben, ordnungsgem&auml;&szlig; im Vermittlerregister registriert sind, die gesetzlichen und beh&ouml;rdlichen Anforderungen an Versicherungsvermittler erf&uuml;llen (insbesondere nach &sect;&sect; 34d f. GewO und &sect;&sect; 59 ff VVG) und f&uuml;r Ihren Endkunden abschlussbevollm&auml;chtigt sind. &Auml;nderungen Ihrer Angaben teilen Sie uns unverz&uuml;glich mit.</span>
                        </li>
                    </ol>
                    <li class="li3"><span class="s1"><strong>Unsere T&auml;tigkeit </strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">CyberDirekt ist zugelassener Versicherungsmakler gem. &sect; 34d Abs. 1 GewO. Mehr Informationen hierzu finden Sie in der <a
                                href="http://www.cyberdirekt.de/erstinformation"><span
                                class="s3">Erstinformation</span></a></span><span class="s2"></span><span
                                class="s1">.</span>
                        </li>
                        <li class="li3">
                            <span class="s1">Soweit Sie f&uuml;r Ihre Endkunden den auf unserer Website angebotenen Versicherungsschutz &uuml;ber das Maklerportal abschlie&szlig;en, nehmen Sie zur Kenntnis, dass dort nur Versicherungsschutz unserer Versicherungspartner, n&auml;mlich</span>
                            <ul class="ul1">
                              <li class="li3" v-for="(el, i ) in sortedMargins" :key="i"><span class="s1">{{ el.longLabel || el.label  }}</span></li>
                            </ul>
                        </li>
                    </ol>
                </ol>
                <p class="p5"><span
                        class="s1">(im Folgenden: &bdquo;<strong>Versicherer</strong>&ldquo;) angeboten wird. </span>
                </p>
                <ol class="ol1">
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Die Ihnen angebotene Auswahl an Versicherungsprodukten ist daher auf unserer Website dargestellten Versicherer beschr&auml;nkt. Eine weitergehende Untersuchung des Marktes f&uuml;r vergleichbare Versicherungsprodukte erfolgt durch uns nicht. Der Marktvergleich basiert auf einer Auswahl von bestimmten, wesentlichen Tarifmerkmalen und bezieht keine Versicherungsprodukte anderer Versicherungsunternehmen ein. Wir empfehlen Ihnen, vor Abschluss der Versicherung alle Details des Tarifs zu betrachten. In den Versicherungsbedingungen k&ouml;nnen wichtige Informationen enthalten sein, welche f&uuml;r das Gesch&auml;ftsmodell Ihres Unternehmens relevant sind.</span>
                        </li>
                        <li class="li4"><span class="s1">Ein Anspruch auf die Verf&uuml;gbarkeit unserer Website und der CyberDirekt Insurance Services wird hiermit nicht begr&uuml;ndet.</span>
                        </li>
                    </ol>
                </ol>
                <ol class="ol1">
                    <li class="li3"><span class="s1"><strong>Haftungsbeschr&auml;nkung </strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Die Haftung von CyberDirekt richtet sich unabh&auml;ngig vom Rechtsgrund nach dieser Ziffer&nbsp;3.</span>
                        </li>
                        <li class="li3"><span class="s1">Wir haften unbeschr&auml;nkt f&uuml;r Sch&auml;den aus der Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit, die auf einer vors&auml;tzlichen oder fahrl&auml;ssigen Handlung bzw. Pflichtverletzung von uns beruhen. Wir haften auch unbeschr&auml;nkt f&uuml;r sonstige Sch&auml;den, die auf einer vors&auml;tzlichen oder grob fahrl&auml;ssigen Handlung bzw. Pflichtverletzung von uns beruhen. </span>
                        </li>
                        <li class="li3"><span class="s1">Ferner haften wir im Rahmen des &sect; 63 Versicherungsvertragsgesetz (VVG) f&uuml;r Sch&auml;den die Ihnen oder dem Endkunden durch die Verletzung einer Pflicht nach &sect; <a
                                href="https://dejure.org/gesetze/VVG/60.html"><span class="s5">60</span></a> VVG oder &sect; <a
                                href="https://dejure.org/gesetze/VVG/61.html"><span class="s5">61</span></a> VVG entsteht, au&szlig;er wenn wir die Pflichtverletzung nicht zu vertreten haben.</span>
                        </li>
                        <li class="li3"><span class="s1">F&uuml;r sonstige Sch&auml;den aufgrund einer einfach fahrl&auml;ssigen Verletzung solcher Pflichten, die f&uuml;r die angemessene und einwandfreie Vertragsdurchf&uuml;hrung grundlegend sind und auf deren Erf&uuml;llung der Nutzer dementsprechend vertraut und vertrauen darf (Kardinalpflichten), haften wir nur beschr&auml;nkt auf den typischerweise vorhersehbaren Schaden. </span>
                        </li>
                        <li class="li3"><span class="s1">Anderweitige Schadens- oder Aufwendungsersatzforderungen sind ausgeschlossen. </span>
                        </li>
                        <li class="li4"><span class="s1">Soweit die Haftung nach den Regelungen dieser Ziffer 3 f&uuml;r CyberDirekt beschr&auml;nkt oder ausgeschlossen ist, sind diese Beschr&auml;nkungen oder Ausschl&uuml;sse auch f&uuml;r die pers&ouml;nliche Haftung der Mitarbeiter, der gesetzlichen Vertreter und Erf&uuml;llungsgehilfen von CyberDirekt anwendbar.</span>
                        </li>
                    </ol>
                    <li class="li3"><span
                            class="s1"><strong>&Auml;nderungen, anwendbares Recht, salvatorische Klausel</strong></span>
                    </li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Wir sind berechtigt, diese AGB zu &auml;ndern. &Auml;nderungen der AGB werden wir Ihnen vorab einschlie&szlig;lich einer Information Ihres Widerspruchsrechts und der Widerspruchsfrist in Textform ank&uuml;ndigen. Die beabsichtigten &Auml;nderungen treten in Kraft, sofern Sie ihnen nicht innerhalb von zwei (2) Wochen nach Erhalt der Ank&uuml;ndigung widersprechen.</span>
                        </li>
                        <li class="li3"><span class="s1">Diese AGB unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss der unter diesem Recht geltenden kollisionsrechtlichen Bestimmungen.</span>
                        </li>
                        <li class="li3"><span class="s1">Sollte eine Bestimmung dieser AGB ganz oder teilweise unwirksam sein oder werden oder eine an sich notwendige Regelung nicht enthalten, so wird dadurch die Wirksamkeit der &uuml;brigen Bestimmungen dieser AGB nicht ber&uuml;hrt.</span>
                        </li>
                    </ol>
                </ol>
                <p class="p6">&nbsp;</p>
                <p class="p2"><span class="s1"><strong>B. Besondere Vermittlerbestimmungen</strong></span></p>
                <ol class="ol1">
                    <li class="li3"><span class="s1"><strong>Vermittlungsverh&auml;ltnis </strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Gegen&uuml;ber dem Endkunden sind nur Sie als Versicherungsvermittler t&auml;tig. Wir handeln nicht als Ihr Untervermittler und Sie sind nicht unser Untervermittler.</span>
                        </li>
                        <li class="li3"><span class="s1">Sie stehen uns und den Versicherern daf&uuml;r ein, dass Sie Ihren Verpflichtungen als Versicherungsvermittler gegen&uuml;ber den Endkunden vollumf&auml;nglich nachkommen, insbesondere die Informations-, Beratungs- und Dokumentationspflichten gegen&uuml;ber den Endkunden erf&uuml;llen. Sofern wir Ihnen unentgeltlich ein Beratungsprotokoll zur Verf&uuml;gung stellen, befreit Sie dies nicht von Ihrer Beratungspflicht gegen&uuml;ber dem Endkunden nach dem VVG, insbesondere nicht von der Beratungspflicht nach &sect; 61 VVG. Auch bei Verwenden des von uns zur Verf&uuml;gung gestellten Beratungsprotokolls sind einzig Sie als Versicherungsvermittler verpflichtet, eine gesetzeskonforme Beratung zu gew&auml;hrleisten. Diese umfasst insbesondere Ihre Pflicht, das Beratungsprotokoll auf Vollst&auml;ndigkeit zu &uuml;berpr&uuml;fen.</span>
                        </li>
                        <li class="li3"><span class="s1">Sollten Endkunden aufgrund einer Verletzung Ihrer Pflichten Anspr&uuml;che gegen uns oder einen Versicherer erheben, stellen Sie uns und den Versicherer unverz&uuml;glich vollumf&auml;nglich frei.</span>
                        </li>
                        <li class="li4"><span class="s1">Sie sind nicht berechtigt, mit Wirkung f&uuml;r uns oder einen Versicherer Abschluss-, K&uuml;ndigungs- oder R&uuml;cktrittserkl&auml;rungen oder sonstige das Versicherungsverh&auml;ltnis betreffende Erkl&auml;rungen entgegenzunehmen oder abzugeben.</span>
                        </li>
                    </ol>
                    <li class="li3"><span class="s1"><strong>Provision</strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Soweit Sie &uuml;ber unsere Website die CyberDirekt Insurance Services in Anspruch nehmen und einen Versicherungsvertrag f&uuml;r Ihre Endkunden abschlie&szlig;en, erhalten Sie von uns f&uuml;r Ihre T&auml;tigkeit (den Vermittlungserfolg) eine Provision, die sich auf Basis der gezahlten Pr&auml;mie berechnet. Wenn f&uuml;r die laufende Betreuung des betreffenden Versicherungsvertrags vom Versicherer eine Bestands- oder Folgeprovision an uns gezahlt wird, erhalten Sie von uns eine zus&auml;tzliche Provision. Die H&ouml;he der Provision ergibt sich aus der Anlage &bdquo;CyberDirekt Makler-Provision&ldquo;</span>
                        </li>
                        <li class="li3"><span class="s1">Mit den vorstehend beschriebenen Provisionen sind alle Ihre Anspr&uuml;che aus Ihrer T&auml;tigkeit im Zusammenhang mit dem betreffenden Versicherungsvertrag gegen uns oder die Versicherer sowie alle Ihre diesbez&uuml;glichen Aufwendungen abgegolten. Ein dar&uuml;berhinausgehender (Kosten-)Erstattungsanspruch, Provisionsanspruch oder sonstiger Verg&uuml;tungsanspruch Ihrerseits besteht nicht, auch nicht gegen&uuml;ber den Versicherern.</span>
                        </li>
                        <li class="li3"><span class="s1">Ihr Anspruch auf Provision f&uuml;r den Vermittlungserfolg entsteht, wenn der Versicherungsvertrag mit dem Versicherer wirksam zustande gekommen ist und die Pr&auml;mienzahlung des Endkunden bei CyberDirekt oder dem Versicherer eingegangen ist; dies gilt f&uuml;r Vertrags&auml;nderungen, Summenerh&ouml;hungen bzw. Risikoerweiterungen einschlie&szlig;lich Dynamik und Nachversicherungen entsprechend. </span>
                        </li>
                        <li class="li3"><span class="s1">Ihr Anspruch auf Provision f&uuml;r die laufende Betreuung entsteht mit Beginn der anschlie&szlig;enden Versicherungsperiode, soweit der Endkunde daf&uuml;r eine weitere Pr&auml;mie zu zahlen hat und diese bei CyberDirekt oder dem Versicherer eingegangen ist. Der Provisionsanspruch f&uuml;r die laufende Betreuung besteht nur solange, wie der betreffende Versicherungsvertrag besteht und der Endkunde von Ihnen betreut wird. Bei einem Vermittlerwechsel oder bei Beendigung der Betreuung des Endkunden durch Sie verlieren Sie Ihren Provisionsanspruch mit dem Ende der Betreuung, sp&auml;testens mit der auf den Wechsel folgenden Hauptf&auml;lligkeit der Pr&auml;mie.</span>
                        </li>
                        <li class="li3"><span class="s1">Ihr Anspruch auf Provision ist f&auml;llig, sobald die f&uuml;r den jeweiligen Versicherungsvertrag vom Versicherer an uns zu zahlende Abschluss- oder Folge- bzw. Bestandsprovision eingegangen ist. Erfolgt die Pr&auml;mienzahlung in Teilraten, ist Ihre Provision jeweils anteilig entsprechend den tats&auml;chlichen Zahlungen der Provision an uns f&auml;llig.</span>
                        </li>
                        <li class="li3"><span class="s1">Die Provision teilt das Schicksal der Pr&auml;mie. Wird ein Versicherungsvertrag &ndash; egal aus welchem Grund &ndash; ge&auml;ndert, aufgehoben oder vorzeitig beendet und die Pr&auml;mie ganz oder teilweise an den Endkunden zur&uuml;ckgezahlt, so ist eine bereits gezahlte Provision ganz oder teilweise an CyberDirekt oder den Versicherer zur&uuml;ckzuerstatten.</span>
                        </li>
                        <li class="li3"><span class="s1">CyberDirekt ist berechtigt, die Provisionss&auml;tze jederzeit durch eine entsprechende Erkl&auml;rung Ihnen gegen&uuml;ber mit Wirkung f&uuml;r das Gesch&auml;ft zu &auml;ndern, dass Sie &uuml;ber CyberDirekt nach Zugang vermitteln.</span>
                        </li>
                    </ol>
                </ol>
                <p class="p2"><span
                        class="s1"><strong>C. Besondere Bedingungen der CyberDirekt Insurance Services</strong></span>
                </p>
                <ol class="ol1">
                    <li class="li3"><span class="s1"><strong>Zustandekommen des Versicherungsvertrags</strong></span>
                    </li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">Der von Ihnen f&uuml;r den Endkunden angefragte Versicherungsschutz entsteht erst, wenn ein Versicherungsvertrag mit dem Versicherer zustande kommt und die erste Pr&auml;mienzahlung &uuml;ber das von Ihnen angegebene Zahlungsverfahren erfolgt ist, soweit dies in den Versicherungsbedingungen ausgewiesen ist. Die Versicherungsprodukte, welche auf der Website dargestellt werden, stellen kein rechtlich bindendes Angebot dar.</span>
                        </li>
                        <li class="li3"><span class="s1">&Auml;nderungen der Angaben der Endkunden (z.B. des Wohnsitzes, der Mobilfunknummer, der E-Mail-Adresse) teilen Sie uns unverz&uuml;glich mit.</span>
                        </li>
                        <li class="li3"><span class="s1">CyberDirekt bietet Ihnen die M&ouml;glichkeit, sich &uuml;ber die angebotenen Produkte auf der Website zu informieren, den von Ihnen ausgew&auml;hlten Versicherungsschutz auszuw&auml;hlen, die Versicherungspr&auml;mie zu berechnen und den Abschluss der Versicherung f&uuml;r den Endkunden zu beantragen. F&uuml;r diese Leistungen von CyberDirekt entstehen Ihnen und den Endkunden keine zus&auml;tzlichen Kosten.</span>
                        </li>
                        <li class="li3"><span class="s1">Im Zuge der Beantragung des Versicherungsschutzes bitten wir Sie um Angabe einer Kontaktperson und E-Mail-Adresse des Endkunden. Dies dient u.a. dem Zweck, dass der Endkunde die CyberDirekt Pr&auml;vention-Services in Anspruch nehmen kann.</span>
                        </li>
                        <li class="li3"><span class="s1">Durch Anklicken des Buttons &bdquo;Kostenpflichtig abschlie&szlig;en&ldquo; im letzten Schritt des Antragsprozesses geben Sie f&uuml;r Ihren Endkunden ein rechtsverbindliches Angebot zum Abschluss des ausgew&auml;hlten Versicherungsschutzes ab. Bis zu diesem Zeitpunkt k&ouml;nnen Sie Ihre Angaben korrigieren bzw. von der Vertragserkl&auml;rung absehen. Unverz&uuml;glich nach Absenden erhalten der Endkunde und Sie von uns per E-Mail eine Best&auml;tigung &uuml;ber die Entgegennahme des Antrags; dies stellt noch kein Abschluss des Versicherungsvertrags dar. Im Anschluss erhalten der Endkunde und Sie per E-Mail eine R&uuml;ckmeldung vom Versicherer &uuml;ber das Zustandekommen des Versicherungsvertrages zusammen mit dem Versicherungsschein. Sollte Ihr Antrag f&uuml;r Ihren Endkunden abgelehnt werden m&uuml;ssen, erhalten der Endkunde und Sie ebenfalls unverz&uuml;glich eine Nachricht vom Versicherer. Sie verpflichten sich, nach Absenden des Antrags regelm&auml;&szlig;ig Ihr E-Mail-Postfach einschlie&szlig;lich des SPAM-Ordners auf einen Eingang der Vertragsbest&auml;tigung zu pr&uuml;fen und den Endkunden auf einen derartigen Eingang hinzuweisen.</span>
                        </li>
                        <li class="li4"><span class="s1">F&uuml;r die Weitergabe der Kommunikation und Vertragserkl&auml;rungen an Ihren Endkunden sind Sie alleinig verantwortlich.</span>
                        </li>
                    </ol>
                    <li class="li3"><span class="s1"><strong>Verwaltung des Versicherungsvertrags </strong></span></li>
                    <ol class="ol1">
                        <li class="li3"><span class="s1">CyberDirekt ist Ihr Ansprechpartner f&uuml;r die Abwicklung des &uuml;ber die Website beantragten Versicherungsschutzes. Hierzu geh&ouml;rt insbesondere die Betreuung in allen Fragen rund um Ihren Versicherungsvertrag, die Verl&auml;ngerung, oder Annahme von &Auml;nderungen der Daten der Endkunden sowie die K&uuml;ndigung des Versicherungsvertrages f&uuml;r den Endkunden. </span>
                        </li>
                        <li class="li3"><span class="s1">Zahlungen im Zusammenhang mit dem Versicherungsschutz, die Meldung und Regulierung von Sch&auml;den oder Abrechnungsfragen richten Sie bitte direkt an den Versicherer. Soweit bei diesen Anliegen die Versicherer oder andere Dienstleister eingebunden sind, stehen wir Ihnen f&uuml;r Informationen zum Bearbeitungsstand zur Verf&uuml;gung oder leiten Ihre Anliegen weiter. Wir sind dabei im Auftrag der jeweiligen Versicherer bzw. als deren Vertreter t&auml;tig.</span>
                        </li>
                        <li class="li3"><span class="s1">Die von CyberDirekt vermittelten Versicherungsvertr&auml;ge sind digitale Versicherungsprodukte. Mit Abschluss des Versicherungsvertrages f&uuml;r Ihren Endkunden bzw. mit der Registrierung &uuml;ber unsere Website erkennen Sie auch f&uuml;r Ihren Endkunden an, dass die Abwicklung des Versicherungsvertrages in elektronischer Form, per E-Mail oder bei Bedarf auch per Telefon stattfindet. Hiervon erfasst ist insbesondere die &Uuml;bermittlung s&auml;mtlicher Informationen, Mitteilungen oder sonstiger Erkl&auml;rungen, die den Abschluss und die Abwicklung des Versicherungsvertrages betreffen. Ausgenommen sind lediglich Erkl&auml;rungen, die der gesetzlichen Schriftform bed&uuml;rfen und von deren Formerfordernis nicht abgewichen werden kann. Soweit wir von Ihren Endkunden aus rechtlichen Gr&uuml;nden eine Erkl&auml;rung in gesetzlicher Schriftform ben&ouml;tigen, weisen wir Sie darauf hin. Soweit f&uuml;r die Abgabe solcher Erkl&auml;rungen Kosten bei Ihnen oder dem Endkunden entstehen (z.B. Portokosten), tragen Sie bzw. der Endkunde diese Kosten. Ein Anspruch auf Erstattung dieser Kosten besteht nicht. </span>
                        </li>
                        <li class="li3"><span class="s1">Wir speichern, archivieren und verwahren s&auml;mtliche Unterlagen zum Abschluss und zu der Abwicklung des Versicherungsvertrages Ihres Endkunden ausschlie&szlig;lich in digitaler Form, soweit keine gesetzlichen Bestimmungen entgegenstehen. &Uuml;ber unsere Website erhalten Sie einen Zugang zu den von uns gespeicherten Unterlagen und Daten. </span>
                        </li>
                    </ol>
                </ol>
                <div v-if="modalData.isCourtageVisible && !isAon">
                    <p class="p5"><span
                            class="s1"><strong>Anlage &bdquo;CyberDirekt Makler-Provision&ldquo;</strong></span></p>
                    <p class="p5">CyberDirekt Provisionss&auml;tze: 1. Juni 2022</p>
                    <table class="table" cellspacing="0" cellpadding="0">
                        <tbody>
                        <tr v-for="(el, i) in sortedMargins" :key="i" >
                          <td>
                            {{ el.label }}
                          </td>
                          <td>
                            {{ getDeci(el.default) }} %
                          </td>
                        </tr>
                        </tbody>
                    </table>
                    <p>Die Provisionssätze gelten für über das Maklerportal eingereichte Cyber-Versicherungsverträge. Bei individuellen Angeboten kann es zu abweichenden Provisionssätzen kommen.</p>
                </div>
    </div>
</template>
<script>
  import { mapGetters } from 'vuex'

export default {
    name: 'AGBContent',
    components: {},
    props: {
        isAon: {
            type: Boolean,
            required: true,
        },
        modalData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: '',
        }
    },
    computed: {
        ...mapGetters(['getBrokerMarginsInfo']),
        margins() {
            let insurers = [];

            if(this.isAon && this.getBrokerMarginsInfo) {
                insurers = this.getBrokerMarginsInfo.insurers.filter(insurer => insurer.aon)
            } else if (this.getBrokerMarginsInfo) {
                insurers = this.getBrokerMarginsInfo.insurers.filter(insurer => insurer.default)
            }
            return insurers;
        },
        sortedMargins() {
            const sorted = [...this.margins].sort((a, b) => a.label.localeCompare(b.label))
            return sorted
        }
    },
    async mounted() {
        if (!this.getBrokerMarginsInfo) {
            await this.$store.dispatch('GET_BROKER_MARGIN_INFO')
        } else {
            // console.log('agb mounted else')
        }
    },
    methods: {
        getDeci(num) {
            return num.toFixed(1)
        }
    }
}
</script>