<template>
    <div class="course-item"
         :class="{ 'course-item--inactive': !courseData.active }">
        <div class="course-item__image"
             :style="{ backgroundImage: `url(${courseData.image})` }"></div>
        <div class="course-item__content">
            <div class="course-item__section">{{ courseData.section }}</div>
            <h3 class="course-item__title">{{ courseData.title }}</h3>
        </div>
        <router-link :to="courseData.slug"
                     v-if="isLink && courseData.active"
                     class="course-item__link"
                     append>
        </router-link>

        <div class="course-item__coming-soon"
             v-if="!courseData.active">
            <span class="course-item__coming-soon-text text-center">nicht freigeschaltet</span>
        </div>
    </div>
</template>

<script>
  import Icon from '@/pages/trainings/Icon.vue';

  export default {
    name: 'course-item',
    data() {
      return {
        isAllMarker: false
      };
    },
    components: {
      Icon
    },
    props: {
      courseData: Object,
      isLink: {
        type: Boolean,
        default: false
      }
    },
    watch: {},
    mounted() {
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
    @import '../../scss/trainings-variables';

    .course-item {
        $corner-radius: 7px;

        border-radius: $corner-radius;
        margin-bottom: 15px;
        position: relative;

        &--inactive > * {
            opacity: 0.35;
        }

        &:not(&--inactive):hover {
            box-shadow: 0 2px 5px rgba(darken($secondary, 40%), 0.15);
        }

        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__coming-soon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 1;
        }

        &__coming-soon-text {
            font-size: 1.25rem;
            line-height: 1.75rem;
            position: absolute;
            left: 50%;
            top: 0;
            padding-top: calc(30% - 0.875rem);
            transform: translate(-50%, 0);
            text-shadow: 1px 1px 3px #fff;
            user-select: none;
            color: $secondary;
            font-weight: 600;
            display: inline-block;
        }

        &__image {
            padding-top: 60%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: $corner-radius $corner-radius 0 0;
        }

        &__content {
            padding: 17px 13px 24px;
            border-radius: 0 0 $corner-radius $corner-radius;
            background: #fff;
            border: solid $table-border-color;
            border-width: 0 1px 1px;
        }

        &:hover &__content {
            border-color: lighten($secondary, 40%);
        }

        &__section {
            font-size: 0.715rem;
            line-height: 1.25rem;
            font-weight: 600;
            color: $secondary;
            text-transform: uppercase;
        }

        &__title {
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-weight: 600;
            margin: 0 0 15px;
            color: $dark-blue;
        }

        &__date-holder {
            font-size: 0.8rem;
            line-height: 1.125rem;
            margin-bottom: 15px;
        }

        &__date {
            font-weight: 600;
            text-transform: uppercase;
            color: $primary;
        }

        &__assign {
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: $dark-blue;
            margin-bottom: 3px;
        }

        &__or {
            padding: 0 9px;
            text-transform: uppercase;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $dark-blue;
        }

        &__employee {
            float: left;
            height: 28px;
            width: 28px;
            padding: 4px 2px;
            font-size: 0.7rem;
            line-height: 1.25rem;
            text-align: center;
            color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 2px #fff;
            text-transform: uppercase;

            &--avatar {
                background-color: $primary;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
            }

            & + & {
                margin-left: -8px;
            }

            &--rest {
                background-color: $secondary;
                padding-right: 7px;
            }

            &--action {
                background-color: #fff;
                border: 1px dashed $default-icon-color;
                cursor: pointer;
                padding: 3px;
                font-size: 0;
                line-height: 0;
            }

            &--disabled {
                cursor: default;
                opacity: 0.5;
            }

            &--success {
                border-color: $green;
            }

            & + &--rest,
            & + &--action {
                margin-left: -4px;
            }
        }
    }

</style>
