export default {
  methods: {
    ensureHttpsMixin(link) {
      if (!/^https:\/\//i.test(link)) {
        return `https://${link}`
      }
      return link
    },
  },
}
