<template>
    <div class="container secondary-page">
        <div class="row page-title">
            <div class="col">
                <h1>Phishing-Test – ein echter Mehrwert für Ihre Kunden</h1>
            </div>
            <div class="col-12 col-md-2 back-btn">
                <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    Phishing Mails sind eines der gefährlichsten Cyberrisiken für kleinere und mittlere Unternehmen. Ein
                    falscher Klick kann ausreichen, um einen Schaden mit beträchtlichen finanziellen Auswirkungen zu
                    verursachen.
                </p>
                <p>
                    Bieten Sie einen echten Mehrwert und unterstützen Sie Ihre Kunden, das eigene Cyberrisiko zu
                    reduzieren. Laden Sie Ihren jeweiligen Kunden zu einem 30-tägigen Phishing-Test ein. Der Test ist
                    für Sie als Partner unverbindlich und kostenfrei.
                </p>
                <p>So funktioniert es:</p>
                <ol>
                    <li>Geben Sie zuerst den Namen Ihres Kunden/des Unternehmens ein. Erstellen Sie per Klick auf den
                        Button „Gutschein-Code erstellen“ einen individuellen Gutschein (jeder Code ist nur einmal
                        verwendbar).
                    </li>
                    <li>Senden Sie Ihrem Kunden den Gutschein-Code per Mail.</li>
                    <li>Der Empfänger kann sich auf <a href="https://cyberdirekt.de/phishing-test/" target="_blank"
                                                       rel="noopener">https://cyberdirekt.de/phishing-test/</a>
                        kostenlos und unverbindlich anmelden und den 30-tägigen Test starten.
                    </li>
                    <li>Nach Ablauf erhält der Kunde eine aggregierte Auswertung.</li>
                    <li>Sie als Makler werden in einer gesonderten Mail ebenfalls über den Abschluss des Tests
                        informiert und können Ihren Kunden anschließend auf passende Absicherungsmöglichkeiten
                        ansprechen.
                    </li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                    <label for="comment">Name des Kunden/Unternehmens</label>
                    <input class="form-control"
                           id="comment"
                           name="comment"
                           v-model="comment"
                           type="text">
                    <small>
                        Tragen Sie zur Dokumentation hier den Empfänger des Codes ein
                    </small>
                </div>
                <button class="btn btn-primary mb-5"
                        @click="generate()">Gutschein-Code erstellen
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 table-wrapper">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Gutschein-Code</th>
                        <th>Erstellt am</th>
                        <th>Genutzt</th>
                        <th>Kunde</th>
                        <th>Kommentar</th>
                        <th>Test Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="voucher in vouchers">
                        <td>{{voucher.voucher.code}}</td>
                        <td>{{getDate(voucher.voucher.created)}}</td>
                        <td>
                            <icon name="check" v-if="!voucher.voucher.valid"></icon>
                        </td>
                        <td>
                            <span v-if="voucher.customer && voucher.customer.firstname">
                                {{voucher.customer.firstname}} {{voucher.customer.latname}}
                            </span>
                            <span v-if="voucher.customer.company">, {{voucher.customer.company}}</span>
                        </td>
                        <td>{{voucher.voucher.comment}}</td>
                        <td>
                            <span>{{getState(voucher)}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <BackToTop />
    </div>
</template>

<script>
  import copy from 'clipboard-copy';
  import Icon from '@/components/Icon';
  import VoucherAPI from '@/api/voucher.js';
  import moment from 'moment';
  import BackToTop from '@/components/reusable/BackToTop.vue';

  const today = moment();

  export default {
    name: 'PhishingVouchers',
    metaInfo: {
      title: 'Phishing-Test'
    },
    components: {
      Icon,
      BackToTop
    },
    data() {
      return {
        comment: '',
        vouchers: []
      }
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    beforeRouteEnter(to, from, next) {
      VoucherAPI
        .list()
        .then((response) => {
          next(vm => {
            vm.setVouchers(response.data.sort((a, b) => (new Date(b.created)).getTime() - (new Date(a.created)).getTime()));
          })
        })
        .catch(() => {
          next(false);
        });
    },
    beforeRouteUpdate(to, from, next) {
      VoucherAPI
        .list()
        .then((response) => {
          next(vm => {
            vm.setVouchers(response.data.sort((a, b) => (new Date(b.created)).getTime() - (new Date(a.created)).getTime()));
          })
        })
        .catch(() => {
          next(false);
        });
    },
    methods: {
      getState(voucher) {
        let state = 'nicht gestartet';

        if (voucher.phishing && voucher.phishing.createdAt) {
          if (moment(voucher.phishing.createdAt).add(30, 'days').isBefore(today)) {
            state = 'beendet';
          } else {
            state = 'gestartet';
          }
        }

        return state;
      },
      setVouchers(vouchers) {
        this.vouchers = vouchers;
      },
      generate() {
        VoucherAPI
          .generate(this.user.id, this.comment)
          .then((response) => {
            this.$toasted.show('Ein neuer Gutschein-Code wurde erstellt und in die Zwischenablage kopiert.', {
              theme: 'bubble',
              type:'success',
              position: 'bottom-left',
              duration: 4000
            });

            this.vouchers.unshift({
              voucher: {
                code: response.data.code,
                created: new Date(),
                comment: this.comment,
                valid: true
              },
              customer: {},
              phishing: {}
            });
            this.comment = '';
            
            copy(response.data.code);
          })
          .catch(() => {
            this.$toasted.show('Ein Gutschein-Code konnte nicht erstellt werden. Bitte wenden Sie sich an unseren Support.', {
              theme: 'bubble',
              position: 'bottom-left',
              duration: 4000
            });
          });
      },
      getDate(date) {
        return moment(date).format('DD.MM.YYYY')
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    @import '../scss/base';
    @import '../scss/form';

    .step {
        display: flex;
        flex-direction: column;
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, .25), 0 8px 16px -8px rgba(0, 0, 0, .3), 0 -6px 16px -6px rgba(0, 0, 0, .025);

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        &__image {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        &__text {
            padding: 16px;
            width: 100%;
            color: #444 !important;

            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    p {
        font-size: 1rem;
    }

    .table-wrapper {
      overflow: auto;
    }
</style>
