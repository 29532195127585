<template>
    <div class="row">
        <div class="col-xs-12 col-sm-8">
            <multiple-choice-template :course-slug="selectedCourse.slug"
                                      v-if="currentSlide"
                                      :quiz-data="currentSlide"></multiple-choice-template>
            <quiz-results v-else-if="currentStep >= quizQuestions.length"/>
        </div>
        <div class="col-xs-12 col-sm-4">
            <training-nav></training-nav>
        </div>
    </div>
</template>

<script>
  import TrainingNav from '@/pages/trainings/TrainingNav.vue';
  import MultipleChoiceTemplate from '@/pages/trainings/course-templates/MultipleChoiceTemplate.vue';
  import QuizResults from '@/pages/trainings/course-templates/QuizResults.vue';

  export default {
    name: 'my-training-module',
    components: {
      TrainingNav,
      MultipleChoiceTemplate,
      QuizResults
    },
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse || {};
      },
      selectedCourseProgress() {
        return this.$store.state.selectedCourseProgress || {};
      },
      savedStepIndex() {
        const quiz = this.selectedCourseProgress.slideSlug.match(/quiz-(\d+)/);

        return quiz ? quiz[1] - 1 : -1;
      },
      currentStep() {
        return +this.$route.params.step;
      },
      currentStepIndex() {
        return this.currentStep ? this.currentStep - 1 : -1;
      },
      selectedCourseSlides() {
        return this.selectedCourse.slides || [];
      },
      quizQuestions() {
        return this.selectedCourseSlides.filter(slide => slide.group === 'quiz');
      },
      currentSlide() {
        return this.quizQuestions[this.currentStepIndex];
      }
    },
    beforeRouteUpdate(to, from, next) {
      const stepFrom = +from.params.step;
      const stepTo = +to.params.step;

      if (stepTo === 1 && this.savedStepIndex === 0) return next();

      if ((stepFrom > stepTo && this.savedStepIndex > -1) || (stepTo > 1 && this.savedStepIndex === -1)) {
        return window.history.forward();
      }

      next();
    }
  }
</script>

<style lang="scss">

</style>
