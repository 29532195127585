<template>
  <div>
    
    <div class="container">
      <div class="row">
        <div class="col-12 columns">
          <h1>Admin Console</h1>
        </div>
      </div>
  
      <div class="row" id="invite_form">
        <div class="col-12 columns">
          <form @submit.prevent.stop="inviteUser()">
            <fieldset>
              <legend>Invite Broker</legend>
              <label for="email">E-mail *</label>
              <input type="email" name="email" v-model="email" required>
              <button type="submit" class="btn-style primary" :class="{ loading: inviteLoading }">
                <span>Invite</span>
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Admin',
  data () {
    return {
      email: '',
      inviteLoading: false
    }
  },
  methods: {
    inviteUser: function () {
      this.inviteLoading = true
      return this.$http.post('/user/invite', { email: this.email })
        .then(res => {
          if (!res.data || res.data.status !== 'OK') {
            return false
          }
          const email = this.email
          this.email = ''

          return this.$toasted.show(`Einladung an ${email} versandt`, {
            theme: 'bubble',
            type:'success',
            position: 'bottom-left',
            duration: 4000
          });
        })
        .then(() => {
          this.inviteLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.container {
  margin-top: $standardGutter;
}
.row {
  text-align: center;
}

#invite_form {
  margin-top: $standardGutter;
}


</style>
