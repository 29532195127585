<template>
  <div>
    <transition name="sfade">
      <div v-if="$route.name === 'Risikofragen' || $route.name === 'Risikofragen2'" id="progress_bar">
        <div class="container">
          <div class="row">
            <div class="col-12 columns">
              <div class="bar" :style="getProgess"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="container">
      <div class="row" id="titles" v-if="!$route.meta.noTitles">
        <div class="col-12 columns">
          <h1 v-if="$route.meta.title">{{$route.meta.title}}</h1>
          <h2 v-if="$route.meta.description">{{$route.meta.description}}</h2>
        </div>
      </div>
    </div>

    <div class="container">
      <router-view v-if="user" :attackVectors="attackVectors" :key="uniqueKey()">
      </router-view>
    </div>
  </div>
</template>

<script>
  import attackVectors from '@/components/shared/AttackVectors'
  import attackVectors2 from '@/components/shared/AttackVectors2'
  import Modal from '@/components/Modal'
  import eventBus from '@/components/shared/EventBus.js'
  import _ from 'lodash'

  export default {
    name: 'Beginnen',
    props: ['user'],
    data() {
      return {}
    },
    computed: {
      attackVectors() {
        return this.$route.name === 'Risikofragen2' ? attackVectors2 : attackVectors;
      },
      getProgess: function () {
        const vector = _.find(this.attackVectors, {id: this.$route.params.id.toString()})
        const index = this.attackVectors.indexOf(vector)
        const width = (index / this.attackVectors.length) * 100;

        return {
          width: width + '%'
        }
      }
    },
    mounted: function () {
      eventBus.$on('nextPage', this.nextPage)
    },
    methods: {
      uniqueKey: function () {
        const remountDisabled = this.$route.meta.disableMounting

        if (remountDisabled) {
          return ''
        }
        return this.$route.params.id || this.$route.name
      },
      emitNextPage: function () {
        eventBus.$emit('nextPageHit')
      },
      nextPage: function (result) {
        let finalRoute;

        // If an object is brought then we are assuming it is a successful hit.
        if (!_.isObject(result) && !result) {
          return false
        }

        if (_.isObject(result) && result.route) {
          finalRoute = result.route;
        } else {
          finalRoute = {name: this.$route.meta.nextPageName}
        }

        return this.$router.push(finalRoute)
      }
    },
    destroyed: function () {
      /* istanbul ignore next */
      eventBus.$off('nextPage')
    },
    components: {
      Modal
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scss/variables';

  .sfade-enter-active, .sfade-leave-active {
    transition: opacity .4s ease-in-out;
    .bar {
      width: 100% !important;
    }
  }

  .sfade-enter, .sfade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  #titles {
    margin-top: $standardGutter;
    margin-bottom: $standardGutter;
  }

  #progress_bar {
    padding-top: 20px;

    .container {
      > div {
        height: 9px;
        background: #e6e6e6;
        position: relative;
        overflow: hidden;
        border-radius: $borderRadius;
        .bar {
          transition: width .3s ease-in-out;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0;
          background: $blueTone;
        }
      }
    }

  }

  #ctas {
    margin-top: $standardGutter;
    margin-bottom: $standardGutter;
    height: 100%;

    a.btn-style {
      border: 0;
    }
    .columns {
      display: flex;
      align-items: center;
      height: 66px;

      a.btn-style:not(.primary) {
        // padding-top: 0;
        // padding-bottom: 0;
        // margin-top: 0;
        background: transparent;
      }
    }
    .skip {
      text-align: center;
      justify-content: center;
      align-items: center;
      a:not(.btn-style) {
        color: $greyTone;
      }
    }
    > div:last-child {
      justify-content: flex-end;
    }
  }

  .modal-view ::v-deep .modal-container {
    transition: all .3s ease;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity .3s ease;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter ::v-deep .modal-container,
  .modal-leave-active ::v-deep .modal-container {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

</style>
