<template>
    <form v-on:submit.prevent.stop="submitForm()" name="step1" id="step_form">
        <div class="row mb-2">
            <div class="col-12">
                <h2 class="text-center">{{title}}</h2>
            </div>
        </div>
        <div class="row mb-3 mb-lg-5">
            <div class="col-12">
                <div class="revenue-text text-center m-auto"
                     :class="{ 'text-grey': selectedRevenueIndex === 0 && selectedRange=== 'small' }">
                  {{getRevenueText(selectedRevenueIndex)}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0 slider-wrap-col">
                <vue-slider :height="10"
                            :dot-size="28"
                            tooltip="none"
                            :data="sliderData"
                            :marks="true"
                            show
                            piecewise-label
                            v-model="selectedRevenueIndex"
                            v-validate="{ required: true}"
                            ref="slider">
                    <template slot="label"
                              slot-scope="{ label, active }">
             <span :class="['custom-label', { active }]">
              {{ sliderValues[label].label }}
             </span>
                    </template>
                </vue-slider>
            </div>
        </div>
        <div class="row" v-if="highRevAvailable">
          <div class="col-12 revenue-range-buttons">
            <button type="button" class="btn"
                    v-on:click="toggleRange('small')"
                    :class="{
                    'btn-secondary': selectedRange=== 'small',
                    'btn-not-selected': selectedRange !== 'small'
                  }">&lt; 10 Mio
            </button>
            <button type="button" class="btn"
                    v-on:click="toggleRange('middle')"
                    :class="{
                    'btn-secondary': selectedRange=== 'middle',
                    'btn-not-selected': selectedRange !== 'middle'
                  }">&lt; 50 Mio
            </button>
            <button type="button" class="btn"
                    v-on:click="toggleRange('large')"
                    :class="{
                    'btn-secondary': selectedRange=== 'large',
                    'btn-not-selected': selectedRange !== 'large'
                  }">&lt; 100 Mio
            </button>
          </div>
        </div>
        <input type="submit" id="step_submit">
        <modal v-if="showLeaveModal" @close="showLeaveModal = false" modal-style="mobile">
            <h3 slot="header" class="mobile-method-selector__modal-title">
                Aufgrund der Höhe Ihres Jahresumsatzes, ist eine individuelle Beratung erforderlich.
            </h3>
            <div slot="body">
                <div v-if="!modalSuccess">
                    <div class="form-group" :class="getFieldClass('modal_name')">
                        <label for="modal_name">Name</label>
                        <input class="form-control"
                               type="text"
                               id="modal_name"
                               name="modal_name"
                               v-validate="{ required: true }"
                               v-model="contactFormData.name">
                    </div>
                    <!-- <div class="form-group" :class="getFieldClass('modal_phone')">
                        <label for="modal_phone">Telefonnummer</label>
                        <phone-resolver class="phone-resolver-container"
                          id="modal_phone"
                          name="modal_phone"
                          v-model="contactFormData.phone"
                          required>
                        </phone-resolver>
                    </div> -->
                    <PhoneForm
                      name="Mobiltelefon"
                      v-model='contactFormData.phone'
                      @numberChange="updatePhoneNumber"
                      v-validate="{ required: true }"
                    />
                    <div class="form-group" :class="getFieldClass('modal_mail')">
                        <label for="modal_mail">E-Mail</label>
                        <input class="form-control"
                               type="text"
                               id="modal_mail"
                               name="modal_mail"
                               v-validate="{ required: true, email: true }"
                               v-model="contactFormData.email">
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox"
                               class="custom-control-input"
                               id="customCheck1"
                               v-model="contactFormData.legalCheck">
                        <label class="custom-control-label" for="customCheck1">
                            Ich stimme den
                            <router-link :to="'./datenschutzhinweise'" target="_blank">Datenschutzhinweisen
                            </router-link>
                            zu
                        </label>
                    </div>
                </div>
                <div v-else>
                    <p class="text-center">Vielen Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen melden.</p>
                </div>
            </div>
            <button class="btn btn-block btn-secondary"
                    type="button"
                    @click="sendSubmission()"
                    :disabled="!contactFormData.legalCheck || modalSuccess"
                    slot="footer">Ich möchte ein Angebot erhalten
            </button>
        </modal>
    </form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import eventBus from '@/components/shared/EventBus.js'
  import StringHelper from '../StringHelper';
  import vueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/default.css'
  import Modal from '../Modal';
  import PhoneForm from './shared/PhoneForm'
  import isStepAllowed from '../mixins/isStepAllowed.js'

  export default {
    name: 'StepBroker',
    components: {
      vueSlider,
      Modal,
      PhoneForm
    },
    data() {
      return {
        telOptions: {
          placeholder: 'Telefonnummer',
          allowedTelCountries: ['DE', 'AT', 'CH']
        },
        selectedRevenueIndex: 0,
        selectedRange: 'small',
        contactFormData: {
          name: '',
          phone: '',
          email: '',
          legalCheck: false
        },
        modalSuccess: false,
        showLeaveModal: false,
        noInsurersForReevenueText: StringHelper.get(this.$store, 'STEP_2_NO_INSURERS_FOR_REVENUE_TEXT', false)
      }
    },
    computed: {
      ...mapGetters([
        'isWWW',
        'isBroker',
        'style',
        'user'
      ]),
      title() {
        return StringHelper.get(this.$store, 'STEP_1B_TITLE');
      },
      windowWidth() {
        let width
        if (window) {
          width = window.innerWidth
        }
        return width
      },
      xsBreakpoint () {
        return (this.$mq === "MQ1" || this.$mq === "xs" || this.windowWidth < 576) ? true : false
      },
      sliderValues() {
        const selectedRange = this.selectedRange || 'small'
        const ranges = {
          small: [
            {value: 0, label: '0'},
            {value: 100000, label: this.xsBreakpoint ? '' : '100 Tsd'},
            {value: 150000, label: ''},
            {value: 250000, label: this.xsBreakpoint ? '250 Tsd' : ''},
            {value: 500000, label: this.xsBreakpoint ? '' : '500 Tsd'},
            {value: 750000, label: ''},
            {value: 1000000, label: '1 Mio'},
            {value: 1500000, label: ''},
            {value: 2000000, label: ''},
            {value: 2500000, label: this.xsBreakpoint ? '' : '2.5 Mio'},
            {value: 3000000, label: this.xsBreakpoint ? '3 Mio' : ''},
            {value: 4000000, label: ''},
            {value: 5000000, label: this.xsBreakpoint ? '' : '5 Mio'},
            {value: 7500000, label: ''},
            {value: 10000000, label: '10 Mio'}
          ],
          middle: [
          {value: 10000000, label: '10 Mio'},
          {value: 12500000, label: ''},
          {value: 15000000, label: this.xsBreakpoint ? '' : '15 Mio'},
          {value: 17500000, label: ''},
          {value: 20000000, label: '20 Mio'},
          {value: 22500000, label: ''},
          {value: 25000000, label: this.xsBreakpoint ? '' : '25 Mio'},
          {value: 27500000, label: ''},
          {value: 30000000, label: '30 Mio'},
          {value: 32500000, label: ''},
          {value: 35000000, label: this.xsBreakpoint ? '' : '35 Mio'},
          {value: 37500000, label: ''},
          {value: 40000000, label: '40 Mio'},
          {value: 42500000, label: ''},
          {value: 45000000, label: this.xsBreakpoint ? '' : '45 Mio'},
          {value: 47500000, label: ''},
          {value: 50000000, label: '50 Mio'}
          ],
          large: [
            { value: 50000000, label: '50 Mio' },
            { value: 55000000 },
            { value: 60000000, label: this.xsBreakpoint ? '' : '60 Mio' },
            { value: 65000000 },
            { value: 70000000, label: '70 Mio' },
            { value: 75000000 },
            { value: 80000000, label: this.xsBreakpoint ? '' : '80 Mio' },
            { value: 85000000 },
            { value: 90000000, label: this.xsBreakpoint ? '' : '90 Mio' },
            { value: 95000000 },
            { value: 100000000, label: '100 Mio' }
          ]
        };

        return ranges[selectedRange]
      },
      sliderData() {
        return Array.from(this.sliderValues.keys());
      },
      inputs() {
        return this.$store.state.checkout.application.inputs;
      },
      highRevAvailable() {
        let check = true;

        if (this.$store.state.user) {
          check = (this.$store.state.user.agency !== 'low-rev');
        }

        return check;
      }
    },
    props: [ 'step' ],
    mounted: function () {
      eventBus.$emit('stepChangeCompleted');

      eventBus.$on('submitStep', () => {
        this.$store.commit('SET_CHECKOUT_LOADING_OVERLAY', true)
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);

        this.$store.dispatch('CALCULATE_CYBER_PRICES').then((data) => {
          this.$store.commit('SET_CHECKOUT_LOADING_OVERLAY', false)

          if (Object.keys(data).length) {
            document.getElementById('step_submit').click();
          } else {
            // if no insurers do not submit next step and open popup
            this.$store.commit('SET_CHECKOUT_LOADING_OVERLAY', false)
            eventBus.$emit('alertUser', {
              type: 'info',
              title: null,
              message: this.noInsurersForReevenueText,
              customCloseBtnText: 'Ok',
              customCloseBtnClass: 'btn',
              customConfirmBtnText: 'Kontaktieren Sie uns jetzt für ein individuelles Angebot',
              customConfirmBtnClass: 'btn btn--primary',
              useConfirmBtn: true,
              onConfirm: () => {
                window.open('https://www.cyberdirekt.de/makler-kontakt/', '_blank')
              }
            })
          }
        })

      });

      this.$nextTick(function () {
        if (this.inputs.netTurnOver <= 10000000) {
          this.selectedRange = 'small';
        } else if (this.inputs.netTurnOver > 10000000 && this.inputs.netTurnOver <= 50000000) {
          this.selectedRange = 'middle';
        } else {
          this.selectedRange = 'large';
        }
        this.selectedRevenueIndex = this.sliderValues.findIndex(sliderValue => sliderValue.value === this.inputs.netTurnOver);
      });

      this.contactFormData.email = this.inputs.email || '';
    },
    watch: {
      selectedRevenueIndex: function (selectedRevenueIndex) {
        const selectedRevenue = this.sliderValues[ selectedRevenueIndex ].value;
        let deductible = 1000;
        let insuredSum = 250000;

        if (selectedRevenue) {
          if (selectedRevenue < 1000000) {
            insuredSum = 250000;
          } else if (selectedRevenue < 5000000) {
            insuredSum = 500000;
          } else if (selectedRevenue < 10000000) {
            insuredSum = 1000000;
          } else if (selectedRevenue < 15000000) {
            insuredSum = 1000000;
            deductible = 1000;
          } else if (selectedRevenue < 25000000) {
            insuredSum = 2000000;
            deductible = 2500;
          } else {
            insuredSum = 3000000;
            deductible = 5000;
          }

          this.$store.dispatch('UPDATE_INPUT_VALUES', {
            deductible,
            insuredSum,
            netTurnOver: this.sliderValues[ selectedRevenueIndex ].value
          });
        }
      }
    },
    methods: {
      updatePhoneNumber (res) {
        this.contactFormData.phone = res.number;
      },
      submitForm: function () {
        this.inputs.netTurnOver = !this.isBroker && this.inputs.netTurnOver <= 0 ? 100000 : this.inputs.netTurnOver;

        if (!this.inputs.netTurnOver) {
          return eventBus.$emit('alertUser', {
            type: 'error',
            title: 'Bitte wählen Sie Ihren Jahresumsatz.',
            message: '',
            customCloseBtnText: 'OK',
            customCloseBtnClass: 'btn btn-primary'
          })
        }

        if (this.$analytics) {
          this.$analytics.track(this.$route.path, {
            label: 'netTurnOver',
            value: this.inputs.netTurnOver
          });
        }

        if (this.inputs.netTurnOver <= 100000000) {
          this.step.completed = true;
          return eventBus.$emit('changeStepForward', this.step);
        } else {
          this.showLeaveModal = true;
        }
      },
      sendSubmission() {
        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              this
                .$store
                .dispatch('INSERT_SUBMISSION', {
                  topic: 'revenueOver10Mio',
                  name: this.contactFormData.name,
                  phone: this.contactFormData.phone,
                  email: this.contactFormData.email,
                  meta: {
                    industry: this.inputs.industry
                  }
                })
                .then(() => {
                  this.modalSuccess = true;
                })
                .catch(() => {
                  eventBus.$emit('alertUser', {
                    type: 'error',
                    message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.',
                    customCloseBtnText: 'OK',
                    customCloseBtnClass: 'btn btn-primary '
                  })
                })
            }
          });
      },
      getRevenueText(index) {
        const currency = this.$options.filters.currency;

        if (index === 0 && this.selectedRange === 'small') {
          return currency(0, 0);
        } else if (index === 0) {
          return currency(this.sliderValues[index].value, 0)
        } else {
          return currency(this.sliderValues[index - 1].value, 0, '') + ' - ' + currency(this.sliderValues[index].value, 0);
        }
      },
      getFieldClass(fieldName) {
        if (!this.fields[ fieldName ]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[ fieldName ].dirty
        }
      },
      toggleRange(value) {
        if (this.selectedRange === value) return;
        this.selectedRange = value;
        this.selectedRevenueIndex = 0;
        this.$refs.slider.setValue(0);
      },
    },
    destroyed: function () {
      eventBus.$off('submitStep')
    },
    mixins: [isStepAllowed]
  }
</script>

<style lang="scss">
    @import '../scss/variables';
    @import '../scss/form';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .btn-secondary {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }

    .btn-not-selected {
      color: $primary !important;
      background-color: transparent;
      background-image: none;
      border: 2px solid $primary !important;
    }

    .phone-resolver-container {
      border: none;
      padding-top: 0;
      padding-left: 0;
    }

    .slider-wrap-col {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;

      @include media-breakpoint-down(sm) {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
      }
    }

    .vue-slider-dot {
        border: 8px solid #fff !important;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        border-radius: 50%;
        background-color: $secondary;
    }

    .vue-slider-dot-handle {
      background-color: $secondary !important;
    }

    .vue-slider {
        // background: $lightGrey !important;
        // border: 1px solid #E7E7E7 !important;
    }

    .vue-slider-rail {
      background: $lightGrey !important;
      border: 1px solid #E7E7E7 !important;
    }

    .vue-slider-mark-step {
      background: none;
    }

    .custom-label {
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 24px;
        transform: translate(-50%, 50px);
        margin-left: 6px;
        color: #BDBBBD;
        font-weight: bold;
        white-space: nowrap;
    }

    .custom-label:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -10px);
        width: 1px;
        height: 7px;
        background-color: #BDBBBD;
    }
</style>

<style lang="scss" scoped>
    @import '../scss/variables';

    h2, form {
        margin-bottom: 40px;
    }

    h2 {
        font-weight: 700;
    }

    .btn-not-selected {
        color: $primary;
        background-color: transparent;
        background-image: none;
        border-color: $primary;
    }

    .revenue-text {
        font-size: 1.8rem;
        font-weight: bold;
        color: $darkBlueTone;
        width: fit-content;
        border-radius: 7px;
        padding: 5px 10px;
        &.text-grey {
            color: #d7d7d7;
        }
    }

    .revenue-range-buttons {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .btn {
        width: 108px;
        display: block;

        + .btn {
          margin-top: 8px;
        }
      }
    }


</style>
