<template>
  <div class="row presse-article-tile">
    <div class="col-12 col-md-6 img-col">
      <div class="presse-article-tile__image-wrap">
        <img class="presse-article-tile__image" :src="getIcon()" :alt="articleData.title">
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="presse-article-tile__content h-100">
        <div class="row h-100">
          <h4 class="presse-article-tile__section col-6 align-self-baseline">{{ articleData.category }}</h4>
          <div class="presse-article-tile__date-holder col-6 align-self-baseline" v-if="articleData.publishedDate">
            <span class="presse-article-tile__date">{{ articleData.publishedDate | formatDate }}</span>
          </div>
          <div class="col-12">
            <h2 class="presse-article-tile__title align-self-baseline">{{ articleData.title }}</h2>
            <p class="presse-article-tile__text">
              {{ articleData.shortTextPreview }}
            </p>
          </div>
          <div class="col-12 mt-3 align-self-end">
            <button class="btn btn-style secondary" type="button" @click="changeLocation()">
              <span v-if="articleData.externalLink"> Zur Website </span>
              <span v-else> Weiter Lesen </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formats from '../helpers/formats'

export default {
  name: 'presseArticleTile',
  props: {
    articleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cyberRisks: []
    }
  },
  methods: {
    changeLocation() {
      if (this.articleData.externalLink) {
        window.open(this.articleData.externalLink, "_blank");
      } else {
        window.open(`/presse/${this.articleData.slug}`, "_blank");
      }
    },
    getIcon() {
      const url = formats(this.articleData.thumbnail, 'small')
      return url
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.presse-article-tile {
  $corner-radius: 7px;
  $table-border-color: $greyTone;

  border-radius: $corner-radius;
  margin-bottom: 80px;


  &:not(&--inactive):hover {
    transform: scale(1.01);
    transition: all 0.2s;
    .presse-article-tile__image {
      transition: all 0.6s;
    }
  }

  .img-col {
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }
  }

  &__image-wrap {
    border-radius: $corner-radius $corner-radius $corner-radius $corner-radius;

    @include media-breakpoint-down(md) {
      border-radius: $corner-radius $corner-radius 0 0;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      border: 1px solid $table-border-color;
      border-bottom: 0;
    }
  }

  &__image {
    width: 100%;
    border-radius: $corner-radius $corner-radius $corner-radius $corner-radius;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      border-radius: $corner-radius $corner-radius 0 0;
    }
  }

  &__content {
    border-radius: 0 0 $corner-radius $corner-radius;
    @include media-breakpoint-down(sm) {
      border: solid $table-border-color;
      border-width: 0 1px 1px;
      padding: 1.5rem 1rem;
    }
  }

  &__title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 0 0 15px;
  }

  &__section {
    font-size: 0.715rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: $secondary;
    text-transform: uppercase;
  }

  &__date-holder {
    font-size: 0.8rem;
    line-height: 1.125rem;
    margin-bottom: 15px;
    text-align: right;
  }

  &__date {
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }
}
</style>
