<template>
  <div>
    <!-- <cd-hero :header="header"></cd-hero> -->
    <div id="customer_letters_lp" class="secondary-page">
      <div class="container">
        <div class="row page-title">
          <div class="col">
            <h1>Pr&auml;sentationen</h1>
          </div>

          <div class="col-12 col-md-2 back-btn">
            <a class="btn-style" href="" @click.prevent.stop="takeMeBack()">&larr;Zurück</a>
          </div>
        </div>

        <div class="row pdf-std-module" v-for="(item, iIndex) in presentations" :key="iIndex" >
          <div class="col-12 col-md-5 std-pdf-preview" :class="item.slug" @click="downloadPresentation(item.presentation)">
            <div class="l-container">
              <make-thumb :url="item.url"></make-thumb>
              <div class="title-area"><h4></h4></div>
            </div>
          </div>

          <div class="col-12 col-md-7 markt-content">
            <div>
              <h4>{{item.title}}</h4>

            <a href="" @click.stop.prevent="downloadPresentation(item.presentation)">Download <i class="ion-android-download"></i></a>

              <!-- <h6>Cyber-Risiken in Unternehmen</h6> -->

              <!-- <ul>
                <li>Katrin Rohmann</li>
                <li>Peter J. Wirnsperger</li>
              </ul> -->
            </div>

          </div>
        </div>

      </div> <!-- end container -->

    </div>

  </div>
</template>

<script>
import Icon from './Icon'
import MakeThumb from '@/components/shared/MakeThumb'

export default {
  name: 'CustomerLetters',
  metaInfo: {
    title: 'Präsentationen'
  },
  data () {
    return {
      presentations: [{
        slug: 'doc-1',
        url: '/static/pdf/marktstudien/presentations/CyberDirekt_Vorlage_Prasentation-pdf.pdf',
        presentation: '/static/pdf/marktstudien/presentations/CyberDirekt_Vorlage_Prasentation.pptx',
        title: 'Vorstellung Cyber-Versicherung'
      }]
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    downloadPresentation (url) {
      window.open(url, '_blank')
    },
    takeMeBack () {
      this.$router.push('/vertriebsmaterialien')
    }
  },
  components: {
    Icon,
    MakeThumb
  }
}
</script>

<style lang="scss" scoped>

@import '../scss/variables';
@import '../scss/mixins';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.letter-btk {
  margin-top: calc($standardGutter * 2);
}

.doc-1 {
  .l-container {
    > div:first-child {
      background-position-y: -60px;
    }
  }
}
</style>
