<template>
  <label class="wrapper" :class="{'wrapper--disabled': disabled}">
    <div class="switch">
      <input type="checkbox" v-model="innerValue" :disabled="disabled">
      <span class="slider round"></span>
    </div>
    <div class="label">
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default() {return false}
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';
.wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;

  &--disabled {
    opacity: 0.4;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  min-width: 2.15rem;
  height: 1.15rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.8rem;
   width: 0.8rem;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(0.85rem);
  -ms-transform: translateX(0.85rem);
  transform: translateX(0.85rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label {
  // position: absolute;
  // left: 3rem;
  // top: 0;
}
</style>
