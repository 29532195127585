const strings = {
  www: {
    STEP_1_TITLE: 'In 5 Minuten zu Ihrer individuellen Cyberversicherung',
    STEP_1_INDUSTRY_HELP_TEXT: 'Wenn Ihre Branche nicht aufgelistet ist, wählen Sie bitte "Sonstiges".',
    STEP_1B_TITLE: 'Bitte geben Sie den jährlichen Nettoumsatz an.',
    STEP_1C_ONLINE_REVENUE_1: 'Erwirtschaften Sie Umsatz online?',
    STEP_1C_ONLINE_REVENUE_2: 'Erwirtschaften Sie min. 1 Mio Ihres Umsatz online?',
    STEP_1C_ONLINE_REVENUE_3: 'Erwirtschaften Sie mehr als 50% Ihres Umsatzes online?',
    STEP_1C_ONLINE_REVENUE_4: 'Setzen Sie einen Dienstleister zum Betrieb Ihres Webshops ein?',
    STEP_1C_ONLINE_REVENUE_5: 'Setzen Sie einen Payment-Dienstleister zur Abwicklung aller eingehenden bargeldlosen Zahlungsvorgänge ein?',
    STEP_2_NO_INSURERS_TEXT: '<p>Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.</p> <p class="mb-2">Ändern Sie ihre Angaben und versuchen Sie es erneut.</p>',
    STEP_2_NO_INSURERS_FOR_REVENUE_TEXT: 'Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.',
    STEP_2_NO_DEDUCTIBLE:"Bitte wählen Sie eine andere Option",
    STEP_3_TITLE: 'Bitte wählen Sie die gewünschte Höhe der Absicherung für Ihr Unternehmen',
    STEP_3_DEDUCTIBLE: 'Bitte wählen Sie Selbstbehalt und Zahlungsweise aus',
    STEP_4_TITLE: 'Sie können Ihren Antrag jetzt abschließen.',
    STEP_4_COMPANY_DATA: 'Bitte geben Sie Ihre amtlichen Firmendaten ein. Bitte verwenden Sie keine Abkürzungen.',
    OFFER_REASON: 'vielen Dank für Ihr Interesse an einer Cyber-Versicherung. Anbei erhalten Sie meine Empfehlung für den Tarif von {insurer} und die zugrunde liegenden Tarifmerkmale, welche ich beim Vergleich mehrerer Anbieter berücksichtigt habe.',
    OFFER_COMMENT: 'Um den Antrag abzuschließen, bitte ich Sie, die Risikofragen des Versicherers vorab zu beantworten. Wenn Sie hierbei Unterstützung benötigen, rufen Sie mich gerne an.',
    OFFER_REASON_NO_RECOMMENDATION: 'vielen Dank für Ihr Interesse an einer Cyber-Versicherung. Anbei erhalten Sie ein Angebot, nebst Leistungsübersicht und den Versicherungsbedingungen.',
    OFFER_COMMENT_NO_RECOMMENDATION: ''
  },
  broker: {
    STEP_1_TITLE: 'Cyberversicherung in 5 Minuten beantragen',
    STEP_1_INDUSTRY_HELP_TEXT: 'Wenn die Branche Ihres Kunden nicht aufgelistet ist, wählen Sie bitte "Sonstiges".',
    STEP_1B_TITLE: 'Bitte geben Sie den jährlichen Nettoumsatz an.',
    STEP_1C_ONLINE_REVENUE_1: 'Erwirtschaftet das Unternehmen Umsatz online?',
    STEP_1C_ONLINE_REVENUE_2: 'Wird min. 1 Mio des Umsatz online erwirtschaftet?',
    STEP_1C_ONLINE_REVENUE_3: 'Wird mehr als 50% des Umsatzes online erwirtschaftet?',
    STEP_1C_ONLINE_REVENUE_4: 'Setzt das Unternehmen zum Betrieb des Webshops einen Dienstleister ein?',
    STEP_1C_ONLINE_REVENUE_5: 'Wird zur Abwicklung aller eingehender bargeldloser Zahlungsvorgänge ein Dienstleister eingesetzt?',
    STEP_2_NO_INSURERS_TEXT: '<p>Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.</p> <p class="mb-2">Ändern Sie ihre Angaben und versuchen Sie es erneut.</p>',
    STEP_2_NO_INSURERS_FOR_REVENUE_TEXT: 'Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.',
    STEP_2_NO_DEDUCTIBLE:"Bitte wählen Sie eine andere Option",
    STEP_3_TITLE: 'Bitte wählen Sie die Versicherungssumme',
    STEP_3_DEDUCTIBLE: 'Bitte wählen Sie Selbstbehalt und Zahlungsweise aus',
    STEP_4_TITLE: 'Sie können den Antrag jetzt abschließen.',
    STEP_4_COMPANY_DATA: 'Bitte geben Sie die amtlichen Firmendaten ein. Bitte verwenden Sie keine Abkürzungen.'
  },
  affiliate: {
    STEP_1_TITLE: 'Jetzt Angebot erhalten',
    STEP_1_INDUSTRY_HELP_TEXT: 'Wenn Ihre Branche nicht aufgelistet ist, wählen Sie bitte "Sonstiges".',
    STEP_1B_TITLE: 'Bitte geben Sie den jährlichen Nettoumsatz an.',
    STEP_1C_ONLINE_REVENUE_1: 'Erwirtschaften Sie Umsatz online?',
    STEP_1C_ONLINE_REVENUE_2: 'Erwirtschaften Sie min. 1 Mio Ihres Umsatz online?',
    STEP_1C_ONLINE_REVENUE_3: 'Erwirtschaften Sie mehr als 50% Ihres Umsatzes online?',
    STEP_1C_ONLINE_REVENUE_4: 'Setzen Sie einen Dienstleister zum Betrieb Ihres Webshops ein?',
    STEP_1C_ONLINE_REVENUE_5: 'Setzen Sie einen Payment-Dienstleister zur Abwicklung aller eingehenden bargeldlosen Zahlungsvorgänge ein?',
    STEP_2_NO_INSURERS_TEXT: '<p>Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.</p> <p class="mb-2">Ändern Sie ihre Angaben und versuchen Sie es erneut.</p>',
    STEP_2_NO_INSURERS_FOR_REVENUE_TEXT: 'Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.',
    STEP_2_NO_DEDUCTIBLE:"Bitte wählen Sie eine andere Option",
    STEP_3_TITLE: 'Bitte wählen Sie die gewünschte Höhe der Absicherung für Ihr Unternehmen',
    STEP_3_DEDUCTIBLE: 'Bitte wählen Sie Selbstbehalt und Zahlungsweise aus',
  },
  'affiliate-synaxon': {
    STEP_1_TITLE: 'Jetzt Angebot erhalten',
    STEP_1_INDUSTRY_HELP_TEXT: 'Wenn Ihre Branche nicht aufgelistet ist, wählen Sie bitte "Sonstiges".',
    STEP_1_ADDITIONAL_TEXT: 'Sie sind Managed-Service-Kunde der einsnulleins GmbH oder eines Partners der SYNAXON AG? – dann können Sie hier den Versicherungsbeitrag für eine exklusive Cyber-Versicherung berechnen.',
    STEP_1B_TITLE: 'Bitte geben Sie den jährlichen Nettoumsatz an.',
    STEP_1C_ONLINE_REVENUE_1: 'Erwirtschaften Sie Umsatz online?',
    STEP_1C_ONLINE_REVENUE_2: 'Erwirtschaften Sie min. 1 Mio Ihres Umsatz online?',
    STEP_1C_ONLINE_REVENUE_3: 'Erwirtschaften Sie mehr als 50% Ihres Umsatzes online?',
    STEP_1C_ONLINE_REVENUE_4: 'Setzen Sie einen Dienstleister zum Betrieb Ihres Webshops ein?',
    STEP_1C_ONLINE_REVENUE_5: 'Setzen Sie einen Payment-Dienstleister zur Abwicklung aller eingehenden bargeldlosen Zahlungsvorgänge ein?',
    STEP_2_NO_INSURERS_TEXT: 'Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich. Bitte schreiben Sie uns eine Nachricht an <a href="mailto:info@pma-assekuranzmakler.de">info@pma-assekuranzmakler.de</a> oder rufen Sie uns unter <a href="tel:02513845000800">0251-3845000800</a> an.',
    STEP_2_NO_INSURERS_FOR_REVENUE_TEXT: 'Für die gewählte Kombination aus Branche und Jahresumsatz ist leider keine automatische Angebotsberechnung möglich.',
    STEP_2_NO_DEDUCTIBLE:"Bitte wählen Sie eine andere Option",
    STEP_3_TITLE: 'Bitte wählen Sie die gewünschte Höhe der Absicherung für Ihr Unternehmen',
    STEP_3_DEDUCTIBLE: 'Bitte wählen Sie Selbstbehalt und Zahlungsweise aus'
  }
};

const StringHelper = {
  get(store, key, isCommon, replaceList) {
    const mode = store.state.checkout.application.mode || 'www';
    const affiliateBroker = store.state.checkout.application.affiliateBroker || null
    const style = isCommon ? 'www': affiliateBroker && affiliateBroker.agency === 'synaxon' ? `${store.state.checkout.application.style}-${affiliateBroker && affiliateBroker.agency}` : store.state.checkout.application.style;
    let str = '';

    if (style && strings[style] && (strings[style][key] || strings[style][key] === '')) {
      str = strings[style][key]
    } else {
      str = strings[mode][key] || 'STRING-NOT-FOUND';
    }

    if (replaceList) {
      Object.keys(replaceList).forEach((replaceKey) => {
        str = str.replace(new RegExp(`{${replaceKey}}`, 'gi'), replaceList[replaceKey]);
      });
    }

    return str;
  }
};

export default StringHelper;
