export default {
  methods: {
    removeUnusedFormsMixin(questions, answers, forms) {
      const filteredForms = {}
      for (const key in forms) {
        // find the corresponding answer
        let answer = undefined
        for (const k in answers) {
          if (k === `q-${key}`) {
            answer = answers[k]
            break
          }
        }

        // find the question from the TR20 data
        const question = questions.find((q) => q.id === key)
        // does the question have a form?
        if (question && question.form) {
          for (const acceptedAnswer in question.form) {
            // we only validate yes and no questions, everything else stays by default
            if (acceptedAnswer === 'yes' || acceptedAnswer === 'no') {
              if (answer === acceptedAnswer) {
                filteredForms[key] = forms[key]
              } else {
                // console.log('answer does not match', acceptedAnswer) 
              }
            } else {
              filteredForms[key] = forms[key]
            }
          }
        }
      }
      return filteredForms
    },
  },
}
