<template>
  <div class="card-container" :class="[`max-${maxCols}-cols`, {'card-container--disabled': disabled}]">
    <CardBasic v-for="(card, index) in cards" :key="index" :card="card" :isLoading="isProcessing(card)"/>
    <div v-if="disabled" class="card-container__dis-text" v-html="disabledMessage" />
  </div>
</template>

<script>
import CardBasic from '@/components/CardBasic';

export default {
  components: {
    CardBasic
  },
  props: {
    cards: {
      type: Array,
      required: true
    },
    maxCols: {
      type: Number,
      default: 5
    }
  },
  computed: {
    disabled () {
      return !this.$store.state.user.orderProcessingChecked
    },
    disabledMessage () {
      const agencyOwnerEmail = this.$store.state.user.brokerAgency ? this.$store.state.user.brokerAgency.email : ''
      const messages = {
        agencyOwner: `Bitte bestätigen Sie unseren Auftragverarbeitungsvertrag. Klicken Sie hierzu in der Kopfzeile auf <b>“Jetzt akzeptieren”</b>`,
        broker: `Leider wurde der Auftragsverarbeitungsvertrag noch nicht vom Hauptaccount (${agencyOwnerEmail}) bestätigt. Bitte wenden Sie sich an die/den Accountinhaber/-in`
      }
      return messages[this.$store.state.user.role]
    },
    processingState () {
      return this.$store.state.checkout.application.isInitialDataLoading;
    },
  },
  methods: {
    isProcessing (card) {
      if(!card.link) { return false }
      if (
        card.link.url
        && card.link.url.params
        && card.link.url.params.id === 'bedingungen'
        && this.processingState === 'bedingungen'
      ) {
        return true
      }
      if(!card.link.url || !(typeof card.link.url === 'string' || card.link.url instanceof String)) { return false }
      if (card.link.url.indexOf('/versicherung/') > -1 && this.processingState === 'versicherung') {
        return true
      } else if (card.link.url.indexOf('offers') > -1 && this.processingState === 'offers'){
        return true
      } else {
        return false 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.card-container {
  display: flex;
  flex-wrap: wrap;

  &--disabled {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -1.5rem;
      left: -1.5rem;
      right: -1.5rem;
      bottom: -1.5rem;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9;
    }

    .card-container__dis-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 2rem;
      max-width: 980px;
      text-align: center;
      z-index: 20;

      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        width: 1000px;
      }
    }
  }

  &.max-5-cols {
    gap: 1rem;

    .card-wrap {
      width: calc(20% - 0.8rem);
      word-wrap: break-word;
      hyphens: auto;
    }

    @include media-breakpoint-down(lg) {
      .card-wrap {
        width: calc(25% - 0.75rem);
        order: 1;

        &--primary {
          order: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .card-wrap {
        width: calc((100% / 3) - 0.67rem);
      }
    }

    @include media-breakpoint-down(sm) {
      .card-wrap {
        width: calc(50% - 0.5rem)
      }
    }

    @include media-breakpoint-down(xs) {
      .card-wrap {
        width: 100%;
      }
    }
  }

  &.max-4-cols {
    gap: 1rem;
  
    .card-wrap {
      width: calc(25% - .75rem)
    }

    @include media-breakpoint-down(md) {
      .card-wrap {
        width: calc((100% / 3) - 0.67rem)
      }
    }

    @include media-breakpoint-down(sm) {
      .card-wrap {
        width: calc(50% - 0.5rem)
      }
    }

    @include media-breakpoint-down(xs) {
      .card-wrap {
        width: 100%;
      }
    }

    ::v-deep .card-wrap.w-100 {
      .card-basic {
        width: calc(25% - 0.75rem);

        @include media-breakpoint-down(md) {
          width: calc((100% / 3) - 0.67rem)
        }

        @include media-breakpoint-down(sm) {
          width: calc(50% - 0.5rem)
        }

        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
    }
  }
}
</style>