<template>
  <div class="footer-tariff-info">
    <p>
        <template v-if="isOffer">*</template>
        
        Der Vergleich basiert auf aktuell 125 Tarifmerkmalen und wird fortlaufend weiterentwickelt. Trotz sorgfältiger Auswertung ist dieser
        Vergleich ohne Gewähr auf Richtigkeit und Vollständigkeit. Es gelten die Versicherungs&shy;bedingungen des jeweiligen Tarifs.
      </p>
  </div>
</template>

<script>
export default {
  name: "FooterTariffInfo",
  props: {
    isOffer: {Boolean, default: false}
  }
};
</script>

<style scoped>
.footer-tariff-info {
  /* Add your component styles here */
  position: relative;
  background: white;
  max-width: 950px;
  z-index: 902;
  padding: 0;
  margin-top: 120px;
}
</style>
