<template>
  <div class="problem-modal">
    <div class="d-flex justify-content-center align-content-center">
      <icon name="alert-circle" color="secondary" width="110"></icon>
    </div>
    <p class="mt-3"> Sehr geehrter Kunde, leider können wir Ihren Antrag aufgrund der Beantwortung dieser Frage nicht
      fortsetzen. </p>
    <p class=""> Kontaktieren Sie uns gerne, um eine individuell passende Absicherung für Ihr Unternehmen zu finden.</p>
    <div class="problem-modal__box">
      <div class="d-flex">
        <icon name="phone" color="white" width="25"></icon>
        <ul class="problem-modal__list">
          <li>
            Beratungsteam
          </li>
          <li>
            Montag bis Freitag 09:00 -18:00
          </li>
          <li>
            030 403695-29
          </li>
        </ul>
      </div>
      <div class="d-flex">
        <icon name="calendar" color="white" width="25"></icon>
        <ul class="problem-modal__list">
          <li>
            <a class="problem-modal__link" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3GXqAqjXjgSbIYGl-8Na6jauPTL7ljcQkQozRCDFq37ubtHeiqWVff2zKNwOHiTbDvjrKrAqIZ">
              <span class="problem-modal__link">
                Termin vereinbaren
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="d-flex">
        <icon name="email" color="white" width="25"></icon>
        <ul class="problem-modal__list">
          <li>
            Mailkontakt
          </li>
          <li>
            makler@cyberdirekt.de
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon.vue';

export default {
  name: 'ProblemModal',
  components: {
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.problem-modal__box {
  padding: 2rem 1rem 2rem 1rem;
  border: 1px solid $topDownColor;
  border-radius: 20px;
  background-color: $topDownColor;
}

.problem-modal__list {
  color: #fff;
  list-style-type: none;
  margin: 0rem;
  padding: 3px 10px 10px 15px;
}
.problem-modal__link {

  color:  #fff;
  text-decoration: underline;
}

</style>
