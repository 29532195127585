<template>
    <div id="vertriebsmaterialien" class="secondary-page sales-materials">
        <b-container>
            <div v-if="!isSubPage" class="go-back">
                <ButtonComponent
                btnStyle="link-primary"
                behavior="internalLink"
                link="/"
                
                >
                <Icon name="arrow-full-left" :width="15" :heght="14" />
                <span class="ml-2">Zurück</span>
                </ButtonComponent>
            </div>
            <NavCards v-if="!isSubPage" :cards="cards" :max-cols="4" />
            
            <router-view></router-view>
        </b-container>
        <BackToTop />
    </div>
</template>

<script>
  import Icon from '@/components/Icon';
  import ButtonComponent from '@/components/reusable/ButtonComponent';
  import NavCards from '@/components/NavCards';
  import BackToTop from '@/components/reusable/BackToTop.vue'

  export default {
    name: 'Vertriebsmaterialien-test',
    metaInfo: {
      title: 'Vertriebsmaterialien'
    },
    components: {
      Icon,
      ButtonComponent,
      NavCards,
      BackToTop
    },
    data() {
      return {
        cards: [
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 28v16a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V28M39 42h-6M29 42h-1" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 10V8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v2M34 10V8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v2M11 6v0a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v0" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 30v0A7.5 7.5 0 0 1 2 22.5V12a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v11a7 7 0 0 1-7 7v0m-22 0h10" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 28h-3a1 1 0 0 0-1 1v0a4 4 0 0 0 4 4v0a4 4 0 0 0 4-4v0a1 1 0 0 0-1-1h-3Zm0 0v-2M35 28h-3a1 1 0 0 0-1 1v0a4 4 0 0 0 4 4v0a4 4 0 0 0 4-4v0a1 1 0 0 0-1-1h-3Zm0 0v-2" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Beratungstool'},
                    type: 'internal'
                },
                title: 'Beratung',
                text: 'Digitaler Beratungs- und Abschlussprozess',
                variant: 'primary',
                wrapClass: 'w-100'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38 4h2v43H8V4h2m20 0h-2m-8 0h-2M35 20H23M35 33H23M30 24h-7M30 37h-7" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 2v5M24 2v5M34 2v5" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="14" cy="9" r="2" stroke="#F26722" stroke-width="2"/><circle cx="24" cy="9" r="2" stroke="#F26722" stroke-width="2"/><circle cx="34" cy="9" r="2" stroke="#F26722" stroke-width="2"/><path d="M13 34.917 14.5 37l4.5-5M13 21.917 14.5 24l4.5-5" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Guidelines'},
                    type: 'internal'
                },
                title: 'Gesprächsleitfäden',
                text: 'Antworten auf oft gestellte Fragen Ihrer Kunden'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m45 36.333-9.5-4.75M3 36.333l20.143-10.071a2 2 0 0 1 1.728-.03L32 29.5" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 19.5V43a2 2 0 0 0 2 2h38a2 2 0 0 0 2-2V19.5M45 19.5 40 17M3 19.5 8 17" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M40 23.5V3h-6M8 23.5V3h22" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m45 20-12.5 9.615M3 20l12.5 9.615" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M13 8h8v8h-8zM25 10h10m-4 4h-6M13 21h23"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Kundenanschreiben'},
                    type: 'internal'
                },
                title: 'Kundenanschreiben & Druckvorlagen',
                text: 'Vordefinierte Kundenanschreiben'
            },
            // Presentations tile is temporary hidden.
            // {
            //     icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 4H5a2 2 0 0 0-2 2v0a2 2 0 0 0 2 2h38a2 2 0 0 0 2-2v0a2 2 0 0 0-2-2H31" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 11v26a2 2 0 0 0 2 2h34a2 2 0 0 0 2-2V11M10 39l-2 7M40 46l-2-7" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M10 26h8v8h-8zM23 27v6M27 27v6M31 27v6M35 27v6M39 27v6M10 16h9m8 0h-4m4 4H10"/><path d="M39 14v8h-8v-8h4" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            //     link: {
            //         label: 'Öffnen',
            //         url: {name: 'Prasentationen'},
            //         type: 'internal'
            //     },
            //     title: 'Präsentationen',
            //     text: 'Übersichtlich und aussagekräftig'
            // },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 21a1 1 0 0 0-1-1H17a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15.25l9.25 5 .5-5h2a1 1 0 0 0 1-1V21Z" stroke="#31A9E0" stroke-width="2" stroke-linejoin="round"/><path d="M30 17V4a1 1 0 0 0-1-1H11m1 21.486L5.5 28 5 23H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h3.5" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 12.917 14.5 15l4.5-5M27 28a3 3 0 1 1 3 3v2M30 36v.5" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: 'https://www.cyberdirekt.de/faq'
                },
                title: 'FAQ',
                text: 'Ihre oft gestellten Fragen und die dazugehörigen Antworten'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 31.5V35a2 2 0 0 0 2 2h40a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-5M2 31.5V5a2 2 0 0 1 2-2h31M2 31.5h39.5M18 37l-2 8m14-8 2 8m0 0H16m16 0h2m-18 0h-3" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M25.737 10 24 7 13 26h22l-5.5-9.5-1.882-3.25M24 15v5M24 22.5v.5" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {
                        name: 'Schadenbeispiele',
                        params: { id: 'Alles' }
                    },
                    type: 'internal'
                },
                title: 'Schadenbeispiele',
                text: 'Aktuell aus der Wirtschaft'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 6v24a2 2 0 0 0 2 2h9M2 6V4a2 2 0 0 1 2-2h39a2 2 0 0 1 2 2v2M2 6h29m14 0v25a1 1 0 0 1-1 1h-1m2-26h-9" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m34.621 40.157 1.886 1.886.957.957m.693-6.379 5.303 5.304a2.5 2.5 0 0 1 0 3.535v0a2.5 2.5 0 0 1-3.536 0L37.464 43m0 0L39 41.5" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><circle cx="28" cy="30" r="12" stroke="#31A9E0" stroke-width="2"/><path d="M21 30c0-1.947.763-3.712 2-5M6 10h8v8H6zM20 10h21M20 14h8M32 14h4" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {
                        name: 'MarktstudienLP',
                        params: {slug: 'cyber-sicherheit'}
                    },
                    type: 'internal'
                },
                title: 'Marktstudien',
                text: 'Eine Auswahl relevanter Studien zu Cyber-Risiken'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38 7H15v40h30V30.5M38 7v7h7m-7-7 7 7m0 0v13" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M36 3V1H6v40h5" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M20 14h4v4h-4zM28 14h4.5m6 4H28M28 34h6.5m3.5 4H28M28 24h8m-4 4h-4M20 24h4v4h-4zM20 34h4v4h-4z"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {
                        name: 'Bedingungen und Formulare',
                        params: {id: 'bedingungen'}
                    },
                    type: 'internal'
                },
                title: 'Bedingungen und Formulare',
                text: 'Alle relevanten Dokumente für den Vertragsabschluss'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M29.988 20.976c.639-2.634 4.386-2.634 5.025 0a2.585 2.585 0 0 0 3.859 1.599c2.315-1.41 4.965 1.239 3.555 3.555a2.585 2.585 0 0 0 1.598 3.858c2.633.639 2.633 4.386 0 5.025a2.585 2.585 0 0 0-1.6 3.859c1.41 2.315-1.238 4.965-3.554 3.555a2.585 2.585 0 0 0-3.858 1.598c-.64 2.633-4.386 2.633-5.025 0a2.585 2.585 0 0 0-3.86-1.6c-2.314 1.41-4.965-1.238-3.555-3.554a2.585 2.585 0 0 0-1.598-3.858c-2.634-.64-2.634-4.386 0-5.025a2.585 2.585 0 0 0 1.6-3.86c-1.41-2.314 1.239-4.965 3.555-3.555a2.585 2.585 0 0 0 3.858-1.598ZM9.97 5.769C8.49 4.192 5.888 5.665 6.48 7.745a2.062 2.062 0 0 1-2.05 2.625c-2.162-.068-2.959 2.814-1.07 3.866a2.064 2.064 0 0 1 .408 3.308c-1.577 1.48-.104 4.08 1.976 3.488a2.063 2.063 0 0 1 2.625 2.05c-.068 2.162 2.814 2.959 3.866 1.07a2.064 2.064 0 0 1 3.308-.408c1.48 1.578 4.08.104 3.488-1.976a2.063 2.063 0 0 1 2.05-2.625c2.162.068 2.959-2.814 1.07-3.866a2.064 2.064 0 0 1-.408-3.308c1.578-1.479.104-4.08-1.976-3.488a2.063 2.063 0 0 1-2.625-2.05c.068-2.162-2.814-2.959-3.866-1.07" stroke="#31A9E0"/><path d="M32.5 26a6.5 6.5 0 1 0 4.596 1.904" stroke="#31A9E0"/><path d="M17.238 12.218a5.151 5.151 0 1 1-8.964 5.077 5.151 5.151 0 0 1 8.964-5.077v0Z" stroke="#F26722"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>',
                link: {
                    label: 'Öffnen',
                    url: '/affiliate',
                    type: 'internal'
                },
                title: 'Angebotsrechner für Kund:innen',
                text: 'Verlinken Sie den Vergleichs-Rechner auf Ihrer Webseite'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 15V7H3v34h42V19M3 13h21m21 0H35m-11 0V7m0 6h7M45 35H24M3 35h7m14 0v6m0-6H14M31 13V7M17 35v6M10 13V7M38 35v6M38 13V7M10 35v6M17 13V7M31 35v6" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m29 24-10 5V19l10 5Z" stroke="#F26722" stroke-width="2" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Erklarvideos'},
                    type: 'internal'
                },
                title: 'Erklärvideos',
                text: '2-Minuten Kurzvideos zur Sensibilisierung'
            }
        ]
      }
    },
    computed: {
      isSubPage() {
        return this.$route.matched.length > 1
      }
    },
    mounted: function () {
    },
    methods: {
      
    }
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .sales-materials {
      padding-top: 2rem;

        @include media-breakpoint-down(sm) {
            padding-top: 1rem;
        }

    }

    .go-back {
        font-size: 1.125rem;
        margin-bottom: 3.5rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }


</style>
