<template>
    <div class="icon" v-bind:class="iconClass" v-bind:style="iconStyle" aria-hidden="true">

        <svg v-if="name === 'check-completed'" v-bind:height="width" v-bind:width="width"
                id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.34 164.9">
            <title>_ionicons_svg_ios-checkmark</title>
            <path d="M214.2,19.3,196.6,1.2A3.78,3.78,0,0,0,193.8,0,3.63,3.63,0,0,0,191,1.2L69,124.1,24.6,79.7a3.87,3.87,0,0,0-5.6,0L1.2,97.5a4,4,0,0,0,0,5.7l56,56c3.6,3.6,8,5.7,11.7,5.7,5.3,0,9.9-3.9,11.6-5.5h.1L214.3,25a4.27,4.27,0,0,0-.1-5.7Z"/>
        </svg>

        <svg v-if="name === 'phone'" v-bind:height="width" v-bind:width="width"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
                    d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
        </svg>

        <svg v-if="name === 'email'" v-bind:height="width" v-bind:width="width"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"/>
        </svg>

        <svg v-if="name === 'calendar'" v-bind:height="width" v-bind:width="width"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/>
            <path fill="none" d="M0 0h24v24H0z"/>
        </svg>

        <svg v-if="name === 'arrow-right'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
            <path d="M0-.25h24v24H0z" fill="none"/>
        </svg>

        <svg
          v-if="name === 'arrow-up'"
          v-bind:height="height"
          v-bind:width="width"
          transform="rotate(180)"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
          />
        </svg>

        <svg v-if="name === 'arrow-down'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/>
        </svg>

        <svg v-if="name === 'cancel'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>

        <svg v-if="name === 'menu'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
        </svg>

        <svg v-if="name === 'chevron-down'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
            <path d="M0-.75h24v24H0z" fill="none"/>
        </svg>

        <svg v-if="name === 'chevron-left'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>

        <svg v-if="name === 'chevron-right'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>

        <svg v-if="name === 'facebook'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                    d="m19,3l-14,0c-1.1,0 -2,0.9 -2,2l0,14c0,1.101 0.9,2 2,2l7,0l0,-7l-2,0l0,-2.475l2,0l0,-2.05c0,-2.164 1.212,-3.684
             3.766,-3.684l1.803,0.002l0,2.605l-1.197,0c-0.994,0 -1.372,0.746 -1.372,1.438l0,1.69l2.568,0l-0.568,2.474l-2,0l0,7l4,0c1.1,0 2,-0.899 2,-2l0,-14c0,-1.1 -0.9,-2 -2,-2z"/>
        </svg>

        <svg v-if="name === 'twitter'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                    d="M19.315999,8.246c0.008,0.162 0.011,0.326 0.011,0.488c0,4.99 -3.797,10.742 -10.74,10.742c-2.133,0 -4.116,-0.625 -5.787,-1.697
            c0.296,0.035 0.596,0.053 0.9,0.053c1.77,0 3.397,-0.604 4.688,-1.615c-1.651,-0.031 -3.046,-1.121 -3.526,-2.621
            c0.23,0.043 0.467,0.066 0.71,0.066c0.345,0 0.679,-0.045 0.995,-0.131c-1.727,-0.348 -3.028,-1.873 -3.028,-3.703c0,-0.016 0,-0.031 0,-0.047
            c0.509,0.283 1.092,0.453 1.71,0.473c-1.013,-0.678 -1.68,-1.832 -1.68,-3.143c0,-0.691 0.186,-1.34 0.512,-1.898
            c1.861,2.285 4.644,3.787 7.781,3.945c-0.064,-0.277 -0.097,-0.564 -0.097,-0.861c0,-2.084 1.689,-3.773 3.774,-3.773
            c1.086,0 2.067,0.457 2.756,1.191c0.859,-0.17 1.667,-0.484 2.397,-0.916c-0.282,0.881 -0.881,1.621 -1.66,2.088
            c0.764,-0.092 1.49,-0.293 2.168,-0.594c-0.506,0.758 -1.146,1.422 -1.884,1.953z"/>
        </svg>

        <svg v-if="name === 'file-pdf'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"/>
        </svg>

        <svg v-if="name === 'share'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/>
        </svg>
        
        <svg
          v-if="name === 'info'"
          v-bind:height="height"
          v-bind:width="width"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
          />
        </svg>

        <svg v-if="name === 'loading'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>

        <svg v-if="name === 'delete'" v-bind:height="width" v-bind:width="width"
             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/><path d="M0 0h24v24H0z" fill="none"/>
        </svg>


        <svg v-if="name === 'alert-circle'" v-bind:height="width" v-bind:width="width" viewBox="0 0 24 24">
          <path :fill="color" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>


        <svg id="Layer_1"
             v-bind:height="width"
             v-bind:width="width"
             data-name="Layer 1"
             v-if="name === 'warning'"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 89.96 80.65">
            <path
                    d="M50,17a5.17,5.17,0,0,0-4.44,2.5L12.69,75.41a5,5,0,0,0,0,5.06A5.62,5.62,0,0,0,17.5,83h65a5.62,5.62,0,0,0,4.81-2.53,5,5,0,0,0,0-5.06L54.44,19.5A5.17,5.17,0,0,0,50,17Zm0,2a3.23,3.23,0,0,1,2.69,1.5l32.9,55.94a3.09,3.09,0,0,1,0,3A4.32,4.32,0,0,1,82.5,81h-65a4.32,4.32,0,0,1-3.09-1.53,3.09,3.09,0,0,1,0-3L47.31,20.5A3.23,3.23,0,0,1,50,19Zm0,19a1,1,0,0,0-1,1V61a1,1,0,0,0,2,0V39A1,1,0,0,0,50,38Zm0,31a2,2,0,1,0,2,2A2,2,0,0,0,50,69Z"
                    transform="translate(-12 -17)"/>
        </svg>

    </div>
</template>

<script>
  const PADDING_RATIO = 0.38;

  export default {
    name: 'icon',
    props: {
      name: {
        type: String,
        required: true
      },
      color: {
        type: String
      },
      height: {
        type: String,
        default: '24'
      },
      width: {
        type: String,
        default: '24'
      },
      circled: {
        type: Boolean,
        default: false
      },
      spinning: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      iconClass() {
        return {
          'icon--primary': this.color === 'primary' || !this.color,
          'icon--secondary': this.color === 'secondary',
          'icon--white': this.color === 'white',
          'icon--black': this.color === 'black',
          'icon--dark-grey': this.color === 'dark-grey',
          'icon--green': this.color === 'green',
          'icon--circled': this.circled === true,
          'icon--spinning': this.spinning === true,
          'icon--brown': this.color === 'brown'
        };
      },
      iconStyle() {
        return {
          padding: this.circled ? this.width * PADDING_RATIO + 'px' : null
        };
      }
    }
  }
</script>

<style lang="scss">
    @import './scss/variables';

    .icon {
        display: inline-block;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        transition: all .3s;

        &--primary {
            fill: $primary;
            border-color: $primary;
        }

        &--secondary {
            fill: $secondary;
            border-color: $secondary;
        }

        &--white {
            fill: #fff;
            border-color: #fff;
        }

        &--green {
            fill: #8bbd23;
            border-color: #8bbd23;
        }

        &--brown {
            fill: #856404;
            border-color: #856404;
        }

        &--black {
            fill: #000;
            border-color: #000;
        }

        &--dark-grey {
            fill: #58595b;
            border-color: #58595b;
        }

        &--circled {
            border-width: 2px;
            border-style: solid;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }

        &--spinning {
            animation: spin 2s linear infinite;
        }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
