<template>
  <div class="container secondary-page presse-article">
    <div class="row page-title">
      <template v-if="article.thumbnail && !article.hideThumbnailOnTop">
        <div class="col-12 col-md-9">
          <img :src="article.thumbnail.url" class="w-100"/>
        </div>
        <div class="col-12 col-md-3 back-btn">
          <router-link class="btn-style" :to="'/presse'">&larr;Zurück</router-link>
        </div>
      </template>
      <div class="col-12 col-md-9" :class="{'mt-5': article.thumbnail && !article.hideThumbnailOnTop}">
        <h1>{{ article.title }}</h1>
      </div>
      <div class="col-12 col-md-3 back-btn" v-if="!article.thumbnail || article.hideThumbnailOnTop">
        <router-link class="btn-style" :to="'/presse'">&larr;Zurück</router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-9">
        <RichText v-if="article.text" :content="article.text"></RichText>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import RichText from '@/components/reusable/RichText'

export default {
  name: 'presseArticle',
  components: {RichText},
  metaInfo() {
    return {
      title: this.article.title,
    }
  },
  data() {
    return {
      article: {
        title: null,
        thumbnail: null
      },
    }
  },
  async mounted() {
    const slug = this.$route.params.id
    const response = await axios.post(
      `${process.env.VUE_APP_CMS_BASE}graphql`,
      {
        query: `{
        articles(where: {slug:"${slug}", brokerPortal: true}) {
          title
          shortTextPreview
          text
          category
          slug
          publishedDate
          author
          externalLink
          thumbnail {
            formats
            url
          }
          hideThumbnailOnTop
        }
      }`,
      }
    )
    this.article = response.data.data.articles[0]
  }
}
</script>
<style lang="scss" scoped>


.container.secondary-page.presse-article ::v-deep .image {
    text-align: center;
}


.container.secondary-page.presse-article ::v-deep .image span {
  font-size: 0.8em;
    color: #667075;
}

.container.secondary-page.presse-article ::v-deep .image img {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}

/* the block coming from strapi */
.container.secondary-page.presse-article ::v-deep .image.image-style-side {
  display: block;
  float: right;
  width: 50%;
}

.container.secondary-page.presse-article ::v-deep .image.image-style-side img {
  padding: 10px;
  display: block;
  width: 100%;
}

.page-title {
  img {
    max-width: fit-content;
  }
}

.rich-text {
  ::v-deep img {
    max-width: 100%;
  }
}

</style>

