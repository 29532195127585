<template>
    <div class="secondary-page">
        <div class="container">
            <div class="row page-title">
                <div class="col">
                    <h1>
                        {{ title }}
                    </h1>
                </div>
                <div class="col-12 col-md-2 back-btn">
                    <router-link :to="{name: 'Mitarbeiterschulung'}" class="btn-style router-link-active">
                        &larr;Zurück
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'my-training',
    data() {
      return {
        idleInterval: null,
        idleTime: 0
      };
    },
    metaInfo() {
      return {
        title: this.title
      };
    },
    computed: {
      selectedCourse() {
        return this.$store.state.selectedCourse || {};
      },
      title() {
        return this.selectedCourse.name;
      }
    },
    methods: {
      startCourse() {
        this.$router.push({params: {slide: '1'}});
      }
    }
  }
</script>

<style lang="scss">
    @import '../../scss/trainings-base';
</style>
