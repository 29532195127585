<template>
    <div class="container login-container">
      <form @submit.prevent.stop="submitForm()" name="reset" class="login-form">
        <h1 class="login-title">Legen Sie Ihr neues Passwort fest</h1>

        <div class="w-100 mb-4">
          <label for="newPassword" class="align-self-start">Neues Passwort *</label>
          <div class="form-group form-group--icon-l form-group--icon-r">
            <Icon name="lock" width="20" class="icon icon-l"></Icon>
            <b-form-input :type="!showPass ? 'password' : 'text' " id="newPassword" class="form-trol" v-model="newPassword" required></b-form-input>
            
            <Icon name="eye" width="17" class="icon icon-r" :class="{'icon-primary': showPass}" @click.native="() => {showPass = !showPass}"></Icon>
          </div>

          <ValidationProvider vid="confirm" v-slot="{ errors }">
            <password name="newPassword"
              :strength-meter-only="true"
                defaultClass="form-control"
                v-model="newPassword"
                id="newPassword"
                placeholder=""
                @score="scoreChanged"
                :secureLength="6"
                toggle
                :badge="false"
                data-vv-rules="required"/>
            <div class="password-feedback" :class="passwordClass">
              {{passwordStrength}}
            </div>
          </ValidationProvider>
        </div>

        <ValidationProvider ref="confirmPass" rules="required|confirmed:confirm" v-slot="{ errors }" mode="eager" name="repeatPassword" class="w-100">
          <div class="form-group form-group--icon-l form-group--icon-r" :class="{'is-danger': errors?.length}">
            <Icon name="lock" width="20" class="icon icon-l"></Icon>
            <b-form-input :type="!showRepeatPass ? 'password' : 'text' " id="repeatPassword" name="repeatPassword" class="form-trol" v-model="repeatPassword" placeholder="Passwort bestätigen" required></b-form-input>

            <Icon name="eye" width="17" class="icon icon-r" :class="{'icon-primary': showRepeatPass}" @click.native="() => {showRepeatPass = !showRepeatPass}"></Icon>
          </div>
        </ValidationProvider>

        <ButtonComponent
          btnStyle="accent"
          behavior="button"
          class="submit-btn"
          :disabled="$refs.confirmPass?.flags?.invalid"
          >Weiter</ButtonComponent>
          
      </form>
    </div>
</template>

<script>
  import Password from 'vue-password-strength-meter';
  import ButtonComponent from '@/components/reusable/ButtonComponent'
  import Icon from '@/components/Icon.vue'
  import { ValidationProvider } from 'vee-validate'

  export default {
    name: 'Reset',
    metaInfo: {
      title: 'Passwort zurücksetzen'
    },
    components: {Password, ButtonComponent, Icon, ValidationProvider},
    data() {
      return {
        showPass: false,
        showRepeatPass: false,
        newPassword: '',
        repeatPassword: '',
        passwordScore: 0,
        passwordStrength: '',
        passwordClass: ''
      }
    },
    mounted: function() {
    },
    methods: {
      submitForm: function() {
        const _token = this.$route.params.tokenId

        if (this.passwordScore >= 2 && this.newPassword === this.repeatPassword) {
          return this.$http
            .post('brokers/reset-password?access_token=' + _token, {newPassword: this.newPassword})
            .then(response => {
              if (response.data === '') {
                this.$router.push('/login/authenticate')
                return this.$toasted.show('Passwort wurde erfolgreich geändert.', {
                  theme: 'bubble',
                  type:'success',
                  position: 'bottom-left',
                  duration: 4000
                });
              }
              return false
            })
        }
      },
      scoreChanged(score) {
        this.passwordScore = score || 0;
        this.passwordStrength = [
          'Komplexität: sehr schwach',
          'Komplexität: schwach',
          'Komplexität: mittel',
          'Komplexität: stark',
          'Komplexität: sehr stark'
        ][score];
        this.passwordClass = [
          'red',
          'red',
          'yellow',
          'green',
          'green'
        ][score];
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../../scss/base';
@import '../../scss/form';
@import '../../scss/variables';
@import '../../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login-form {
  background: #fff;
  border-radius: 0.5rem;
  color: #454545;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: #454545;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
      font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}


.login-title {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
}

.Password {
  width: 100%;
}

.form-group.is-danger input {
  border-width: 2px;
}
</style>


