export default {
  computed: {
    insTaxRate() {
      const rates = this.$store.state.checkout.application.insTaxRate || {
        aon: '11',
        default: '19'
      }
      const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
      const isAon = aonKeys.some(k => k === this.agencyKeyValue);
      return isAon ? rates.aon : rates.default
    },
    agencyKeyValue() {
      const agencyKey = this.$store.state.checkout.application.mode === 'www' ?
        this.$store.state.checkout.application.inputs.key
        : this.mode === 'offer' ?
        this.$store.state.offer.offer.key
        : this.$store.state.checkout.application.style === 'affiliate' && this.$store.state.checkout.application.affiliateBroker ?
        this.$store.state.checkout.application.affiliateBroker.agency
        : this.$store.state.user.brokerAgency.key
        return agencyKey
    }
  }
}

