<template>
  <div class="tb-row gap-sm">
    <div class="tb-cell-title-wrap sticky-col">
      <div class="tb-cell tb-cell--title">
        <slot name="label"></slot>
      </div>
    </div>
    <div class="tb-row">
      <slot name="properties">
      </slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../../scss/print/row.scss';

.row {
  height: 100%;
}
.tb-row {
  display: flex;
  gap: 0.5rem;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }
}

.tb-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0.5rem;
  min-height: 48px;
  width: 290px;
  height: 100%;
  background: #dff1f9;
  border-radius: 0.25rem;
  text-align: center;

  &--title {
    // padding: 0.5rem 0.5rem 0.5rem 4rem;
    align-items: flex-start;
    text-align: left;
    font-weight: bold;
    width: 100%;
  }
}
</style>
