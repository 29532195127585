import { render, staticRenderFns } from "./Forgot.vue?vue&type=template&id=3b31b750&scoped=true"
import script from "./Forgot.vue?vue&type=script&lang=js"
export * from "./Forgot.vue?vue&type=script&lang=js"
import style0 from "./Forgot.vue?vue&type=style&index=0&id=3b31b750&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b31b750",
  null
  
)

export default component.exports