<template>
    <v-touch @swipeleft="next"
             @swiperight="back"
             :swipe-options="{ direction: 'horizontal' }"
             class="emotet-email course-base-template">
        <h2 class="course-base-template__title">{{ content.title }}</h2>
        <div class="emotet-email__holder" ref="container">
            <img :src="require('@/pages/trainings/assets/courses/email-security/emotet-email.png')"
                 class="emotet-email__image"
                 alt="Bild: CERT-Bund/BSI">

            <div class="emotet-email__tooltip"
                 style="top: 3.5%; left: 0%;"
                 :style="{ transform: iconTransform }"
                 v-tooltip="{
             content: 'Herr Müller erhält eine Email von Frau Meier, welche eine Antwort auf eine vorherige Email zu sein scheint',
             classes: 'custom-template__tooltip-content',
             trigger: 'hover click',
           }">
                1
            </div>

            <div class="emotet-email__tooltip emotet-email__show-fake-url"
                 style="top: 24%; left: 73%;"
                 :style="{ transform: iconTransform }"
                 v-tooltip="{
             content: 'In seinen Text fügt der Computervirus einen Link ein. Doch der muss nicht zu der Adresse führen, die lesbar ist (also im Beispiel musterfirma.de...). Wenn Sie den Mauszeiger auf den Link halten (ohne ihn anzuklicken!) zeigt Ihnen das E-Mail-Programm, dass der Klick auf eine ganz andere Adresse führt',
             classes: 'custom-template__tooltip-content',
             trigger: 'hover click',
           }">
                2
            </div>

            <div class="emotet-email__tooltip emotet-email__show-highlight"
                 style="top: 62%; left: 92%;"
                 :style="{ transform: iconTransform }"
                 v-tooltip="{
             content: 'Der Computervirus späht aus Outlook-Postfächern nicht nur Email-Kontakte aus, die zur weiteren Verbreitung genutzt werden. Darüber hinaus werden auch die ersten 16kB jeder E-Mail ausgelesen und in der automatisch generierten Nachricht eingefügt. Besonders tückisch ist, wenn der Absender der E-Mail offenbar jemand ist, dem Sie kürzlich einen Schriftwechsel hatten.',
             classes: 'custom-template__tooltip-content',
             trigger: 'hover click',
           }">
                3
            </div>

            <div class="emotet-email__tooltip"
                 style="top: 18%; left: 0;"
                 :style="{ transform: iconTransform }"
                 v-tooltip="{
             content: 'Oftmals können Sie die gefälschte Email nur anhand der Wortwahl und eventueller Fehler in der Grammatik erkennen. In diesem Fall ist die Nachricht in nahezu perfektem Deutsch formuliert. Nur aufgrund des Wechsels in der Anrede von „Sie“ auf „Du“ konnte Herr Müller erahnen, dass mit der Email etwas nicht stimmt.',
             classes: 'custom-template__tooltip-content',
             trigger: 'hover click',
           }">
                4
            </div>

            <div class="emotet-email__btn-mask emotet-email__show-fake-url"
                 :style="{ transform: iconTransform }"></div>

            <div class="emotet-email__fake-url"
                 :style="{ transform: scaleRatio }">
                http://super-plus.pl/css/oo6a-atf3y-frzom/
            </div>

            <div class="emotet-email__highlight"
                 :style="{ transform: scaleRatio }">
                Von Emotet zuvor auf infizierten System ausgespähte authentische E-Mail
            </div>

            <div class="emotet-email__image-from">Bild: CERT-Bund/BSI</div>
        </div>
        <div class="row mb-15">
            <div class="col-6">
                <button class="btn btn--secondary btn--border"
                        type="button"
                        name="back"
                        @click="back">Zurück
                </button>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn--secondary"
                        type="button"
                        name="next"
                        @click="next">Weiter
                </button>
            </div>
        </div>
    </v-touch>
</template>

<script>
  import {throttle} from 'lodash';

  function calc() {
    let width = this.$refs.container && this.$refs.container.clientWidth || this.maxWidth;
    width = Math.min(width, window.innerWidth, this.maxWidth);

    this.currentWidth = width;
  }

  export default {
    name: 'emotet-email',
    data() {
      return {
        maxWidth: 685,
        currentWidth: 685
      };
    },
    mounted() {
      window.addEventListener('resize', this.onResize);
      this.calc();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    computed: {
      scaleRatio() {
        return `scale(${this.currentWidth / this.maxWidth})`;
      },
      iconTransform() {
        return `translate(-50%, -50%) ${this.scaleRatio}`;
      },
      slideNumber() {
        return +this.$route.params.slide;
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      }
    },
    props: {
      content: Object
    },
    methods: {
      calc: calc,
      onResize: throttle(calc, 100),
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      next() {
        this.$router.push({
          params: { ...this.$route.params, slide: this.nextSlideNumber }
        });
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .emotet-email {
        &__holder {
            position: relative;
            max-width: 685px;
            margin: 0 auto 35px;
        }

        &__image {
            max-width: 100%;
            vertical-align: top;
        }

        &__tooltip {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            transform: translate(-50%, -50%);
            padding: 3px;
            border: 1px solid darken($primary, 15%);
            background: $primary;
            border-radius: 50%;
            height: 28px;
            width: 28px;
            text-align: center;
            color: #fff;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 600;

            .icon {
                border-radius: 50%;
                box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.35);

                path {
                    transform-origin: center;
                    transform: scale(1.15);
                }
            }
        }

        &__fake-url {
            position: absolute;
            top: 28%;
            left: 40%;
            color: $red;
            font-size: 1.15rem;
            line-height: 1.75rem;
            opacity: 0;
            white-space: nowrap;
            transform-origin: top left;

            &:before {
                content: '';
                position: absolute;
                bottom: 100%;
                right: 50%;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid $red;
            }
        }

        &__highlight {
            position: absolute;
            top: 42%;
            left: 1%;
            height: 294px;
            width: 670px;
            padding: 84px 50px 0 250px;
            color: $red;
            font-size: 1.15rem;
            line-height: 1.75rem;
            opacity: 0;
            border: 2px dashed $red;
            transition: opacity 0.4s ease-in-out;
            transform-origin: top left;
        }

        &__image-from {
            position: absolute;
            top: 100%;
            left: 12px;
            font-size: 0.8rem;
            line-height: 1rem;
            color: $dark-blue;
        }

        &__btn-mask {
            position: absolute;
            top: 24%;
            left: 36%;
            cursor: pointer;
            width: 478px;
            height: 26px;
        }

        &__show-fake-url:hover ~ &__fake-url {
            opacity: 1;
        }

        &__show-highlight:hover ~ &__highlight {
            opacity: 1;
        }

        .btn {
            font-size: 1rem;
            padding: 9px;
            min-width: 207px;
            border-radius: 8px;
        }

        @media screen and (max-width: 767px) {
            .btn {
                font-size: 1rem;
                padding: 7px;
                min-width: 120px;
                border-radius: 8px;
            }
        }
    }
</style>
