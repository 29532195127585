// add the mixin in route/component where we need to check if there is enough store data
export default {
  beforeMount() {
    const store = this.$store.state.checkout.application
    const self = this
    const mode = (function() {
      if (self.$route.name === 'Offer') {
        return 'offer'
      } else if (store.style === 'affiliate') {
        return 'affiliate'
      } else {
        return 'other'
      }
    }())

    const entryRoute = (function() {
      switch (mode) {
        // case 'affiliate':
        //   return '/versicherung/1a/ihr-unternehmen/branche'
        default:
          return '/versicherung/1a/ihr-unternehmen/branche'
      }
    }())

    const exceptions = [
      mode === 'offer',
      this.$route.query && this.$route.query.shared
    ]

    const isException = exceptions.some((exception) => {
      return !!exception
    })

    !isException && this.isStepAllowed() === false ? this.$router.push(entryRoute) : ''
  },
  methods: {
    isStepAllowed() {
      const store = this.$store.state.checkout.application

  //we can improve solution, so every step will have its conditions:
      // const notAllowedConditionsObj = {
      //   emptyStep() {
          
      //   },
      //   emptyIndustry() {
    
      //   },
      //   emptyNetTurnOver() {
    
      //   },
      // }
    
      // const steps = {
      //   '1b': ['emptyStep', 'emptyIndustry'],
      //   '2': ['emptyStep', 'emptyIndustry', 'emptyNetTurnOver']
      // }

      // simple solution
      const notAllowedConditions = [
        function emptyStep() {
          const activeStep = store.activeStep
          return Object.keys(activeStep).length === 0
        },
        function emptyIndustry() {
          const industry = store.inputs.industry.l
          return !industry.trim()
        }
      ]

      let isAllowed
      for (let i=0; i < notAllowedConditions.length; i++) {
        if (notAllowedConditions[i]()) {
          isAllowed = false
          break
        }
      }

      return isAllowed
    }
  }
}

