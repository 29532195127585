<template>
  <div class="header-notifications d-print-none">
    <div v-for="(notif, i) in notifications" :key="i" class="header-notification" :style="{'background-color': notif.bgColor}">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="header-notif">
              <span class="header-notif-text">
                {{ notif.text }}
              </span>
              <button class="header-notif-cta" type="button" @click="handleBtnClick(i)">
                {{ notif.btn.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import brokerApi from '../api/broker';

  export default {
    props: {
      user: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    data() {
      return {
        notifications: []
      }
    },

    mounted() {
      this.initNotifications()
    },

    methods: {
      initNotifications() {
        this.notifications = []
        this.initNewsletterNotification()
        this.initAvvNotification()
      },

      initNewsletterNotification() {
        brokerApi
        .getNewsletterSubscriptionState()
        .then((response) => {
          if (response.data && !response.data.subscribed) {
            this.notifications.push({
              bgColor: '#f26722',
              text: 'Möchten Sie regelmäßig unseren Newsletter mit Fachbeiträgen zur Cyberversicherung erhalten?',
              btn: {
                title: 'anmelden',
                clickEmitter: 'subscribeNewsletter'
              }
            })
          }
          this.updateNotifState()
        });
      },

      initAvvNotification() {
        // if (this.user.role === 'agencyOwner') {
        if (this.user.role === 'agencyOwner' && (!this.user.orderProcessingChecked || this.user.orderProcessingChecked === undefined)) {
          this.notifications.push({
            bgColor: '#58595b',
            text: 'Bitte akzeptieren Sie unseren neuen Auftragsdatenverarbeitungsvertrag (AVV), um die Dienstleistungen von CyberDirekt weiterhin nutzen zu können. Für Rückfragen stehen wir Ihnen unser Vertrieb gerne zur Verfügung.',
            btn: {
                title: 'Jetzt akzeptieren',
                clickEmitter: 'openAvvModal'
            }
          })
        }
        this.updateNotifState()
      },

      updateNotifState() {
        const isNotification = this.notifications && this.notifications.length ? true : false
        this.$emit('updateNotifState', isNotification)
      },

      handleBtnClick(index) {
        this.$emit(this.notifications[index].btn.clickEmitter)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scss/variables';
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';
  .header-notification {
      z-index: 1;
      left: 0;
      right: 0;
      top: 62px;
      line-height: 30px;
      background: $secondary;
      color: #fff;
      line-height: 1.4;
      border-bottom: 1px solid #fff;
      margin-bottom: 1rem;
      padding: 0.675rem 1rem;

      @include media-breakpoint-up(md) {
        top: 68px;
      }

      @include media-breakpoint-up(lg) {
        top: 68px;
      }
  }

  .header-notification + .header-notification {
    margin-top: -17px;
  }

  .header-notif-cta {
      padding: 4px 8px;
      // margin-left: 8px;
      background: none;
      color: #fff;
      border-radius: 3px;
      border: 2px solid rgba(255, 255, 255, .8);
      font-weight: bold;
      line-height: 1;
      cursor: pointer;
  }
  .header-notif-text {
    margin-right: 0.5rem;
  }

  .header-notif {
    @include media-breakpoint-down(sm) {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
</style>
