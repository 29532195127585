<template>
    <div>
        <!-- <cd-hero :header="header"></cd-hero> -->
        <div id="customer_letters_lp" class="secondary-page">
            <div class="container">
                <div class="row page-title">
                    <div class="col">
                        <h1>Kundenanschreiben & Druckvorlagen</h1>
                    </div>

                    <div class="col-12 col-md-2 back-btn">
                        <a class="btn-style" href="" @click.prevent.stop="takeMeBack()">&larr;Zurück</a>
                    </div>
                </div>
                <div v-for="(letter, index) in letters" :key="index">
                    <div class="row letter-btk">
                        <div class="col-12">
                            <h3>{{letter.title}}</h3>
                        </div>
                    </div>
                    <div class="row pdf-std-module"
                         :class="'letter-' + iIndex"
                         v-for="(item, iIndex) in letter.items"
                         :key="iIndex">
                        <div class="col-12 col-md-5 std-pdf-preview" @click="downloadLetter(item.letter)">
                            <div class="l-container">
                                <make-thumb :url="getUrl(item.url)"></make-thumb>
                                <div class="title-area"><h4></h4></div>
                            </div>
                        </div>

                        <div class="col-12 col-md-7 markt-content">
                            <div>
                                <h4>{{item.title}}</h4>
                                <!-- <h6>Cyber-Risiken in Unternehmen</h6> -->
                                <!--
                                                  <ul>
                                                    <li>Katrin Rohmann</li>
                                                    <li>Peter J. Wirnsperger</li>
                                                  </ul> -->
                                <a href="" @click.stop.prevent="downloadLetter(item.letter)">Download <i
                                        class="ion-android-download"></i></a>

                            </div>

                        </div>
                    </div>

                </div>

            </div> <!-- end container -->

        </div>

    </div>
</template>

<script>
  import Icon from './Icon'
  import MakeThumb from '@/components/shared/MakeThumb'

  export default {
    name: 'CustomerLetters',
    metaInfo: {
      title: 'Anschreiben'
    },
    data() {
      return {
        letters: [
          {
            title: 'Anschreiben',
            items: [
              {
                url: 'Anschreiben_Bitkomstudie.pdf',
                letter: 'Anschreiben_Bitkomstudie.docx',
                title: 'CyberDirekt Anschreiben Bitkomstudie'
              },
              {
                url: 'CyberDirekt_Anschreiben_Arztpraxis.pdf',
                letter: 'CyberDirekt_Anschreiben_Arztpraxis.docx',
                title: 'CyberDirekt Anschreiben Arztpraxis'
              },
              {
                url: 'CyberDirekt_Anschreiben_Elektroindustrie.pdf',
                letter: 'CyberDirekt_Anschreiben_Elektroindustrie.docx',
                title: 'CyberDirekt Anschreiben Elektroindustrie'
              },
              {
                url: 'V2_CyberDirekt_Anschreiben_DSGVO_JPB.pdf',
                letter: 'V2_CyberDirekt_Anschreiben_DSGVO_JPB.docx',
                title: 'CyberDirekt Anschreiben DSGVO'
              },
              {
                url: 'CyberDirekt_Anschreiben_Homeoffice.pdf',
                letter: 'CyberDirekt_Anschreiben_Homeoffice.docx',
                title: 'CyberDirekt Anschreiben Homeoffice'
              },
              {
                url: 'V2_Anschreiben_Erpressungstrojaner_JPB.pdf',
                letter: 'V2_Anschreiben_Erpressungstrojaner_JPB.docx',
                title: 'CyberDirekt Anschreiben Erpressungstrojaner'
              },
              {
                url: 'V2_Anschreiben_Cyber-Attacke-kann-jeden-treffen_JPB.pdf',
                letter: 'V2_Anschreiben_Cyber-Attacke-kann-jeden-treffen_JPB.docx',
                title: 'Cyber-Attacke kann jeden treffen'
              },
              {
                url: 'V3_Anschreiben_Kreditkartendaten_JPB.pdf',
                letter: 'V3_Anschreiben_Kreditkartendaten_JPB.docx',
                title: 'CyberDirekt Anschreiben Kreditkartendaten'
              },
              {
                url: 'Anschreibenvorlage-Bedeutung-der-Cyberversicherung.pdf',
                letter: 'Anschreibenvorlage-Bedeutung-der-Cyberversicherung.docx',
                title: 'Anschreibenvorlage Bedeutung der Cyberversicherung'
              }
            ]
          },
          {
            title: 'Druckvorlagen',
            items: [
              {
                url: '300319_broschuere_arzt_geaendert.pdf',
                letter: '300319_broschuere_arzt_geaendert.pdf',
                title: 'Broschüre / Flyer Ärzte'
              },
              {
                url: '300319_broschuere_arzt_geaendert_kein_anschnitt.pdf',
                letter: '300319_broschuere_arzt_geaendert_kein_anschnitt.pdf',
                title: 'Broschüre / Flyer Ärzte (ohne Rand)'
              }
            ]
          }
        ]
      }
    },
    computed: {},
    mounted() {
    },
    methods: {
      getUrl(fileName) {
        return `https://s3.eu-central-1.amazonaws.com/de-cyberdirekt-uploads-public/letters/${fileName}`;
      },
      downloadLetter(fileName) {
        window.open(this.getUrl(fileName), '_blank')
      },
      takeMeBack() {
        this.$router.push('/vertriebsmaterialien')
      }
    },
    components: {
      Icon,
      MakeThumb
    }
  }
</script>

<style lang="scss" scoped>

    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .letter-btk {
        margin-top: calc($standardGutter * 2);
    }

    .letter-0 {
        // display: none;
        .l-container {
            .thumbnailer {
                background-position-y: -40px;
            }
        }
    }

</style>
