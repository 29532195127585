<template>
    <div>
        <div class="course-start"
             :style="{ backgroundImage: `url(${image})` }">
            <div class="course-start__dialog">
                <div class="course-start__title">{{ title }}</div>
                <button class="btn btn--secondary course-start__start"
                        @click="onStart"
                        type="button">Kurs starten
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'course-start',
    props: {
      image: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      onStart: {
        type: Function,
        required: true
      }
    }
  }
</script>

<style lang="scss">
    @import '../../../scss/trainings-variables';

    .course-start {
        position: relative;
        padding-top: 56.75%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        margin-bottom: 15px;

        &__dialog {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 30px 20px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.35);
            text-align: center;
            width: 60%;
            max-width: 300px;
            min-width: 240px;
        }

        &__title {
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.75rem;
            margin-bottom: 27px;
        }

        &__start {
            min-width: 170px;
        }

        @media screen and (max-width: 767px) {
            &__dialog {
                padding: 20px 15px;
                width: 50%;
            }

            &__title {
                margin-bottom: 20px;
            }

            &__start {
                min-width: 150px;
            }
        }
    }
</style>
