<template>
    <course-base-template :title="slide.title"
                          :subtitle="slide.subtitle"
                          :course-slug="courseSlug"
                          :icon="slide.icon"
                          :text="slide.text"
                          @back="back"
                          @next="next"></course-base-template>
</template>

<script>
  import CourseBaseTemplate from './CourseBaseTemplate.vue';

  export default {
    name: 'simple-template',
    components: {
      CourseBaseTemplate
    },
    props: {
      slide: {
        type: Object,
        required: true
      },
      courseSlug: String
    },
    computed: {
      slideNumber() {
        return +this.$route.params.slide;
      },
      prevSlideNumber() {
        return this.slideNumber - 1;
      },
      nextSlideNumber() {
        return this.slideNumber + 1;
      }
    },
    methods: {
      back() {
        const prevSlide = this.prevSlideNumber > 0 ? this.prevSlideNumber : null;

        this.$router.push({
          params: {
            ...this.$route.params,
            slide: prevSlide
          }
        });
      },
      next() {
        this.$router.push({
          params: {...this.$route.params, slide: this.nextSlideNumber}
        });
      }
    }
  }
</script>

<style lang="scss">

</style>
