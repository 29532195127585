import Vue from 'vue';
import store from '../store';

export default {
  getNewsletterSubscriptionState() {
    let brokerId = store.state.auth.userId;
    return Vue.http2.get(`brokers/${brokerId}/newsletter-subscription-state`);
  },
  async getAVVState() {
    let brokerId = store.state.auth.userId;
    const role = store.state.user.role

    let broker = {}
    if(role === 'agencyOwner') {
      const res = await Vue.http2.get(`brokers/${brokerId}`);
      broker = res.data
    } else if (role === 'broker') {
      if(store.state.supervisorData.orderProcessingChecked && store.state.supervisorData.orderProcessingCheckedOn ) {
        broker = {
          orderProcessingChecked: store.state.supervisorData.orderProcessingChecked,
          orderProcessingCheckedOn: store.state.supervisorData.orderProcessingCheckedOn,
        }
      } else {
        const { data } = await Vue.http2.get(`brokers/my-supervisor-data`)
        broker = data
      }
    }

    if (broker.orderProcessingChecked && broker.orderProcessingCheckedOn) {
      return {
        hasAgreed: true,
        orderProcessingChecked: broker.orderProcessingChecked,
        orderProcessingCheckedOn: broker.orderProcessingCheckedOn
      }
    }
    return {
      hasAgreed: false
    }
  },
  subscribeToNewsletter() {
    let brokerId = store.state.auth.userId;
    return Vue.http2.get(`brokers/${brokerId}/subscribe-to-newsletter`);
  },
  getAgencyBrokers() {
    return Vue.http2.get(`brokers/list-enterprise-brokers`);
  }
};
