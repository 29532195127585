<template>
  <Card class="scores-info py-4 mb-3 mb-lg-0">
            <div class="mb-3">
              <b>Gesamtwertung</b>
            </div>

            <b-form-checkbox
              v-if="!isOffer && !isAffiliate"
              class="cd-control checkbox-accent mb-2 d-print-none"
              id="hide-scores"
              name="hide-scores"
              v-model="isScoreNotVisible"
            >
              Wertung ausblenden
            </b-form-checkbox>
            <div class="text-sm">
              <ReadMore
                :content="{
                  bg: '#ebf0f2'
                }">
                <p>
                  Der Vergleich basiert auf aktuell 125 Tarifmerkmalen und wird fortlaufend weiterentwickelt. Trotz sorgfältiger Auswertung ist dieser Vergleich ohne Gewähr auf Richtigkeit und Vollständigkeit. Es gelten die Versicherungs&shy;bedingungen des jeweiligen Tarifs.
                </p>
                
                <ButtonComponent
                  btnStyle="link-primary"
                  behavior="externalLink"
                  :link="`${CLIENT_BASE}methodik-vergleich`"
                >
                  Weitere Informationen
                </ButtonComponent>
                <template #trigger-open>
                  <div class="d-flex justify-content-center">
                    <ButtonComponent
                      btn-style="link-primary"
                      btn-size="xs"
                      font-size="0.95rem"
                      behavior="button"
                      type="button">
                      Einblenden
                      <Icon name="arrow-down" width="1rem" class="ml-2"></Icon>
                    </ButtonComponent>
                  </div>
                </template>
                <template #trigger-close>
                  <div class="d-flex justify-content-center trigger-close">
                    <ButtonComponent
                      btn-style="link-primary"
                      btn-size="xs"
                      font-size="0.95rem"
                      behavior="button"
                      type="button">
                      Ausblenden <Icon name="arrow-up" width="1rem" class="ml-2"></Icon>
                    </ButtonComponent>
                  </div>
                </template>
              </ReadMore>
            </div>
          </Card>
</template>

<script>
import Card from '../../block/Card.vue'
import ReadMore from '../../ui/ReadMore.vue'
import ButtonComponent from '../../ui/ButtonComponent.vue'
import Icon from '../../../../Icon.vue'

export default {
   components: {
    Card,
    ReadMore,
    ButtonComponent,
    Icon
  },
  props: ['isOffer', 'isAffiliate', 'isScoreNotVisible'],
  computed: {
    CLIENT_BASE() {
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE;
      return CLIENT_BASE.replace(/\/?$/, '/')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
