<template>          
  <div class="thumbnailer">
    <!-- <img src="/static/tail-spin.svg" class="loader" v-if="!finishedDownload"> -->
  </div>
</template>

<script>
import PDFJS from 'pdfjs-dist'
import verge from 'verge'

export default {
  name: 'MakeThumb',
  data () {
    return {
      loaded: false,
      finishedDownload: false
    }
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.visibilityChanged()
    window.addEventListener('scroll', this.visibilityChanged)
  },
  computed: {
  },
  components: {
  },
  methods: {
    makeThumb () {
      const vm = this

      PDFJS.getDocument(this.url)
        .promise
        .then(doc => {
          return doc.getPage(1).then(this.genThumb)
            .then(function (canvas) {
              vm.$el.style.backgroundImage = 'url("' + canvas.toDataURL() + '")';
              setTimeout(() => {
                vm.finishedDownload = true
              }, 500)
            })
        })
    },
    visibilityChanged () {
      if (this.loaded) {
        return false
      }

      if (verge.inViewport(this.$el)) {
        this.makeThumb()
        this.loaded = true
      }
    },
    genThumb(page) {
      const vp = page.getViewport(1)
      const canvas = document.createElement('canvas')
      // const { width, height } = this.$el.parentElement.getBoundingClientRect()

      canvas.width = 595
      canvas.height = 842

      const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height)
      // console.log('width', width)
      // console.log('height', height)
      return page.render({
        canvasContext: canvas.getContext('2d'),
        viewport: page.getViewport(scale)
      }).promise.then(() => {
        return canvas
      })
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.visibilityChanged)
  }
}
</script>

<style lang="scss" scoped>
  // @import './scss/variables';


.thumbnailer {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loader {
    width: 83px;
  }
}

</style>
