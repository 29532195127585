import { mapGetters } from 'vuex'
export default {
  computed: {


    ...mapGetters(["style", 'isWWW', 'isBroker']),
    isAffiliateMixin() {
      return this.style === "affiliate";
    },
    order1Mixin() {
      if (this.isAffiliate) {
        return "Angebot";
      }

      return "Jetzt";
    },
    order2Mixin() {
      if (this.isAffiliate) {
        return "anfordern";
      } else if (this.isBroker || this.isWWW) {
        return "beantragen";
      }

      return "abschließen";
    },
    maxOffers() {
      return this.$store.state.checkout.application.maxOffers || this.$store.state.checkout.application.inputs.maxOffers || 4
    },
  },
};
