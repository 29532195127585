import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueMq from 'vue-mq'
import pick from 'lodash/pick';
import { get as getValue } from 'lodash';
import CryptoJS from "crypto-js";

// session storage
const noStorageData = {};
let hasSessionStorage = false;

if (window.sessionStorage) {
  try {
    const x = 'testStorage';
    window.sessionStorage.setItem(x, x);
    window.sessionStorage.removeItem(x);
    hasSessionStorage = true;
  } catch (e) {
    hasSessionStorage = false;
  }
}

let persistedState;

try {
  persistedState = createPersistedState({
    key: 'cd-broker',
    storage: {
      // set
      setItem(key, value) {
        if (hasSessionStorage) {
          try {
            window.sessionStorage.setItem(key, value);
          } catch(err) {
            console.error('local storage setItem: ')
          }
        } else {
          // console.log('G - noStorageData :', key)
          noStorageData[key] = value;
        }
      },

      // get
      getItem(key) {
        if (hasSessionStorage) {
          try {
            return window.sessionStorage.getItem(key);
          } catch(err) {
            console.error('local storage getItem: ')
          }
        } else {
          // console.log('R - noStorageData :', key)
          return noStorageData[key];
        }
      },

      // remove
      removeItem(key) {
        if (hasSessionStorage) {
          try {
            window.sessionStorage.removeItem(key);
            // console.log('R', key)
          } catch(err) {
            console.error('local storage removeItem: ')
          }
        } else {
          // console.log('remove', key)
          noStorageData[key] = null;
        }
      }
    },
    // this is a workaround to exclude the applicationInitialised from persisting.
    paths: [
      'auth',
      'user',
      'riskQuestions2',
      // 'offer',
      'selectedCourse',
      'selectedCourseProgress',
      'presseArticles',
      'notifications',
      'seenNotifications',
      'eventModalContent',
      'webinarList',
      'guidelines',
      'navigationOpened',
      'showNoOfferPopup',

      // 'checkout.application'
      // (!) Fixing safari "quotaExceededError":
      // Safari has the limitation for localStorage / sessionStorage size.
      // If we include all 'checkout.application' object here, then it will be caching a lot of the store data, wich will lead to "quotaExceededError"
      // As a workaround we could include only specific required data to cache and avoid to include big arrays and objects
      'checkout.application.activeStep',
      'checkout.application.affiliateBroker',
      'checkout.application.affiliateToken',
      'checkout.application.broker',
      'checkout.application.countryInterface',
      'checkout.application.cyberPrices',
      'checkout.application.industries',
      'checkout.application.inputs',
      'checkout.application.insTaxRate',
      // 'checkout.application.insuranceProperties', // a lot of data, but can't use app without it
      // just FYI: this is all the new data for the TR20, is important to have it in the store,
      // bedingungen-und-formulare/bedingungen will not work without this
      // 'checkout.application.insuranceProperties2_0', //a lot of data
      // bedingungen-und-formulare/bedingungen will not work without this too
      'checkout.application.insurers', //
      // 'checkout.application.insurersWithScores', // a lot of data, but can't use checkout without it
      'checkout.application.insurersWithScoresUpdateToken', // helps with getter update
      'checkout.application.isCheckoutLoadingOverlay',
      // 'checkout.application.isInitialDataLoaded', // do not cache it, so the app will fetch required data from the server
      'checkout.application.isInitialDataLoading',
      'checkout.application.isPrintMode',
      'checkout.application.isScoreVisible',
      'checkout.application.legalInsurers',
      'checkout.application.legalSteps',
      // 'checkout.application.maxOffers',
      'checkout.application.mode',
      'checkout.application.modulesChanged',
      'checkout.application.offer',
      'checkout.application.overallBenchmark',
      'checkout.application.prices',
      // 'checkout.application.printInsurers',
      'checkout.application.public',
      // 'checkout.application.questions',
      'checkout.application.selectedModulesType',
      // 'checkout.application.serviceProvider',
      'checkout.application.steps',
      'checkout.application.style',
      'checkout.application.insurerSortingKey',
    ], // paths to use for the persistedstate (everything else will be scrapped)
  });
} catch (e) {
  console.error(e);
}

Vue.use(Vuex);
// /session storage
// console.log(process.env.NODE_ENV ? process.env.NODE_ENV : '???')


Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1200,
    xl: 1440,
    xxl: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

// setup axions
const baseURL = process.env.API_BASE || process.env.VUE_APP_API_BASE;
const _axios = axios.create({
  baseURL: baseURL,
  withCredentials: true
});

Vue.http2 = Vue.prototype.$http = _axios;

// set X-ACCESS-TOKEN header for API calls
Vue.http2.interceptors.request.use(function(config) {
  let sessionValue;

  let exception = 'customers/send-email'
  if (config.url.indexOf('/api/') && config.url.indexOf(exception) === -1) {
    sessionValue = sessionStorage.getItem('cd-broker');
    sessionValue = sessionValue ? JSON.parse(sessionValue) : {};

    if (sessionValue.auth && sessionValue.auth.token) {
      config.headers['X-Access-Token'] = sessionValue.auth.token;
    }
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

Vue.http2.interceptors.response.use(function(response) {
  return response

}, function(error) {

  if (error.response.status === 401) {
    // handle unauthorised response
    store.commit('CLEAR_AUTH')
    if (!window.location.pathname.includes('authenticate') && !window.location.pathname.includes('reset')) {
      window.location.reload()
    }
  }

  return Promise.reject(error)
})



const store = new Vuex.Store({
  state: {
    application_name: 'portal-2',
    auth: {
      token: '',
      userId: '',
      role: null
    },
    user: {},
    riskQuestions2: [],
    offer: {},
    selectedCourse: null,
    selectedCourseProgress: null,
    presseArticles:[],
    notifications:[],
    seenNotifications:[],
    eventModalContent: null,
    webinarList:[],
    guidelines: [],
    navigationOpened: false,
    showNoOfferPopup: true,
    showInactivePopup: false,
    isActiveStatusTimePoint: new Date('2023/1/6'),
    brokerMarginsInfo: null,
    isFullScreenLoader: false
  },
  actions: {
    SEND_EMAIL (context, data) {
      //  #redme
      // By default, the only mandatory fields are "source" and "message".
      // Any additional variables with any names could be added and then will be displayed in the mail. So please make sure the variable names (keys) are readable.
      // In case we will need more destinations for any other contact forms, the function will be the same, just the "source" var will be changing.
      return Vue.http2.post('customers/send-email', data)
    },
    GET_GUIDELINES: async ({commit}) => {
      const { data } = await axios.post(`${process.env.VUE_APP_CMS_BASE}graphql`, {
        query: `{
          maklerPortalQuestions {
            question
            answer
            show
            expanded
          }
        }`
      })
      commit('SET_GUIDELINES', data.data.maklerPortalQuestions);
    },
    GET_WEBINAR_LIST: async ({commit}) => {
      // this endpoint gets 2 lists of 1)past webinars & 2)upcoming webinars that were sorted manually in cms
      const response = await axios.post(`${process.env.VUE_APP_CMS_BASE}graphql`, {
        query: `{
          webinarList {
            pastWebinars {
              webinar {
                id
                category
                upper_text
                text_1
                text_2
                text_3
                video_url
                cover_image{
                  formats
                  url
                }
                video_file{
                  url
                }
                button{
                  title
                  style
                  link
                  behavior
                }
                updatedAt
              }
            }
            upcomingWebinars {
              webinar {
                id
                category
                upper_text
                text_1
                text_2
                text_3
                video_url
                cover_image{
                  formats
                  url
                }
                video_file{
                  url
                }
                button{
                  title
                  style
                  link
                  behavior
                }
                updatedAt
              }
            }
          }
        }`
      })
      commit('SET_WEBINAR_LIST', response.data.data.webinarList);
    },
    GET_COURSE_DATA: async ({commit}, courseSlug) => {
      try {
        const course = await import(/* webpackChunkName: "course-[request]" */ `@/pages/trainings/${courseSlug}.json`);
        commit('SELECT_COURSE', course);
        return {success: true};
      } catch (error) {
        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_COURSE_PROGRESS: async ({commit}, courseSlug) => {
      let courseProgress = {
        trainingSlug: courseSlug,
        slideSlug: 'slide-0',
        isDone: false,
        result: '',
        answers: {},
        createdAt: new Date('2020-04-07T13:41:02.738Z'),
        updatedAt: new Date('2020-04-07T13:41:02.738Z'),
        quizRepeatCount: 0
      };

      commit('SET_COURSE_PROGRESS', courseProgress);

      return {success: true};
    },
    SAVE_PROGRESS: async ({commit}, updatedProgress) => {
      commit('UPDATE_COURSE_PROGRESS', updatedProgress);
      return {success: true};
    },
    LOGIN(context, data) {
      return Vue
        .http2
        .post('brokers/login?include=user', data)
        .then(async (response) => {
          if (!response) {
            return false
          }
          let {id, userId} = response.data;

          context.commit('SET_ACCESS_TOKEN', {
            token: id,
            userId
          });
          return await context.dispatch('GET_CURRENT_USER');
        })
        .catch(error => {
          throw error.response;
        });
    },
    async GET_BROKER_MARGIN_INFO(context, data) {
      const res = await Vue.http2.get(`insuranceproperties/get-broker-margins`)
      const secretKey = 's5v8y/B?E(H+MbQeThVmYq3t6w9z$C&F';
      const bytes = CryptoJS.AES.decrypt(res.data, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      context.commit('SET_BROKER_MARGINS', decryptedData);
      return true;
    },
    LOGIN_OAUTH(context, data) {
      const OAUTH_BASE = process.env.OAUTH_BASE || process.env.VUE_APP_OAUTH_BASE
      return Vue
        .http2
        .post(`${OAUTH_BASE}login`, data)
        .then((response) => {
          console.log('show decision view');
          console.log(response);
        })
        .catch(error => {
          throw error.response;
        });
    },
    LOGIN_SSO_CONCEPTIF(context, encryptedToken) {
      return Vue
        .http2
        .post('brokertool/create-or-login?agency=CONCEPTIF&data=' + encryptedToken, {})
        .then((response) => {
          const {id, userId} = response.data;

          context.commit('SET_ACCESS_TOKEN', {
            token: id,
            userId
          });

          return context.dispatch('GET_CURRENT_USER');
        });
    },
    LOGIN_SSO_PMA(context, encryptedToken) {
      return Vue
        .http2
        .post('brokertool/create-or-login?agency=PMA&data=' + encryptedToken, {})
        .then((response) => {
          const {id, userId} = response.data;

          context.commit('SET_ACCESS_TOKEN', {
            token: id,
            userId
          });

          return context.dispatch('GET_CURRENT_USER');
        });
    },
    LOGIN_SSO_SDV(context, encryptedToken) {
      return Vue
        .http2
        .post('brokertool/create-or-login?agency=SDV&data=' + encryptedToken, {})
        .then((response) => {
          const {id, userId} = response.data;

          context.commit('SET_ACCESS_TOKEN', {
            token: id,
            userId
          });

          return context.dispatch('GET_CURRENT_USER');
        });
    },
    FORGOT(context, email) {
      return Vue
        .http2
        .post('brokers/reset', {email: email})
    },
    LOGOUT(context) {
      const token = context.state.auth.token;
      return Vue
        .http2
        .post('brokers/logout', {
          id: token
        }).then(() => {
          context.commit('CLEAR_AUTH')
        });
    },
    GET_OFFERS(context, offerId) {
      const filter = {
        include: {
          relation: 'owner',
          scope: {
            fields: ['brokerAgencyId'],
          },
        },
        fields: [
          'additionalNotes',
          'answers',
          'answersOnline',
          'billingDate',
          'city',
          'comment',
          'company',
          'baobabQuoteCreatedDate',
          'baobabQuoteExpireDate',
          'baobabQuoteId',
          'baobabQuoteNumber',
          'baobabQuoteStatus',
          'corvusClientId',
          'corvusQuote',
          'corvusQuoteCreatedDate',
          'corvusQuoteExpireDate',
          'corvusQuoteId',
          'corvusQuoteStatus',
          'created',
          'createdBy',
          'deductible',
          'description',
          'editReason',
          'email',
          'firstname',
          'form',
          'gross',
          'id',
          'industry',
          'insuredSum',
          'insuredSumOption1',
          'insuredSumOption2',
          'insurer',
          'lastname',
          'link',
          'log',
          'net',
          'netTurnOver',
          'note',
          'paymentPeriod',
          'paymentMethod',
          'phone',
          'postal',
          'priceOverwrite',
          'pricesOption1',
          'pricesOption2',
          'reason',
          'salutation',
          'salutationOverwrite',
          'selectedModules',
          'selection',
          'showPricePerformWinner',
          'showRating',
          'sortBy',
          'state',
          'street',
          'startDate',
          'style',
          'quoteBindings',
          'transferLog',
          'website',
        ],
      }
      let offerDetailUrl = offerId ? `/${offerId}/?filter=${JSON.stringify(filter)}` : ''
      let offer = {}

      context.commit('SET_OFFER_LOADING', true)
      return Vue.http2
        .get(`/offers${offerDetailUrl}`)
        .then((response) => {
          offer = response.data

          const update = pick(offer, [
            'netTurnOver',
            'insuredSum',
            'insuredSumOption1',
            'insuredSumOption2',
            'insurer',
            'deductible',
            'paymentPeriod',
            'answers',
            'form',
            'answersOnline',
            'industry',
            'selectedModules',
            'sepa',
            'dueDate',
            'billingDate',
            'paymentMethod',
            'street',
            'postal',
            'city',
            'title',
            'additionalNotes',
            'startDate'
          ])
          context.commit('UPDATE_INPUT_VALUES', update)

          // We need the CALCULATE_CYBER_PRICES function, otherwise, the cockpit may break in some cases. So this check needs to be DELETED
          return context.dispatch('CALCULATE_CYBER_PRICES', { withAnswers: true })
        })
        .then(() => {
          context.commit('SET_OFFER', offer)
          return offer
        })
        .finally(() => {
          context.commit('SET_OFFER_LOADING', false)
        })
    },
    CHANGE_OFFER_OWNER(context, data) {
      const {offerId, createdBy} = data;

      return Vue
        .http2
        .patch(`/offers/${offerId}`, { createdBy });
    },
    REASSIGN_ENTERPRISE_OFFER(context, data) {
      const {offerId, brokerId} = data;

      return Vue
        .http2
        .post('/offers/reassign-enterprise-offer', {offerId, brokerId});
    },
    ASSIGN_BACK_ENTERPRISE_OFFER(context, data) {
      const {offerId} = data;

      return Vue
        .http2
        .post(`/offers/assign-back-enterprise-offer/${offerId}`, {});
    },
    PATCH_OFFERS(context, data) {
      const cloneData = Object.assign({}, data);

      delete cloneData.id;

      if (!cloneData.insurer || cloneData.insurer === 'no-recommendation') {
        cloneData.net = 0;
        cloneData.gross = 0;
      }

      return Vue
        .http2
        .patch(`/offers/${data.id}`, cloneData);
    },
    CREATE_BROKER(context, user) {
      return Vue
        .http2
        .post('/brokeragencies/', user);
    },
    SEND_OFFER(context, data) {
      return Vue
        .http2
        .post('/offers/send', data);
    },
    GET_USERS_AGENCY_FROM_VUEX(context, agencyId) {
      return new Promise((resolve, reject) => {
        if(this.state.user.brokerAgency.id === agencyId) {
          resolve(this.state.user.brokerAgency);
        } else {
          reject('BrokerAgency and Agency ids are not matching')
        }
      });
    },
    GET_USERS_AGENCY(context, agencyId) {
      return Vue
        .http2
        .get(`/brokeragencies/${agencyId}`)
        .then((response) => {
          return response
        })
    },
    async GET_USER_LAST_LOGIN_DATE(context) {
      const { data } = await Vue
        .http2
        .get(`brokers/my-last-login-date`)
      return data.date
    },

    async GET_MY_SUPERVISOR_DATA(context) {
      const { data } = await Vue
        .http2
        .get(`brokers/my-supervisor-data`)
      return data
    },

    GET_CURRENT_USER(context) {
      return new Promise(async(resolve, reejct) => {

        const brokerId = context.state.auth.userId;
        const lastLoginDateString = await context.dispatch('GET_USER_LAST_LOGIN_DATE')

        return Vue
          .http2
          .get(`brokers/${brokerId}?filter={"include":"brokerAgency"}`)
          .then((response) => {
            (async function(){
              const {
                salutation,
                id,
                brokerRegistrationId,
                firstname,
                lastname,
                email,
                billingEmail,
                hideOffers,
                twoFactorSMS,
                mobile,
                termsChecked,
                orderProcessingChecked,
                orderProcessingCheckedOn,
                brokerAgencyId,
                gutBeratenId,
                agency,
                role,
                bank,
                accountHolder,
                IBAN,
                BIC,
                brokerAgency,
                isActive,
                created
                // CYB-3097 use next two lines if response will have no "inactive" property, but "lastLoginDate"
                // lastLoginDate,
                // inactive = await context.dispatch('IS_USER_INACTIVE', lastLoginDate)
              } = response.data;

              const supervisorData =
                role !== 'agencyOwner'
                  ? await context.dispatch('GET_MY_SUPERVISOR_DATA')
                  : {}

              context.commit('SET_SUPERVISOR_DATA', supervisorData)
              context.commit('SET_CURRENT_USER', {
                id,
                salutation,
                brokerRegistrationId,
                brokerAgencyId,
                gutBeratenId,
                firstname,
                lastname,
                agency,
                email,
                billingEmail,
                hideOffers,
                twoFactorSMS,
                mobile,
                termsChecked,
                orderProcessingChecked: supervisorData.hasOwnProperty('orderProcessingChecked') ? supervisorData.orderProcessingChecked : orderProcessingChecked,
                orderProcessingCheckedOn: supervisorData.hasOwnProperty('orderProcessingCheckedOn') ? supervisorData.CheckedOn : orderProcessingCheckedOn,
                role,
                bank,
                accountHolder,
                IBAN,
                BIC,
                brokerAgency,
                isActive,
                lastLoginDateString,
                created
              });
              context.commit('SET_COUNTRY_INTERFACE', {agencyKey: brokerAgency.key, setFrom: 'broker-portal'})
              resolve()
            })()
          });
      })
    },
    async GET_CURRENT_USER_ACTIVE_STATUS(context) {
      const brokerId = context.state.auth.userId;
      let isActive

      await Vue
        .http2
        .get(`brokers/${brokerId}?filter={"include":"brokerAgency"}`)
        .then((response) => {
          isActive = response.data.isActive
        });
      context.commit('SET_ACTIVE_STATUS', isActive)
      return isActive
    },
    UPDATE_USER(context, data) {
      const brokerId = context.state.auth.userId;

      return Vue
        .http2
        .patch(`brokers/${brokerId}`, data)
        .then((response) => {
          const {
            salutation,
            brokerRegistrationId,
            firstname,
            lastname,
            email,
            billingEmail,
            hideOffers,
            termsChecked,
            orderProcessingChecked,
            orderProcessingCheckedOn,
            twoFactorSMS,
            mobile,
            brokerAgencyId,
            gutBeratenId,
            role,
            bank,
            accountHolder,
            IBAN,
            BIC,
            // isActive,
          } = response.data;

          context.commit('SET_CURRENT_USER', {
            salutation,
            brokerRegistrationId,
            brokerAgencyId,
            gutBeratenId,
            firstname,
            lastname,
            email,
            billingEmail,
            hideOffers,
            twoFactorSMS,
            orderProcessingChecked,
            orderProcessingCheckedOn,
            mobile,
            termsChecked,
            role,
            bank,
            accountHolder,
            IBAN,
            BIC,
            isActive,
          });
        });
    },
    UPDATE_AGENCY(context, data) {
      const {agencyId, newData} = data;

      return Vue
        .http2
        .patch(`/brokeragencies/${agencyId}`, newData)
        .then((response) => {
          return response
        })
    },
    SET_RISK_QUESTIONS2(context, questions) {
      context.commit('SET_RISK_QUESTIONS2', questions);
    },
    CHANGE_PASSWORD(context, pwdData) {
      return Vue.http2
        .post(`brokers/change-password`, pwdData)
    },
    SAVE_CUSTOMER_REGISTRATION({state}, {firstname, lastname}) {
      (function({industry, onlineRevenue, answersOnline, insuredSum, deductible}) {
        const regData = {
          firstname,
          lastname,
          industry,
          onlineRevenue,
          answersOnline,
          insuredSum,
          deductible
        };

        return Vue.http2
          .post(`/registrations/incomplete`, regData);
      })(state.checkout.application.inputs);
    },
    GET_PRESSE_ARTICLES: async (context) => {
      const response = await axios.post(`${process.env.VUE_APP_CMS_BASE}graphql`, {
        query: `{
          articles(where: { brokerPortal: true }) {
            title
            shortTextPreview
            category
            slug
            publishedDate
            author
            externalLink
            thumbnail {
              formats
              url
            }
            hideThumbnailOnTop
          }
        }`
      })
      context.commit('SET_PRESSE_ARTICLES', response.data.data.articles);
    },
    GET_NOTIFICATIONS: async (context) => {
      const response = await axios.post(`${process.env.VUE_APP_CMS_BASE}graphql`, {
        query: `{
          maklerNotificationList {
            notifications {
              notification {
                id
                big_window_header
                big_window_small_left_side_text_optional
                big_window_button_text
                big_window_button_link
                big_window_middle_button_text_optional
                big_window_middle_button_link_optional
                button_internal_redirect_optional
                big_window_price_optional
                notification_header
                notification_text
                notification_link_target
                notification_button_text
                event_start_date_optional
                event_end_date_optional
                show_until
                big_window_main_components_optional {
                  __typename
                  ... on ComponentContentBasicList {
                    list {
                      item
                    }
                    header
                  }
                  ... on ComponentRepeatableCopyItem {
                    title
                    copy
                    subtitle
                  }
                }
              }
            }
          }
        }`
      })
      const { notifications } = response.data.data.maklerNotificationList || []
      // filter out the old ones
      const now = Date.now()
      const filteredNotifications = notifications ? notifications.filter( n => Date.parse(n.notification && n.notification.show_until ? n.notification.show_until : 0) > now) : []
      context.commit('SET_NOTIFICATIONS', filteredNotifications );
    },
    SET_SEEN_NOTIFICATIONS: (context, id) => {
      context.commit('UPDATE_SEEN_NOTIFICATIONS', id );
    },
    IS_USER_ACTIVE_BY_LOGIN_DATE: (contxt, lastLoginDateString) => {
      const lastLoginDate = new Date(lastLoginDateString)
      const today = new Date()
      const isLastLoginBiggerThanToday = (lastLoginDate.getTime() - today.getTime()) > 1000
      let isErrors = false
      if (isLastLoginBiggerThanToday) {
        // console.error('Login date is bigger than today')
        isErrors = true
      }

      if (isErrors) {
        return
      }

      function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60 * 24);
      }

      return getDifferenceInDays(lastLoginDate, today) >= 365 ? false : true
    },
    SET_ACTIVE_STATUS: (context, isActive) => {
      // const brokerId = context.state.auth.userId
      // Vue
      //   .http2
      //   .patch(`brokers/${brokerId}`, {
      //     isActive,
      //   })
      context.commit('SET_ACTIVE_STATUS', isActive)
      isActive ? context.commit('STOP_SHOWING_INACTIVE_POPUP') : ''
    },
    CREATE_CORVUS_QUOTE: (context, data) => {
      const {offerId, action} = data
      return Vue.http2.post(`offers/create-corvus-quote`, {offerId, action})
    },
    GET_CORVUS_QUOTE: (context, corvusQuoteId) => {
      return Vue.http2.get(`offers/get-corvus-quote/${corvusQuoteId}`)
    },
    CREATE_BAOBAB_QUOTE: (context, data) => {
      const {offerId, action} = data
      return Vue.http2.post(`offers/create-baobab-quote`, {offerId, action})
    },
    GET_BAOBAB_QUOTE: (context, offerId) => {
      return Vue.http2.post(`offers/create-baobab-quote`, {offerId, action: 'check'})
    }
  },
  mutations: {
    SET_BROKER_MARGINS: (state, data) => {
      state.brokerMarginsInfo = data;
    },
    TOGGLE_OFFERS_PREVIEW: (state, isOpened) => {
      state.user.hideOffers = isOpened;
    },
    SET_NAVIGATION: (state, isOpened) => {
      state.navigationOpened = isOpened
    },
    TOGGLE_NAVIGATION: (state) => {
      state.navigationOpened = !state.navigationOpened
    },
    SET_WEBINAR_LIST: (state, webinarList) => {
      state.webinarList = webinarList;
    },
    SELECT_COURSE: (state, courseData) => {
      state.selectedCourse = courseData;
    },
    SET_COURSE_PROGRESS: (state, courseProgress) => {
      state.selectedCourseProgress = courseProgress;
    },
    UPDATE_COURSE_PROGRESS: (state, courseProgressPatch) => {
      state.selectedCourseProgress = {
        ...state.selectedCourseProgress,
        ...courseProgressPatch
      };
    },
    SET_OFFER: (state, offer) => {
      state.offer = offer;
    },
    SET_OFFER_SELECTION: (state, selection) => {
      if (!state.offer) {
        state.offer = {};
      }

      state.offer.selection = selection;
    },
    SET_OFFER_LOADING: (state, bool) => {
      state.isOfferLoading = bool;
    },
    SET_ACCESS_TOKEN: (state, authData) => {
      state.auth = authData;
    },
    SET_CURRENT_USER: (state, user) => {
      state.user = user;
    },
    SET_ACTIVE_STATUS: (state, status) => {
      state.user.isActive = status;
    },
    SET_SUPERVISOR_DATA: (state, data) => {
      state.supervisorData = data
    },
    SET_RISK_QUESTIONS2: (state, questions) => {
      state.riskQuestions2 = questions;
    },
    SET_PRESSE_ARTICLES: (state, articles) => {
      state.presseArticles = articles;
    },
    SET_NOTIFICATIONS: (state, notifications) => {
      state.notifications = notifications;
    },
    UPDATE_SEEN_NOTIFICATIONS: (state, id) => {
      const existsAlready = state.seenNotifications.find(e => e === id)
      if (existsAlready) return
      state.seenNotifications.push(id);
    },
    SET_EVENTMODAL_CONTENT: (state, id) => {
        state.eventModalContent = id
    },
    SET_GUIDELINES: (state, guidelines) => {
      state.guidelines = guidelines;
    },
    RESET_ROOT_OFFER(state) {
      state.offer = null
    },
    START_SHOWING_NO_OFFER_POPUP(state) {
      state.showNoOfferPopup = true
    },
    STOP_SHOWING_NO_OFFER_POPUP(state) {
      state.showNoOfferPopup = false
    },
    START_SHOWING_INACTIVE_POPUP(state) { //CYB-3097
      state.showInactivePopup = true
    },
    STOP_SHOWING_INACTIVE_POPUP(state) { //CYB-3097
      state.showInactivePopup = false
    },
    UPDATE_USER_STATE(state, {key, value}) {
      state.user[key] = value
    },
    CLEAR_AUTH(state) {
      state.auth = {
        token: '',
        userId: '',
        role: null
      }
      delete state.user.role
    },
    SET_COUNTRY_INTERFACE(state, {agencyKey, setFrom}) {
      if (state.checkout) {
        const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
        const isAon = aonKeys.some(k => k === agencyKey);
        if (isAon) {
          state.checkout.application.countryInterface = {
            value: 'AT', // Austria
            setFrom
          }
        } else {
          state.checkout.application.countryInterface = {
            value: 'DE', // Germany
            setFrom
          }
        }
      }
    },
    SET_FULL_SCREEN_LOADER(state, bool) {
      state.isFullScreenLoader = bool
    }
  },
  getters: {
    agencyKey(state) {
      return state.user.brokerAgency ? state.user.brokerAgency.key : null
    },
    getBrokerMarginsInfo(state) {
      return state.brokerMarginsInfo || null;
    },
    isAon(state, getters) {
      //aon austria or the test agency
      const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
      return aonKeys.some(k => k === getters.agencyKey);
    },
    hideOffers(state) {
      return state.user.hideOffers || false
    },
    webinarList(state) {
      return state.webinarList;
    },
    guidelines(state) {
      return state.guidelines;
    },
    offer(state) {
      return state.offer;
    },
    userId(state) {
      return state.auth.userId;
    },
    user(state) {
      return state.user;
    },
    isProfileValid(state) {
      let propertyCheck = [
        'salutation',
        'brokerRegistrationId',
        'firstname',
        'lastname',
        'email'
      ];

      if (state.user.role === 'broker')  {
        propertyCheck = propertyCheck.filter(e => e!== 'brokerRegistrationId')
      }

      return propertyCheck.every(property => !!state.user[property]);
    },
    isTermsChecked(state) {
      return state.user.termsChecked;
    },
    presseArticles(state) {
      return state.presseArticles;
    },
    notifications(state) {
      return state.notifications;
    },
    eventModalId(state) {
      return state.eventModalContent;
    },
    hasNewNotification(state) {
      const newIds = state.notifications.filter( n => {
        const seen = state.seenNotifications.find(e => e === n.id)
        if (!seen) {
          return n
        }
      })
      return newIds.length > 0
    },
    isFullScreenLoader(state) {
      return state.isFullScreenLoader
    }
  },
  plugins: [persistedState],
});

export default store
