// when type not specified it returns original url
export default function (imageObject, type = false) {

    const placeholder =  '/static/vectors/placeholder.png';

    if (!imageObject) {
      console.error('no image ')
      return placeholder
    }

    const imageUrl = findFormat(imageObject, type)
    return imageUrl
  }
  
  function  findFormat(imgO, type) {
    if (!imgO.formats) {
      return imgO.url
    }
  
    if (imgO.formats && imgO.formats.large && type === 'large') {
      return imgO.formats.large.url
    } else if (imgO.formats && imgO.formats.medium && type === 'medium') {
      return imgO.formats.medium.url
    } else if (imgO.formats && imgO.formats.small && type === 'small') {
      return imgO.formats.small.url
    } else {
      return imgO.url
    }
  }
  