<template>
  <div class="container d-print-none" :class="{'overflow-bg': this.navOpen}" @click="closeNav">
    <div
      id="b_nav"
      class="b_nav"
      :class="{active: navOpen}"
    >
      <div class="b_nav-inner">
        <ul class="b_nav__group">
          <li v-for="(item, index) in menu" :key="index">
              <a :href="item.external" target="_blank" rel="noopener" v-if="item.external">{{item.label}}</a>
              <router-link v-on:click.native="navOpen = false" :to="item.route" exact v-else>{{item.label}}</router-link>
          </li>
        </ul>
        <div class="b_nav__separator"></div>
        <ul id="user_actions" class="b_nav__group">
            <li>
                <router-link to="/settings">Einstellungen</router-link>
            </li>
            <li><a href="#" @click.prevent="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
    <!-- <button @click="toggleNav">Test</button> -->
  </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'

  export default {
    name: 'BHeader',
    props: ['user'],
    data() {
      return {
        menu: [
          {
            label: 'Marktvergleich',
            route: '/versicherung/1a/ihr-unternehmen/branche'
          },
          {
            label: 'Beratung & Vertrieb',
            route: {
              name: 'Vertriebsmaterialien'
            }
          },
          {
            label: 'Angebote',
            route: {
              name: 'Angebote'
            }
          },
          {
            label: 'Neuigkeiten',
            route: {
              name: 'Presse'
            }
          },
          {
            label: 'Weiterbildung',
            route: {
              name: 'Weiterbildung'
            }
          },
          {
            label: 'Affiliate Link',
            route: {
              name: 'Affiliate'
            }
          },
          {
            label: 'Phishing-Test',
            route: {
              name: 'PhishingVouchers'
            }
          },
          {
            label: 'Web Security Check',
            external: 'https://www.cyberdirekt.de/web-security-check'
          },
          {
            label: 'Mitarbeiterschulungen',
            route: {
              name: 'Mitarbeiterschulung'
            }
          },
          {
            label: 'Support',
            route: {
              name: 'Support'
            }
          }
        ]
      }
    },
    computed: {
      navOpen: {
        get() {
          return this.$store.state.navigationOpened
        },
        set(newValue) {
          this.$store.commit('SET_NAVIGATION', newValue)
        }
      }
    },
    mounted: function () {
      const vm = this
      eventBus.$on('openNav', res => {
        vm.navOpen = res
      })
    },
    methods: {
      logout() {
        const self = this;

        this.$store.dispatch('LOGOUT')
          .then(() => {
            self.$router.push('/login/authenticate');
          });
      },
      toggleNav() {
        this.navOpen = !this.navOpen
        eventBus.$emit('openNav', this.navOpen)
      },
      closeNav() {
        this.navOpen = false
        eventBus.$emit('openNav', this.navOpen)
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../scss/variables";
    @import "../scss/mixins";
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .b_nav-inner {
      padding: 1rem 1rem calc($standardGutter + 62px);
    }

    .b_nav {
        @include transition(width .4s);
        position: fixed;
        overflow: auto;
        top: 68px;
        right: 0;
        transform: translateX(100%);
        font-size: 1.15rem;
        height: 100%;
        width: 100%;
        max-width: 380px;
        background: #ffffff;
        box-shadow: 0 0.3px 0.4px rgba(0, 0, 0, 0.025),0 0.9px 1.5px rgba(0, 0, 0, 0.05),0 3.5px 6px rgba(0, 0, 0, 0.1);;
        border: 0;
        z-index: 10500;
        animation: slideToRight 0.35s ease-in-out;

        @include media-breakpoint-down(md) {
          top: 68px;
        }

        @include media-breakpoint-down(sm) {
          top: 62px;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
            li {
                a {
                    display: block;
                    padding: 0.75rem 1rem;
                    cursor: pointer;
                    color: #000;
                    transition: .2s;
                    border-radius: 4px;
                }
                a:hover {
                  text-decoration: none;
                    color: $blueTone;
                    background: rgba(49,169,224, 0.15);
                }

                a:not(.router-link-active):hover {
                }

                a.router-link-active {
                  color: $blueTone;
                    // opacity: 0.15;
                }
            }
            // li + li {
            //   margin-top: 0.75rem;
            // }
        }
        .b_nav__separator {
            top: 0;
            left: 0;
            height: 1px;
            margin: 1rem;
            background: #f3f3f3;
          }
    }

    .overflow-bg:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .b_nav.active {
      transform: translateX(0);
      animation: slideFromRight 0.35s ease-in-out;
    }

    @keyframes slideFromRight {
      0% {
        transform: translateX(100%)
      }
      100% {
        transform: translateX(0)
      }
    }
    @keyframes slideToRight {
      0% {
        transform: translateX(0)
      }
      100% {
        transform: translateX(100%)
      }
    }

</style>
