<template>
    <div class="view-main" :class="{'show-notification': isHeaderNotification}">
        <HeaderNotifications
            ref="notifs"
            :user="user"
            @updateNotifState="updateNotifState"
            @openAvvModal="toggleAvvModal"
            @subscribeNewsletter="subscribeNewsletter"
        >
        </HeaderNotifications>
        <div class="container">

            <div class="offers-preview__hide-button">
                <button class="btn btn-text"
                        type="button"
                        @click="toggleOffers()">
                    <span> [ Angebote
                        <span v-if="!hideOffers">
                            ausblenden
                        </span>
                        <span v-else>
                             einblenden
                        </span> ]
                    </span>
                </button>
            </div>
            <transition name="fade">
                <div
                v-if="!hideOffers"
                class="top-block"
                :class="[
                    {'show-cut': !isTouchDevice && $mq === 'lg'},
                    {'show-full': !isTouchDevice && offersHover && $mq === 'lg'}
                ]" >
                    <!-- <UserDetails :user="user" class="top-block__aside" /> -->
                    <div class="top-block__main">
                        <OfferPreview @mouseover.native="offersHover = true" @mouseleave.native="offersHover = false" />
                        <div class="offers-preview__show-all-btn">
                            <router-link to="/offers" class="btn btn-text">
                                <span> [ Zu allen Angeboten ] </span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </transition>

            <NavCards :cards="navCards" />
            <ModalAvv v-if="isAvvModal" @accept="reInitNotifs"/>
            <BackToTop />
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ModalAvv from '@/components/ModalAvv'
  import Icon from './Icon';
  import OfferPreview from '@/components/OfferPreview';
  import NavCards from '@/components/NavCards';
  import CardBasic from '@/components/CardBasic';
  import HeaderNotifications from '@/components/HeaderNotifications';
  import brokerApi from '../api/broker';
//   import UserDetails from '@/components/UserDetails';
  import BackToTop from '@/components/reusable/BackToTop.vue'
  import eventBus from '@/components/shared/EventBus.js'

  export default {
    name: 'Main',
    components: {
    ModalAvv,
    Icon,
    OfferPreview,
    NavCards,
    CardBasic,
    HeaderNotifications,
    //   UserDetails,
    BackToTop
},
    props: ['user'],
    data() {
      return {
        offersHover: false,
        isAvvModal: false,
        navCards: [
            {
                icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 4H9a2 2 0 0 0-2 2v38a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-8M11 26h9M11 31h9M11 36h9" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M26 26h9M26 31h9M26 36h9" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x="17" y="1" width="12" height="6" rx="2" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m12 16.941 2.453 1.544a1 1 0 0 0 1.362-.287L20 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="m28 19 6-6M28 13l6 6" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: '/versicherung/1a/ihr-unternehmen/branche',
                    type: 'internal'
                },
                title: 'Marktvergleich',
                text: 'Neues Angebot erstellen',
                variant: 'primary'
            },
            {
                 icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36.5 16c5.52 3.68 6.486 3.897 4 18-.217 1.228 0 2.5.5 4M36 26.366l-7.563-6.786c-1.512-1.454-4.537 0-3.025 2.424l3.813 6.415c.14.237.175.52.11.788-1.03 4.2.71 7.854 5.152 8.793" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M44 45v-2a2 2 0 0 0-2-2H32a2 2 0 0 0-2 2v2" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M27 37H14m20-17V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v27m6 6v-5a1 1 0 0 0-1-1H8m6 6-6-6" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 8h8M13 14h16M13 19h8M13 24h9" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: '/offers',
                    type: 'internal'
                },
                title: 'Angebote',
                text: 'Erstellte Angebote einsehen'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.75 45H40a6 6 0 0 0 6-6V11M4 11H2v28a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V3h32v4" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 40V7h2" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M32 8H18v12h24V8h-6M35 26h7v8h-7zM18 26h13m0 4h-2.5M18 30h6.5m6.5 4H18" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Presse'},
                    type: 'internal'
                },
                title: 'Neuigkeiten',
                text: 'Presse und News rundum Cyber'
            },
            {
                icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M45 17v7M45 24l-2 5M45 24l2 5M45 24v8" stroke="#F26722"/><path d="m1 16 22.203-9.653a2 2 0 0 1 1.595 0L47 16l-22.203 9.653a2 2 0 0 1-1.595 0L1 16Z" stroke="#31A9E0"/><path d="M9 20v20.016c9.022 5.202 21.48 5.076 30 0V24.003" stroke="#31A9E0"/><path d="M9 35.016c7.851 4.527 18.305 5.019 26.5 1.725" stroke="#31A9E0"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Weiterbildung'},
                    type: 'internal'
                },
                title: 'Weiterbildung',
                text: 'Webinare & Cyber‑Akademie'
            },
            {
                icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19.792 13.987c-5.07-.442-7.703 3.996-8.085 4.696a3.361 3.361 0 0 0-.095.19L5.89 31.05a9 9 0 0 0 4.317 11.974l1.81.85a9 9 0 0 0 11.973-4.316l5.567-11.843.032-.07c.11-.24.739-1.707.794-3.629" stroke="#31A9E0"/><path d="M17.645 18.517c-1.971.68-2.856 2.835-3.545 4.29-1.366 2.88-3.23 6.9-4.647 9.972a4.987 4.987 0 0 0 2.412 6.604l1.852.87a5 5 0 0 0 6.652-2.398l5.44-11.574.033-.071c.085-.2.49-1.21.484-2.378" stroke="#31A9E0"/><path d="M26.323 23.846c.687-1.96 3.335-1.485 4.054.168" stroke="#31A9E0"/><path d="M29.383 35.134c5.07.441 7.703-3.997 8.085-4.697.035-.063.065-.125.095-.19l5.723-12.176A9 9 0 0 0 38.97 6.097l-1.81-.85a9 9 0 0 0-11.973 4.316l-5.567 11.844-.032.069c-.109.24-.739 1.707-.793 3.63" stroke="#31A9E0"/><path d="M31.53 30.603c1.972-.68 2.856-2.835 3.545-4.289 1.366-2.881 3.23-6.901 4.648-9.973a4.987 4.987 0 0 0-2.413-6.604l-1.852-.87a5 5 0 0 0-6.652 2.398l-5.44 11.574a2.088 2.088 0 0 0-.033.072c-.085.199-.49 1.21-.484 2.377" stroke="#31A9E0"/><path d="M22.852 25.275c-.687 1.96-3.335 1.484-4.054-.17" stroke="#31A9E0"/><path d="m7 8 2.5 2.5M41.675 41l-2.5-2.5M4 16l2.5 1M44.675 33l-2.5-1M15.958 7.682 15.717 5M32.717 41.318 32.958 44" stroke="#F26722"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>',
                link: {
                    label: 'Öffnen',
                    url: '/affiliate',
                    type: 'internal'
                },
                title: 'Affiliate Link',
                text: 'Marktvergleich für Ihre Website'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 28v16a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V28M39 42h-6M29 42h-1" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 10V8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v2M34 10V8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v2M11 6v0a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v0" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 30v0A7.5 7.5 0 0 1 2 22.5V12a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v11a7 7 0 0 1-7 7v0m-22 0h10" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 28h-3a1 1 0 0 0-1 1v0a4 4 0 0 0 4 4v0a4 4 0 0 0 4-4v0a1 1 0 0 0-1-1h-3Zm0 0v-2M35 28h-3a1 1 0 0 0-1 1v0a4 4 0 0 0 4 4v0a4 4 0 0 0 4-4v0a1 1 0 0 0-1-1h-3Zm0 0v-2" stroke="#454545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Vertriebsmaterialien'},
                    type: 'internal'
                },
                title: 'Beratung & Vertrieb',
                text: 'Gesprächsleitfaden & Vertriebsmaterialien',
                variant: 'primary'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke-width="2" stroke-linecap="round"><path d="m13.912 5.938-8.5 14.722a2 2 0 0 0 .732 2.732l27.712 16a2 2 0 0 0 2.733-.732l8.5-14.722m-31.177-18 1.5-2.598a2 2 0 0 1 2.732-.732l18 10.392M13.912 5.938l10.537 15.96a2 2 0 0 0 1.55.894l19.09 1.146m0 0 1.5-2.598a2 2 0 0 0-.733-2.732l-6.709-3.874M6.412 18.928l14.892-1.794m16.284 19.794-4.354-10.194" stroke="#31A9E0" stroke-linejoin="round"/><path d="M8 7H2M11 31H3M42 45h-8M27 40h-8M15 40h-1M17 35h-1M4 16H3" stroke="#F26722"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'PhishingVouchers'},
                    type: 'internal'
                },
                title: 'Phishing-Test',
                text: 'Kostenlosen Phising-Test starten'
            },
            {
                icon: '<svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke-width="2"><path d="m34.778 40.435 4.95 4.95a4 4 0 0 0 5.657 0v0a4 4 0 0 0 0-5.657l-7.778-7.778-1.415 1.414m-1.414 7.071-2.828-2.828 1.414-1.415m1.414 4.243 2.829-2.828m-4.243-1.415 2.828-2.828m-2.828 2.828L29.672 32.5m6.52.864L32.5 29.672" stroke="#F26722" stroke-linecap="round" stroke-linejoin="round"/><circle cx="19" cy="19" r="17" stroke="#31A9E0"/><path d="M19 6c7.18 0 13 5.82 13 13s-5.82 13-13 13S6 26.18 6 19c0-5.784 3.777-10.686 9-12.373" stroke="#31A9E0" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 19a9.967 9.967 0 0 1 2.859-7" stroke="#F26722" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>',
                link: {
                    label: 'Öffnen',
                    url: 'https://www.cyberdirekt.de/web-security-check'
                },
                title: 'Web Security Check',
                text: 'Kostenlose Kurzprüfung starten'
            },
            {
                icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.682 27.107a5.003 5.003 0 1 1 0-10.006 5.003 5.003 0 0 1 0 10.006ZM27.414 34.829a10.011 10.011 0 0 1 7.268-3.13 10.005 10.005 0 0 1 6.697 2.572M18.497 25.502a6.2 6.2 0 1 1 0-12.399 6.2 6.2 0 0 1 0 12.399Z" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M30.11 43.206c-.309-3.703-1.232-6.138-1.737-6.995a12.271 12.271 0 0 0-4.396-4.395 11.744 11.744 0 0 0-5.91-1.602c-2.07 0-4.105.552-5.91 1.602a12.204 12.204 0 0 0-3.693 3.328l-.63.945" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 44.196C12.846 44.196 3.804 35.154 3.804 24 3.804 12.846 12.846 3.804 24 3.804c11.154 0 20.196 9.042 20.196 20.196 0 11.154-9.042 20.196-20.196 20.196Z" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Mitarbeiterschulung'},
                    type: 'internal'
                },
                title: 'Mitarbeiterschulungen',
                text: 'Fortbildung zur Schadensprävention'
            },
            {
                icon: '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38 23.5V17c0-7.732-6.268-14-14-14v0c-7.732 0-14 6.268-14 14v6.5c-.102 5.953.17 9.157 1.5 14.5M36.5 38c1.33-5.343 1.602-8.547 1.5-14.5" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M13 18v-1c0-6.075 4.925-11 11-11v0c6.075 0 11 4.925 11 11v1l3 2" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M36.5 38c-1.294.825-2.412 1.476-3.486 2m0 0c-1.795.876-3.469 1.397-5.642 1.782-.672.12-1.056.85-.751 1.46l.64 1.28c.15.299.439.505.77.539 2.075.208 3.055-.07 4.926-1.22a.985.985 0 0 0 .455-.975L33.014 40Z" stroke="#F26722" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.96 24v0a6 6 0 0 0-5.276 6.645l.35 3.05a5 5 0 0 0 5.467 4.405l.999-.1M38 24v0a6 6 0 0 1 5.277 6.645l-.35 3.05A5 5 0 0 1 37.46 38.1l-1-.1" stroke="#31A9E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                link: {
                    label: 'Öffnen',
                    url: {name: 'Support'},
                    type: 'internal'
                },
                title: 'Support nötig?',
                text: 'Schnell & einfach Termin buchen'
            }
        ],
        isHeaderNotification: false
      }
    },
    computed: {
        ...mapGetters(['hideOffers']),
        isTouchDevice () {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
        }
    },
    methods: {
        toggleOffers () {
            this.$store.commit('TOGGLE_OFFERS_PREVIEW', !this.hideOffers)
        },
        toggleAvvModal() {
            this.isAvvModal = !this.isAvvModal
        },
        subscribeNewsletter() {
            brokerApi
            .subscribeToNewsletter()
            .then((response) => {
                if (response.data && response.data.subscribed) {
                    this.reInitNotifs()
                } else {
                    this.showNewsletterError();
                }
            })
            .catch(() => {
                this.showNewsletterError();
            })
        },
        showNewsletterError() {
            this.$toasted.show('Bei der Newsletter-Anmeldung ist ein Fehler aufgetreten. Bitte wenden Sie sich and den Support. (Tel.: +49 30 40 36 95 29)', {
            theme: 'bubble',
            position: 'bottom-left',
            duration: 4000
            });
        },
        updateNotifState(isNotif) {
            this.isHeaderNotification = isNotif
        },
        reInitNotifs() {
            this.$refs.notifs.initNotifications()
        }
    },
    mounted () {
    //   this.$store
    //     .dispatch('UPDATE_USER', {
    //       orderProcessingChecked: false
    //     })
        eventBus.$on('toggleAvv', () => {
            this.isAvvModal = !this.isAvvModal
        })
    },
    destroyed: function () {
        eventBus.$off('toggleAvv')
    }
  }
</script>

<style lang="scss" scoped>
@import '../scss/variables';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .view-main {
        h1 {
            text-align: center;
            margin-bottom: 19px;
        }
    }

    .top-block {
        display: flex;
        margin-bottom: 2rem;
        transition: 1s;
        overflow: hidden;
        height: 100%;

        &:hover .top-block__main {
            position: relative;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            overflow: visible;
        }

        &__aside {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            width: 20%;
            padding-right: 1rem;
            
            @include media-breakpoint-down(lg) {
                width: 25%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &__main {
            position: relative;
            width: 100%;
            height: 100%;
            
            // @include media-breakpoint-down(lg) {
            //     width: 75%;
            // }

             @include media-breakpoint-down(md) {
                width: auto;
            }
        }
    }

    .top-block.show-cut {
        @include media-breakpoint-between(md,xl) {
            height: 140px;
        }

        @include media-breakpoint-down(md) {
            height: 100%;
        }

    }
    .offers-preview__hide-button, .offers-preview__show-all-btn {
        text-align: right;
    }

    .top-block.show-full {
            @include media-breakpoint-between(md,xl) {
                overflow: visible;

                ::v-deep .offer-preview {
                    box-shadow: 0px 0px 16px rgba(88, 89, 91, 0.24);

                    &__icon {
                        display: none;
                        top: auto;
                        bottom: 0;
                        transform: translateX(-50%) rotate(180deg);
                    }

                    &::after {
                        width: 0;
                        height: 0
                    }
                }
            }
        
    }
    .fade-enter-active,
    .fade-leave-active {
        transition-property: opacity;
        transition-duration: .6s;
    }

    .fade-enter-active {
        transition-delay: .2s;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
