<template>
  <div class="main">
    <div class="docx-wrapper">
    <section class="docx" style="padding: 113.4pt 56.7pt 56.7pt; width: 595.3pt; min-height: 841.9pt;">
        
        <article>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 20pt; font-size: 20pt;">A</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 20pt; font-size: 20pt;">uftragsverarbeitungsvertrag</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">g</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">emäß</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 15pt; font-size: 15pt;">Artikel
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 15pt; font-size: 15pt;">28</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 15pt; font-size: 15pt;">
                    DSGVO</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">z</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">wischen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">
                    dem</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; min-height: 15pt; font-size: 15pt;">Auftraggeber</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">-
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">gemäß
                    Hauptvertrag</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">
                    -</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">u</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">nd</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">
                    der</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; min-height: 15pt; font-size: 15pt;">CyberDirekt
                    GmbH</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">vertreten durch
                    d</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">ie</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">
                    Geschäftsführer</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Hanno Pingsmann
                    + Ole Sieverding</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Köpenicker
                    Straße 154</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">10997
                    Berlin</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 7.5pt; font-size: 7.5pt;">-
                    nachstehend Auftragnehmer genannt -</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: center;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;"></span>
            </p>
        </article>
        <!-- <footer>
            <p class="docx_fuzeile"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">AVV - CyberDirekt
                    GmbH - V 5.20</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"><span
                        class="docx-tab-stop"> </span>Stand: </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">01.03.2023</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"><span
                        class="docx-tab-stop"> </span>Seite </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">1</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"> von </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">2</span></p>
        </footer> -->
    </section>
    <section class="docx" style="padding: 113.4pt 56.7pt 56.7pt; width: 595.3pt; min-height: 841.9pt;">
        <article>
            <p style="margin-bottom: 0pt; line-height: 1;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt;">Inhaltsverzeichnis</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1;"></p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314727"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Präambel</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">3</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314728"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 1 Dauer
                        der Verarbeitung</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">3</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314729"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 2
                        Gegenstand der Verarbeitung</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">3</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314730"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 3 Art und
                        Zweck der Verarbeitung</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">4</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314731"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 4
                        Allgemeine Pflichten des Auftragnehmers und Weisungsgebundenheit</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">4</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314732"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 5
                        Mitteilungspflichten des Auftragnehmers</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">6</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314733"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 6
                        Subunternehmer und Unterauftragsverhältnisse</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">6</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314734"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 7
                        Technische und organisatorische Maßnahmen</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">8</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314735"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 8
                        Pflichten bei Beendigung des Vertrages</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">9</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314736"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 9 Rechte
                        und Pflichten des Auftraggebers</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">10</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314737"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 10
                        Sonderkündigungsrecht</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">10</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314738"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 11
                        Sonstige Bestimmungen</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">11</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314739"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 12
                        Schlussbestimmungen</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">11</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314740"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">§ 13
                        Bestätigungen</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">11</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314741"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Anlage 1:
                        Subunternehmer / Unterauftragsverarbeiter</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">12</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314742"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Anlage 2:
                        Technische und organisatorische Maßnahmen gemäß Art. 32 DSGVO</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">13</span></a>
            </p>
            <p class="docx_verzeichnis1"><a href="#_Toc116314743"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Anlage 3:
                        Betroffene Personen und Datenkategorien</span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"><span
                            class="docx-tab-stop"> </span></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;"></span><span
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">18</span></a>
            </p>
            <p style="line-height: 1;"><span id="_Hlk63272327"></span></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p style="line-height: 1;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314727"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">P</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">räambel</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dieser
                    Vertrag</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-18-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">basiert
                    auf dem zwischen den Parteien bestehenden Hauptvertrag;</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-18-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">findet
                    auf alle Tätigkeiten Anwendung, bei denen Mitarbeiter des Auftragnehmers oder durch ihn beauftragte
                    Subunternehmer/Unterauftragnehmer gemäß Anlage 1 dieses Vertrages personenbezogene Daten des
                    Auftraggebers verarbeiten bzw. auf diese Zugriff haben;</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-18-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">regelt
                    die Rechte und Pflichten von Auftraggeber und -nehmer, nachfolgend als </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Parteien</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    bezeichnet, im Rahmen der Verarbeitung von personenbezogenen Daten im Auftrag;</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-18-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 21.25pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">ersetzt</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">,</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    falls vorhanden</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">,</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    alle vorherigen Auftragsverarbeitungsverträge zwischen den Parteien; vorherige Verträge verlieren
                    mit der Unterzeichnung dieses Vertrages ihre Gültigkeit.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">In
                    diesem Vertrag verwendete Begriffe sind entsprechend ihrer Definition in der EU
                    Datenschutz-Grundverordnung, nachfolgend als DSGVO bezeichnet, zu verstehen. </span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">In
                    diesem Sinn ist der Auftraggeber der Verantwortliche, der Auftragnehmer der
                    Auftragsverarbeiter.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Soweit
                    Erklärungen nachfolgend schriftlich zu erfolgen haben, vereinbaren die Parteien die Textform gemäß
                    §&nbsp;126 b BGB, soweit dies gesetzlich zulässig ist und keine Partei ein berechtigtes Interesse an
                    der Beweissicherung mittels Schriftform gemäß §§&nbsp;126, 126 a BGB der jeweils anderen Partei
                    angezeigt hat.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Im
                    Übrigen können Erklärungen auch in anderer Form erfolgen, soweit eine angemessene Nachweisbarkeit
                    gewährleistet ist.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dieser
                    Vertrag regelt ausschließlich die ausdrücklich aufgeführten Punkte. </span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Für
                    alle in diesem Vertrag nicht geregelten Punkte gelten die Bestimmungen des Hauptvertrages.</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Erst
                    soweit im Hauptvertrag auch keine Regelung getroffen ist, gilt das Gesetz.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314728"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    1 Dauer der Verarbeitung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Dieser
                    Auftragsverarbeitungsvertrag beginnt unmittelbar mit Abschluss und gilt auf unbestimmte Zeit bis zur
                    Kündigung dieses Vertrages oder des Hauptvertrages durch eine der beiden Parteien.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314729"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    2 Gegenstand der Verarbeitung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    exakte Gegenstand</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    der Verarbeitung</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    ergibt sich aus dem Hauptvertrag, auf welchen hier explizit verwiesen wird.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314730"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    3 Art und Zweck der Verarbeitung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-35-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Art
                    und </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">der
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Zweck
                    der Verarbeitung</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    ergibt sich aus dem Hauptvertrag, auf welchen hier explizit verwiesen wird.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-35-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">D</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">ie
                    Kategorien der </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">möglichen
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">betroffenen
                    Personen sowie der</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    möglichen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">verarbeiteten
                    Daten sind in Anlage </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">3</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    zu diesem Vertrag näher spezifiziert.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314731"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    4 Allgemeine Pflichten des Auftragnehmers und Weisungsgebundenheit</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber hat hinsichtlich der Verarbeitung im Auftrag gegenüber dem Auftragnehmer ein
                    Weisungsrecht.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Im
                    Rahmen des Auftrags verarbeitete Daten wird der Auftragnehmer nur entsprechend der getroffenen
                    vertraglichen Vereinbarung oder nach Weisung des Auftraggebers berichtigen, löschen, sperren oder
                    weiterleiten.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Den
                    entsprechenden schriftlichen Weisungen des Auftraggebers gemäß § 9 Abs. 3 dieses Vertrages wird der
                    Auftragnehmer jederzeit Folge leisten.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">wird
                    den Auftraggeber unverzüglich darauf aufmerksam machen, wenn eine vom Auftraggeber erteilte Weisung
                    seiner Meinung nach gegen gesetzliche Bestimmungen verstößt.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">ist
                    berechtigt, die Durchführung der entsprechenden Weisung solange auszusetzen, bis sie durch den
                    Verantwortlichen bestätigt oder geändert wird.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">hat
                    ihm erteilte Weisungen und deren Umsetzung schriftlich zu dokumentieren.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">verarbeitet
                    personenbezogene Daten ausschließlich wie vertraglich vereinbart oder wie vom Auftraggeber
                    angewiesen, es sei denn, der Auftragnehmer ist gesetzlich zu einer bestimmten Verarbeitung
                    verpflichtet. </span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sofern
                    solche Verpflichtungen für ihn bestehen, teilt der Auftragnehmer diese dem Auftraggeber vor der
                    Verarbeitung mit, es sei denn, die Mitteilung ist ihm gesetzlich verboten.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">verwendet
                    darüber hinaus die zur Verarbeitung überlassenen Daten für keine anderen - insbesondere nicht für
                    eigene - Zwecke.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">bestätigt,
                    dass ihm die einschlägigen, allgemeinen datenschutzrechtlichen Vorschriften bekannt sind.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Er
                    beachtet die Grundsätze ordnungsgemäßer Datenverarbeitung und wird die Daten, die er im Auftrag für
                    den Auftraggeber verarbeitet, getrennt von anderen Daten verarbeiten.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Eine
                    physische Trennung ist nicht zwingend erforderlich.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">verpflichtet
                    sich, die Vertraulichkeit bei der Verarbeitung streng zu wahren. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Personen,
                    die Kenntnis von den im Auftrag verarbeiteten Daten erhalten können, haben sich schriftlich zur
                    Vertraulichkeit zu verpflichten, soweit sie nicht bereits gesetzlich einer einschlägigen
                    Geheimhaltungspflicht unterliegen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">In
                    diesem Zusammenhang sichert der Auftragnehmer zu, dass die bei ihm zur Verarbeitung eingesetzten
                    Personen vor Beginn der Verarbeitung mit den relevanten Bestimmungen des Datenschutzes und dieses
                    Vertrag</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">e</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">s
                    vertraut gemacht wurden. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Entsprechende
                    Schulungs- und Sensibilisierungsmaßnahmen sind angemessen regelmäßig zu wiederholen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">trägt
                    dafür Sorge, dass zur Auftragsverarbeitung eingesetzte Personen hinsichtlich der Erfüllung der
                    Datenschutzanforderungen laufend angemessen angeleitet und überwacht werden.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-21-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">leitet
                    alle an ihn gerichteten Anfragen unverzüglich an den Auftraggeber weiter.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Alle
                    erforderlichen Angaben und Dokumentationen sind vorzuhalten und dem Auftraggeber auf Anforderung
                    unverzüglich zuzuleiten.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Wird
                    der Auftraggeber durch Aufsichtsbehörden oder andere Stellen einer Kontrolle unterzogen, oder machen
                    betroffene Personen gegen ihn Rechte geltend, verpflichtet sich der Auftragnehmer, den Auftraggeber
                    im erforderlichen Umfang zu unterstützen, soweit die Verarbeitung im Auftrag betroffen ist.</span>
            </p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Auskünfte
                    an Dritte oder Betroffene darf der Auftragnehmer nur nach vorheriger Zustimmung durch den
                    Auftraggeber erteilen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">D</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">er
                    Auftragnehmer </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">hat
                    nachfolgend benannte, </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">fachkundige
                    und zuverlässige Person als </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">externen
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Beauftragten
                    für den Datenschutz</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    bestellt</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">:</span>
            </p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dipl.-Kfm.
                    Guido Babinsky</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">c/o
                    basucon GmbH</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Röntgenstraße
                    44 a</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">D-</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">86368
                    Gersthofen</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Fon:
                    +49 </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">821
                    899 473</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">-</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    0</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mail:
                </span><a href="mailto:datenschutz@cyberdirekt.de"><span class="docx_hyperlink"
                        style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">datenschutz@cyberdirekt.de</span></a>
            </p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Änderungen
                    in der Person oder den Aufgaben des Beauftragten teilt der Auftragnehmer dem Auftraggeber
                    unverzüglich mit.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">In
                    Zweifelsfällen kann sich der Auftraggeber direkt an den </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">externen
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenschutzbeauftragten
                    wenden.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-20-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Auftragsverarbeitung erfolgt grundsätzlich innerhalb der EU oder des EWR. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Jegliche
                    Verlagerung in ein Drittland darf nur mit ausdrücklicher Zustimmung des Auftraggebers und unter den
                    in Kapitel </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">5</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    der DSGVO enthaltenen Bedingungen sowie unter Einhaltung der Bestimmungen dieses Vertrag</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">e</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">s
                    erfolgen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314732"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    5 Mitteilungspflichten des Auftragnehmers</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer teilt dem Auftraggeber Verletzungen des Schutzes personenbezogener Daten unverzüglich
                    mit. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Auch
                    begründete Verdachtsfälle sind mitzuteilen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Mitteilung hat spätestens innerhalb von </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">48</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    Stunden ab Kenntnis des Auftragnehmers vom relevanten Ereignis an eine vom Auftraggeber benannte
                    Adresse zu erfolgen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sie
                    muss mindestens folgende Angaben enthalten:</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-23-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">den
                    Namen und die Kontaktdaten des Datenschutzbeauftragten oder einer sonstigen Anlaufstelle für weitere
                    Informationen;</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-23-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">eine
                    Beschreibung der Art der Verletzung des Schutzes personenbezogener Daten, soweit möglich mit Angabe
                    der Kategorien, der ungefähren Zahl der betroffenen Personen und der ungefähren Zahl der betroffenen
                    personenbezogenen Datensätze;</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-23-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">eine
                    Beschreibung der vom Auftragnehmer ergriffenen oder vorgeschlagenen Maßnahmen zur Behebung der
                    Verletzung des Schutzes personenbezogener Daten und gegebenenfalls Maßnahmen zur Abmilderung ihrer
                    möglichen nachteiligen Auswirkungen;</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-23-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">eine
                    Beschreibung der wahrscheinlichen Folgen der Verletzung des Schutzes personenbezogener Daten.</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ebenfalls
                    unverzüglich mitzuteilen sind erhebliche Störungen bei der Auftragsdurchführung sowie Verstöße des
                    Auftragnehmers oder der bei ihm beschäftigten Personen gegen datenschutzrechtliche Bestimmungen oder
                    die in diesem Vertrag getroffenen Festlegungen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer informiert den Auftraggeber unverzüglich über Kontrollen oder Maßnahmen von
                    Aufsichtsbehörden oder anderen Dritten, soweit diese einen Bezug zur Auftragsverarbeitung aufweisen.
                </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-22-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer unterstützt den Auftraggeber unter Berücksichtigung der Art der Verarbeitung und der
                    dem Auftragnehmer zur Verfügung stehenden Informationen bei der Einhaltung seiner Pflichten gemäß
                    Art. 32 bis 36 DSGVO im erforderlichen Umfang.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314733"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    6 Subunternehmer und Unterauftragsverhältnisse</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer ist berechtigt, zum Zweck der Erfüllung dieses Vertrages, Subunternehmer bzw.
                    Unterauftragsverarbeiter zu beauftragen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Beauftragung von Subunternehmern ist nur zulässig, wenn dem Subunternehmer vertraglich mindestens
                    Datenschutzpflichten auferlegt wurden, die mit den in diesem Vertrag vereinbarten Pflichten
                    vergleichbar sind.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber erhält auf Verlangen Einsicht in die relevanten Verträge zwischen Auftragnehmer und
                    Subunternehmer.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Rechte des Auftraggebers müssen auch gegenüber dem Subunternehmer wirksam ausgeübt werden können.
                </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Insbesondere
                    muss der Auftraggeber berechtigt sein, jederzeit in dem hier festgelegten Umfang Kontrollen auch bei
                    Subunternehmern durchführen zu können oder durch Dritte durchführen zu lassen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Verantwortlichkeiten des Auftragnehmers und des Subunternehmers sind eindeutig voneinander
                    abzugrenzen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer wählt den Subunternehmer unter besonderer Berücksichtigung der Eignung der vom
                    Subunternehmer getroffenen technischen und organisatorischen Maßnahmen sorgfältig aus.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Weiterleitung von im Auftrag verarbeiteten Daten an den Subunternehmer ist erst zulässig, wenn sich
                    der Auftragnehmer schriftlich dokumentiert davon überzeugt hat, dass der Subunternehmer seine
                    Verpflichtungen vollständig erfüllt hat.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Beauftragung von Subunternehmern, die Verarbeitungen im Auftrag nicht ausschließlich auf dem Gebiet
                    der EU</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    oder</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    des EWR</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    erbringen, ist </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">nur
                    zulässig, wenn die Subunternehmer geeignete Garantien gemäß Art. 46 Abs. 2 DSGVO bieten.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer hat die Einhaltung der Pflichten des Subunternehmers regelmäßig, spätestens alle 12
                    Monate, angemessen zu überprüfen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Prüfung und ihr Ergebnis sind so aussagekräftig schriftlich zu dokumentieren, dass sie für einen
                    fachkundigen Dritten nachvollziehbar sind.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    hier niedergelegten sonstigen Pflichten des Auftragnehmers gegenüber Subunternehmern bleiben
                    unberührt.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Unterauftragsverhältnisse
                    im Sinne dieses Vertrages sind nur solche Leistungen, die einen direkten Zusammenhang mit der
                    Erbringung der Hauptleistung aufweisen. </span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Nebenleistungen,
                    wie beispielsweise Transport, Wartung und Reinigung sowie die Inanspruchnahme von
                    Telekommunikationsdienstleistungen oder Benutzerservices sind nicht erfasst.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Pflicht des Auftragnehmers, auch in diesen Fällen die Beachtung von Datenschutz und Datensicherheit
                    sicherzustellen, bleibt hiervon unberührt.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Zurzeit
                    sind die in der Anlage 1 zu diesem Vertrag mit Namen, Anschrift</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">,
                    Verarbeitungs</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">region</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    und Auftragsinhalt bezeichneten Subunternehmer mit der Verarbeitung von personenbezogenen Daten in
                    dem dort genannten Umfang beschäftigt und durch den Auftraggeber genehmigt.</span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer wird den Auftraggeber während der Vertragslaufzeit über jede beabsichtigte Änderung in
                    Bezug auf die Hinzuziehung oder die Ersetzung von Subunternehmern bzw. Unterauftragsverarbeitern
                    informieren.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Diese
                    Information wird dem Auftraggeber gemäß Art. 28 Abs. 9 DSGVO schriftlich zur Verfügung gestellt; die
                    Information kann auch in einem elektronischen Format übermittelt werden.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber hat gemäß Art. 28 Abs. 2 Satz 2 nach Erhalt der vorstehenden Information die
                    Möglichkeit, gegen die beabsichtigte Änderung mit einer Frist von 4 Wochen ab Erhalt der Information
                    Einspruch zu erheben.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Nach
                    dem Einspruch des Auftraggebers haben beide Vertragsparteien das Recht, das Vertragsverhältnis ab
                    Zugang des Einspruchs mit einer Frist von 4 Wochen zu kündigen.</span></p>
            <p class="docx_listenabsatz docx-num-24-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Erfolgt
                    innerhalb der</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    im vorherigen Absatz</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    genannten Frist von 4 Wochen kein Einspruch des Auftraggebers, gilt die Zustimmung des Auftraggebers
                    zur Beauftragung des weiteren Subunternehmers bzw. Unterauftragsverarbeiters als erteilt.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314734"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    7 Technische und organisatorische Maßnahmen</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer trifft alle erforderlichen technischen und organisatorischen Maßnahmen gemäß Art. 32
                    DSGVO zum angemessenen Schutz der personenbezogenen Daten des Auftraggebers, insbesondere mindestens
                    die in der Anlage 2 zu diesem Vertrag aufgeführten Maßnahmen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ein
                    Verweis auf Informationen, die dieser Vereinbarung oder ihren Anlagen nicht unmittelbar entnommen
                    werden können, ist nicht zulässig.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Datensicherheitsmaßnahmen können der technischen und organisatorischen Weiterentwicklung
                    entsprechend angepasst werden, soweit Änderungen an technische und rechtliche Gegebenheiten
                    erforderlich werden. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Wesentliche
                    Änderungen, die die Integrität, Vertraulichkeit oder Verfügbarkeit der personenbezogenen Daten
                    beeinträchtigen können, wird der Auftragnehmer im Voraus mit dem Auftraggeber abstimmen.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Zur
                    Aufrechterhaltung der Informationssicherheit erforderliche Änderungen hat der Auftragnehmer
                    unverzüglich umzusetzen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Änderungen
                    sind dem Auftraggeber unverzüglich mitzuteilen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Soweit
                    die getroffenen Sicherheitsmaßnahmen den ursprünglichen Anforderungen des Auftraggebers nicht oder
                    nicht mehr genügen, benachrichtigt der Auftragnehmer den Auftraggeber unverzüglich.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer sichert zu, dass die im Auftrag verarbeiteten Daten von sonstigen Datenbeständen
                    strikt getrennt werden. </span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Eine
                    physische Trennung ist jedoch nicht zwingend erforderlich.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Kopien
                    oder Duplikate werden ohne Wissen des Auftraggebers nicht erstellt.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ausgenommen
                    sind technisch notwendige, temporäre Vervielfältigungen, soweit eine Beeinträchtigung des hier
                    vereinbarten Datenschutzniveaus ausgeschlossen ist.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Verarbeitung von Daten in Privatwohnungen (Homeoffice) sowie mobiles Arbeiten sind nur gestattet,
                    wenn vom Auftragnehmer sichergestellt ist, dass</span></p>
            <p class="docx_listenabsatz" style="line-height: 1;"></p>
            <p class="docx_listenabsatz docx-num-31-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">dabei
                    ein diesem Vertrag entsprechendes Niveau an Datenschutz und Datensicherheit gewährleistet
                    ist;</span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt;"></p>
            <p class="docx_listenabsatz docx-num-31-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">die
                    in diesem Vertrag bestimmten Kontrollrechte des Auftraggebers </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">–
                    unter Beachtung der Persönlichkeitsrechte der Mitarbeiter – </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">uneingeschränkt
                    auch </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">direkt
                    bei den jeweiligen Mitarbeitern</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    ausgeübt werden können;</span></p>
            <p class="docx_listenabsatz" style="line-height: 1; margin-left: 21.3pt;"></p>
            <p class="docx_listenabsatz docx-num-31-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">eine
                    speziell auf diese Art der Verarbeitung abgestimmte Datenschutzrichtlinie existiert;</span></p>
            <p class="docx_listenabsatz" style="line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt;"></p>
            <p class="docx_listenabsatz docx-num-31-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">die
                    Mitarbeiter, die diese Art der Verarbeitung durchführen, nachweislich für diese Art der Verarbeitung
                    geschult wurden.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Verarbeitung von Daten im Auftrag mit Privatgeräten ist unter keinen Umständen gestattet.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dedizierte
                    Datenträger, die vom Auftraggeber stammen bzw. für den Auftraggeber genutzt werden, werden besonders
                    gekennzeichnet und unterliegen der laufenden Verwaltung. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sie
                    sind jederzeit angemessen aufzubewahren und dürfen unbefugten Personen nicht zugänglich sein.</span>
            </p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sämtliche
                    Ein- und Ausgänge werden schriftlich dokumentiert.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-25-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer führt den regelmäßigen Nachweis der Erfüllung seiner Pflichten, insbesondere der
                    vollständigen Umsetzung der vereinbarten technischen und organisatorischen Maßnahmen sowie ihrer
                    Wirksamkeit.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Nachweis kann auch durch genehmigte Verhaltensregeln oder ein genehmigtes Zertifizierungsverfahren
                    erbracht werden.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314735"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    8 Pflichten bei Beendigung des Vertrages</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Bei
                    Beendigung des Auftragsverhältnisses, oder jederzeit auf Verlangen des Auftraggebers, hat der
                    Auftragnehmer die im Auftrag verarbeiteten Daten nach Wahl des Auftraggebers entweder zu vernichten
                    oder an den Auftraggeber zu übergeben. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ebenfalls
                    zu vernichten sind sämtliche vorhandenen Kopien der Daten.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Vernichtung hat so zu erfolgen, dass eine Wiederherstellung auch von Restinformationen mit
                    vertretbarem Aufwand nicht mehr möglich ist. </span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Eine
                    physische Vernichtung erfolgt gemäß DIN 66399. </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer ist verpflichtet, die unverzügliche Rückgabe bzw. Löschung auch bei Subunternehmern
                    herbeizuführen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer hat den Nachweis der ordnungsgemäßen Vernichtung</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    oder Übergabe</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    zu führen und dem Auftraggeber unverzüglich vorzulegen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dokumentationen,
                    die dem Nachweis der ordnungsgemäßen Datenverarbeitung dienen, sind durch den Auftragnehmer den
                    jeweiligen Aufbewahrungsfristen entsprechend auch über das Vertragsende hinaus aufzubewahren.
                </span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Er
                    kann sie zu seiner Entlastung dem Auftraggeber bei Vertragsende übergeben.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-26-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Parteien sind sich darüber einig, dass die Einrede des Zurückbehaltungsrechts durch den
                    Auftragnehmer im Sinne des § 273 BGB hinsichtlich der verarbeiteten Daten und der zugehörigen
                    Datenträger ausgeschlossen </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">ist</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">.</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314736"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    9 Rechte und Pflichten des Auftraggebers</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Für
                    die Beurteilung der Zulässigkeit der beauftragten Verarbeitung sowie für die Wahrung der Rechte von
                    Betroffenen ist allein der Auftraggeber verantwortlich.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber erteilt alle Aufträge, Teilaufträge oder Weisungen schriftlich und dokumentiert
                    diese.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber ist verpflichtet, weisungs</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">-
                    und kontroll</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">berechtigte
                    Personen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">,
                    welche nicht zu den vertretungsberechtigten </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Organen/</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Personen
                    des Auftraggebers gehören, </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">namentlich
                    und konkret zu benennen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    und </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">dem
                    Auftragnehmer schriftlich mitzuteilen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">.</span>
            </p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">In
                    dringenden Fällen können Weisungen durch die vom Auftraggeber konkret benannten Personen gegenüber
                    dem Auftragnehmer auch mündlich erteilt werden.</span></p>
            <p class="docx_listenabsatz"></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Solche
                    Weisungen wird der Auftraggeber unverzüglich schriftlich bestätigen und dokumentieren.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber informiert den Auftragnehmer unverzüglich, wenn er Fehler oder Unregelmäßigkeiten bei
                    der Prüfung der Auftragsergebnisse feststellt.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-27-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber ist berechtigt, die Einhaltung der Vorschriften über den Datenschutz und der
                    vertraglichen Vereinbarungen beim Auftragnehmer in angemessenem Umfang selbst, oder durch geeignete
                    Dritte, insbesondere durch die Einholung von Auskünften, die Einsichtnahme in die gespeicherten
                    Daten und die Datenverarbeitungsprogramme, sowie sonstige Kontrollen unter folgenden Maßgaben vor
                    Ort zu kontrollieren:</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-28-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Den
                    mit der Kontrolle betrauten Personen oder geeigneten Dritten, die der Auftraggeber namentlich und
                    konkret benannt hat, ist vom Auftragnehmer soweit erforderlich Zutritt und Einblick zu
                    ermöglichen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-28-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftragnehmer ist verpflichtet, erforderliche Auskünfte zu erteilen, Abläufe zu demonstrieren und
                    Nachweise zu führen, die zur Durchführung einer Kontrolle erforderlich sind.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-28-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Kontrollen
                    beim Auftragnehmer haben ohne vermeidbare Störungen seines Geschäftsbetrieb</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">e</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">s
                    zu erfolgen.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-28-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Soweit
                    nicht aus vom Auftraggeber schriftlich zu dokumentierenden, dringlichen Gründen anders angezeigt,
                    finden Kontrollen nach angemessener Vorankündigung und zu Geschäftszeiten des Auftragnehmers, sowie
                    nicht häufiger als alle 12 Monate statt.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-28-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.25pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Soweit
                    der Auftragnehmer den Nachweis der korrekten Umsetzung der vereinbarten Datenschutzpflichten
                    erbringt, soll sich eine Kontrolle auf Stichproben beschränken.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314737"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    10 Sonderkündigungsrecht</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-29-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Der
                    Auftraggeber kann diesen Vertrag jederzeit ohne Einhaltung einer Frist außerordentlich kündigen,
                    wenn ein schwerwiegender Verstoß des Auftragnehmers gegen </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">geltende
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenschutzvorschriften
                    oder die Bestimmungen dieses Vertrages vorliegt, der Auftragnehmer eine rechtmäßige Weisung des
                    Auftraggebers nicht ausführt oder der Auftragnehmer Kontrollrechte des Auftraggebers vertragswidrig
                    verweigert. </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-29-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ein
                    schwerwiegender Verstoß liegt insbesondere vor, wenn der Auftragnehmer die in diesem Vertrag
                    bestimmten Pflichten, insbesondere die vereinbarten technischen und organisatorischen Maßnahmen, in
                    erheblichem Maße nicht erfüllt oder nicht erfüllt hat.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-29-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Bei
                    unerheblichen Verstößen setzt der Auftraggeber dem Auftragnehmer eine angemessene Frist zur Abhilfe.
                    Erfolgt die Abhilfe nicht rechtzeitig, so ist der Auftraggeber zur außerordentlichen Kündigung wie
                    in diesem Abschnitt beschrieben berechtigt.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314738"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    11 Sonstige Bestimmungen</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-30-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Beide
                    Parteien sind verpflichtet, alle im Rahmen des Vertragsverhältnisses erlangten Kenntnisse von
                    Geschäftsgeheimnissen und Datensicherheitsmaßnahmen der jeweils anderen Partei auch nach Beendigung
                    des Vertrages dauerhaft und unbefristet vertraulich zu behandeln.</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 21.3pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-30-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Bestehen
                    Zweifel, ob eine Information der Geheimhaltungspflicht unterliegt, ist sie bis zur schriftlichen
                    Freigabe durch die andere Partei vertraulich zu behandeln. </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-30-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sollte
                    Eigentum des Auftraggebers beim Auftragnehmer durch Maßnahmen Dritter, beispielsweise durch Pfändung
                    oder Beschlagnahme, durch ein Insolvenz- oder Vergleichsverfahren oder durch sonstige Ereignisse
                    gefährdet werden, so hat der Auftragnehmer den Auftraggeber unverzüglich zu verständigen.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314739"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    12 Schlussbestimmungen</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dieser
                    Vertrag ersetzt frühere Fassungen mit Wirkung ab dem Tag des Abschlusses.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Erfüllungsort
                    für alle Leistungen des Auftragnehmers ist der Sitz des Auftragnehmers. </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Änderungen
                    oder Ergänzungen zu diesem Vertrag bedürfen der Schriftform. Dies gilt auch für die Abbedingung des
                    Schriftformerfordernisses.</span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sollten
                    Bestimmungen dieses Vertrages unwirksam oder nichtig sein oder werden, bleibt die Wirksamkeit der
                    übrigen Bestimmungen hiervon unberührt. </span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Parteien verpflichten sich, die unwirksame oder nichtige Bestimmung durch eine solche zu ersetzen,
                    die dem mit der unwirksamen oder nichtigen Bestimmung wirtschaftlich Gewollten am nächsten kommt.
                </span></p>
            <p
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
            </p>
            <p class="docx_listenabsatz docx-num-19-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -21.3pt; margin-left: 21.3pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Gerichtsstand
                    für Streitigkeiten aus diesem Vertrag ist der Sitz de</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">s</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    Auftragnehmers.</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314740"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">§
                    13 Bestätigungen</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Die
                    Vertragsparteien bestätigen, über den Inhalt des heutigen Vertrages einig zu sein und jeweils eine
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Ausfertigung
                    des Vertrages</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    nebst der </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anlage
                    1</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Subunternehmer/Unterauftragnehmer</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">,
                    der </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anlage
                    2</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Technische
                    und organisatorische Maßnahmen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    sowie</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">der
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anlage
                    3</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Betroffene</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    Personen und</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-style: italic; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenkategorien</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    e</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">rhalten</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    und zum dauerhaften Verbleib auf Ihren IT-Systemen gespeichert</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    zu haben.</span></p>
            <p></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314741"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">A</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">nlage
                    1: Subunternehmer / Unterauftragsverarbeiter</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <table class="first-row first-col no-vband docx_tabellenraster"
                style="width: 481.9pt; table-layout: auto; margin-left: auto; margin-right: auto;">
                <colgroup>
                    <col style="width: 77.95pt;">
                    <col style="width: 120.45pt;">
                    <col style="width: 56.7pt;">
                    <col style="width: 113.4pt;">
                    <col style="width: 113.4pt;">
                </colgroup>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Firma</span>
                        </p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Anschrift
                                / Land</span></p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Region
                                der</span></p>
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Daten-</span>
                        </p>
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">verarbeitung</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Beschreibung</span>
                        </p>
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">der
                                Leistung</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Vertrags-</span>
                        </p>
                        <p style="text-align: center;"><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">grundlage</span><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br></span>
                        </p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Afi
                                Technologies</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Inc</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">.</span>
                        </p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">210
                                N </span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">University
                                Dr.</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Nr.
                                303 </span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>Coral
                                Springs, FL 33071</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">USA</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Niederlande</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Back-Up
                                / Recovery</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span><span
                                style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">
                            </span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span id="_Hlk116313466"></span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Albacross</span>
                        </p>
                        <p style="text-align: justify;"><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Nordic
                                AB</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Tegelbacken
                                4 A</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">111
                                52 Stockholm</span></p>
                        <p style="text-align: justify;"><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Schweden</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Analyse
                                von Kundenbesuchen auf der Webseite</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">AVV</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">gem.
                                Art. 28 DSGVO</span></p>
                        <p></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Amazon</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Web
                                Services EMEA SARL</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Domagkstraße
                                28</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>80807&nbsp;München</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Cloud-Hosting</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Atlassian</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Pty
                                Ltd</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Singel
                                236</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">1016
                                AB Amsterdam</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Niederlande</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Projektmanagement-
                                und Organisationssoftware</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Google</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Ireland
                                Limited</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Gordon
                                House</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Barrow
                                Street</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Dublin
                                4</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Irland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Bereitstellung
                                von cloudbasierten Serviceanwendungen</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Mailchimp</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">The
                                Rocket </span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Science
                                Group</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">LLC
                                675</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Ponce
                                de Leon Ave NE, Suite 5000</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Atlanta,
                                GA 30308</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">USA</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">USA</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Email-
                                und Newsletterversand</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">MongoDB</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Inc.</span>
                        </p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Design
                                Offices</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Berlin
                                Humboldthafen </span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Alexanderufer
                                3-7 10117 Berlin</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Bereitstellung
                                von Datenbankanwendungen</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Plivo</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Inc.</span>
                        </p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">201
                                Mission Street </span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Suite
                                230</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">San
                                Francisco, CA 94105</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">USA</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">SMS-Gateway
                                für 2-Faktor-Authentifizierung</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">salesforce.com</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Germany
                                GmbH</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Erika-Mann-Straße
                                31&nbsp;</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>80636
                                München&nbsp;</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">CRM-System</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Sendinblue
                                GmbH</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Köpenicker
                                Straße 126</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">10179
                                Berlin</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Email-
                                und Newsletterversand</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">AVV
                                gem. Art. 28 DSGVO</span></p>
                        <p></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">united-domains
                                AG</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Gautinger
                                Straße 10</span><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;"><br>82319&nbsp;Starnberg</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Deutschland</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">E-Mail-Versand
                                für Phishing-Test</span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">AVV
                                gem. Art. 28 DSGVO</span></p>
                        <p></p>
                    </td>
                </tr>
                <tr style="text-align: center;">
                    <td style="width: 77.95pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Vonage</span>
                        </p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Holdings
                                Corp.</span></p>
                    </td>
                    <td style="width: 120.45pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">25
                                Canada Square </span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Level
                                37</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">London
                                E14 5LQ</span></p>
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">England</span>
                        </p>
                    </td>
                    <td style="width: 56.7pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">Europa</span>
                        </p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">SMS-Kommunikationsdienstleistungen
                            </span></p>
                    </td>
                    <td style="width: 113.4pt; border: 0.75pt solid rgb(0, 0, 0); padding: 0.7pt 0.3pt 0.7pt 0.7pt;">
                        <p><span
                                style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 9pt; font-size: 9pt;">DPA
                                mit EU-Standardvertragsklauseln gem. Art 44 ff. DSGVO</span></p>
                    </td>
                </tr>
            </table>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314742"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">Anlage</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">
                    2</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">:
                    Technische und organisatorische Maßnahmen gemäß Art</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">.</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">
                    32 DSGVO</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 14.2pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Maßnahmen
                    zur Vertraulichkeit</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 14.2pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span id="_Hlk62828984"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Zutrittskontrolle</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Außenfenster -
                    gemäß aktuellem Stand für Einbruchsicherheit</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Außentüren -
                    gemäß aktuellem Stand für Einbruchsicherheit</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Außentüren - mit
                    Türknauf an der Außenseite</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Besucherbegleitung
                    - innerhalb der Geschäftsräume</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Klingelanlage -
                    mit Gegensprechanlage</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Schließsystem -
                    mit Sicherheitsschlössern, Profilzylindern und Schlüsseln</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Schlüsselregistrierung
                    - bei Ausgabe und Rückgabe</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Schlüsselverwaltung
                    - durch Führung einer Bestandsliste</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Sorgfältige
                    Auswahl - des Reinigungs- und Wartungspersonals</span></p>
            <p class="docx_listenabsatz docx-num-33-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Zugangskontrollsystem
                    - mit Chipkarten oder Transpondersystemen</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Zugangskontrolle</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anti-Viren-Software
                    - für Clients</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anti-Viren-Software
                    - für Cloud-Server im Rechenzentrum</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Anti-Viren-Software
                    - für mobile Geräte</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Aufstellung
                    von IT-Systemen - mit Zugriffsmöglichkeit nur für berechtigte Benutzer</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Aufstellung
                    von Multifunktionsgeräten - mit Zugriffsmöglichkeit nur für berechtigte Benutzer</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit Benutzername + Passwort</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit biometrischen Daten</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit PIN</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit Zwei-Faktor-Lösung</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerberechtigungen
                    - mit zentraler Verwaltung durch Administratoren</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerprofile
                    - nach dem „need-to-know-Prinzip“</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Firewall
                    - für Clients</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Firewall
                    - für mobile Geräte</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Intrusion
                    Detection Systeme - für Cloud-Server im Rechenzentrum</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mobile
                    Device Management - für alle mobilen Geräte</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Passwortmanagement
                    - mit Passwortrichtlinie zu den Mindestanforderungen</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Passwortmanagement
                    - mit zentraler Verwaltung</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sicherheitsupdates
                    - auf allen Geräten zum Schutz vor Sicherheitslücken</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sperrung
                    - aller Geräte durch Desktopsperren nach Zeitablauf</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller mobilen Geräte nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - der Cloud-Server im Rechenzentrum nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx_listenabsatz docx-num-34-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">VPN-Technologie
                    - für alle Remote-Zugriffe</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Zugriffskontrolle</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Administratorenanzahl
                    - auf das absolute Minimum reduziert</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Aktenschränke
                    - mit Schlössern zur Aufbewahrung von analogen Akten</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerrechtekonzept
                    - mit Verwaltung durch Administratoren</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Berechtigungskonzept
                    - mit abgestufter Regelung der Zugriffsrechte</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Clean-Desk-Prinzip
                    - zur Verhinderung von unberechtigten Zugriffen auf sensible Informationen an unbeaufsichtigten
                    Arbeitsplätzen</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenträgervernichtungsunternehmen
                    - mit Zertifizierung gemäß DIN 66399</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Passwortmanagement
                    - mit Passwortrichtlinie zu den Mindestanforderungen</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - der Akten- und Datenträgervernichtung</span></p>
            <p class="docx_listenabsatz docx-num-2-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - von Zugriffen auf Anwendungen, insbesondere bei der Eingabe, Änderung und Löschung von
                    Daten</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span id="_Hlk62829499"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Weitergabekontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - aller Abrufe und Zugriffe</span></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sorgfältige
                    Auswahl - des Transportunternehmens, -personals bzw. der Fahrzeuge</span></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - von E-Mails beim Transport mittels SSL- / TLS-Verfahren</span></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - von E-Mails mittels erzwungenem TLS-Verfahren</span></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - von Verbindungen ins Internet über Verbindungen wie HTTPS oder SFTP</span></p>
            <p class="docx-num-3-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">VPN-Technologie
                    - für alle Remote-Zugriffe</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span id="_Hlk62829578"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    des Trennungsgebotes</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-4-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Berechtigungskonzept
                    - mit abgestufter Regelung der Datenbankrechte</span></p>
            <p class="docx_listenabsatz docx-num-4-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Berechtigungskonzept
                    - mit abgestufter Regelung der Zugriffsrechte</span></p>
            <p class="docx_listenabsatz docx-num-4-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mandantenfähigkeit
                    - aller relevanten Anwendungen</span></p>
            <p class="docx_listenabsatz docx-num-4-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Physikalische
                    Trennung - von Betriebssystem, Datenbanken und Datenträgern</span></p>
            <p class="docx_listenabsatz docx-num-4-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Trennung
                    - von Entwicklungs-, Produktiv- und Testumgebung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Pseudonymisierung</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-5-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Kennziffern
                    - zur Pseudonymisierung von Personen bei klar definierten Verarbeitungstätigkeiten</span></p>
            <p class="docx_listenabsatz docx-num-5-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Trennung
                    - von Datensätzen und Zuordnungsdateien bei pseudonymisierten Daten</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                </span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Verschlüsselung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zur Verwendung von Datenträgern und Geräten</span></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller Cloud-Server im Rechenzentrum nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller mobilen Geräte nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller unternehmenseigenen Websites mittels HTTPS</span></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - von E-Mails beim Transport mittels erzwungenem TLS-Verfahren</span></p>
            <p class="docx-num-6-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - von Verbindungen ins Internet über Verbindungen wie HTTPS oder SFTP</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Datenträgerkontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenträgervernichtungsunternehmen
                    - mit Zertifizierung gemäß DIN 66399</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dokumentation
                    - aller Datenträger- und Gerätenutzer mit Ausgabe- und Rücknahmeprotokoll</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Installationsverbot
                    - für nicht freigegebene Software</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Inventarisierung
                    - aller Datenträger und Geräte</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Physische
                    Löschung - von Datenträgern vor Wiederverwendung</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Vernichtung
                    - Ordnungsgemäße Vernichtung von Datenträgern (DIN 66399)</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller Cloud-Server im Rechenzentrum nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx-num-7-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verschlüsselung
                    - aller mobilen Geräte nach dem aktuellen Stand der Technik, z. B. mittels AES-256- /
                    RAS-Verfahren</span></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Benutzerkontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit Benutzername + Passwort</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit biometrischen Daten</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit PIN</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Authentifikation
                    - mit Zwei-Faktor-Lösung</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerrechtekonzept
                    - mit zentraler Verwaltung durch Administratoren</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Dokumentation
                    - aller Datenträger- und Gerätenutzer mit Ausgabe- und Rücknahmeprotokoll</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sperrung
                    - aller Benutzeraccounts ausgeschiedener Mitarbeiter</span></p>
            <p class="docx-num-8-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Sperrung
                    von Ex-Mitarbeiter - Sperren von Benutzeraccounts ausgeschiedener Mitarbeiter</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Übertragungskontrolle</span></p>
            <p class="docx_listenabsatz" style="margin-left: 56.7pt;"></p>
            <p class="docx_listenabsatz docx-num-9-0" style="text-indent: -14.15pt; margin-left: 56.7pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - aller Abrufe und Zugriffe</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 14.2pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Maßnahmen
                    zur Integrität</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Eingabekontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-10-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzernamenkonzept
                    - mit individuellen Benutzernamen zur Nachvollziehbarkeit von Eingabe, Änderung und Löschung von
                    Daten</span></p>
            <p class="docx-num-10-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerrechtekonzept
                    - mit individuellen Berechtigungen zur Eingabe, Änderung und Löschung von Daten sowie Verwaltung
                    durch Administratoren</span></p>
            <p class="docx-num-10-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Nutzungsverbot
                    - für nicht freigegebene Hard- und Software</span></p>
            <p class="docx-num-10-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Programmübersicht
                    - mit welchen Apps / welcher Software Daten eingegeben, geändert oder gelöscht werden können</span>
            </p>
            <p class="docx-num-10-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - von Zugriffen auf Anwendungen, insbesondere bei der Eingabe, Änderung und Löschung von
                    Daten</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Datenintegrität</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-11-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Archivierungsverfahren
                    - für E-Mails</span></p>
            <p class="docx-num-11-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Backup-
                    und Recovery-Konzept - mit permanenter Datensicherung und regelmäßiger Datenrücksicherung</span></p>
            <p class="docx-num-11-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Physikalische
                    Trennung - von Betriebssystem, Datenbanken, Datensicherungen und Datenträgern</span></p>
            <p class="docx-num-11-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Trennung
                    - von Produktiv- und Testumgebung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Speicherkontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-12-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerprofile
                    - nach dem „need-to-know-Prinzip“</span></p>
            <p class="docx-num-12-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Benutzerrechtekonzept
                    - mit individuellen Berechtigungen zur Eingabe, Änderung und Löschung von Daten sowie Verwaltung
                    durch Administratoren</span></p>
            <p class="docx-num-12-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Protokollierung
                    - von Zugriffen auf Anwendungen, insbesondere bei der Eingabe, Änderung und Löschung von
                    Daten</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 14.2pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Maßnahmen
                    zur Verfügbarkeit und Belastbarkeit</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Verfügbarkeitskontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Backup- und
                    Recovery-Konzept - mit permanenter Datensicherung und regelmäßiger Datenrücksicherung</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Feuchtigkeits-
                    und Temperaturüberwachung - der Cloud-Server im Rechenzentrum</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Feuer- und
                    Rauchmeldeanlagen - zur Überwachung der Cloud-Server im Rechenzentrum</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Klimatisierung
                    - der Serverräume im Rechenzentrum der Cloud-Server</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Monitoring -
                    aller kritischen und sensiblen Prozesse der Cloud-Server im Rechenzentrum</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Physikalische
                    Trennung - von Betriebssystem, Datenbanken, Datensicherungen und Datenträgern</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Redundante
                    Datenspeicherung - der Cloud-Server im Rechenzentrum mit RAID-Systemen für alle Server</span></p>
            <p class="docx-num-13-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 10pt; font-size: 10pt;">Videoüberwachung
                    - der Serverräume im Rechenzentrum der Cloud-Server</span><span> </span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der raschen Wiederherstellbarkeit</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-14-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Backup-
                    und Recovery-Konzept - mit permanenter Datensicherung und regelmäßiger Datenrücksicherung</span></p>
            <p class="docx-num-14-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">IT-Administrator
                    - mit ausreichender fachlicher Eignung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 14.2pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Weitere
                    Maßnahmen zum Datenschutz</span></p>
            <p class="docx_listenabsatz"
                style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Auftragskontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Abschluss
                    - von Verträgen zur Auftragsverarbeitung gem. Art. 28 DSGVO oder mit EU
                    Standard-Vertragsklauseln</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Auswahl
                    - der Auftragnehmer unter Sorgfaltsgesichtspunkten, insbesondere im Hinblick auf Datenschutz und
                    Datensicherheit</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Regelmäßige
                    Überprüfung - der technischen- und organisatorischen Maßnahmen des Auftragnehmers</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verbindliche
                    Regelungen - zum Einsatz weiterer Subunternehmer</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verbindliche
                    Regelungen - zum Ort der Datenverarbeitung</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verbindliche
                    Regelungen - zur Löschung, Rückgabe, Vernichtung der verarbeiteten Daten nach Beendigung des
                    Auftrages</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verbindliche
                    Regelungen - zur Schulung der Mitarbeiter des Auftragnehmers</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verbindliche
                    Regelungen - zur Weisungsgebundenheit des Auftragnehmers</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Vereinbarung
                    - wirksamer Kontrollrechte gegenüber dem Auftragnehmer</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verpflichtung
                    - der Mitarbeiter des Auftragnehmers auf Vertraulichkeit gem. Art 28 Abs. 3 S. 2 lit. b, Art. 29,
                    Art. 32 Abs. 4 DSGVO</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verpflichtung
                    - zur Bestellung und Benennung eines Datenschutzbeauftragten beim Auftragnehmer bei Vorliegen einer
                    Bestellpflicht</span></p>
            <p class="docx-num-15-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Vorabprüfung
                    - der Auftragnehmer im Hinblick auf Datenschutz und Datensicherheit</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    des Managementsystems zum Datenschutz</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenschutzbeauftragter
                    - zur Umsetzung und Überwachung des Datenschutzes im Unternehmen</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenschutz-Folgenabschätzungen
                    - bei Einführung neuer Prozesse und Überschreitung von bestimmten Schwellenwerten gem. Art. 35
                    DGSVO</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Schnittstelle
                    - für Betroffene zur Geltendmachung und Ausübung von Betroffenenrechten gem. Art. 15 ff.
                    DGSVO</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Schwachstellenanalysen
                    - mit regelmäßigen Penetrations- oder Phishing-Tests zur Überprüfung der IT-Sicherheit</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Single
                    Point of Contact (SPoC) - für Datenschutzbehörden und Betroffene</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Software
                    - mit datenschutzfreundlichen Voreinstellungen gem. Art. 25 DSGVO</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Software
                    - zur Dokumentation der Einhaltung datenschutzrechtlicher Anforderungen</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Versionierung
                    - von Dateien</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verzeichnis
                    der technischen- und organisatorischen Maßnahmen - gem. Art. 32 DSGVO mit laufender Überprüfung oder
                    anlassbezogener Anpassung</span></p>
            <p class="docx-num-16-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verzeichnis
                    der Verarbeitungstätigkeiten - gem. Art. 30 DSGVO mit laufender Überprüfung oder anlassbezogener
                    Anpassung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-1-1"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -28.35pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt; text-decoration: underline;">Beschreibung
                    der Organisationskontrolle</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 56.7pt; text-align: justify;"></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Audits
                    - zur laufenden und regelmäßigen Kontrolle der Einhaltung der Datenschutzrichtlinien</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datenschutzbeauftragter
                    - zur Umsetzung und Überwachung des Datenschutzes im Unternehmen</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zum Umgang mit Betroffenenanfragen gem. Art. 15 ff. DSGVO</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zum Umgang mit Daten beim mobilen Arbeiten und im Homeoffice</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zum Umgang mit Daten im Unternehmen</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zum Umgang mit Datenschutzverletzungen gem. Art. 33 und 34 DSGVO</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Richtlinie
                    - zur Erfüllung der Informationspflichten gem. Art 13 und 14 DSGVO</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Schulung
                    - aller Mitarbeiter zum Datenschutz und zur Datensicherheit mindestens 1 x pro Jahr</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Verpflichtung
                    - aller Mitarbeiter auf das Datengeheimnis und Vertraulichkeit</span></p>
            <p class="docx-num-17-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 56.7pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Zentrale
                    Dokumentation - aller Richtlinien zum Datenschutz und zur IT-Sicherheit mit Zugriffsmöglichkeiten
                    für Mitarbeiter nach Bedarf / Berechtigung</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_berschrift1" style="margin-bottom: 0pt; line-height: 1;"><span
                    id="_Toc116314743"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">Anlage
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">3</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">:
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 12.5pt; font-size: 12.5pt; text-decoration: none;">Betroffene
                    Personen und Datenkategorien</span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; margin-left: 14.2pt;"></p>
            <p class="docx_listenabsatz docx-num-32-0"
                style="margin-top: 2pt; margin-bottom: 0pt; line-height: 1; text-indent: -14.35pt; margin-left: 14.2pt;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Kategorien
                    der </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">möglichen
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">betroffenen
                    Personen</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    in Abhängigkeit vom Hauptvertrag</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">:</span>
            </p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; margin-left: 14.2pt;"></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Interessenten
                    für den Abschluss eines Versicherungsvertrages</span></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mitarbeiter
                    der Interessenten</span></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mitarbeiter
                    der Kunden</span></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Mitarbeiter
                    des Auftraggebers</span></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Versicherte
                    Personen</span></p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Versicherungsnehmer</span>
            </p>
            <p class="docx_listenabsatz docx-num-39-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Versicherungsvermittler</span>
            </p>
            <p style="margin-bottom: 0pt; line-height: 1;"></p>
            <p class="docx_listenabsatz docx-num-32-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.35pt; margin-left: 14.2pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Kategorien
                    der </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">möglichen
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Daten</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">:</span>
            </p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; margin-left: 14.2pt;"></p>
            <p class="docx-num-36-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Von
                    Interessenten für den Abschluss eines Versicherungsvertrages, </span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">v</span><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">ersicherten
                    Personen und Versicherungsnehmern:</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Beratungsdokumentationen</span>
            </p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Datum,
                    Ort und Zeitpunkt von Beratungsgesprächen</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span id="_Hlk36567189"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Einwilligungserklärungen</span>
            </p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Gesprächsprotokolle</span>
            </p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Korrespondenz
                    jeglicher Art</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Stammdaten
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 8pt; font-size: 8pt;">(im
                    Sinne Code of Conduct der deutschen Versicherungswirtschaft / GDV vom 29.06.2018)</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Steuerliche
                    Daten</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Interessenten und Kunden zu Aus-, Fort- und
                    Weiterbildungszwecken nutzen</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Interessenten und Kunden zu Testzwecken nutzen</span></p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span id="_Hlk36562326"></span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Interessenten und Kunden zur Vertragsverwaltung nutzen</span>
            </p>
            <p class="docx_listenabsatz docx-num-38-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Umsatz-
                    und Vermögensdaten</span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; margin-left: 14.2pt;"></p>
            <p class="docx_listenabsatz docx-num-36-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt;"><span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Von
                    Versicherungsvermittlern:</span></p>
            <p class="docx_listenabsatz" style="margin-bottom: 0pt; line-height: 1; margin-left: 14.2pt;"></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">AVAD-Auskunft</span>
            </p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Bankdaten</span>
            </p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Beruflicher
                    Werdegang</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Bonitätsauskünfte
                    und -daten</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Daten
                    zum Forderungsmanagement</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Einwilligungserklärungen</span>
            </p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Korrespondenz
                    jeglicher Art</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Namen</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    und Kontaktdaten</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Nutzungsdaten
                    aus bereitgestellter Software</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Polizeiliches
                    Führungszeugnis</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Qualifikationsnachweise</span>
            </p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Tätigkeitsnachweise
                    und -statistiken</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnahmedaten
                </span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">von</span><span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">
                    Seminaren, Web-Seminaren und sonstigen Schulungen</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Vermittler zu Aus-, Fort- und Weiterbildungszwecken
                    nutzen</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Vermittler zu Testzwecken nutzen</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Vermittler zur Vertragsverwaltung nutzen</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Vermittlervertrags-
                    und Provisionsdaten</span></p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Weiterbildungsdaten</span>
            </p>
            <p class="docx_listenabsatz docx-num-37-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Zuverlässigkeitsprüfung
                    nach GewO und den zugehörigen Verordnungen</span></p><br>
            <p class="docx-num-36-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.15pt; margin-left: 28.35pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; font-weight: bold; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Von
                    Mitarbeitern des Auftraggebers / Kunden:</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; margin-left: 42.55pt; text-align: justify;"></p>
            <p class="docx_listenabsatz docx-num-40-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Einwilligungserklärungen</span>
            </p>
            <p class="docx_listenabsatz docx-num-40-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Auftraggeber und Kunden zu Aus-, Fort- und
                    Weiterbildungszwecken Ihrer Mitarbeiter nutzen</span></p>
            <p class="docx_listenabsatz docx-num-40-0"
                style="margin-bottom: 0pt; line-height: 1; text-indent: -14.2pt; margin-left: 42.55pt; text-align: justify;">
                <span
                    style="font-family: &quot;Century Gothic&quot;; color: rgb(0, 0, 0); min-height: 10pt; font-size: 10pt;">Teilnehmer-
                    und Nutzungsdaten von Onlinetools, die Auftraggeber und Kunden zu Testzwecken Ihrer Mitarbeiter
                    nutzen</span></p>
            <p style="margin-bottom: 0pt; line-height: 1; text-align: justify;"></p>
        </article>
        <!-- <footer>
            <p class="docx_fuzeile"><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">AVV - CyberDirekt
                    GmbH - V 5.20</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"><span
                        class="docx-tab-stop"> </span>Stand: </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">01.03.2023</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"><span
                        class="docx-tab-stop"> </span>Seite </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">1</span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;"> von </span><span
                    style="font-family: &quot;Century Gothic&quot;; min-height: 6pt; font-size: 6pt;">2</span></p>
        </footer> -->
    </section>
</div>

    <!-- data accepted div -->
    <p class="MsoNormal" v-if="avvData.avvAccepted"
        style="
            margin-top: 3em;
            margin-right: 0in;
    
            margin-left: 28.35pt;
            text-align: justify;
            text-indent: -14.15pt;
            line-height: normal;
          ">
      <b><span lang="DE" style="font-size: 10pt; color: black;">AVV bestätigt am {{ avvData.avvAcceptedOn | formatDate }}</span></b>
    </p>
  </div>
</template>

<script>
// https://www.textfixer.com/html/convert-word-to-html.php
export default {
  name: 'BodyAvv',
  props: {
    avvData: {
      type: Object,
      default: () => ({
        avvAccepted: false,
        avvAcceptedOn: ''
      }),
      required: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.docx-wrapper {
        // backg÷round: gray;
        padding: 30px;
        padding-bottom: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .docx-wrapper>section.docx {
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        margin-bottom: 30px;
    }

    .docx {
        color: black;
    }

    section.docx {
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        overflow: hidden;
    }

    section.docx>article {
        margin-bottom: auto;
    }

    .docx table {
        border-collapse: collapse;
    }

    .docx table td,
    .docx table th {
        vertical-align: top;
    }

    .docx p {
        margin: 0pt;
        min-height: 1em;
    }

    .docx span {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .docx a {
        color: inherit;
        text-decoration: inherit;
    }
</style><!--docxjs document theme values-->
<style>
    .docx {
        --docx-majorHAnsi-font: Cambria;
        --docx-minorHAnsi-font: Calibri;
        --docx-dk1-color: #000000;
        --docx-lt1-color: #FFFFFF;
        --docx-dk2-color: #1F497D;
        --docx-lt2-color: #EEECE1;
        --docx-accent1-color: #4F81BD;
        --docx-accent2-color: #C0504D;
        --docx-accent3-color: #9BBB59;
        --docx-accent4-color: #8064A2;
        --docx-accent5-color: #4BACC6;
        --docx-accent6-color: #F79646;
        --docx-hlink-color: #0000FF;
        --docx-folHlink-color: #800080;
    }
</style><!--docxjs document styles-->
<style>
    .docx span {
        font-family: Arial;
        min-height: 11.00pt;
        font-size: 11.00pt;
    }

    .docx p {
        margin-bottom: 8.00pt;
        line-height: 1.08;
    }

    p.docx_berschrift1 {}

    p.docx_berschrift1 span {
        font-weight: bold;
        min-height: 16.00pt;
        font-size: 16.00pt;
        text-decoration: underline;
    }

    .docx table,
    table.docx_normaletabelle td {
        padding-top: 0.00pt;
        padding-left: 5.40pt;
        padding-bottom: 0.00pt;
        padding-right: 5.40pt;
    }

    span.docx_hyperlink {
        color: #0563C1;
        text-decoration: underline;
    }

    p.docx_kopfzeile {
        margin-bottom: 0.00pt;
        line-height: 1.00;
    }

    span.docx_kopfzeilezchn p {
        margin-bottom: 0.00pt;
        line-height: 1.00;
    }

    p.docx_fuzeile {
        margin-bottom: 0.00pt;
        line-height: 1.00;
    }

    span.docx_fuzeilezchn p {
        margin-bottom: 0.00pt;
        line-height: 1.00;
    }

    p.docx_listenabsatz {
        margin-left: 36.00pt;
    }

    p.docx_verzeichnis2 {
        margin-bottom: 5.00pt;
        text-indent: 10.30pt;
        margin-left: 11.00pt;
    }

    table.docx_tabellenraster p {
        margin-bottom: 0.00pt;
        line-height: 1.00;
    }

    table.docx_tabellenraster span {
        font-family: var(--docx-minorHAnsi-font);
        color: #595959;
        min-height: 10.00pt;
        font-size: 10.00pt;
    }

    table.docx_tabellenraster td {
        border-top: 0.50pt solid black;
        border-left: 0.50pt solid black;
        border-bottom: 0.50pt solid black;
        border-right: 0.50pt solid black;
        padding-top: 0.00pt;
        padding-left: 5.40pt;
        padding-bottom: 0.00pt;
        padding-right: 5.40pt;
    }

    span.docx_nichtaufgelsteerwhnung {
        color: #605E5C;
        background-color: #E1DFDD;
    }
</style><!--docxjs document numbering styles-->
<style>
    p.docx-num-34-0:before {
        content: "\9";
        counter-increment: docx-num-34-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-34-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-34-0 {
        counter-reset: docx-num-34-1;
    }

    p.docx-num-34-1:before {
        content: "o\9";
        counter-increment: docx-num-34-1;
        font-family: Courier New;
    }

    p.docx-num-34-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-34-1 {
        counter-reset: docx-num-34-2;
    }

    p.docx-num-34-2:before {
        content: "\9";
        counter-increment: docx-num-34-2;
        font-family: Wingdings;
    }

    p.docx-num-34-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-34-2 {
        counter-reset: docx-num-34-3;
    }

    p.docx-num-34-3:before {
        content: "\9";
        counter-increment: docx-num-34-3;
        font-family: Symbol;
    }

    p.docx-num-34-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-34-3 {
        counter-reset: docx-num-34-4;
    }

    p.docx-num-34-4:before {
        content: "o\9";
        counter-increment: docx-num-34-4;
        font-family: Courier New;
    }

    p.docx-num-34-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-34-4 {
        counter-reset: docx-num-34-5;
    }

    p.docx-num-34-5:before {
        content: "\9";
        counter-increment: docx-num-34-5;
        font-family: Wingdings;
    }

    p.docx-num-34-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-34-5 {
        counter-reset: docx-num-34-6;
    }

    p.docx-num-34-6:before {
        content: "\9";
        counter-increment: docx-num-34-6;
        font-family: Symbol;
    }

    p.docx-num-34-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-34-6 {
        counter-reset: docx-num-34-7;
    }

    p.docx-num-34-7:before {
        content: "o\9";
        counter-increment: docx-num-34-7;
        font-family: Courier New;
    }

    p.docx-num-34-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-34-7 {
        counter-reset: docx-num-34-8;
    }

    p.docx-num-34-8:before {
        content: "\9";
        counter-increment: docx-num-34-8;
        font-family: Wingdings;
    }

    p.docx-num-34-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-15-0:before {
        content: "\9";
        counter-increment: docx-num-15-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-15-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-15-0 {
        counter-reset: docx-num-15-1;
    }

    p.docx-num-15-1:before {
        content: "o\9";
        counter-increment: docx-num-15-1;
        font-family: Courier New;
    }

    p.docx-num-15-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-15-1 {
        counter-reset: docx-num-15-2;
    }

    p.docx-num-15-2:before {
        content: "\9";
        counter-increment: docx-num-15-2;
        font-family: Wingdings;
    }

    p.docx-num-15-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-15-2 {
        counter-reset: docx-num-15-3;
    }

    p.docx-num-15-3:before {
        content: "\9";
        counter-increment: docx-num-15-3;
        font-family: Symbol;
    }

    p.docx-num-15-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-15-3 {
        counter-reset: docx-num-15-4;
    }

    p.docx-num-15-4:before {
        content: "o\9";
        counter-increment: docx-num-15-4;
        font-family: Courier New;
    }

    p.docx-num-15-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-15-4 {
        counter-reset: docx-num-15-5;
    }

    p.docx-num-15-5:before {
        content: "\9";
        counter-increment: docx-num-15-5;
        font-family: Wingdings;
    }

    p.docx-num-15-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-15-5 {
        counter-reset: docx-num-15-6;
    }

    p.docx-num-15-6:before {
        content: "\9";
        counter-increment: docx-num-15-6;
        font-family: Symbol;
    }

    p.docx-num-15-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-15-6 {
        counter-reset: docx-num-15-7;
    }

    p.docx-num-15-7:before {
        content: "o\9";
        counter-increment: docx-num-15-7;
        font-family: Courier New;
    }

    p.docx-num-15-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-15-7 {
        counter-reset: docx-num-15-8;
    }

    p.docx-num-15-8:before {
        content: "\9";
        counter-increment: docx-num-15-8;
        font-family: Wingdings;
    }

    p.docx-num-15-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-25-0:before {
        content: "" counter(docx-num-25-0, decimal)".\9";
        counter-increment: docx-num-25-0;
    }

    p.docx-num-25-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-25-0 {
        counter-reset: docx-num-25-1;
    }

    p.docx-num-25-1:before {
        content: "" counter(docx-num-25-1, lower-alpha)".\9";
        counter-increment: docx-num-25-1;
    }

    p.docx-num-25-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-25-1 {
        counter-reset: docx-num-25-2;
    }

    p.docx-num-25-2:before {
        content: "" counter(docx-num-25-2, lower-roman)".\9";
        counter-increment: docx-num-25-2;
    }

    p.docx-num-25-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-25-2 {
        counter-reset: docx-num-25-3;
    }

    p.docx-num-25-3:before {
        content: "" counter(docx-num-25-3, decimal)".\9";
        counter-increment: docx-num-25-3;
    }

    p.docx-num-25-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-25-3 {
        counter-reset: docx-num-25-4;
    }

    p.docx-num-25-4:before {
        content: "" counter(docx-num-25-4, lower-alpha)".\9";
        counter-increment: docx-num-25-4;
    }

    p.docx-num-25-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-25-4 {
        counter-reset: docx-num-25-5;
    }

    p.docx-num-25-5:before {
        content: "" counter(docx-num-25-5, lower-roman)".\9";
        counter-increment: docx-num-25-5;
    }

    p.docx-num-25-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-25-5 {
        counter-reset: docx-num-25-6;
    }

    p.docx-num-25-6:before {
        content: "" counter(docx-num-25-6, decimal)".\9";
        counter-increment: docx-num-25-6;
    }

    p.docx-num-25-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-25-6 {
        counter-reset: docx-num-25-7;
    }

    p.docx-num-25-7:before {
        content: "" counter(docx-num-25-7, lower-alpha)".\9";
        counter-increment: docx-num-25-7;
    }

    p.docx-num-25-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-25-7 {
        counter-reset: docx-num-25-8;
    }

    p.docx-num-25-8:before {
        content: "" counter(docx-num-25-8, lower-roman)".\9";
        counter-increment: docx-num-25-8;
    }

    p.docx-num-25-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-33-0:before {
        content: "\9";
        counter-increment: docx-num-33-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-33-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-33-0 {
        counter-reset: docx-num-33-1;
    }

    p.docx-num-33-1:before {
        content: "•\9";
        counter-increment: docx-num-33-1;
        font-family: Century Gothic;
    }

    p.docx-num-33-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -35.50pt;
        margin-left: 114.05pt;
    }

    p.docx-num-33-1 {
        counter-reset: docx-num-33-2;
    }

    p.docx-num-33-2:before {
        content: "\9";
        counter-increment: docx-num-33-2;
        font-family: Wingdings;
    }

    p.docx-num-33-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-33-2 {
        counter-reset: docx-num-33-3;
    }

    p.docx-num-33-3:before {
        content: "\9";
        counter-increment: docx-num-33-3;
        font-family: Symbol;
    }

    p.docx-num-33-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-33-3 {
        counter-reset: docx-num-33-4;
    }

    p.docx-num-33-4:before {
        content: "o\9";
        counter-increment: docx-num-33-4;
        font-family: Courier New;
    }

    p.docx-num-33-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-33-4 {
        counter-reset: docx-num-33-5;
    }

    p.docx-num-33-5:before {
        content: "\9";
        counter-increment: docx-num-33-5;
        font-family: Wingdings;
    }

    p.docx-num-33-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-33-5 {
        counter-reset: docx-num-33-6;
    }

    p.docx-num-33-6:before {
        content: "\9";
        counter-increment: docx-num-33-6;
        font-family: Symbol;
    }

    p.docx-num-33-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-33-6 {
        counter-reset: docx-num-33-7;
    }

    p.docx-num-33-7:before {
        content: "o\9";
        counter-increment: docx-num-33-7;
        font-family: Courier New;
    }

    p.docx-num-33-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-33-7 {
        counter-reset: docx-num-33-8;
    }

    p.docx-num-33-8:before {
        content: "\9";
        counter-increment: docx-num-33-8;
        font-family: Wingdings;
    }

    p.docx-num-33-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-21-0:before {
        content: "" counter(docx-num-21-0, lower-alpha)".\9";
        counter-increment: docx-num-21-0;
    }

    p.docx-num-21-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-21-0 {
        counter-reset: docx-num-21-1;
    }

    p.docx-num-21-1:before {
        content: "" counter(docx-num-21-1, lower-alpha)".\9";
        counter-increment: docx-num-21-1;
    }

    p.docx-num-21-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-21-1 {
        counter-reset: docx-num-21-2;
    }

    p.docx-num-21-2:before {
        content: "" counter(docx-num-21-2, lower-roman)".\9";
        counter-increment: docx-num-21-2;
    }

    p.docx-num-21-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-21-2 {
        counter-reset: docx-num-21-3;
    }

    p.docx-num-21-3:before {
        content: "" counter(docx-num-21-3, decimal)".\9";
        counter-increment: docx-num-21-3;
    }

    p.docx-num-21-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-21-3 {
        counter-reset: docx-num-21-4;
    }

    p.docx-num-21-4:before {
        content: "" counter(docx-num-21-4, lower-alpha)".\9";
        counter-increment: docx-num-21-4;
    }

    p.docx-num-21-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-21-4 {
        counter-reset: docx-num-21-5;
    }

    p.docx-num-21-5:before {
        content: "" counter(docx-num-21-5, lower-roman)".\9";
        counter-increment: docx-num-21-5;
    }

    p.docx-num-21-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-21-5 {
        counter-reset: docx-num-21-6;
    }

    p.docx-num-21-6:before {
        content: "" counter(docx-num-21-6, decimal)".\9";
        counter-increment: docx-num-21-6;
    }

    p.docx-num-21-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-21-6 {
        counter-reset: docx-num-21-7;
    }

    p.docx-num-21-7:before {
        content: "" counter(docx-num-21-7, lower-alpha)".\9";
        counter-increment: docx-num-21-7;
    }

    p.docx-num-21-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-21-7 {
        counter-reset: docx-num-21-8;
    }

    p.docx-num-21-8:before {
        content: "" counter(docx-num-21-8, lower-roman)".\9";
        counter-increment: docx-num-21-8;
    }

    p.docx-num-21-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-36-0:before {
        content: "" counter(docx-num-36-0, lower-alpha)".\9";
        counter-increment: docx-num-36-0;
    }

    p.docx-num-36-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 43.10pt;
    }

    p.docx-num-36-0 {
        counter-reset: docx-num-36-1;
    }

    p.docx-num-36-1:before {
        content: "" counter(docx-num-36-1, lower-alpha)".\9";
        counter-increment: docx-num-36-1;
    }

    p.docx-num-36-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 79.10pt;
    }

    p.docx-num-36-1 {
        counter-reset: docx-num-36-2;
    }

    p.docx-num-36-2:before {
        content: "" counter(docx-num-36-2, lower-roman)".\9";
        counter-increment: docx-num-36-2;
    }

    p.docx-num-36-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 115.10pt;
    }

    p.docx-num-36-2 {
        counter-reset: docx-num-36-3;
    }

    p.docx-num-36-3:before {
        content: "" counter(docx-num-36-3, decimal)".\9";
        counter-increment: docx-num-36-3;
    }

    p.docx-num-36-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 151.10pt;
    }

    p.docx-num-36-3 {
        counter-reset: docx-num-36-4;
    }

    p.docx-num-36-4:before {
        content: "" counter(docx-num-36-4, lower-alpha)".\9";
        counter-increment: docx-num-36-4;
    }

    p.docx-num-36-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 187.10pt;
    }

    p.docx-num-36-4 {
        counter-reset: docx-num-36-5;
    }

    p.docx-num-36-5:before {
        content: "" counter(docx-num-36-5, lower-roman)".\9";
        counter-increment: docx-num-36-5;
    }

    p.docx-num-36-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 223.10pt;
    }

    p.docx-num-36-5 {
        counter-reset: docx-num-36-6;
    }

    p.docx-num-36-6:before {
        content: "" counter(docx-num-36-6, decimal)".\9";
        counter-increment: docx-num-36-6;
    }

    p.docx-num-36-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 259.10pt;
    }

    p.docx-num-36-6 {
        counter-reset: docx-num-36-7;
    }

    p.docx-num-36-7:before {
        content: "" counter(docx-num-36-7, lower-alpha)".\9";
        counter-increment: docx-num-36-7;
    }

    p.docx-num-36-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 295.10pt;
    }

    p.docx-num-36-7 {
        counter-reset: docx-num-36-8;
    }

    p.docx-num-36-8:before {
        content: "" counter(docx-num-36-8, lower-roman)".\9";
        counter-increment: docx-num-36-8;
    }

    p.docx-num-36-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 331.10pt;
    }

    p.docx-num-30-0:before {
        content: "" counter(docx-num-30-0, decimal)".\9";
        counter-increment: docx-num-30-0;
    }

    p.docx-num-30-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-30-0 {
        counter-reset: docx-num-30-1;
    }

    p.docx-num-30-1:before {
        content: "" counter(docx-num-30-1, lower-alpha)".\9";
        counter-increment: docx-num-30-1;
    }

    p.docx-num-30-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-30-1 {
        counter-reset: docx-num-30-2;
    }

    p.docx-num-30-2:before {
        content: "" counter(docx-num-30-2, lower-roman)".\9";
        counter-increment: docx-num-30-2;
    }

    p.docx-num-30-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-30-2 {
        counter-reset: docx-num-30-3;
    }

    p.docx-num-30-3:before {
        content: "" counter(docx-num-30-3, decimal)".\9";
        counter-increment: docx-num-30-3;
    }

    p.docx-num-30-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-30-3 {
        counter-reset: docx-num-30-4;
    }

    p.docx-num-30-4:before {
        content: "" counter(docx-num-30-4, lower-alpha)".\9";
        counter-increment: docx-num-30-4;
    }

    p.docx-num-30-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-30-4 {
        counter-reset: docx-num-30-5;
    }

    p.docx-num-30-5:before {
        content: "" counter(docx-num-30-5, lower-roman)".\9";
        counter-increment: docx-num-30-5;
    }

    p.docx-num-30-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-30-5 {
        counter-reset: docx-num-30-6;
    }

    p.docx-num-30-6:before {
        content: "" counter(docx-num-30-6, decimal)".\9";
        counter-increment: docx-num-30-6;
    }

    p.docx-num-30-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-30-6 {
        counter-reset: docx-num-30-7;
    }

    p.docx-num-30-7:before {
        content: "" counter(docx-num-30-7, lower-alpha)".\9";
        counter-increment: docx-num-30-7;
    }

    p.docx-num-30-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-30-7 {
        counter-reset: docx-num-30-8;
    }

    p.docx-num-30-8:before {
        content: "" counter(docx-num-30-8, lower-roman)".\9";
        counter-increment: docx-num-30-8;
    }

    p.docx-num-30-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-11-0:before {
        content: "\9";
        counter-increment: docx-num-11-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-11-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-11-0 {
        counter-reset: docx-num-11-1;
    }

    p.docx-num-11-1:before {
        content: "o\9";
        counter-increment: docx-num-11-1;
        font-family: Courier New;
    }

    p.docx-num-11-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-11-1 {
        counter-reset: docx-num-11-2;
    }

    p.docx-num-11-2:before {
        content: "\9";
        counter-increment: docx-num-11-2;
        font-family: Wingdings;
    }

    p.docx-num-11-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-11-2 {
        counter-reset: docx-num-11-3;
    }

    p.docx-num-11-3:before {
        content: "\9";
        counter-increment: docx-num-11-3;
        font-family: Symbol;
    }

    p.docx-num-11-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-11-3 {
        counter-reset: docx-num-11-4;
    }

    p.docx-num-11-4:before {
        content: "o\9";
        counter-increment: docx-num-11-4;
        font-family: Courier New;
    }

    p.docx-num-11-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-11-4 {
        counter-reset: docx-num-11-5;
    }

    p.docx-num-11-5:before {
        content: "\9";
        counter-increment: docx-num-11-5;
        font-family: Wingdings;
    }

    p.docx-num-11-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-11-5 {
        counter-reset: docx-num-11-6;
    }

    p.docx-num-11-6:before {
        content: "\9";
        counter-increment: docx-num-11-6;
        font-family: Symbol;
    }

    p.docx-num-11-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-11-6 {
        counter-reset: docx-num-11-7;
    }

    p.docx-num-11-7:before {
        content: "o\9";
        counter-increment: docx-num-11-7;
        font-family: Courier New;
    }

    p.docx-num-11-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-11-7 {
        counter-reset: docx-num-11-8;
    }

    p.docx-num-11-8:before {
        content: "\9";
        counter-increment: docx-num-11-8;
        font-family: Wingdings;
    }

    p.docx-num-11-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-18-0:before {
        content: "\9";
        counter-increment: docx-num-18-0;
        font-family: Wingdings;
    }

    p.docx-num-18-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-18-0 {
        counter-reset: docx-num-18-1;
    }

    p.docx-num-18-1:before {
        content: "o\9";
        counter-increment: docx-num-18-1;
        font-family: Courier New;
    }

    p.docx-num-18-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-18-1 {
        counter-reset: docx-num-18-2;
    }

    p.docx-num-18-2:before {
        content: "\9";
        counter-increment: docx-num-18-2;
        font-family: Wingdings;
    }

    p.docx-num-18-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-18-2 {
        counter-reset: docx-num-18-3;
    }

    p.docx-num-18-3:before {
        content: "\9";
        counter-increment: docx-num-18-3;
        font-family: Symbol;
    }

    p.docx-num-18-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-18-3 {
        counter-reset: docx-num-18-4;
    }

    p.docx-num-18-4:before {
        content: "o\9";
        counter-increment: docx-num-18-4;
        font-family: Courier New;
    }

    p.docx-num-18-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-18-4 {
        counter-reset: docx-num-18-5;
    }

    p.docx-num-18-5:before {
        content: "\9";
        counter-increment: docx-num-18-5;
        font-family: Wingdings;
    }

    p.docx-num-18-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-18-5 {
        counter-reset: docx-num-18-6;
    }

    p.docx-num-18-6:before {
        content: "\9";
        counter-increment: docx-num-18-6;
        font-family: Symbol;
    }

    p.docx-num-18-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-18-6 {
        counter-reset: docx-num-18-7;
    }

    p.docx-num-18-7:before {
        content: "o\9";
        counter-increment: docx-num-18-7;
        font-family: Courier New;
    }

    p.docx-num-18-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-18-7 {
        counter-reset: docx-num-18-8;
    }

    p.docx-num-18-8:before {
        content: "\9";
        counter-increment: docx-num-18-8;
        font-family: Wingdings;
    }

    p.docx-num-18-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-5-0:before {
        content: "\9";
        counter-increment: docx-num-5-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-5-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-5-0 {
        counter-reset: docx-num-5-1;
    }

    p.docx-num-5-1:before {
        content: "o\9";
        counter-increment: docx-num-5-1;
        font-family: Courier New;
    }

    p.docx-num-5-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-5-1 {
        counter-reset: docx-num-5-2;
    }

    p.docx-num-5-2:before {
        content: "\9";
        counter-increment: docx-num-5-2;
        font-family: Wingdings;
    }

    p.docx-num-5-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-5-2 {
        counter-reset: docx-num-5-3;
    }

    p.docx-num-5-3:before {
        content: "\9";
        counter-increment: docx-num-5-3;
        font-family: Symbol;
    }

    p.docx-num-5-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-5-3 {
        counter-reset: docx-num-5-4;
    }

    p.docx-num-5-4:before {
        content: "o\9";
        counter-increment: docx-num-5-4;
        font-family: Courier New;
    }

    p.docx-num-5-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-5-4 {
        counter-reset: docx-num-5-5;
    }

    p.docx-num-5-5:before {
        content: "\9";
        counter-increment: docx-num-5-5;
        font-family: Wingdings;
    }

    p.docx-num-5-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-5-5 {
        counter-reset: docx-num-5-6;
    }

    p.docx-num-5-6:before {
        content: "\9";
        counter-increment: docx-num-5-6;
        font-family: Symbol;
    }

    p.docx-num-5-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-5-6 {
        counter-reset: docx-num-5-7;
    }

    p.docx-num-5-7:before {
        content: "o\9";
        counter-increment: docx-num-5-7;
        font-family: Courier New;
    }

    p.docx-num-5-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-5-7 {
        counter-reset: docx-num-5-8;
    }

    p.docx-num-5-8:before {
        content: "\9";
        counter-increment: docx-num-5-8;
        font-family: Wingdings;
    }

    p.docx-num-5-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-2-0:before {
        content: "\9";
        counter-increment: docx-num-2-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-2-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-2-0 {
        counter-reset: docx-num-2-1;
    }

    p.docx-num-2-1:before {
        content: "o\9";
        counter-increment: docx-num-2-1;
        font-family: Courier New;
    }

    p.docx-num-2-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-2-1 {
        counter-reset: docx-num-2-2;
    }

    p.docx-num-2-2:before {
        content: "\9";
        counter-increment: docx-num-2-2;
        font-family: Wingdings;
    }

    p.docx-num-2-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-2-2 {
        counter-reset: docx-num-2-3;
    }

    p.docx-num-2-3:before {
        content: "\9";
        counter-increment: docx-num-2-3;
        font-family: Symbol;
    }

    p.docx-num-2-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-2-3 {
        counter-reset: docx-num-2-4;
    }

    p.docx-num-2-4:before {
        content: "o\9";
        counter-increment: docx-num-2-4;
        font-family: Courier New;
    }

    p.docx-num-2-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-2-4 {
        counter-reset: docx-num-2-5;
    }

    p.docx-num-2-5:before {
        content: "\9";
        counter-increment: docx-num-2-5;
        font-family: Wingdings;
    }

    p.docx-num-2-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-2-5 {
        counter-reset: docx-num-2-6;
    }

    p.docx-num-2-6:before {
        content: "\9";
        counter-increment: docx-num-2-6;
        font-family: Symbol;
    }

    p.docx-num-2-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-2-6 {
        counter-reset: docx-num-2-7;
    }

    p.docx-num-2-7:before {
        content: "o\9";
        counter-increment: docx-num-2-7;
        font-family: Courier New;
    }

    p.docx-num-2-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-2-7 {
        counter-reset: docx-num-2-8;
    }

    p.docx-num-2-8:before {
        content: "\9";
        counter-increment: docx-num-2-8;
        font-family: Wingdings;
    }

    p.docx-num-2-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-13-0:before {
        content: "\9";
        counter-increment: docx-num-13-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-13-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-13-0 {
        counter-reset: docx-num-13-1;
    }

    p.docx-num-13-1:before {
        content: "o\9";
        counter-increment: docx-num-13-1;
        font-family: Courier New;
    }

    p.docx-num-13-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-13-1 {
        counter-reset: docx-num-13-2;
    }

    p.docx-num-13-2:before {
        content: "\9";
        counter-increment: docx-num-13-2;
        font-family: Wingdings;
    }

    p.docx-num-13-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-13-2 {
        counter-reset: docx-num-13-3;
    }

    p.docx-num-13-3:before {
        content: "\9";
        counter-increment: docx-num-13-3;
        font-family: Symbol;
    }

    p.docx-num-13-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-13-3 {
        counter-reset: docx-num-13-4;
    }

    p.docx-num-13-4:before {
        content: "o\9";
        counter-increment: docx-num-13-4;
        font-family: Courier New;
    }

    p.docx-num-13-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-13-4 {
        counter-reset: docx-num-13-5;
    }

    p.docx-num-13-5:before {
        content: "\9";
        counter-increment: docx-num-13-5;
        font-family: Wingdings;
    }

    p.docx-num-13-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-13-5 {
        counter-reset: docx-num-13-6;
    }

    p.docx-num-13-6:before {
        content: "\9";
        counter-increment: docx-num-13-6;
        font-family: Symbol;
    }

    p.docx-num-13-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-13-6 {
        counter-reset: docx-num-13-7;
    }

    p.docx-num-13-7:before {
        content: "o\9";
        counter-increment: docx-num-13-7;
        font-family: Courier New;
    }

    p.docx-num-13-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-13-7 {
        counter-reset: docx-num-13-8;
    }

    p.docx-num-13-8:before {
        content: "\9";
        counter-increment: docx-num-13-8;
        font-family: Wingdings;
    }

    p.docx-num-13-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-23-0:before {
        content: "" counter(docx-num-23-0, lower-alpha)".\9";
        counter-increment: docx-num-23-0;
    }

    p.docx-num-23-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-23-0 {
        counter-reset: docx-num-23-1;
    }

    p.docx-num-23-1:before {
        content: "" counter(docx-num-23-1, lower-alpha)".\9";
        counter-increment: docx-num-23-1;
    }

    p.docx-num-23-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-23-1 {
        counter-reset: docx-num-23-2;
    }

    p.docx-num-23-2:before {
        content: "" counter(docx-num-23-2, lower-roman)".\9";
        counter-increment: docx-num-23-2;
    }

    p.docx-num-23-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-23-2 {
        counter-reset: docx-num-23-3;
    }

    p.docx-num-23-3:before {
        content: "" counter(docx-num-23-3, decimal)".\9";
        counter-increment: docx-num-23-3;
    }

    p.docx-num-23-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-23-3 {
        counter-reset: docx-num-23-4;
    }

    p.docx-num-23-4:before {
        content: "" counter(docx-num-23-4, lower-alpha)".\9";
        counter-increment: docx-num-23-4;
    }

    p.docx-num-23-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-23-4 {
        counter-reset: docx-num-23-5;
    }

    p.docx-num-23-5:before {
        content: "" counter(docx-num-23-5, lower-roman)".\9";
        counter-increment: docx-num-23-5;
    }

    p.docx-num-23-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-23-5 {
        counter-reset: docx-num-23-6;
    }

    p.docx-num-23-6:before {
        content: "" counter(docx-num-23-6, decimal)".\9";
        counter-increment: docx-num-23-6;
    }

    p.docx-num-23-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-23-6 {
        counter-reset: docx-num-23-7;
    }

    p.docx-num-23-7:before {
        content: "" counter(docx-num-23-7, lower-alpha)".\9";
        counter-increment: docx-num-23-7;
    }

    p.docx-num-23-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-23-7 {
        counter-reset: docx-num-23-8;
    }

    p.docx-num-23-8:before {
        content: "" counter(docx-num-23-8, lower-roman)".\9";
        counter-increment: docx-num-23-8;
    }

    p.docx-num-23-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-4-0:before {
        content: "\9";
        counter-increment: docx-num-4-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-4-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-4-0 {
        counter-reset: docx-num-4-1;
    }

    p.docx-num-4-1:before {
        content: "o\9";
        counter-increment: docx-num-4-1;
        font-family: Courier New;
    }

    p.docx-num-4-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-4-1 {
        counter-reset: docx-num-4-2;
    }

    p.docx-num-4-2:before {
        content: "\9";
        counter-increment: docx-num-4-2;
        font-family: Wingdings;
    }

    p.docx-num-4-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-4-2 {
        counter-reset: docx-num-4-3;
    }

    p.docx-num-4-3:before {
        content: "\9";
        counter-increment: docx-num-4-3;
        font-family: Symbol;
    }

    p.docx-num-4-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-4-3 {
        counter-reset: docx-num-4-4;
    }

    p.docx-num-4-4:before {
        content: "o\9";
        counter-increment: docx-num-4-4;
        font-family: Courier New;
    }

    p.docx-num-4-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-4-4 {
        counter-reset: docx-num-4-5;
    }

    p.docx-num-4-5:before {
        content: "\9";
        counter-increment: docx-num-4-5;
        font-family: Wingdings;
    }

    p.docx-num-4-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-4-5 {
        counter-reset: docx-num-4-6;
    }

    p.docx-num-4-6:before {
        content: "\9";
        counter-increment: docx-num-4-6;
        font-family: Symbol;
    }

    p.docx-num-4-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-4-6 {
        counter-reset: docx-num-4-7;
    }

    p.docx-num-4-7:before {
        content: "o\9";
        counter-increment: docx-num-4-7;
        font-family: Courier New;
    }

    p.docx-num-4-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-4-7 {
        counter-reset: docx-num-4-8;
    }

    p.docx-num-4-8:before {
        content: "\9";
        counter-increment: docx-num-4-8;
        font-family: Wingdings;
    }

    p.docx-num-4-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-16-0:before {
        content: "\9";
        counter-increment: docx-num-16-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-16-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-16-0 {
        counter-reset: docx-num-16-1;
    }

    p.docx-num-16-1:before {
        content: "o\9";
        counter-increment: docx-num-16-1;
        font-family: Courier New;
    }

    p.docx-num-16-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-16-1 {
        counter-reset: docx-num-16-2;
    }

    p.docx-num-16-2:before {
        content: "\9";
        counter-increment: docx-num-16-2;
        font-family: Wingdings;
    }

    p.docx-num-16-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-16-2 {
        counter-reset: docx-num-16-3;
    }

    p.docx-num-16-3:before {
        content: "\9";
        counter-increment: docx-num-16-3;
        font-family: Symbol;
    }

    p.docx-num-16-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-16-3 {
        counter-reset: docx-num-16-4;
    }

    p.docx-num-16-4:before {
        content: "o\9";
        counter-increment: docx-num-16-4;
        font-family: Courier New;
    }

    p.docx-num-16-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-16-4 {
        counter-reset: docx-num-16-5;
    }

    p.docx-num-16-5:before {
        content: "\9";
        counter-increment: docx-num-16-5;
        font-family: Wingdings;
    }

    p.docx-num-16-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-16-5 {
        counter-reset: docx-num-16-6;
    }

    p.docx-num-16-6:before {
        content: "\9";
        counter-increment: docx-num-16-6;
        font-family: Symbol;
    }

    p.docx-num-16-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-16-6 {
        counter-reset: docx-num-16-7;
    }

    p.docx-num-16-7:before {
        content: "o\9";
        counter-increment: docx-num-16-7;
        font-family: Courier New;
    }

    p.docx-num-16-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-16-7 {
        counter-reset: docx-num-16-8;
    }

    p.docx-num-16-8:before {
        content: "\9";
        counter-increment: docx-num-16-8;
        font-family: Wingdings;
    }

    p.docx-num-16-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-12-0:before {
        content: "\9";
        counter-increment: docx-num-12-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-12-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-12-0 {
        counter-reset: docx-num-12-1;
    }

    p.docx-num-12-1:before {
        content: "o\9";
        counter-increment: docx-num-12-1;
        font-family: Courier New;
    }

    p.docx-num-12-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-12-1 {
        counter-reset: docx-num-12-2;
    }

    p.docx-num-12-2:before {
        content: "\9";
        counter-increment: docx-num-12-2;
        font-family: Wingdings;
    }

    p.docx-num-12-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-12-2 {
        counter-reset: docx-num-12-3;
    }

    p.docx-num-12-3:before {
        content: "\9";
        counter-increment: docx-num-12-3;
        font-family: Symbol;
    }

    p.docx-num-12-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-12-3 {
        counter-reset: docx-num-12-4;
    }

    p.docx-num-12-4:before {
        content: "o\9";
        counter-increment: docx-num-12-4;
        font-family: Courier New;
    }

    p.docx-num-12-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-12-4 {
        counter-reset: docx-num-12-5;
    }

    p.docx-num-12-5:before {
        content: "\9";
        counter-increment: docx-num-12-5;
        font-family: Wingdings;
    }

    p.docx-num-12-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-12-5 {
        counter-reset: docx-num-12-6;
    }

    p.docx-num-12-6:before {
        content: "\9";
        counter-increment: docx-num-12-6;
        font-family: Symbol;
    }

    p.docx-num-12-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-12-6 {
        counter-reset: docx-num-12-7;
    }

    p.docx-num-12-7:before {
        content: "o\9";
        counter-increment: docx-num-12-7;
        font-family: Courier New;
    }

    p.docx-num-12-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-12-7 {
        counter-reset: docx-num-12-8;
    }

    p.docx-num-12-8:before {
        content: "\9";
        counter-increment: docx-num-12-8;
        font-family: Wingdings;
    }

    p.docx-num-12-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-8-0:before {
        content: "\9";
        counter-increment: docx-num-8-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-8-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-8-0 {
        counter-reset: docx-num-8-1;
    }

    p.docx-num-8-1:before {
        content: "o\9";
        counter-increment: docx-num-8-1;
        font-family: Courier New;
    }

    p.docx-num-8-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-8-1 {
        counter-reset: docx-num-8-2;
    }

    p.docx-num-8-2:before {
        content: "\9";
        counter-increment: docx-num-8-2;
        font-family: Wingdings;
    }

    p.docx-num-8-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-8-2 {
        counter-reset: docx-num-8-3;
    }

    p.docx-num-8-3:before {
        content: "\9";
        counter-increment: docx-num-8-3;
        font-family: Symbol;
    }

    p.docx-num-8-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-8-3 {
        counter-reset: docx-num-8-4;
    }

    p.docx-num-8-4:before {
        content: "o\9";
        counter-increment: docx-num-8-4;
        font-family: Courier New;
    }

    p.docx-num-8-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-8-4 {
        counter-reset: docx-num-8-5;
    }

    p.docx-num-8-5:before {
        content: "\9";
        counter-increment: docx-num-8-5;
        font-family: Wingdings;
    }

    p.docx-num-8-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-8-5 {
        counter-reset: docx-num-8-6;
    }

    p.docx-num-8-6:before {
        content: "\9";
        counter-increment: docx-num-8-6;
        font-family: Symbol;
    }

    p.docx-num-8-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-8-6 {
        counter-reset: docx-num-8-7;
    }

    p.docx-num-8-7:before {
        content: "o\9";
        counter-increment: docx-num-8-7;
        font-family: Courier New;
    }

    p.docx-num-8-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-8-7 {
        counter-reset: docx-num-8-8;
    }

    p.docx-num-8-8:before {
        content: "\9";
        counter-increment: docx-num-8-8;
        font-family: Wingdings;
    }

    p.docx-num-8-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-26-0:before {
        content: "" counter(docx-num-26-0, decimal)".\9";
        counter-increment: docx-num-26-0;
    }

    p.docx-num-26-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-26-0 {
        counter-reset: docx-num-26-1;
    }

    p.docx-num-26-1:before {
        content: "" counter(docx-num-26-1, lower-alpha)".\9";
        counter-increment: docx-num-26-1;
    }

    p.docx-num-26-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-26-1 {
        counter-reset: docx-num-26-2;
    }

    p.docx-num-26-2:before {
        content: "" counter(docx-num-26-2, lower-roman)".\9";
        counter-increment: docx-num-26-2;
    }

    p.docx-num-26-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-26-2 {
        counter-reset: docx-num-26-3;
    }

    p.docx-num-26-3:before {
        content: "" counter(docx-num-26-3, decimal)".\9";
        counter-increment: docx-num-26-3;
    }

    p.docx-num-26-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-26-3 {
        counter-reset: docx-num-26-4;
    }

    p.docx-num-26-4:before {
        content: "" counter(docx-num-26-4, lower-alpha)".\9";
        counter-increment: docx-num-26-4;
    }

    p.docx-num-26-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-26-4 {
        counter-reset: docx-num-26-5;
    }

    p.docx-num-26-5:before {
        content: "" counter(docx-num-26-5, lower-roman)".\9";
        counter-increment: docx-num-26-5;
    }

    p.docx-num-26-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-26-5 {
        counter-reset: docx-num-26-6;
    }

    p.docx-num-26-6:before {
        content: "" counter(docx-num-26-6, decimal)".\9";
        counter-increment: docx-num-26-6;
    }

    p.docx-num-26-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-26-6 {
        counter-reset: docx-num-26-7;
    }

    p.docx-num-26-7:before {
        content: "" counter(docx-num-26-7, lower-alpha)".\9";
        counter-increment: docx-num-26-7;
    }

    p.docx-num-26-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-26-7 {
        counter-reset: docx-num-26-8;
    }

    p.docx-num-26-8:before {
        content: "" counter(docx-num-26-8, lower-roman)".\9";
        counter-increment: docx-num-26-8;
    }

    p.docx-num-26-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-19-0:before {
        content: "" counter(docx-num-19-0, ordinal)"\9";
        counter-increment: docx-num-19-0;
    }

    p.docx-num-19-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-19-0 {
        counter-reset: docx-num-19-1;
    }

    p.docx-num-19-1:before {
        content: "" counter(docx-num-19-1, lower-alpha)".\9";
        counter-increment: docx-num-19-1;
    }

    p.docx-num-19-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-19-1 {
        counter-reset: docx-num-19-2;
    }

    p.docx-num-19-2:before {
        content: "" counter(docx-num-19-2, lower-roman)".\9";
        counter-increment: docx-num-19-2;
    }

    p.docx-num-19-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-19-2 {
        counter-reset: docx-num-19-3;
    }

    p.docx-num-19-3:before {
        content: "" counter(docx-num-19-3, decimal)".\9";
        counter-increment: docx-num-19-3;
    }

    p.docx-num-19-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-19-3 {
        counter-reset: docx-num-19-4;
    }

    p.docx-num-19-4:before {
        content: "" counter(docx-num-19-4, lower-alpha)".\9";
        counter-increment: docx-num-19-4;
    }

    p.docx-num-19-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-19-4 {
        counter-reset: docx-num-19-5;
    }

    p.docx-num-19-5:before {
        content: "" counter(docx-num-19-5, lower-roman)".\9";
        counter-increment: docx-num-19-5;
    }

    p.docx-num-19-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-19-5 {
        counter-reset: docx-num-19-6;
    }

    p.docx-num-19-6:before {
        content: "" counter(docx-num-19-6, decimal)".\9";
        counter-increment: docx-num-19-6;
    }

    p.docx-num-19-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-19-6 {
        counter-reset: docx-num-19-7;
    }

    p.docx-num-19-7:before {
        content: "" counter(docx-num-19-7, lower-alpha)".\9";
        counter-increment: docx-num-19-7;
    }

    p.docx-num-19-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-19-7 {
        counter-reset: docx-num-19-8;
    }

    p.docx-num-19-8:before {
        content: "" counter(docx-num-19-8, lower-roman)".\9";
        counter-increment: docx-num-19-8;
    }

    p.docx-num-19-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-3-0:before {
        content: "\9";
        counter-increment: docx-num-3-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-3-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-3-0 {
        counter-reset: docx-num-3-1;
    }

    p.docx-num-3-1:before {
        content: "o\9";
        counter-increment: docx-num-3-1;
        font-family: Courier New;
    }

    p.docx-num-3-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-3-1 {
        counter-reset: docx-num-3-2;
    }

    p.docx-num-3-2:before {
        content: "\9";
        counter-increment: docx-num-3-2;
        font-family: Wingdings;
    }

    p.docx-num-3-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-3-2 {
        counter-reset: docx-num-3-3;
    }

    p.docx-num-3-3:before {
        content: "\9";
        counter-increment: docx-num-3-3;
        font-family: Symbol;
    }

    p.docx-num-3-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-3-3 {
        counter-reset: docx-num-3-4;
    }

    p.docx-num-3-4:before {
        content: "o\9";
        counter-increment: docx-num-3-4;
        font-family: Courier New;
    }

    p.docx-num-3-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-3-4 {
        counter-reset: docx-num-3-5;
    }

    p.docx-num-3-5:before {
        content: "\9";
        counter-increment: docx-num-3-5;
        font-family: Wingdings;
    }

    p.docx-num-3-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-3-5 {
        counter-reset: docx-num-3-6;
    }

    p.docx-num-3-6:before {
        content: "\9";
        counter-increment: docx-num-3-6;
        font-family: Symbol;
    }

    p.docx-num-3-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-3-6 {
        counter-reset: docx-num-3-7;
    }

    p.docx-num-3-7:before {
        content: "o\9";
        counter-increment: docx-num-3-7;
        font-family: Courier New;
    }

    p.docx-num-3-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-3-7 {
        counter-reset: docx-num-3-8;
    }

    p.docx-num-3-8:before {
        content: "\9";
        counter-increment: docx-num-3-8;
        font-family: Wingdings;
    }

    p.docx-num-3-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-37-0:before {
        content: "\9";
        counter-increment: docx-num-37-0;
        font-family: Wingdings;
        min-height: 9.00pt;
        font-size: 9.00pt;
    }

    p.docx-num-37-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 64.30pt;
    }

    p.docx-num-37-0 {
        counter-reset: docx-num-37-1;
    }

    p.docx-num-37-1:before {
        content: "o\9";
        counter-increment: docx-num-37-1;
        font-family: Courier New;
    }

    p.docx-num-37-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 100.30pt;
    }

    p.docx-num-37-1 {
        counter-reset: docx-num-37-2;
    }

    p.docx-num-37-2:before {
        content: "\9";
        counter-increment: docx-num-37-2;
        font-family: Wingdings;
    }

    p.docx-num-37-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 136.30pt;
    }

    p.docx-num-37-2 {
        counter-reset: docx-num-37-3;
    }

    p.docx-num-37-3:before {
        content: "\9";
        counter-increment: docx-num-37-3;
        font-family: Symbol;
    }

    p.docx-num-37-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 172.30pt;
    }

    p.docx-num-37-3 {
        counter-reset: docx-num-37-4;
    }

    p.docx-num-37-4:before {
        content: "o\9";
        counter-increment: docx-num-37-4;
        font-family: Courier New;
    }

    p.docx-num-37-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 208.30pt;
    }

    p.docx-num-37-4 {
        counter-reset: docx-num-37-5;
    }

    p.docx-num-37-5:before {
        content: "\9";
        counter-increment: docx-num-37-5;
        font-family: Wingdings;
    }

    p.docx-num-37-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 244.30pt;
    }

    p.docx-num-37-5 {
        counter-reset: docx-num-37-6;
    }

    p.docx-num-37-6:before {
        content: "\9";
        counter-increment: docx-num-37-6;
        font-family: Symbol;
    }

    p.docx-num-37-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 280.30pt;
    }

    p.docx-num-37-6 {
        counter-reset: docx-num-37-7;
    }

    p.docx-num-37-7:before {
        content: "o\9";
        counter-increment: docx-num-37-7;
        font-family: Courier New;
    }

    p.docx-num-37-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 316.30pt;
    }

    p.docx-num-37-7 {
        counter-reset: docx-num-37-8;
    }

    p.docx-num-37-8:before {
        content: "\9";
        counter-increment: docx-num-37-8;
        font-family: Wingdings;
    }

    p.docx-num-37-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 352.30pt;
    }

    p.docx-num-17-0:before {
        content: "\9";
        counter-increment: docx-num-17-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-17-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-17-0 {
        counter-reset: docx-num-17-1;
    }

    p.docx-num-17-1:before {
        content: "o\9";
        counter-increment: docx-num-17-1;
        font-family: Courier New;
    }

    p.docx-num-17-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-17-1 {
        counter-reset: docx-num-17-2;
    }

    p.docx-num-17-2:before {
        content: "\9";
        counter-increment: docx-num-17-2;
        font-family: Wingdings;
    }

    p.docx-num-17-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-17-2 {
        counter-reset: docx-num-17-3;
    }

    p.docx-num-17-3:before {
        content: "\9";
        counter-increment: docx-num-17-3;
        font-family: Symbol;
    }

    p.docx-num-17-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-17-3 {
        counter-reset: docx-num-17-4;
    }

    p.docx-num-17-4:before {
        content: "o\9";
        counter-increment: docx-num-17-4;
        font-family: Courier New;
    }

    p.docx-num-17-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-17-4 {
        counter-reset: docx-num-17-5;
    }

    p.docx-num-17-5:before {
        content: "\9";
        counter-increment: docx-num-17-5;
        font-family: Wingdings;
    }

    p.docx-num-17-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-17-5 {
        counter-reset: docx-num-17-6;
    }

    p.docx-num-17-6:before {
        content: "\9";
        counter-increment: docx-num-17-6;
        font-family: Symbol;
    }

    p.docx-num-17-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-17-6 {
        counter-reset: docx-num-17-7;
    }

    p.docx-num-17-7:before {
        content: "o\9";
        counter-increment: docx-num-17-7;
        font-family: Courier New;
    }

    p.docx-num-17-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-17-7 {
        counter-reset: docx-num-17-8;
    }

    p.docx-num-17-8:before {
        content: "\9";
        counter-increment: docx-num-17-8;
        font-family: Wingdings;
    }

    p.docx-num-17-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-39-0:before {
        content: "\9";
        counter-increment: docx-num-39-0;
        font-family: Wingdings;
        min-height: 9.00pt;
        font-size: 9.00pt;
    }

    p.docx-num-39-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 32.20pt;
    }

    p.docx-num-39-0 {
        counter-reset: docx-num-39-1;
    }

    p.docx-num-39-1:before {
        content: "o\9";
        counter-increment: docx-num-39-1;
        font-family: Courier New;
    }

    p.docx-num-39-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 68.20pt;
    }

    p.docx-num-39-1 {
        counter-reset: docx-num-39-2;
    }

    p.docx-num-39-2:before {
        content: "\9";
        counter-increment: docx-num-39-2;
        font-family: Wingdings;
    }

    p.docx-num-39-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 104.20pt;
    }

    p.docx-num-39-2 {
        counter-reset: docx-num-39-3;
    }

    p.docx-num-39-3:before {
        content: "\9";
        counter-increment: docx-num-39-3;
        font-family: Symbol;
    }

    p.docx-num-39-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 140.20pt;
    }

    p.docx-num-39-3 {
        counter-reset: docx-num-39-4;
    }

    p.docx-num-39-4:before {
        content: "o\9";
        counter-increment: docx-num-39-4;
        font-family: Courier New;
    }

    p.docx-num-39-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 176.20pt;
    }

    p.docx-num-39-4 {
        counter-reset: docx-num-39-5;
    }

    p.docx-num-39-5:before {
        content: "\9";
        counter-increment: docx-num-39-5;
        font-family: Wingdings;
    }

    p.docx-num-39-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 212.20pt;
    }

    p.docx-num-39-5 {
        counter-reset: docx-num-39-6;
    }

    p.docx-num-39-6:before {
        content: "\9";
        counter-increment: docx-num-39-6;
        font-family: Symbol;
    }

    p.docx-num-39-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 248.20pt;
    }

    p.docx-num-39-6 {
        counter-reset: docx-num-39-7;
    }

    p.docx-num-39-7:before {
        content: "o\9";
        counter-increment: docx-num-39-7;
        font-family: Courier New;
    }

    p.docx-num-39-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 284.20pt;
    }

    p.docx-num-39-7 {
        counter-reset: docx-num-39-8;
    }

    p.docx-num-39-8:before {
        content: "\9";
        counter-increment: docx-num-39-8;
        font-family: Wingdings;
    }

    p.docx-num-39-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 320.20pt;
    }

    p.docx-num-31-0:before {
        content: "" counter(docx-num-31-0, lower-alpha)".\9";
        counter-increment: docx-num-31-0;
        min-height: 10.00pt;
        font-size: 10.00pt;
    }

    p.docx-num-31-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 57.30pt;
    }

    p.docx-num-31-0 {
        counter-reset: docx-num-31-1;
    }

    p.docx-num-31-1:before {
        content: "o\9";
        counter-increment: docx-num-31-1;
        font-family: Courier New;
    }

    p.docx-num-31-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 93.30pt;
    }

    p.docx-num-31-1 {
        counter-reset: docx-num-31-2;
    }

    p.docx-num-31-2:before {
        content: "\9";
        counter-increment: docx-num-31-2;
        font-family: Wingdings;
    }

    p.docx-num-31-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 129.30pt;
    }

    p.docx-num-31-2 {
        counter-reset: docx-num-31-3;
    }

    p.docx-num-31-3:before {
        content: "\9";
        counter-increment: docx-num-31-3;
        font-family: Symbol;
    }

    p.docx-num-31-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 165.30pt;
    }

    p.docx-num-31-3 {
        counter-reset: docx-num-31-4;
    }

    p.docx-num-31-4:before {
        content: "o\9";
        counter-increment: docx-num-31-4;
        font-family: Courier New;
    }

    p.docx-num-31-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 201.30pt;
    }

    p.docx-num-31-4 {
        counter-reset: docx-num-31-5;
    }

    p.docx-num-31-5:before {
        content: "\9";
        counter-increment: docx-num-31-5;
        font-family: Wingdings;
    }

    p.docx-num-31-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 237.30pt;
    }

    p.docx-num-31-5 {
        counter-reset: docx-num-31-6;
    }

    p.docx-num-31-6:before {
        content: "\9";
        counter-increment: docx-num-31-6;
        font-family: Symbol;
    }

    p.docx-num-31-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 273.30pt;
    }

    p.docx-num-31-6 {
        counter-reset: docx-num-31-7;
    }

    p.docx-num-31-7:before {
        content: "o\9";
        counter-increment: docx-num-31-7;
        font-family: Courier New;
    }

    p.docx-num-31-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 309.30pt;
    }

    p.docx-num-31-7 {
        counter-reset: docx-num-31-8;
    }

    p.docx-num-31-8:before {
        content: "\9";
        counter-increment: docx-num-31-8;
        font-family: Wingdings;
    }

    p.docx-num-31-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 345.30pt;
    }

    p.docx-num-28-0:before {
        content: "" counter(docx-num-28-0, decimal)".\9";
        counter-increment: docx-num-28-0;
    }

    p.docx-num-28-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-28-0 {
        counter-reset: docx-num-28-1;
    }

    p.docx-num-28-1:before {
        content: "" counter(docx-num-28-1, decimal)".\9";
        counter-increment: docx-num-28-1;
        font-weight: bold;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx-num-28-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-28-1 {
        counter-reset: docx-num-28-2;
    }

    p.docx-num-28-2:before {
        content: "" counter(docx-num-28-2, lower-roman)".\9";
        counter-increment: docx-num-28-2;
    }

    p.docx-num-28-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-28-2 {
        counter-reset: docx-num-28-3;
    }

    p.docx-num-28-3:before {
        content: "" counter(docx-num-28-3, decimal)".\9";
        counter-increment: docx-num-28-3;
    }

    p.docx-num-28-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-28-3 {
        counter-reset: docx-num-28-4;
    }

    p.docx-num-28-4:before {
        content: "" counter(docx-num-28-4, lower-alpha)".\9";
        counter-increment: docx-num-28-4;
    }

    p.docx-num-28-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-28-4 {
        counter-reset: docx-num-28-5;
    }

    p.docx-num-28-5:before {
        content: "" counter(docx-num-28-5, lower-roman)".\9";
        counter-increment: docx-num-28-5;
    }

    p.docx-num-28-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-28-5 {
        counter-reset: docx-num-28-6;
    }

    p.docx-num-28-6:before {
        content: "" counter(docx-num-28-6, decimal)".\9";
        counter-increment: docx-num-28-6;
    }

    p.docx-num-28-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-28-6 {
        counter-reset: docx-num-28-7;
    }

    p.docx-num-28-7:before {
        content: "" counter(docx-num-28-7, lower-alpha)".\9";
        counter-increment: docx-num-28-7;
    }

    p.docx-num-28-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-28-7 {
        counter-reset: docx-num-28-8;
    }

    p.docx-num-28-8:before {
        content: "" counter(docx-num-28-8, lower-roman)".\9";
        counter-increment: docx-num-28-8;
    }

    p.docx-num-28-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-24-0:before {
        content: "" counter(docx-num-24-0, decimal)".\9";
        counter-increment: docx-num-24-0;
    }

    p.docx-num-24-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-24-0 {
        counter-reset: docx-num-24-1;
    }

    p.docx-num-24-1:before {
        content: "" counter(docx-num-24-1, lower-alpha)".\9";
        counter-increment: docx-num-24-1;
    }

    p.docx-num-24-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-24-1 {
        counter-reset: docx-num-24-2;
    }

    p.docx-num-24-2:before {
        content: "" counter(docx-num-24-2, lower-roman)".\9";
        counter-increment: docx-num-24-2;
    }

    p.docx-num-24-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-24-2 {
        counter-reset: docx-num-24-3;
    }

    p.docx-num-24-3:before {
        content: "" counter(docx-num-24-3, decimal)".\9";
        counter-increment: docx-num-24-3;
    }

    p.docx-num-24-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-24-3 {
        counter-reset: docx-num-24-4;
    }

    p.docx-num-24-4:before {
        content: "" counter(docx-num-24-4, lower-alpha)".\9";
        counter-increment: docx-num-24-4;
    }

    p.docx-num-24-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-24-4 {
        counter-reset: docx-num-24-5;
    }

    p.docx-num-24-5:before {
        content: "" counter(docx-num-24-5, lower-roman)".\9";
        counter-increment: docx-num-24-5;
    }

    p.docx-num-24-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-24-5 {
        counter-reset: docx-num-24-6;
    }

    p.docx-num-24-6:before {
        content: "" counter(docx-num-24-6, decimal)".\9";
        counter-increment: docx-num-24-6;
    }

    p.docx-num-24-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-24-6 {
        counter-reset: docx-num-24-7;
    }

    p.docx-num-24-7:before {
        content: "" counter(docx-num-24-7, lower-alpha)".\9";
        counter-increment: docx-num-24-7;
    }

    p.docx-num-24-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-24-7 {
        counter-reset: docx-num-24-8;
    }

    p.docx-num-24-8:before {
        content: "" counter(docx-num-24-8, lower-roman)".\9";
        counter-increment: docx-num-24-8;
    }

    p.docx-num-24-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-40-0:before {
        content: "\9";
        counter-increment: docx-num-40-0;
        font-family: Wingdings;
        min-height: 9.00pt;
        font-size: 9.00pt;
    }

    p.docx-num-40-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 50.20pt;
    }

    p.docx-num-40-0 {
        counter-reset: docx-num-40-1;
    }

    p.docx-num-40-1:before {
        content: "o\9";
        counter-increment: docx-num-40-1;
        font-family: Courier New;
    }

    p.docx-num-40-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 86.20pt;
    }

    p.docx-num-40-1 {
        counter-reset: docx-num-40-2;
    }

    p.docx-num-40-2:before {
        content: "\9";
        counter-increment: docx-num-40-2;
        font-family: Wingdings;
    }

    p.docx-num-40-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 122.20pt;
    }

    p.docx-num-40-2 {
        counter-reset: docx-num-40-3;
    }

    p.docx-num-40-3:before {
        content: "\9";
        counter-increment: docx-num-40-3;
        font-family: Symbol;
    }

    p.docx-num-40-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 158.20pt;
    }

    p.docx-num-40-3 {
        counter-reset: docx-num-40-4;
    }

    p.docx-num-40-4:before {
        content: "o\9";
        counter-increment: docx-num-40-4;
        font-family: Courier New;
    }

    p.docx-num-40-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 194.20pt;
    }

    p.docx-num-40-4 {
        counter-reset: docx-num-40-5;
    }

    p.docx-num-40-5:before {
        content: "\9";
        counter-increment: docx-num-40-5;
        font-family: Wingdings;
    }

    p.docx-num-40-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 230.20pt;
    }

    p.docx-num-40-5 {
        counter-reset: docx-num-40-6;
    }

    p.docx-num-40-6:before {
        content: "\9";
        counter-increment: docx-num-40-6;
        font-family: Symbol;
    }

    p.docx-num-40-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 266.20pt;
    }

    p.docx-num-40-6 {
        counter-reset: docx-num-40-7;
    }

    p.docx-num-40-7:before {
        content: "o\9";
        counter-increment: docx-num-40-7;
        font-family: Courier New;
    }

    p.docx-num-40-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 302.20pt;
    }

    p.docx-num-40-7 {
        counter-reset: docx-num-40-8;
    }

    p.docx-num-40-8:before {
        content: "\9";
        counter-increment: docx-num-40-8;
        font-family: Wingdings;
    }

    p.docx-num-40-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 338.20pt;
    }

    p.docx-num-32-0:before {
        content: "" counter(docx-num-32-0, lower-alpha)".\9";
        counter-increment: docx-num-32-0;
    }

    p.docx-num-32-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 18.00pt;
    }

    p.docx-num-32-0 {
        counter-reset: docx-num-32-1;
    }

    p.docx-num-32-1:before {
        content: "" counter(docx-num-32-1, decimal)".\9";
        counter-increment: docx-num-32-1;
        font-weight: bold;
        min-height: 14.00pt;
        font-size: 14.00pt;
    }

    p.docx-num-32-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 54.00pt;
    }

    p.docx-num-32-1 {
        counter-reset: docx-num-32-2;
    }

    p.docx-num-32-2:before {
        content: "" counter(docx-num-32-2, lower-roman)".\9";
        counter-increment: docx-num-32-2;
    }

    p.docx-num-32-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 90.00pt;
    }

    p.docx-num-32-2 {
        counter-reset: docx-num-32-3;
    }

    p.docx-num-32-3:before {
        content: "" counter(docx-num-32-3, decimal)".\9";
        counter-increment: docx-num-32-3;
    }

    p.docx-num-32-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 126.00pt;
    }

    p.docx-num-32-3 {
        counter-reset: docx-num-32-4;
    }

    p.docx-num-32-4:before {
        content: "" counter(docx-num-32-4, lower-alpha)".\9";
        counter-increment: docx-num-32-4;
    }

    p.docx-num-32-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 162.00pt;
    }

    p.docx-num-32-4 {
        counter-reset: docx-num-32-5;
    }

    p.docx-num-32-5:before {
        content: "" counter(docx-num-32-5, lower-roman)".\9";
        counter-increment: docx-num-32-5;
    }

    p.docx-num-32-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 198.00pt;
    }

    p.docx-num-32-5 {
        counter-reset: docx-num-32-6;
    }

    p.docx-num-32-6:before {
        content: "" counter(docx-num-32-6, decimal)".\9";
        counter-increment: docx-num-32-6;
    }

    p.docx-num-32-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 234.00pt;
    }

    p.docx-num-32-6 {
        counter-reset: docx-num-32-7;
    }

    p.docx-num-32-7:before {
        content: "" counter(docx-num-32-7, lower-alpha)".\9";
        counter-increment: docx-num-32-7;
    }

    p.docx-num-32-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 270.00pt;
    }

    p.docx-num-32-7 {
        counter-reset: docx-num-32-8;
    }

    p.docx-num-32-8:before {
        content: "" counter(docx-num-32-8, lower-roman)".\9";
        counter-increment: docx-num-32-8;
    }

    p.docx-num-32-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 306.00pt;
    }

    p.docx-num-38-0:before {
        content: "\9";
        counter-increment: docx-num-38-0;
        font-family: Wingdings;
        min-height: 9.00pt;
        font-size: 9.00pt;
    }

    p.docx-num-38-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 46.35pt;
    }

    p.docx-num-38-0 {
        counter-reset: docx-num-38-1;
    }

    p.docx-num-38-1:before {
        content: "o\9";
        counter-increment: docx-num-38-1;
        font-family: Courier New;
    }

    p.docx-num-38-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 82.35pt;
    }

    p.docx-num-38-1 {
        counter-reset: docx-num-38-2;
    }

    p.docx-num-38-2:before {
        content: "\9";
        counter-increment: docx-num-38-2;
        font-family: Wingdings;
    }

    p.docx-num-38-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 118.35pt;
    }

    p.docx-num-38-2 {
        counter-reset: docx-num-38-3;
    }

    p.docx-num-38-3:before {
        content: "\9";
        counter-increment: docx-num-38-3;
        font-family: Symbol;
    }

    p.docx-num-38-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 154.35pt;
    }

    p.docx-num-38-3 {
        counter-reset: docx-num-38-4;
    }

    p.docx-num-38-4:before {
        content: "o\9";
        counter-increment: docx-num-38-4;
        font-family: Courier New;
    }

    p.docx-num-38-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 190.35pt;
    }

    p.docx-num-38-4 {
        counter-reset: docx-num-38-5;
    }

    p.docx-num-38-5:before {
        content: "\9";
        counter-increment: docx-num-38-5;
        font-family: Wingdings;
    }

    p.docx-num-38-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 226.35pt;
    }

    p.docx-num-38-5 {
        counter-reset: docx-num-38-6;
    }

    p.docx-num-38-6:before {
        content: "\9";
        counter-increment: docx-num-38-6;
        font-family: Symbol;
    }

    p.docx-num-38-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 262.35pt;
    }

    p.docx-num-38-6 {
        counter-reset: docx-num-38-7;
    }

    p.docx-num-38-7:before {
        content: "o\9";
        counter-increment: docx-num-38-7;
        font-family: Courier New;
    }

    p.docx-num-38-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 298.35pt;
    }

    p.docx-num-38-7 {
        counter-reset: docx-num-38-8;
    }

    p.docx-num-38-8:before {
        content: "\9";
        counter-increment: docx-num-38-8;
        font-family: Wingdings;
    }

    p.docx-num-38-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 334.35pt;
    }

    p.docx-num-7-0:before {
        content: "\9";
        counter-increment: docx-num-7-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-7-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-7-0 {
        counter-reset: docx-num-7-1;
    }

    p.docx-num-7-1:before {
        content: "o\9";
        counter-increment: docx-num-7-1;
        font-family: Courier New;
    }

    p.docx-num-7-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-7-1 {
        counter-reset: docx-num-7-2;
    }

    p.docx-num-7-2:before {
        content: "\9";
        counter-increment: docx-num-7-2;
        font-family: Wingdings;
    }

    p.docx-num-7-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-7-2 {
        counter-reset: docx-num-7-3;
    }

    p.docx-num-7-3:before {
        content: "\9";
        counter-increment: docx-num-7-3;
        font-family: Symbol;
    }

    p.docx-num-7-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-7-3 {
        counter-reset: docx-num-7-4;
    }

    p.docx-num-7-4:before {
        content: "o\9";
        counter-increment: docx-num-7-4;
        font-family: Courier New;
    }

    p.docx-num-7-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-7-4 {
        counter-reset: docx-num-7-5;
    }

    p.docx-num-7-5:before {
        content: "\9";
        counter-increment: docx-num-7-5;
        font-family: Wingdings;
    }

    p.docx-num-7-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-7-5 {
        counter-reset: docx-num-7-6;
    }

    p.docx-num-7-6:before {
        content: "\9";
        counter-increment: docx-num-7-6;
        font-family: Symbol;
    }

    p.docx-num-7-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-7-6 {
        counter-reset: docx-num-7-7;
    }

    p.docx-num-7-7:before {
        content: "o\9";
        counter-increment: docx-num-7-7;
        font-family: Courier New;
    }

    p.docx-num-7-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-7-7 {
        counter-reset: docx-num-7-8;
    }

    p.docx-num-7-8:before {
        content: "\9";
        counter-increment: docx-num-7-8;
        font-family: Wingdings;
    }

    p.docx-num-7-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-29-0:before {
        content: "" counter(docx-num-29-0, decimal)".\9";
        counter-increment: docx-num-29-0;
    }

    p.docx-num-29-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-29-0 {
        counter-reset: docx-num-29-1;
    }

    p.docx-num-29-1:before {
        content: "" counter(docx-num-29-1, lower-alpha)".\9";
        counter-increment: docx-num-29-1;
    }

    p.docx-num-29-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-29-1 {
        counter-reset: docx-num-29-2;
    }

    p.docx-num-29-2:before {
        content: "" counter(docx-num-29-2, lower-roman)".\9";
        counter-increment: docx-num-29-2;
    }

    p.docx-num-29-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-29-2 {
        counter-reset: docx-num-29-3;
    }

    p.docx-num-29-3:before {
        content: "" counter(docx-num-29-3, decimal)".\9";
        counter-increment: docx-num-29-3;
    }

    p.docx-num-29-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-29-3 {
        counter-reset: docx-num-29-4;
    }

    p.docx-num-29-4:before {
        content: "" counter(docx-num-29-4, lower-alpha)".\9";
        counter-increment: docx-num-29-4;
    }

    p.docx-num-29-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-29-4 {
        counter-reset: docx-num-29-5;
    }

    p.docx-num-29-5:before {
        content: "" counter(docx-num-29-5, lower-roman)".\9";
        counter-increment: docx-num-29-5;
    }

    p.docx-num-29-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-29-5 {
        counter-reset: docx-num-29-6;
    }

    p.docx-num-29-6:before {
        content: "" counter(docx-num-29-6, decimal)".\9";
        counter-increment: docx-num-29-6;
    }

    p.docx-num-29-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-29-6 {
        counter-reset: docx-num-29-7;
    }

    p.docx-num-29-7:before {
        content: "" counter(docx-num-29-7, lower-alpha)".\9";
        counter-increment: docx-num-29-7;
    }

    p.docx-num-29-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-29-7 {
        counter-reset: docx-num-29-8;
    }

    p.docx-num-29-8:before {
        content: "" counter(docx-num-29-8, lower-roman)".\9";
        counter-increment: docx-num-29-8;
    }

    p.docx-num-29-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-10-0:before {
        content: "\9";
        counter-increment: docx-num-10-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-10-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-10-0 {
        counter-reset: docx-num-10-1;
    }

    p.docx-num-10-1:before {
        content: "o\9";
        counter-increment: docx-num-10-1;
        font-family: Courier New;
    }

    p.docx-num-10-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-10-1 {
        counter-reset: docx-num-10-2;
    }

    p.docx-num-10-2:before {
        content: "\9";
        counter-increment: docx-num-10-2;
        font-family: Wingdings;
    }

    p.docx-num-10-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-10-2 {
        counter-reset: docx-num-10-3;
    }

    p.docx-num-10-3:before {
        content: "\9";
        counter-increment: docx-num-10-3;
        font-family: Symbol;
    }

    p.docx-num-10-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-10-3 {
        counter-reset: docx-num-10-4;
    }

    p.docx-num-10-4:before {
        content: "o\9";
        counter-increment: docx-num-10-4;
        font-family: Courier New;
    }

    p.docx-num-10-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-10-4 {
        counter-reset: docx-num-10-5;
    }

    p.docx-num-10-5:before {
        content: "\9";
        counter-increment: docx-num-10-5;
        font-family: Wingdings;
    }

    p.docx-num-10-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-10-5 {
        counter-reset: docx-num-10-6;
    }

    p.docx-num-10-6:before {
        content: "\9";
        counter-increment: docx-num-10-6;
        font-family: Symbol;
    }

    p.docx-num-10-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-10-6 {
        counter-reset: docx-num-10-7;
    }

    p.docx-num-10-7:before {
        content: "o\9";
        counter-increment: docx-num-10-7;
        font-family: Courier New;
    }

    p.docx-num-10-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-10-7 {
        counter-reset: docx-num-10-8;
    }

    p.docx-num-10-8:before {
        content: "\9";
        counter-increment: docx-num-10-8;
        font-family: Wingdings;
    }

    p.docx-num-10-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-20-0:before {
        content: "" counter(docx-num-20-0, decimal)".\9";
        counter-increment: docx-num-20-0;
    }

    p.docx-num-20-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-20-0 {
        counter-reset: docx-num-20-1;
    }

    p.docx-num-20-1:before {
        content: "" counter(docx-num-20-1, lower-alpha)".\9";
        counter-increment: docx-num-20-1;
    }

    p.docx-num-20-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-20-1 {
        counter-reset: docx-num-20-2;
    }

    p.docx-num-20-2:before {
        content: "" counter(docx-num-20-2, lower-roman)".\9";
        counter-increment: docx-num-20-2;
    }

    p.docx-num-20-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-20-2 {
        counter-reset: docx-num-20-3;
    }

    p.docx-num-20-3:before {
        content: "" counter(docx-num-20-3, decimal)".\9";
        counter-increment: docx-num-20-3;
    }

    p.docx-num-20-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-20-3 {
        counter-reset: docx-num-20-4;
    }

    p.docx-num-20-4:before {
        content: "" counter(docx-num-20-4, lower-alpha)".\9";
        counter-increment: docx-num-20-4;
    }

    p.docx-num-20-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-20-4 {
        counter-reset: docx-num-20-5;
    }

    p.docx-num-20-5:before {
        content: "" counter(docx-num-20-5, lower-roman)".\9";
        counter-increment: docx-num-20-5;
    }

    p.docx-num-20-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-20-5 {
        counter-reset: docx-num-20-6;
    }

    p.docx-num-20-6:before {
        content: "" counter(docx-num-20-6, decimal)".\9";
        counter-increment: docx-num-20-6;
    }

    p.docx-num-20-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-20-6 {
        counter-reset: docx-num-20-7;
    }

    p.docx-num-20-7:before {
        content: "" counter(docx-num-20-7, lower-alpha)".\9";
        counter-increment: docx-num-20-7;
    }

    p.docx-num-20-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-20-7 {
        counter-reset: docx-num-20-8;
    }

    p.docx-num-20-8:before {
        content: "" counter(docx-num-20-8, lower-roman)".\9";
        counter-increment: docx-num-20-8;
    }

    p.docx-num-20-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-35-0:before {
        content: "" counter(docx-num-35-0, decimal)".\9";
        counter-increment: docx-num-35-0;
    }

    p.docx-num-35-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-35-0 {
        counter-reset: docx-num-35-1;
    }

    p.docx-num-35-1:before {
        content: "" counter(docx-num-35-1, lower-alpha)".\9";
        counter-increment: docx-num-35-1;
    }

    p.docx-num-35-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-35-1 {
        counter-reset: docx-num-35-2;
    }

    p.docx-num-35-2:before {
        content: "" counter(docx-num-35-2, lower-roman)".\9";
        counter-increment: docx-num-35-2;
    }

    p.docx-num-35-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-35-2 {
        counter-reset: docx-num-35-3;
    }

    p.docx-num-35-3:before {
        content: "" counter(docx-num-35-3, decimal)".\9";
        counter-increment: docx-num-35-3;
    }

    p.docx-num-35-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-35-3 {
        counter-reset: docx-num-35-4;
    }

    p.docx-num-35-4:before {
        content: "" counter(docx-num-35-4, lower-alpha)".\9";
        counter-increment: docx-num-35-4;
    }

    p.docx-num-35-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-35-4 {
        counter-reset: docx-num-35-5;
    }

    p.docx-num-35-5:before {
        content: "" counter(docx-num-35-5, lower-roman)".\9";
        counter-increment: docx-num-35-5;
    }

    p.docx-num-35-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-35-5 {
        counter-reset: docx-num-35-6;
    }

    p.docx-num-35-6:before {
        content: "" counter(docx-num-35-6, decimal)".\9";
        counter-increment: docx-num-35-6;
    }

    p.docx-num-35-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-35-6 {
        counter-reset: docx-num-35-7;
    }

    p.docx-num-35-7:before {
        content: "" counter(docx-num-35-7, lower-alpha)".\9";
        counter-increment: docx-num-35-7;
    }

    p.docx-num-35-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-35-7 {
        counter-reset: docx-num-35-8;
    }

    p.docx-num-35-8:before {
        content: "" counter(docx-num-35-8, lower-roman)".\9";
        counter-increment: docx-num-35-8;
    }

    p.docx-num-35-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-9-0:before {
        content: "\9";
        counter-increment: docx-num-9-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-9-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-9-0 {
        counter-reset: docx-num-9-1;
    }

    p.docx-num-9-1:before {
        content: "o\9";
        counter-increment: docx-num-9-1;
        font-family: Courier New;
    }

    p.docx-num-9-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-9-1 {
        counter-reset: docx-num-9-2;
    }

    p.docx-num-9-2:before {
        content: "\9";
        counter-increment: docx-num-9-2;
        font-family: Wingdings;
    }

    p.docx-num-9-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-9-2 {
        counter-reset: docx-num-9-3;
    }

    p.docx-num-9-3:before {
        content: "\9";
        counter-increment: docx-num-9-3;
        font-family: Symbol;
    }

    p.docx-num-9-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-9-3 {
        counter-reset: docx-num-9-4;
    }

    p.docx-num-9-4:before {
        content: "o\9";
        counter-increment: docx-num-9-4;
        font-family: Courier New;
    }

    p.docx-num-9-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-9-4 {
        counter-reset: docx-num-9-5;
    }

    p.docx-num-9-5:before {
        content: "\9";
        counter-increment: docx-num-9-5;
        font-family: Wingdings;
    }

    p.docx-num-9-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-9-5 {
        counter-reset: docx-num-9-6;
    }

    p.docx-num-9-6:before {
        content: "\9";
        counter-increment: docx-num-9-6;
        font-family: Symbol;
    }

    p.docx-num-9-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-9-6 {
        counter-reset: docx-num-9-7;
    }

    p.docx-num-9-7:before {
        content: "o\9";
        counter-increment: docx-num-9-7;
        font-family: Courier New;
    }

    p.docx-num-9-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-9-7 {
        counter-reset: docx-num-9-8;
    }

    p.docx-num-9-8:before {
        content: "\9";
        counter-increment: docx-num-9-8;
        font-family: Wingdings;
    }

    p.docx-num-9-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-1-0:before {
        content: "" counter(docx-num-1-0, decimal)".\9";
        counter-increment: docx-num-1-0;
    }

    p.docx-num-1-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-1-0 {
        counter-reset: docx-num-1-1;
    }

    p.docx-num-1-1:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)".\9";
        counter-increment: docx-num-1-1;
    }

    p.docx-num-1-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -21.60pt;
        margin-left: 57.60pt;
    }

    p.docx-num-1-1 {
        counter-reset: docx-num-1-2;
    }

    p.docx-num-1-2:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)".\9";
        counter-increment: docx-num-1-2;
    }

    p.docx-num-1-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -25.20pt;
        margin-left: 79.20pt;
    }

    p.docx-num-1-2 {
        counter-reset: docx-num-1-3;
    }

    p.docx-num-1-3:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)".\9";
        counter-increment: docx-num-1-3;
    }

    p.docx-num-1-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -32.40pt;
        margin-left: 104.40pt;
    }

    p.docx-num-1-3 {
        counter-reset: docx-num-1-4;
    }

    p.docx-num-1-4:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)"." counter(docx-num-1-4, decimal)".\9";
        counter-increment: docx-num-1-4;
    }

    p.docx-num-1-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -39.60pt;
        margin-left: 129.60pt;
    }

    p.docx-num-1-4 {
        counter-reset: docx-num-1-5;
    }

    p.docx-num-1-5:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)"." counter(docx-num-1-4, decimal)"." counter(docx-num-1-5, decimal)".\9";
        counter-increment: docx-num-1-5;
    }

    p.docx-num-1-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -46.80pt;
        margin-left: 154.80pt;
    }

    p.docx-num-1-5 {
        counter-reset: docx-num-1-6;
    }

    p.docx-num-1-6:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)"." counter(docx-num-1-4, decimal)"." counter(docx-num-1-5, decimal)"." counter(docx-num-1-6, decimal)".\9";
        counter-increment: docx-num-1-6;
    }

    p.docx-num-1-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -54.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-1-6 {
        counter-reset: docx-num-1-7;
    }

    p.docx-num-1-7:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)"." counter(docx-num-1-4, decimal)"." counter(docx-num-1-5, decimal)"." counter(docx-num-1-6, decimal)"." counter(docx-num-1-7, decimal)".\9";
        counter-increment: docx-num-1-7;
    }

    p.docx-num-1-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -61.20pt;
        margin-left: 205.20pt;
    }

    p.docx-num-1-7 {
        counter-reset: docx-num-1-8;
    }

    p.docx-num-1-8:before {
        content: "" counter(docx-num-1-0, decimal)"." counter(docx-num-1-1, decimal)"." counter(docx-num-1-2, decimal)"." counter(docx-num-1-3, decimal)"." counter(docx-num-1-4, decimal)"." counter(docx-num-1-5, decimal)"." counter(docx-num-1-6, decimal)"." counter(docx-num-1-7, decimal)"." counter(docx-num-1-8, decimal)".\9";
        counter-increment: docx-num-1-8;
    }

    p.docx-num-1-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -72.00pt;
        margin-left: 234.00pt;
    }

    p.docx-num-27-0:before {
        content: "" counter(docx-num-27-0, decimal)".\9";
        counter-increment: docx-num-27-0;
    }

    p.docx-num-27-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-27-0 {
        counter-reset: docx-num-27-1;
    }

    p.docx-num-27-1:before {
        content: "" counter(docx-num-27-1, lower-alpha)".\9";
        counter-increment: docx-num-27-1;
    }

    p.docx-num-27-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-27-1 {
        counter-reset: docx-num-27-2;
    }

    p.docx-num-27-2:before {
        content: "" counter(docx-num-27-2, lower-roman)".\9";
        counter-increment: docx-num-27-2;
    }

    p.docx-num-27-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-27-2 {
        counter-reset: docx-num-27-3;
    }

    p.docx-num-27-3:before {
        content: "" counter(docx-num-27-3, decimal)".\9";
        counter-increment: docx-num-27-3;
    }

    p.docx-num-27-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-27-3 {
        counter-reset: docx-num-27-4;
    }

    p.docx-num-27-4:before {
        content: "" counter(docx-num-27-4, lower-alpha)".\9";
        counter-increment: docx-num-27-4;
    }

    p.docx-num-27-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-27-4 {
        counter-reset: docx-num-27-5;
    }

    p.docx-num-27-5:before {
        content: "" counter(docx-num-27-5, lower-roman)".\9";
        counter-increment: docx-num-27-5;
    }

    p.docx-num-27-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-27-5 {
        counter-reset: docx-num-27-6;
    }

    p.docx-num-27-6:before {
        content: "" counter(docx-num-27-6, decimal)".\9";
        counter-increment: docx-num-27-6;
    }

    p.docx-num-27-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-27-6 {
        counter-reset: docx-num-27-7;
    }

    p.docx-num-27-7:before {
        content: "" counter(docx-num-27-7, lower-alpha)".\9";
        counter-increment: docx-num-27-7;
    }

    p.docx-num-27-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-27-7 {
        counter-reset: docx-num-27-8;
    }

    p.docx-num-27-8:before {
        content: "" counter(docx-num-27-8, lower-roman)".\9";
        counter-increment: docx-num-27-8;
    }

    p.docx-num-27-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-14-0:before {
        content: "\9";
        counter-increment: docx-num-14-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-14-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-14-0 {
        counter-reset: docx-num-14-1;
    }

    p.docx-num-14-1:before {
        content: "o\9";
        counter-increment: docx-num-14-1;
        font-family: Courier New;
    }

    p.docx-num-14-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-14-1 {
        counter-reset: docx-num-14-2;
    }

    p.docx-num-14-2:before {
        content: "\9";
        counter-increment: docx-num-14-2;
        font-family: Wingdings;
    }

    p.docx-num-14-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-14-2 {
        counter-reset: docx-num-14-3;
    }

    p.docx-num-14-3:before {
        content: "\9";
        counter-increment: docx-num-14-3;
        font-family: Symbol;
    }

    p.docx-num-14-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-14-3 {
        counter-reset: docx-num-14-4;
    }

    p.docx-num-14-4:before {
        content: "o\9";
        counter-increment: docx-num-14-4;
        font-family: Courier New;
    }

    p.docx-num-14-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-14-4 {
        counter-reset: docx-num-14-5;
    }

    p.docx-num-14-5:before {
        content: "\9";
        counter-increment: docx-num-14-5;
        font-family: Wingdings;
    }

    p.docx-num-14-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-14-5 {
        counter-reset: docx-num-14-6;
    }

    p.docx-num-14-6:before {
        content: "\9";
        counter-increment: docx-num-14-6;
        font-family: Symbol;
    }

    p.docx-num-14-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-14-6 {
        counter-reset: docx-num-14-7;
    }

    p.docx-num-14-7:before {
        content: "o\9";
        counter-increment: docx-num-14-7;
        font-family: Courier New;
    }

    p.docx-num-14-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-14-7 {
        counter-reset: docx-num-14-8;
    }

    p.docx-num-14-8:before {
        content: "\9";
        counter-increment: docx-num-14-8;
        font-family: Wingdings;
    }

    p.docx-num-14-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    p.docx-num-22-0:before {
        content: "" counter(docx-num-22-0, decimal)".\9";
        counter-increment: docx-num-22-0;
    }

    p.docx-num-22-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 36.00pt;
    }

    p.docx-num-22-0 {
        counter-reset: docx-num-22-1;
    }

    p.docx-num-22-1:before {
        content: "" counter(docx-num-22-1, lower-alpha)".\9";
        counter-increment: docx-num-22-1;
    }

    p.docx-num-22-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 72.00pt;
    }

    p.docx-num-22-1 {
        counter-reset: docx-num-22-2;
    }

    p.docx-num-22-2:before {
        content: "" counter(docx-num-22-2, lower-roman)".\9";
        counter-increment: docx-num-22-2;
    }

    p.docx-num-22-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 108.00pt;
    }

    p.docx-num-22-2 {
        counter-reset: docx-num-22-3;
    }

    p.docx-num-22-3:before {
        content: "" counter(docx-num-22-3, decimal)".\9";
        counter-increment: docx-num-22-3;
    }

    p.docx-num-22-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 144.00pt;
    }

    p.docx-num-22-3 {
        counter-reset: docx-num-22-4;
    }

    p.docx-num-22-4:before {
        content: "" counter(docx-num-22-4, lower-alpha)".\9";
        counter-increment: docx-num-22-4;
    }

    p.docx-num-22-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 180.00pt;
    }

    p.docx-num-22-4 {
        counter-reset: docx-num-22-5;
    }

    p.docx-num-22-5:before {
        content: "" counter(docx-num-22-5, lower-roman)".\9";
        counter-increment: docx-num-22-5;
    }

    p.docx-num-22-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 216.00pt;
    }

    p.docx-num-22-5 {
        counter-reset: docx-num-22-6;
    }

    p.docx-num-22-6:before {
        content: "" counter(docx-num-22-6, decimal)".\9";
        counter-increment: docx-num-22-6;
    }

    p.docx-num-22-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 252.00pt;
    }

    p.docx-num-22-6 {
        counter-reset: docx-num-22-7;
    }

    p.docx-num-22-7:before {
        content: "" counter(docx-num-22-7, lower-alpha)".\9";
        counter-increment: docx-num-22-7;
    }

    p.docx-num-22-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 288.00pt;
    }

    p.docx-num-22-7 {
        counter-reset: docx-num-22-8;
    }

    p.docx-num-22-8:before {
        content: "" counter(docx-num-22-8, lower-roman)".\9";
        counter-increment: docx-num-22-8;
    }

    p.docx-num-22-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -9.00pt;
        margin-left: 324.00pt;
    }

    p.docx-num-6-0:before {
        content: "\9";
        counter-increment: docx-num-6-0;
        font-family: Wingdings;
        min-height: 8.00pt;
        font-size: 8.00pt;
    }

    p.docx-num-6-0 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 60.55pt;
    }

    p.docx-num-6-0 {
        counter-reset: docx-num-6-1;
    }

    p.docx-num-6-1:before {
        content: "o\9";
        counter-increment: docx-num-6-1;
        font-family: Courier New;
    }

    p.docx-num-6-1 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 96.55pt;
    }

    p.docx-num-6-1 {
        counter-reset: docx-num-6-2;
    }

    p.docx-num-6-2:before {
        content: "\9";
        counter-increment: docx-num-6-2;
        font-family: Wingdings;
    }

    p.docx-num-6-2 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 132.55pt;
    }

    p.docx-num-6-2 {
        counter-reset: docx-num-6-3;
    }

    p.docx-num-6-3:before {
        content: "\9";
        counter-increment: docx-num-6-3;
        font-family: Symbol;
    }

    p.docx-num-6-3 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 168.55pt;
    }

    p.docx-num-6-3 {
        counter-reset: docx-num-6-4;
    }

    p.docx-num-6-4:before {
        content: "o\9";
        counter-increment: docx-num-6-4;
        font-family: Courier New;
    }

    p.docx-num-6-4 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 204.55pt;
    }

    p.docx-num-6-4 {
        counter-reset: docx-num-6-5;
    }

    p.docx-num-6-5:before {
        content: "\9";
        counter-increment: docx-num-6-5;
        font-family: Wingdings;
    }

    p.docx-num-6-5 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 240.55pt;
    }

    p.docx-num-6-5 {
        counter-reset: docx-num-6-6;
    }

    p.docx-num-6-6:before {
        content: "\9";
        counter-increment: docx-num-6-6;
        font-family: Symbol;
    }

    p.docx-num-6-6 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 276.55pt;
    }

    p.docx-num-6-6 {
        counter-reset: docx-num-6-7;
    }

    p.docx-num-6-7:before {
        content: "o\9";
        counter-increment: docx-num-6-7;
        font-family: Courier New;
    }

    p.docx-num-6-7 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 312.55pt;
    }

    p.docx-num-6-7 {
        counter-reset: docx-num-6-8;
    }

    p.docx-num-6-8:before {
        content: "\9";
        counter-increment: docx-num-6-8;
        font-family: Wingdings;
    }

    p.docx-num-6-8 {
        display: list-item;
        list-style-position: inside;
        list-style-type: none;
        text-indent: -18.00pt;
        margin-left: 348.55pt;
    }

    .docx-wrapper {
        counter-reset: docx-num-34-0 docx-num-15-0 docx-num-25-0 docx-num-33-0 docx-num-21-0 docx-num-36-0 docx-num-30-0 docx-num-11-0 docx-num-18-0 docx-num-5-0 docx-num-2-0 docx-num-13-0 docx-num-23-0 docx-num-4-0 docx-num-16-0 docx-num-12-0 docx-num-8-0 docx-num-26-0 docx-num-19-0 docx-num-3-0 docx-num-37-0 docx-num-17-0 docx-num-39-0 docx-num-31-0 docx-num-28-0 docx-num-24-0 docx-num-40-0 docx-num-32-0 docx-num-38-0 docx-num-7-0 docx-num-29-0 docx-num-10-0 docx-num-20-0 docx-num-35-0 docx-num-9-0 docx-num-1-0 docx-num-27-0 docx-num-14-0 docx-num-22-0 docx-num-6-0;
    }

</style>
