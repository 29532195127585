<template>
  <div class="id-anchor" :id="content.name"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  methods: {
    anchorClickHandle (e) {
      // this function allows to use same scrolling anchor again and again
      if (e.target.href && e.target.href === window.location.href && e.target.href.includes('#')) {
        const hash = e.target.hash
        window.location.hash = ''
        window.location.hash = hash
      }
    }
  },

  mounted () {
    if (document && !window.$anchorClickHandling) {
      window.$anchorClickHandling = true
      document.addEventListener('click', this.anchorClickHandle)
    }
  },

  destroyed () {
    if (document) {
      window.$anchorClickHandling = false
      document.removeEventListener('click', this.anchorClickHandle)
    }
  }
}
</script>

<style lang="scss">
.id-anchor {
  position: relative;
  top: -100px; // to ignore header height
}
</style>
