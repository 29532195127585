<template>
    <div class="login-wrapper">
        <div class="login-page">
            <a :href="clientLink" target="_blank"><img class="cd-logo" src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_white.svg"/></a>
            <router-view :user="user" class="login"></router-view>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        user: {
          email: '',
          password: '',
          token: ''
        }
      }
    },

    computed: {
      clientLink () {
        return process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE
      }
    },

    mounted: function() {
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
    @import '../../scss/variables';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    $standardGutter: 30px;

    .login-wrapper {
        &:before {
            position: fixed;
            z-index: 1000;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            content: '';
            background-color: $darkBlueTone;
        }

        // height: calc(100vh - 150px);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 0 !important;

        .cd-logo {
            margin-bottom: 3.5rem;
        }

        .login-page {
            z-index: 1100;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 478px;
            width: 100%;
        }
    }


</style>

<style lang="scss">
    @import '../../scss/variables';
    @import '../../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';


    .login {
        &__box {
            width: 350px;
            max-width: 400px;
            padding: 48px;
            background: #fff;
            border-radius: 3px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        }

        &__headline {
            margin-bottom: 48px;
            opacity: .8;
            text-align: center;
            color: #fff;
            font-size: 1.6rem;
        }

        &__logo {
            max-width: 250px;
            margin-bottom: 48px;
        }

        .form-group label {
            font-weight: bold;
        }
        // button:not(.btn-clean) {
        //     border: 1px solid;
        //     padding: 1em 2em;
        //     cursor: pointer;
        // }
        .panels {
            text-align: center;
            padding-top: calc($standardGutter * 2);

            fieldset {
                margin: auto;

                legend {
                    width: auto;
                }

                label, input {
                    width: 100%;
                }

                input {
                    height: 35px;
                    padding: .8rem;
                }

                button:not(.btn-clean) {
                    margin-top: $standardGutter;
                }

                @include media-breakpoint-up(md) {
                    max-width: 390px;
                }
            }
        }
        #forgot_password_cta {
            margin-top: calc($standardGutter * 2);
        }
    }
</style>
