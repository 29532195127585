<template>
  <div class="acc-table" :class="{'css-contain-compatible': browserCompatibleWithContain}">
    <div class="acc-table__header" header-tag="header" role="tab">
      <b-button
        block
        v-b-toggle="id"
        variant="info"
        class="acc-btn text-left d-flex align-items-center"
      >
        <div class="sticky-container">
          <div class="sticky-col acc-table__header-label">
            <div class="arrow d-print-none">
            <Icon name="arrow-short-down" :width="12" :height="8" class="d-print-none"></Icon>
        </div>
        <span><slot name="header"></slot></span>
        <svg
          v-if="info"
          v-tooltip.top-start="info"
          @click.stop
          class="info-icon d-print-none"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#info-a)">
            <circle cx="11" cy="7" r="7" fill="#fff" />
            <rect x="10" y="6" width="2" height="5" rx="1" fill="#BEC6CA" />
            <rect x="10" y="3" width="2" height="2" rx="1" fill="#BEC6CA" />
          </g>
          <defs>
            <filter
              id="info-a"
              x="0"
              y="0"
              width="22"
              height="22"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_913_1827"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_913_1827"
                result="shape"
              />
            </filter>
          </defs></svg>
          </div>
        </div>
      </b-button>
    </div>
    <b-collapse
      :id="id"
      :visible="isOpenOnInit"
      @show="handleShowAcc($el)"
      role="tabpanel"
      class="acc-table__body-wrap"
    >
      <div class="acc-table__body">
        <slot name="body"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Icon from '../../ui/Icon'
import detectBrowser from '../../../../helpers/detectBrowser'
import eventBus from '@/components/shared/EventBus.js'

export default {
  components: { Icon },
  props: {
    id: {
      type: String
    },
    isOpenOnInit: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    // color: {
    //   type: String,
    //   default: ''
    // }
  },
  computed: {
    browserCompatibleWithContain () {
      // css property: contain
      // https://caniuse.com/mdn-css_properties_contain
      const browser = detectBrowser()
      const notCompatible =
        (browser.name === 'Safari' && browser.version < 15.4)
        || (browser.name === 'Firefox' && browser.version < 69)
        || (browser.name === 'IE')
      return !notCompatible
    }
  },
  methods: {
    handleShowAcc ($el) {
      if (this.mode === 'offer') {
        const elements = $el.querySelectorAll('.syncscroll')
        for (let i = 0; i < elements.length; i++) {
          eventBus.$emit('showAccordionSyncScroll', elements[i])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../../scss/print/accordionTable.scss';

.acc-table {  
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: fit-content;
}

.acc-table__header {
  position: sticky;
  left: 0;
  max-width: calc(100vw - 1.25rem);
  
  @include media-breakpoint-down(md) {
    // max-width: 100%;
  }

  @include media-breakpoint-down(xs) {
    max-width: calc(100vw - 2.85rem);
  }
}

.acc-table__header-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-left: 1.5rem;
  white-space: break-spaces;

  @include media-breakpoint-down(sm) {
    font-size: 0.875rem;
    padding-left: 1.5rem;
  }
}

.acc-table__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

::v-deep .btn {
  background: $primary;
  padding: 0.5rem 1.5rem 0.5rem 0;
  font-weight: bold;
  transition: 0.2s;
  border: 0;

  &:hover {
    background: $primary;
    filter: rgba($primary, 0.9);
  }

  &:active {
    background-color: rgba($primary, 0.9);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #bde4f5;
    background: $primary;
  }
  
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  background: #fff;
  box-shadow: 0px 36px 14px rgba(0, 0, 0, 0.01),
    0px 20px 12px rgba(0, 0, 0, 0.05);
  border-radius: 20rem;
  margin-right: 1rem;

  @include media-breakpoint-down(sm) {
    background: none;
    box-shadow: none;
    padding: 0;
    margin-right: 1rem;
    min-width: auto;
    width: auto;
  }

  .icon {
    transition: transform .4s;
    color: $primary;

    @include media-breakpoint-down(sm) {
      color: #fff;
    }
  }
}

::v-deep .info-icon {
  margin-top: -12px;

  @include media-breakpoint-down(sm) {
    position: absolute;
    margin-top: 0;
  }
}

.trigger-wrap {
  width: 100%;
}

::v-deep .not-collapsed {
  .arrow .icon {
    transform: rotate(180deg);
  }
}

.sticky-container {
  width: 100%;
}

.acc-btn {
  &:hover {
    background: rgba($primary, 0.9) !important;
  }
}

.css-contain-compatible {
  ::v-deep .collapsing {
    // fix blinkning when showing/hiding accordion
    overflow: visible;
    contain: paint;
  }
}
</style>
