<template>
    <div class="" id="your_business">
        <form v-on:submit.prevent.stop="submitForm()" name="step4" id="step_form">
            <div class="row mb-3">
                <div class="col-12">
                    <h2 class="text-center">
                        {{title}}
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 selected-insurer__logo" v-if="insurer">
                    <img :class="inputs.insurer" :src="getInsurerLogoPath(insurer)" :alt="insurer.label">
                </div>
            </div>
            <div class="row boxed-select-packages mb-4 d-flex flex-column flex-md-row justify-content-center"
                 v-if="products && products.length > 0">
                <div class="company"
                     :class="{ selected: product.insuredSum === inputs.insuredSum, last: products.indexOf(product) + 1 === products.length }"
                     v-for="(product, index) in products"
                     @click="inputs.insuredSum = product.insuredSum"
                     :key="index">
                    <div class="c-logo">
                        {{product.name}}
                    </div>
                    <div class="our-recommendation">
                        <div class="price">
                            <div class="price__net-amount">{{product.gross | currency}}</div>
                            <div class="price__info">inkl. {{insTaxRate}}% Versicherungssteuer</div>
                        </div>
                        <div class="hr-s"></div>
                        <div class="insurance-price">
                            <div class="insurance-price__amount">{{getInsuredSum(product) | currency(0)}}</div>
                            <div class="insurance-price__info">Versicherungssumme</div>
                        </div>
                    </div>
                    <div class="cd-checkbox select-button">
                        <label class="cd-checkbox__label" :for="'radio-' + product.name">Auswählen</label>
                        <input class="cd-checkbox__input"
                               type="radio"
                               :id="'radio-' + product.name"
                               name="selectedProduct"
                               v-model="inputs.insuredSum"
                               :value="product.insuredSum">
                        <label class="cd-checkbox__input-replacement" :for="'radio-' + product.id"></label>
                    </div>
                </div>
            </div>
            <input type="submit" id="step_submit">
        </form>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import eventBus from '@/components/shared/EventBus.js';
  import StringHelper from '../StringHelper';
  import store from '@/store/index';
  import isStepAllowed from '../mixins/isStepAllowed.js'
  import agencyKey from '../mixins/agencyKey';

  export default {
    name: 'Step2',
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'style',
        'products',
        'affiliateBroker',
        'isBroker'
      ]),
      isAffiliate() {
        return this.style === 'affiliate';
      },
      affiliateBroker() {
        return this.$store.state.checkout.application.affiliateBroker;
      },
      title() {
        return StringHelper.get(this.$store, 'STEP_3_TITLE');
      },
      inputs() {
        return this.$store.state.checkout.application.inputs;
      },
      insurers() {
        return this.$store.getters.filteredInsurers;
      },
      insurer() {
        return this.$store.state.checkout.application.insurers.find(insurer => insurer.key === this.$store.state.checkout.application.inputs.insurer);
      }
    },
    props: ['step'],
    mounted: function() {
      eventBus.$emit('stepChangeCompleted');
      eventBus.$on('submitStep', () => {
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        document.getElementById('step_submit').click()
      });

      this.$store.dispatch('UPDATE_INPUT_VALUES', {offeredProducts: this.products}); 
      window.scrollTo(0, 0);
    },
    beforeRouteEnter(to, from, next) {
        const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store;
        storeInstance
        .dispatch('CALCULATE_CYBER_PRODUCTS')
        .then(() => {
            next();
        });
    },
    beforeRouteUpdate(to, from, next) {
      const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store;
      storeInstance
        .dispatch('CALCULATE_CYBER_PRODUCTS')
        .then(() => {
          next();
        });
    },
    methods: {
      getInsuredSum(product) {
        // TODO need generic function here to change visible insuredSum
        return this.insurer.key === 'condor' && product.insuredSum === 100000 ? 125000 : product.insuredSum;
      },
      getInsurerLogoPath(insurer) {
        return insurer.logoUrl;
      },
      submitForm: function() {
        this.step.completed = true;
        return eventBus.$emit('changeStepForward', this.step);
      }
    },
    destroyed: function() {
      eventBus.$off('submitStep')
    },
    mixins: [isStepAllowed, agencyKey]
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '../scss/signup-shared';
    @import '../scss/form';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    @include media-breakpoint-down(sm) {
        #your_business {
            padding-top: 116px !important;
        }
    }

    .selected-insurer {
        &__logo {
            text-align: center;

            img {
                max-width: 200px;
            }
        }
    }

    .controls {
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        flex-direction: row;
        background: $lightGrey;
        border-bottom: 1px solid $lightGrey3;

        &__item {
            flex: 1;
            padding: 8px;

            + .controls__item {
                border-left: 1px solid $lightGrey3;
            }
        }

        &__select {
            border: 0;
            background: none;
            text-align-last: center;
            padding: 0;
            height: 20px !important;

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        &__label {
            display: block;
            font-size: 0.8rem;
            font-weight: bold;
            text-align: center;
        }
    }

    .boxed-select-packages {
        .company {
            padding: 0 1em 1em;

            div.c-logo {
                font-size: 2rem;
                color: #000;
                background: $cProductHoverColor;
                height: 91px;
            }

            div.our-recommendation {
                color: #000;
                overflow: hidden;
                > div {
                    padding-top: calc($standardGutter / 2);
                }

                > div:last-child {
                    padding-bottom: calc($standardGutter / 2);
                }

                .price {
                    color: $darkBlueTone;

                    &__net-amount {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }

                    &__info {
                        font-size: .8rem;
                    }
                }

                .insurance-price {
                    color: $darkBlueTone;
                    font-size: 1.1rem;
                    line-height: 1.1;

                    &__amount {
                        font-weight: bold;
                        font-size: 1.1rem;
                    }

                    &__info {
                        font-size: .8rem;
                    }
                }
            }

            > div {
                .c-product {
                    span {
                        font-weight: 500;
                    }
                }
            }
            .hr-s {
                margin: calc($standardGutter / 2) auto calc($standardGutter / 2);
                width: 246px;
                border-bottom: 2px solid;
                border-color: #11334c;
            }
        }

        .company.last {

        }

        .company.selected {

            .c-logo {
                background: $blueTone;
                color: #fff;
            }
            .hr-s {
                border-color: $blueTone;
            }
        }

        .company:hover {
            .hr-s {
                border-color: $blueTone;
            }
        }

        .company:not(.selected) {

            div.our-recommendation {
                background: #efefef;
            }

        }

    }

    #product_list {
        margin-top: 0;

        .c-product {
            justify-content: flex-end;
            padding-right: 75px;
        }
    }

    #check_area {
        padding-top: $standardGutter;
        padding-bottom: $standardGutter;
        text-align: center;
        h5 {
            color: #000;
        }
    }

    @include media-breakpoint-down(md) {
        #check_area {
            padding-top: 0;
        }
    }

    @media screen and (max-width: $mediumOverwrite) {
        .product {
            margin-bottom: $standardGutter;
        }
    }

</style>
