import Vue from 'vue';
import moment from 'moment';

export default {
  generate(brokerId, comment) {
    const validUntil = moment().add(3, 'month').format('YYYY-MM-DD');

    return Vue.http2.post(`/voucher/generate`, {
      brokerId,
      multiUse: false,
      action: 'phishing',
      keyword: 'broker-portal',
      validUntil,
      comment
    });
  },
  list() {
    return Vue.http2.get(`/voucher/get-voucher-details`);
  }
}
