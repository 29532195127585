<template>
  <div>
    <div id="presse_lp" class="secondary-page">
      <div class="container">
        <div class="row page-title">
          <div class="col">
            <h1>Presse</h1>
          </div>
          <div class="col-12 col-md-2 back-btn">
            <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-for="(post,index) in presseArticlesSorted" :key="index">
            <presse-article-tile :articleData="post" />
          </div>
        </div>
      </div>
    </div>
    <BackToTop />
  </div>
</template>

<script>
import PresseArticleTile from './PresseArticleTile'
import { mapGetters } from 'vuex'
import BackToTop from '@/components/reusable/BackToTop.vue'

export default {
  name: 'Presse',
  metaInfo: {
    title: 'Presse',
  },
  components: {
    PresseArticleTile,
      BackToTop
  },
  data() {
    return {
      left: [],
      right: [],
      center: [],
    }
  },
  computed: {
    ...mapGetters(['presseArticles']),
    presseArticlesSorted () {
      const articlesCopy = [...this.presseArticles]
      return articlesCopy.sort(this.compare);
    },
  },
  methods: {
    compare (a, b) {
      const articleA = a.publishedDate
      const articleB = b.publishedDate

      let comparison = 0
      if (articleA > articleB) {
        comparison = -1
      } else if (articleA < articleB) {
        comparison = 1
      }
      return comparison
    },
  }
}
</script>

