<template>
    <div class="container">
        <form v-on:submit.prevent.stop="submitForm()" name="step3" id="step_form">
            <div class="question">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <div class="row" id="question_header">
                            <h2 class="question__title">
                                Frage 2 von 2
                            </h2>
                        </div>
                        <p class="question__text">
                            Sind Umstände bekannt, die auf ein anstehendes Ermittlungsverfahren hinweisen?
                        </p>
                    </div>
                    <div class="col-12 col-sm-4 d-flex align-items-end justify-content-end"
                         id="desktop_navigator">
                        <div class="question__answers text-center">
                            <button type="button"
                                    @click="answerQuestion('no')"
                                    :class="{'btn-primary': isSelected('no')}"
                                    class="btn btn-default mr-2">Nein
                            </button>
                            <button type="button"
                                    @click="answerQuestion('yes')"
                                    :class="{'btn-primary': isSelected('yes')}"
                                    class="btn btn-default">Ja
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="inputs.answers['q-roland-2'] === 'yes'">
                <div class="col-12 form-group" :class="getFieldClass('investigationReason')">
                    <label for="investigationReason">Falls ja, welche?</label>
                    <input class="form-control"
                           type="text"
                           id="investigationReason"
                           name="investigationReason"
                           v-validate="'required'"
                           v-model="inputs.legal.investigationReason">
                </div>
                <div class="col-12 form-group" :class="getFieldClass('whoCancelled')">
                    <label>Fanden innerhalb der letzten 2 Jahre Straf- oder Ordnungswidrigkeitenverfahren (außerhalb des
                        Straßenverkehrs) statt?</label>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="currentInvestigationNo"
                               v-model="inputs.legal.currentInvestigation"
                               value="no"
                               name="currentInvestigation">
                        <label class="custom-control-label" for="currentInvestigationNo">Nein</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="currentInvestigationYes"
                               v-model="inputs.legal.currentInvestigation"
                               value="yes"
                               name="whoCancelled">
                        <label class="custom-control-label" for="currentInvestigationYes">Ja</label>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group" :class="getFieldClass('investigationCount')" v-if="inputs.legal.currentInvestigation === 'yes'">
                    <label for="investigationCount">Anzahl</label>
                    <input class="form-control"
                           type="text"
                           id="investigationCount"
                           name="investigationCount"
                           v-validate="'required'"
                           v-model="inputs.legal.investigationCount">
                </div>
                <div class="col-12 form-group" :class="getFieldClass('fileNumber')" v-if="inputs.legal.currentInvestigation === 'yes'">
                    <label for="fileNumber">Gegenstand des Verfahrens inkl. Aktenzeichen</label>
                    <input class="form-control"
                           type="text"
                           id="fileNumber"
                           name="fileNumber"
                           v-validate="'required'"
                           v-model="inputs.legal.fileNumber">
                    <p>
                        Bereits eingeleitete Verfahren und solche, die im mittelbaren oder unmittelbaren Zusammenhang
                        mit diesen stehen, sind vom Versicherungsschutz ausgeschlossen
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <button type="button"
                            class="btn-style"
                            @click="goBack()">
                        <icon name="chevron-left" color="secondary"></icon>
                        Zurück
                    </button>
                </div>
                <div class="col-6 text-right">
                    <button type="button"
                            class="btn-style primary"
                            @click="submitForm()"
                            v-if="isForwardVisible()">
                        Weiter
                        <icon name="chevron-right" color="white"></icon>
                    </button>
                </div>
            </div>
            <input type="submit" id="step_submit">
        </form>
        <div id="navigator" style="margin-top: 30px;">
            <div class="question__answers text-center">
                <button type="button"
                        @click="answerQuestion('no')"
                        :class="{'btn-primary': isSelected('no')}"
                        class="btn btn-default">Nein
                </button>
                <button type="button"
                        @click="answerQuestion('yes')"
                        :class="{'btn-primary': isSelected('yes')}"
                        class="btn btn-default btn-primary">Ja
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import eventBus from '@/components/shared/EventBus.js'
  import Icon from '../Icon.vue';

  export default {
    name: 'StepLegalQ2',
    data() {
      return {}
    },
    computed: {
      inputs() {
        return this.$store.state.checkout.application.inputs;
      }
    },
    props: ['step'],
    mounted() {
      eventBus.$emit('stepChangeCompleted');
      eventBus.$emit('disableHdrNext', true);
    },
    methods: {
      getFieldClass(fieldName) {
        if (!this.fields[fieldName]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      },
      goBack() {
        eventBus.$emit('goBack');
      },
      submitForm() {
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        this.step.completed = true;

        return eventBus.$emit('changeStepForward', this.step);
      },
      answerQuestion(answer) {
        this.$set(this.inputs.answers, 'q-roland-2', answer);
        this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs);
        if (answer === 'no') {
          this.submitForm();
        } else {
          if (!this.inputs.legal.whoCancelled) {
            this.$set(this.inputs.legal, 'whoCancelled', 'customer');
          }
          if (!this.inputs.legal.cancellationDate) {
            this.$set(this.inputs.legal, 'cancellationDate', Date.now());
          }
        }
      },
      isSelected(answer) {
        return this.inputs.answers['q-roland-2'] === answer;
      },
      isForwardVisible() {
        return ['yes', 'no'].indexOf(this.inputs.answers['q-roland-2']) > -1;
      }
    },
    destroyed: function () {
      eventBus.$off('submitStep');
    },
    components: {
      Icon
    }
  }
</script>

<style lang="scss" scoped>
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '../scss/button';
    @import '../scss/form';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .question {
        display: inline-block;
        margin-right: 10px;
        min-height: 220px;
        width: 100%;

        .button {
            margin-right: 0;
        }

        &__text {
            position: relative;
        }

        &__answers {
            .btn {
                margin-bottom: 8px;
                width: 107px;
                border-radius: 8px;
                box-shadow: none;
            }
            .btn:not(.btn-primary) {
                background: #ddd;
                outline-color: #ddd;
            }

            .btn.btn-primary {
                color: #000;
                border: 0;
            }
            .btn:active {
                $thisColor: #efe9e9;
                background: $thisColor;
            }
        }
    }

    #navigator {
        position: fixed;
        bottom: 0;
        z-index: 10000;
        left: 0;
        width: 100%;
        margin-bottom: 0;
        display: none;
    }

    @include media-breakpoint-down(xs) {
        #navigator {
            display: block;
        }

        #desktop_navigator {
            display: none !important;
        }

        .question {
            &__answers {
                display: flex;
                height: 50px;
                .btn {
                    margin: 0;
                    width: 50%;
                    border-radius: 0;
                }
            }
        }
    }

</style>
