const routeNavigationMixin = {
  methods: {
    handleRouteNavigation(next, vm) {
      // we want the navigation go forward anyway
      // even if the data arent there or some other problem occured
      try {
        if (!vm.$store.state.checkout.application.isInitialDataLoaded) {
          vm.$store.dispatch('GET_INITIAL_DATA').then(() => {
            next()
          })
        } else {
          next()
        }
        // vm is access to the component instance with vuex etc.
      } catch (error) {
        vm.$toasted.show('Problem beim Laden von Daten, möglicherweise gibt es einige Probleme', {
          theme: 'bubble',
          type: 'warning',
          position: 'bottom-left',
          duration: 4000,
        })
        next()
      }
    },
  },
}

export default routeNavigationMixin
