<template>
  <div class="offers-wrapper"> 
    <OffersComponent :limit="3" :enableFilters="false"  />
  </div>
</template>

<script>
import OffersComponent from '@/components/OffersComponent'
  export default {
    components: {
      OffersComponent
    }
  }
</script>

<style lang="scss" scoped>
</style>