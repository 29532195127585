<template>
  <Card class="scores-info py-4 mb-3 mb-lg-0">
    <div class="mb-3">
      <b>Gesamtwertung</b>
    </div>
    <p>Bei Ratingkreisen mit zwei Ringen gilt:</p>
    <div class="text-with-dot text-sm">
      <svg width="30" height="20">
        <line x1="0" y1="10" x2="25" y2="10" stroke="#11b36e" stroke-width="8" />
      </svg>
      <p>Exklusiver CyberDirekt Tarif</p>
    </div>
    <div class="text-with-dot text-sm">
      <svg width="30" height="20">
        <line x1="0" y1="5" x2="25" y2="5" stroke="#89E0AC" stroke-width="4" />
        <line x1="0" y1="11" x2="25" y2="11" stroke="#89E0AC" stroke-width="4" />
      </svg>
      <p>Standardtarif</p>
    </div>
    <div class="mb-3"></div>

    <div class="text-sm">
      <p>
        Informationen zu der Methodik unseres Leistungsvergleiches* finden Sie
        <ButtonComponent btnStyle="link" behavior="externalLink" :link="`${CLIENT_BASE}methodik-vergleich`"> hier</ButtonComponent>.
      </p>
    </div>
  </Card>
</template>

<script>
import Card from '../../block/Card.vue'
import ButtonComponent from '../../ui/ButtonComponent.vue'

export default {
  components: {
    Card,
    ButtonComponent,
  },
  props: ['isOffer', 'isAffiliate'],
  computed: {
    CLIENT_BASE() {
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE
      return CLIENT_BASE.replace(/\/?$/, '/')
    },
  },
}
</script>

<style lang="scss" scoped>
.text-with-dot {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.standard {
  background-color: #5acd9c;
}

.brighter {
  background-color: #11b36e;
}
</style>
