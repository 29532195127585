<template>
  <div
    class="card-wrap"
    :class="[card.wrapClass, {
        'card-wrap--primary': card.variant === 'primary',}]">
    <div 
      v-if="isLoading" 
      class="card-basic"
      :class="{
        'card-basic--primary': card.variant === 'primary',
        'card-basic--loader': isLoading
        }">
      <LoaderComponent/>
    </div>
    <router-link
    v-else-if="card.link.type === 'internal'"
    :to="card.link.url"
    class="card-basic" 
    :class="{'card-basic--primary': card.variant === 'primary'}">   
      <div class="card-basic__header">
        <div class="card-basic__icon" v-html="card.icon" />
        <div class="card-basic__link">
          <span class="card-basic__link-label">
            {{ card.link.label }}
          </span>
          <Icon name="arrow-full-right" />
        </div>
      </div>
      <div class="card-basic__title">{{ card.title }}</div>
      <div class="card-basic__text">{{ card.text }}</div>
    </router-link>

    <a
    v-else
    :href="card.link.url"
    target="_blank"
    class="card-basic"
    :class="{'card-basic--primary': card.variant === 'primary'}">
      <div class="card-basic__header">
        <div class="card-basic__icon" v-html="card.icon" />
        <div class="card-basic__link">
          <span class="card-basic__link-label">
            {{ card.link.label }}
          </span>
          <Icon name="arrow-full-right" />
        </div>
      </div>
      <div class="card-basic__title">{{ card.title }}</div>
      <div class="card-basic__text">{{ card.text }}</div>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import LoaderComponent from '@/components/reusable/LoaderComponent'

export default {
  components: {
    Icon,
    LoaderComponent
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.card-basic {
  display: flex;
  flex-direction: column;
  background: $greyBlueDark;
  border-radius: 0.25rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  color: $blackColor;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration: none;
      box-shadow: 0px 0px 16px rgba(88, 89, 91, 0.24);
      transition: 0.2s;

      .card-basic__link {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @include media-breakpoint-down(sm) {
      .card-basic__link {
        opacity: 1;
        transform: translateX(0);
      }
  }

  &--primary {
    background: $blueTone;
    color: $whiteColor;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .card-basic__link {
          color: $whiteColor;
        }
      }
    }

    @include media-breakpoint-down(sm) {
        .card-basic__link {
          color: $whiteColor;
        }
    }
  }

  &--loader {
    background: $greyBlueDark;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__icon {
    display: inline-flex;
  }

::v-deep
 &__link {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateX(-20px);
    transition: 0.2s;
    color: $blueTone;

    .icon, .icon svg {
      color: inherit;
      transition: all 0s;
    }
  }


  &__link-label {
    margin-right: 0.5rem;
    font-weight: bold;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}
</style>