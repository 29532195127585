<template>
    <div class="secondary-page">
        <div class="container">
            <div class="row page-title">
                <div class="col">
                    <h1>Cyber-Akademie</h1>
                </div>
                <div class="col-12 col-md-2 back-btn">
                    <router-link class="btn-style" :to="'/'">&larr;Zurück</router-link>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-md-10">
                    <p>Es ist keine separate Anmeldung erforderlich. Sie können aktuell bis zu fünf Fortbildungsstunden sammeln.</p>
                    <a class="btn-style" type="button" href="https://cyberdirekt.elearnio.com" target="_blank" rel="noopener">
                        <icon name="arrow-right" color="secondary"></icon>zur Cyber-Akademie
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <a type="button" href="https://cyberdirekt.elearnio.com" target="_blank" rel="noopener">
                        <img class="img-fluid shadow" src="/static/cyber-akademie.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import icon  from "@/components/Icon"
  export default {
    name: 'CyberAkademie',
    metaInfo: {
      title: 'CyberAkademie'
    },
    data() {
      return {
      }
    },
    computed: {},
    mounted: function () {
    },
    methods: {},
    components: {icon}
  }
</script>

<style lang="scss">
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .shadow {
        @include shadow();
    }
</style>
