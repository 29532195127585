import axios from 'axios'
import Vue from 'vue';

const API_BASE = process.env.API_BASE || process.env.VUE_APP_API_BASE
const axiosRef = Vue.http2 ? Vue.http2 : axios

export default {
  calculateCyberPremium(revenue, insuredSum, selectedModules, deductible, paymentPeriod, industry, answers, filter, meta, agency, form) {
    let filterString = filter ? `?filter=${filter}` : ''
    let params = {
      revenue,
      insuredSum,
      selectedModules,
      deductible,
      paymentPeriod,
      industry,
      answers,
      meta,
      form
    }

    if (agency) {
      params.agencyKeyOverwrite = agency;
    }

    return axiosRef.post(`${API_BASE}price-calculator/calculate-cyber-premium${filterString}`, params)
  },
  // used in step 2 after direct link to sign a registration with a single insurer
  calculateCyberPremiumProducts(insurer, revenue, productNames, insuredSums, selectedModules, deductible, paymentPeriod, industry, answers, meta, form, agency) {
    let params = {
      insurer,
      revenue,
      productNames,
      insuredSums,
      selectedModules,
      deductible,
      paymentPeriod,
      industry,
      answers,
      meta,
      form
    }

    if (agency) {
      params.agencyKeyOverwrite = agency;
    }

    return axiosRef.post(`${API_BASE}price-calculator/calculate-cyber-premium-products`, params)
  }
}
