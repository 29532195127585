<template>
    <div class="vue-progress flex flex--v-center">
        <div class="vue-progress__holder">
            <div class="vue-progress__line"
                 :style="{ transform: `translateX(-100%) translateX(${progress})` }"></div>
        </div>
        <div class="vue-progress__label">
            <slot>
                {{ progress }}
            </slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'vue-progress',
    props: {
      value: {
        type: Number,
        default: 0
      }
    },
    computed: {
      progress() {
        return Math.max(0, Math.min(100, Math.round(this.value))) + '%';
      }
    }
  }
</script>

<style lang="scss">
    @import '../../scss/trainings-variables';

    .vue-progress {
        &__holder,
        &__line {
            height: 5px;
            border-radius: 4px;
        }
        &__holder {
            background-color: $progressbar-bg-color;
            width: calc(100% - 55px);
            overflow: hidden;
        }

        &__line {
            background-color: $progressbar-line-color;
            transition: transform 0.4s ease-in-out;
            width: 100%;
        }

        &__label {
            width: 55px;
            font-size: 0.8rem;
            line-height: 1.25rem;
            color: $dark-blue;
            text-align: center;
        }
    }
</style>
