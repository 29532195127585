import Vue from 'vue';

export default {
  list(limit, offset) {
    let req = {};

    if (limit) {
      req.limit = limit;
    }

    if (offset) {
      req.offset = offset;
    }

    return Vue.http2.post('offers/listAgencyOffers', req);
  },
  remove(offerId) {
    return Vue.http2.delete(`offers/${offerId}`);
  }
};
