<template>
  <button
    v-if="btn.behavior === 'button'"
    :class="[styleClass, sizeClass]"
    @click="handleClick"
  >
    <Icon v-if="icon && (!icon.position || icon.position === 'left')" :name="icon.name" :width="icon.width" />
    <slot />
    <Icon v-if="icon && icon.position === 'right'" :name="icon.name" :width="icon.width" />
  </button>
  <router-link
    v-else-if="btn.behavior === 'internalLink'"
    :to="btn.link"
    :class="[styleClass, sizeClass]"
    @click="handleClick"
  >
    <Icon v-if="icon && (!icon.position || icon.position === 'left')" :name="icon.name" :width="icon.width" />
    <slot />
    <Icon v-if="icon && icon.position === 'right'" :name="icon.name" :width="icon.width" />
  </router-link>
  <a
    v-else
    :href="btn.link || '#'"
    :class="[styleClass, sizeClass]"
    @click="handleClick"
    target="_blank"
  >
    <Icon v-if="icon && (!icon.position || icon.position === 'left')" :name="icon.name" :width="icon.width" />
    <slot />
    <Icon v-if="icon && icon.position === 'right'" :name="icon.name" :width="icon.width" />
  </a>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: { Icon },
  props: {
    button: {
      type: Object,
      default: null
    },
    link: {
      type: String,
      default: ''
    },
    behavior: {
      type: String,
      default: 'internalLink'
      // e.g. 'button', 'externalLink'
    },
    btnStyle: {
      type: String,
      default: 'primary'
    },
    btnSize: {
      type: String,
      default: 'base'
    },
    icon: {
      type: Object,
      default: null
    }
  },
  computed: {
    btn() {
      let btn = {}
      btn.link = this.button?.link || this.link
      btn.behavior = this.button?.behavior || this.behavior
      btn.style = this.button?.style || this.btnStyle
      btn.size = this.btnSize
      return btn
    },
    styleClass() {
      return this.btn.style === 'accent'
        ? 'btn btn-accent'
        : this.btn.style === 'primary'
        ? 'btn btn-primary'
        : this.btn.style === 'accentBorder'
        ? 'btn btn-accent-border'
        : this.btn.style === 'whiteBorder'
        ? 'btn-basic btn-white-border'
        : this.btn.style === 'neutral'
        ? 'btn btn-neutral'
        : this.btn.style === 'link-primary' || this.btn.style === 'linkPrimary'
        ? 'link link-primary'
        : this.btn.style === 'link-accent' || this.btn.style === 'linkAccent'
        ? 'link link-accent'
        : ''
    },
    sizeClass() {
      return this.btn.size === 'md'
        ? 'btn-md'
        : this.btn.size === 'sm'
        ? 'btn-sm'
        : ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2.125rem;
  font-size: 1.15rem;
  border-radius: 4px;
  font-weight: 500;
  transition: 0.3s;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    opacity: 0.55;
    cursor: not-allowed;
  }
}

.btn-basic {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.btn-accent {
  background-image: linear-gradient(to bottom, transparent 0%, #e54c00 100%);
  background-color: #f26722;
  background-position: bottom;
  box-shadow: 0 4px 12px rgba(242, 103, 34, 0.4);
  font-weight: 600;
  border: 2px solid #f26722;

  &:hover {
    background-color: #ffa679;
    color: #fff;
  }
}

.btn-primary {
  background-image: linear-gradient(to bottom, transparent 0%, #31a9e0 100%);
  background-color: #45b9ef;
  background-position: bottom;
  box-shadow: 0 4px 12px rgba(49, 169, 224, 0.4);
  border: 2px solid #45b9ef;

  &:hover {
    background-color: #6fd1fe;
  }
}

.btn-accent-border {
  font-size: 1.125rem;
  border-radius: 4px;
  font-weight: 800;
  transition: 0.3s;
  color: #f26722;
  background-color: transparent;
  background-position: bottom;
  border: 2px solid #f26722;

  &:hover {
    background-color: #f26722;
    color: #fff;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.btn-white-border {
  display: inline-flex;
  justify-content: center;
  padding: calc(0.75rem - 2px) calc(2.25rem - 2px);
  font-size: 1.125rem;
  border-radius: 4px;
  font-weight: 800;
  transition: 0.3s;
  color: #fff;
  background-color: transparent;
  // box-shadow: 0 4px 12px rgba(242, 255, 255, 0.4);
  border: 2px solid #fff;

  &:hover {
    background-color: #fff;
    color: $blackColor;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.btn-neutral {
  border: 2px solid $greyBlueDark;
  color: $blackColor;
}

.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.link-primary {
  display: inline-flex;
  align-items: center;
  color: $primary;
  font-weight: bold;
  transition: 0.3s;
  text-decoration: none;

  .icon {
    transition: ease-in-out 0.2s;
  }

  &:hover {
    filter: brightness(120%);

    .icon {
      transform: translateX(3px);
    }
  }
}

.link-accent {
  display: inline-flex;
  align-items: center;
  color: $orangeTone;
  font-weight: bold;
  transition: 0.3s;
  text-decoration: none;

  .icon {
    transition: ease-in-out 0.2s;
    fill: $orangeTone;
  }

  &:hover {
    filter: brightness(120%);

    .icon {
      transform: translateX(3px);
    }
  }
}

.btn-md {
  padding: 0.7rem 5rem;
}
.btn-sm {
  padding: 5px 10px;
  min-width: 45%;
}

a {
  cursor: pointer;
}
</style>
