<template>
    <div class="secondary-page">
        <div class="container">
            <div class="row page-title">
                <div class="col">
                    <h1>
                        Mitarbeiterschulung
                    </h1>
                    <p>In diesem Bereich finden Sie die Kurse, welche für Ihr persönliches Cyber-Training zur Verfügung
                        stehen.</p>
                </div>
                <div class="col-12 col-md-2 back-btn">
                    <a href="/" class="btn-style router-link-active">
                        &larr;Zurück</a>
                </div>
            </div>
            <div class="row"
                 v-match-heights="{
          el: ['.course-item__content'],
          disabled: [ 767 ],
        }">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                     v-for="course in courses"
                     :key="course.id">
                    <course-item :course-data="course"
                                 :is-link="true"></course-item>
                </div>
            </div>
        </div>
      <BackToTop />
    </div>
</template>

<script>
  import CourseItem from '@/pages/trainings/CourseItem.vue';
  import BackToTop from '@/components/reusable/BackToTop.vue'

  export default {
    name: 'my-trainings',
    components: {
      CourseItem,
      BackToTop
    },
    data() {
      return {
        courses: [
          {
            id: '3',
            slug: 'email-security',
            image: require('@/pages/trainings/assets/email-security.jpg'),
            section: 'IT-Sicherheit',
            title: 'E-Mail Sicherheit',
            employees: [],
            active: true
          },
          {
            id: '4',
            slug: 'social-media',
            image: require('@/pages/trainings/assets/social-media.jpg'),
            section: 'IT-Sicherheit',
            title: 'Social Media',
            employees: [],
            active: true
          },
          {
            id: '9',
            slug: '5-golden-rules',
            image: require('@/pages/trainings/assets/5-golden-rules.jpg'),
            section: 'IT-Sicherheit',
            title: 'Die 5 goldenen Regeln der Cyber-Sicherheit',
            // start_date: new Date(2019, 0, 30).toJSON(),
            employees: [],
            active: true
          },
          {
            id: '5',
            slug: 'byod',
            image: require('@/pages/trainings/assets/byod.jpg'),
            section: 'IT-Sicherheit',
            title: 'Bring Your Own Device',
            // start_date: new Date(2019, 0, 30).toJSON(),
            employees: [],
            active: false
          },
          {
            id: '1',
            slug: 'social-engineering',
            image: require('@/pages/trainings/assets/social-engineering.jpg'),
            section: 'IT-Sicherheit',
            title: 'Social Engineering',
            employees: [],
            active: false
          },
          {
            id: '2',
            slug: 'password-security',
            image: require('@/pages/trainings/assets/password-security.jpg'),
            section: 'IT-Sicherheit',
            title: 'Passwort Sicherheit',
            employees: [],
            active: false
          },
          {
            id: '6',
            slug: 'connection-security',
            image: require('@/pages/trainings/assets/connection-security.jpg'),
            section: 'IT-Sicherheit',
            title: 'Verbindungssicherheit',
            // start_date: new Date(2019, 0, 30).toJSON(),
            employees: [],
            active: false
          },
          {
            id: '7',
            slug: 'physical-security',
            image: require('@/pages/trainings/assets/physical-security.jpg'),
            section: 'IT-Sicherheit',
            title: 'Physische Sicherheit',
            // start_date: new Date(2019, 0, 30).toJSON(),
            employees: [],
            active: false
          },
          {
            id: '8',
            slug: 'patchmanagement',
            image: require('@/pages/trainings/assets/patchmanagement.jpg'),
            section: 'IT-Sicherheit',
            title: 'Patchmanagement',
            // start_date: new Date(2019, 0, 30).toJSON(),
            employees: [],
            active: false
          },
          {
            id: '10',
            slug: 'cyber-emergency',
            image: require('@/pages/trainings/assets/cyber-emergency.jpg'),
            section: 'IT-Sicherheit',
            title: 'Cyber-Notfall',
            employees: [],
            active: false,
          },
          {
            id: '11',
            slug: 'malware',
            image: require('@/pages/trainings/assets/malware-image.jpg'),
            section: 'IT-Sicherheit',
            title: 'Schadprogramme',
            employees: [],
            active: false,
          },
          {
            id: '12',
            slug: 'remote-work',
            image: require('@/pages/trainings/assets/remote-work.jpg'),
            section: 'IT-Sicherheit',
            title: 'Remote Work',
            employees: [],
            active: false,
          }
        ]
      };
    },
    metaInfo() {
      return {
        title: this.title
      };
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
    @import '../../scss/variables';
    @import '../../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
</style>
