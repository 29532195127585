<template>
  <div>
    <div class="row dont-touchmove" id="top_panel">
      <div class="col-12 col-md-6 columns">
        <div><h1>{{vector.title}}</h1></div>
        <!-- <p>{{vector.description}}</p> -->
      </div>

      <div class="col-12 col-md-6 columns">

        <div>
          <svg @click="closeModal()" version="1.1" class="close-btn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 138.8 138.8" style="enable-background:new 0 0 138.8 138.8;" xml:space="preserve">
          <g id="Ebene_1" class="st0">
          </g>
          <g id="Ebene_2" class="st0">
          </g>
          <g id="Ebene_3">
            <g id="XMLID_3_">
              <!-- <circle id="XMLID_1_" class="st1" cx="69.4" cy="69.4" r="64.9"/> -->
              <!-- <path id="XMLID_2_" class="st2" d="M69.4,138.8C31.1,138.8,0,107.7,0,69.4S31.1,0,69.4,0s69.4,31.1,69.4,69.4 -->
                <!-- S107.7,138.8,69.4,138.8z M69.4,9C36.1,9,9,36.1,9,69.4s27.1,60.4,60.4,60.4s60.4-27.1,60.4-60.4S102.7,9,69.4,9z"/> -->
            </g>
            <g id="XMLID_8_">
              
                <rect id="XMLID_27_" x="26.8" y="64.2" transform="matrix(0.6974 -0.7167 0.7167 0.6974 -28.8142 70.8532)" class="st2" width="85.5" height="10.8"/>
            </g>
            <g id="XMLID_10_">
              
                <rect id="XMLID_25_" x="26.9" y="64.2" transform="matrix(-0.7168 -0.6973 0.6973 -0.7168 71.0356 167.9528)" class="st2" width="85.5" height="10.8"/>
            </g>
          </g>
          <g id="Ebene_4">
          </g>
        </svg>
        </div>

      </div>      
      
    </div>

    <div class="row" id="page_links">
      <div class="col-12 columns">

        <div class="row">
          <div v-for="tab in vector.tabs" :class="columnClass">
            <router-link :to="{name: 'BeginnenSP', params: {tabId: tab.id}}">
              <span>{{tab.label}}</span>
            </router-link>
          </div>
        </div>

      </div>
    </div>

    <div
      class=""
      id="content_panel"
      tabindex="1"
      :class="tabPosition">
      <transition :name="transitionName">
        <router-view class="child-view" :key="$route.params.tabId" :tab="tabData"></router-view>
      </transition>
    </div>

  </div>
</template>

<script>
import _ from 'lodash'
import Swipe from 'swipejs'
import eventBus from '@/components/shared/EventBus.js'

export default {
  name: 'BeginnenLP',
  props: ['vector'],
  data () {
    return {
      transitionName: 'slide-left'
    }
  },
  beforeRouteUpdate (to, from, next) {
    const toId = to.params.tabId
    const fromId = from.params.tabId

    const contentPanel = document.getElementById('content_panel')
    const self = this

    setTimeout(() => {
      if (!self.smoothScroll) {
        return
      }
      /* istanbul ignore next */
      self.smoothScroll(contentPanel, 0, 200)
    }, 400)
    // contentPanel.scrollTo(0, 0)

    this.transitionName = toId < fromId ? 'slide-right' : 'slide-left'
    next()
  },
  mounted: function () {
    const self = this

    self.setPanelHeight()

    self.directionClass()
    eventBus.$on('rebindSwipe', function () {
      setTimeout(() => {
        self.directionClass()
      }, 1100)
    })
  },
  computed: {
    columnClass: function () {
      const colSpace = 12 / this.vector.tabs.length
      return 'col-12 col-md-COL columns'.replace(/COL/g, colSpace)
    },
    tabData: function () {
      return _.find(this.vector.tabs, { id: this.$route.params.tabId }) || {}
    },
    tabPosition: function () {
      var tab = _.find(this.vector.tabs, { id: this.$route.params.tabId })

      if (!tab) {
        return ''
      }

      const tabIndex = this.vector.tabs.indexOf(tab)
      var result = ''

      if (tabIndex === 0) {
        result = 'first'
      } else if (tabIndex === this.vector.tabs.length - 1) {
        result = 'last'
      }
      const modalBody = document.getElementById('modal_body')

      if (modalBody) {
        modalBody.classList.remove('first', 'last')

        if (result) {
          modalBody.classList.add(result)
        }
      }
      return result
    }
  },
  methods: {
    setPanelHeight: function () {
      var outerHeight = (el) => {
        var height = el.offsetHeight
        var style = getComputedStyle(el)

        height += parseInt(style.marginTop) + parseInt(style.marginBottom)
        return height
      }

      const topPanelHeight = outerHeight(document.getElementById('top_panel'))
      const pagelinksHeight = outerHeight(document.getElementById('page_links'))
      const containerHeight = outerHeight(document.getElementById('modal_container'))

      document.getElementById('content_panel').style.height = (containerHeight - (pagelinksHeight + topPanelHeight)) - 30 + 'px'
    },
    smoothScroll: function (element, target, duration) {
      target = Math.round(target)
      duration = Math.round(duration)

      if (duration < 0) {
        return Promise.reject('bad duration')
      }
      if (duration === 0) {
        element.scrollTop = target
        return Promise.resolve('done')
      }

      var startTime = Date.now()
      var endTime = startTime + duration

      var startTop = element.scrollTop
      var distance = target - startTop

      var smoothStep = function (start, end, point) {
        /* istanbul ignore next */
        if (point <= start) {
          return 0
        }

        if (point >= end) {
          return 1
        }
        var x = (point - start) / (end - start)
        return x * x * (3 - 2 * x)
      }

      return new Promise(function (resolve, reject) {
        var previousTop = element.scrollTop

        var scrollFrame = function () {
          var now = Date.now()
          var point = smoothStep(startTime, endTime, now)
          var frameTop = Math.round(startTop + (distance * point))
          element.scrollTop = frameTop

          // check if we're done!
          if (now >= endTime) {
            resolve('done')
            return
          }
          // If we were supposed to scroll but didn't, then we
          // probably hit the limit, so consider it done; not
          // interrupted.

          /* istanbul ignore next */
          if (element.scrollTop === previousTop && element.scrollTop !== frameTop) {
            resolve('done')
            return
          }
          previousTop = element.scrollTop

          // schedule next frame for execution
          setTimeout(scrollFrame, 0)
        }
        // boostrap the animation process
        setTimeout(scrollFrame, 0)
      })
    },
    directionClass: function () {
      const $contentPanel = document.getElementById('content_panel')
      const self = this

      if (!$contentPanel) {
        return
      }
      // console.log(self, Swipe)
      window.modalSwipe = new Swipe($contentPanel, {
        startSlide: 0,
        draggable: false,
        speed: 200,
        autoRestart: false,
        continuous: false,
        disableScroll: false,
        stopPropagation: false,
        distance: 80,
        arrowContainer: document.getElementById('modal_body'),
        callback: function (index, element, direction) {
          self.swipeHandler(direction)
        }
      })
    },
    closeModal: function () {
      return this.$router.push({ name: 'Vectors' })
    },
    swipeHandler: function (direction) {
      var tabId = parseInt(this.$route.params.tabId)
      if (isNaN(tabId)) {
        // Maybe we should probably throw a 404?
        console.error('Route TabId should be a number')
        return false
      }

      if (direction < 0) {
        tabId = (tabId + 1 > this.vector.tabs.length) ? tabId : tabId + 1
      } else {
        tabId = (tabId - 1 === 0) ? tabId : tabId - 1
      }
      return this.$router.push({ name: 'BeginnenSP', params: {tabId: tabId.toString()} })
    }
  },
  destroyed: function () {
    eventBus.$off('rebindSwipe')
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.row {
  > .columns {
    padding-left: calc($standardGutter * 2);
    padding-right: calc($standardGutter * 2);
  }
}

#top_panel {
  // height: 110px;
  padding-top: $standardGutter;
  padding-bottom: 0;

  > .columns {
    // height: 100%;
    padding-left: calc($standardGutter * 2);
    padding-right: calc($standardGutter * 2);
    > div {
      display: flex;
      height: 47px;
      line-height: 1;
      justify-content: flex-start;
      align-items: center;
    }
    
    svg.close-btn {
      $thisDimension: 50px;
      width: $thisDimension;
      position: relative;
      left: 13px;
      z-index: 10001;

      .st0{display:none;}
      .st1{fill:#C1C1C1;display:none;}
      .st2{fill:#000;}
    }

    svg.close-btn:hover,
    svg.close-btn:active {
      cursor: pointer;
      .st2{fill: $orangeTone;}
    }
  }
 
  > div.columns:last-child {
    > div {
      justify-content: flex-end;
    }
    // height: auto;
  }
}

#page_links {
  margin-top: $standardGutter;
  // margin-bottom: $standardGutter * 2;
  text-align: center;
  border-bottom: 1px solid $greyTone;

  .columns {
    > div {
      .columns {
        a {
          position: relative;
          top: 1px;
          height: 60px;
          display: block;
          text-decoration: none;
          text-transform: uppercase;
          color: #6a6a6a;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.25rem;
          span {
            display: block;
          }

          &::before,
          &::after {
            position: relative;
            top: 1px;
            box-sizing: inherit;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            height: 100%;
            width: 100%;
            transform-origin: center; // Ensure scaling is done from the center (expands outwards)
          }

          &::before {
            border-bottom: 5px solid $orangeTone;
            transform: scale3d(0,1,1); // Shrink only width
          }

          &::after {
            transform: scale3d(1,0,1); // Shrink only height
          }

        }
        a:hover {
          color: $orangeTone;
        }
        a.router-link-exact-active {
          color: $orangeTone;
          &::before,
          &::after {
            transform: scale3d(1,1,1); // Show full-size
            transition: transform 0.5s;
          }
        }        
      }
    }
  }
  
  // .router-link-exact-active {
  //   text-decoration: underline;
  //   font-weight: bold;
  // }
}
#content_panel {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  ::v-deep #container_block {
    width: auto!important;
  }
}

$translateVal: 100%;

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
.child-view {
  position: absolute;
  transition: all .5s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate($translateVal, 0);
  transform: translate($translateVal, 0);
}
.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-($translateVal), 0);
  transform: translate(-($translateVal), 0);
}

</style>
