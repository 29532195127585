<template>
    <button v-if="btn.behavior === 'button'" :class="[styleClass, sizeClass]" :style="cssProps" @click="handleClick">
      <Icon v-if="icon && icon.position === 'left'" :name="icon.name" />
      <slot /> 
      <Icon v-if="icon && icon.position === 'right'" :name="icon.name" />
    </button>
    <router-link v-else-if="btn.behavior === 'internalLink'" :to="btn.link" :class="[styleClass, sizeClass]" :style="cssProps" @click="handleClick">
      <Icon v-if="icon && icon.position === 'left'" :name="icon.name" />
      <slot />
      <Icon v-if="icon && icon.position === 'right'" :name="icon.name" />
    </router-link>
    <a v-else :href="btn.link || '#'" :class="[styleClass, sizeClass]" :style="cssProps" @click="handleClick" :target="openNewTab === undefined ? '_blank' : ''">      
      <Icon v-if="icon && icon.position === 'left'" :name="icon.name" />
      <slot />      
      <Icon v-if="icon && icon.position === 'right'" :name="icon.name" />
    </a>
</template>

<script>
import Icon from './Icon'

export default {
  components: {Icon},
  props: {
    button: {
      type: Object,
      default: null
    },
    link: {
      type: String,
      default: ''
    },
    behavior: {
      type: String,
      default: 'internalLink'
      // e.g. 'button', 'externalLink'
    },
    btnStyle: {
      type: String,
      default: 'primary'
    },
    btnSize: {
      type: String,
      default: 'base'
    },
    fontSize: {
      type: String
    },
    icon: {
      type: Object,
      default: null
    },
    openNewTab: {
      default: undefined,
    }
  },
  computed: {
    btn () {
      let btn = {}
      btn.link = this.link
      btn.behavior = this.behavior
      btn.style = this.btnStyle
      btn.size = this.btnSize
      btn.fontSize = this.fontSize
      return btn
    },
    styleClass () {
      return this.btn.style === 'accent'
        ? 'btn btn-accent'
        : this.btn.style === 'primary'
        ? 'btn btn-primary'
        : this.btn.style === 'neutral'
        ? 'btn btn-neutral'
        : this.btn.style === 'accent-border'
        ? 'btn btn-accent-border'
        : this.btn.style === 'link-primary'
        ? 'link link-primary' 
        : ''
    },
    sizeClass () {
      return this.btn.size === 'md' ? 'btn-md' : this.btn.size === 'sm' ? 'btn-sm' : ''
    },
    cssProps () {
      return {
        '--font-size': this.btn.fontSize || '1.125rem'
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2.125rem;
  font-size: var(--font-size);
  border-radius: 4px;
  font-weight: 500;
  transition: 0.3s;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    opacity: 0.55;
    cursor: not-allowed;
  }
}

.btn-accent {
  background-image: linear-gradient(to bottom, transparent 0%, #e54c00 100%);
  background-color: #f26722;
  background-position: bottom;
  box-shadow: 0 4px 12px rgba(242, 103, 34, 0.4);
  font-weight: bold;

  &:hover {
    background-color: #ffa679;
    color: #fff;
  }
}

.btn-primary {
  background-image: linear-gradient(to bottom, transparent 0%, #31a9e0 100%);
  background-color: #45b9ef;
  background-position: bottom;
  box-shadow: 0 4px 12px rgba(49, 169, 224, 0.4);
  &:hover {
    background-color: #6fd1fe;
  }
}

.btn-neutral {
  border: 2px solid $greyBlueDark;
  color: $blackColor
}

.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.link-primary {
  display: inline-flex;
  align-items: center;
  color: $primary;
  font-size: var(--font-size);
  font-weight: bold;
  transition: 0.3s;
  text-decoration: none;

  .icon {
    transition: ease-in-out 0.2s;
  }

  &:hover {
    filter: brightness(120%);
    text-decoration: none;

    .icon {
      transform: translateX(3px);
    }
  }
}

.btn-accent-border {  
  display: inline-block;
  padding: calc(0.75rem - 2px) calc(2.25rem - 2px);
  font-size: 1.125rem;
  border-radius: 0.25rem;
  font-weight: 800;
  transition: 0.3s;
  color: #f26722;
  background-color: transparent;
  background-position: bottom;
  box-shadow: 0 4px 12px rgba(242, 103, 34, 0.4);
  border: 2px solid #f26722;

  &:hover {
    background-color: #f26722;
    color: #fff;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.btn-md {
  padding: 0.7rem 5rem;
}
.btn-sm {
  padding: 5px 10px;
  min-width: 45%;
}

a {
  cursor: pointer;
}

*[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
