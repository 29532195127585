<template>
  <div>
    <cd-hero :header="header"></cd-hero>
    <div id="kontakt_body" class="has-rails">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 parent-col lhr">

            <!-- <div class=""> -->

              <div class="contact-card">
               <div>
                  <img src="../assets/kontakt/support.svg">
                  <h2>Für Kunden</h2>
                  <div class="c-item row">
                    <strong class="col-4">Email</strong> <a class="col-8 orange-link" href="mailto:info@cyberdirekt.de" target="_blank">info@cyberdirekt.de</a>
                  </div>
                  <div class="c-item row">
                    <strong class="col-4">Telefon</strong> <a class="col-8 orange-link" href="tel:03040366036" target="_blank">030 403660 36</a>
                  </div>
               </div>
              </div>
            </div>

          <div class="col-12 col-md-6 parent-col lhr">
              <div class="contact-card">
               <div>
                  <img src="../assets/kontakt/support.svg">
                  <h2>Für Makler</h2>
                  <div class="c-item row">
                    <strong class="col-4">Email</strong> <a class="col-8 orange-link" href="mailto:makler@cyberdirekt.de" target="_blank">makler@cyberdirekt.de</a>
                  </div>
                  <div class="c-item row">
                    <strong class="col-4">Telefon</strong> <a class="col-8 orange-link" href="tel:03040369529" target="_blank">030 403695 29</a>
                  </div>
               </div>
              </div>
          </div>
        </div>

         <div class="row">
            <div class="col-12 col-md-6 parent-col rhr un-pad-me">

            <div class="contact-card hotline">
              <div>
                <img src="../assets/kontakt/achtung.svg">
                <h2>Für Kunden mit Versicherungsvertrag</h2>
                <h4>24/7 Hotline im Schadensfall</h4>
                <h4>030 403660 35</h4>
              </div>
            </div>
            </div>
            <div class="col-12 col-md-6 parent-col rhr">

              <div class="contact-card adresse">
               <div>
                  <div class="c-item row">
                    <strong class="col-4">Anschrift</strong>
                    <div class="col-8">
                      <span>CyberDirekt GmbH</span>
                      <span>Köpenicker Str. 154A</span>
                      <span>Aufgang D, 3. OG</span>
                      <span>10997 Berlin</span>
                    </div>
                  </div>
               </div>
              </div>


            </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import CdHero from '@/components/shared/CdHero'

export default {
  name: 'Kontakt',
  metaInfo: {
    title: 'Kontakt'
  },
  data () {
    return {
      header: {
        title: 'Kontakt',
        image: 'https://s3.eu-central-1.amazonaws.com/images.cyberdirekt.de/hero_kontakt_makler_portal_5d1d3801a4.jpg',
        style: 'no-box'
      }
    }
  },
  mounted: function () {
  },
  methods: {
  },
  components: {
    CdHero
  }
}
</script>

<style lang="scss" scoped>

@import '../scss/variables';
@import '../scss/mixins';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.has-rails {
  position: relative;
  &:before {
    @include absoluteMe();
    content: ' ';
    width: 50%;
  }
  .row {

    #lhr {

      h2:not(:first-child) {
        margin-top: $standardGutter;
      }

    }

    #rhr {
      background: #fff;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column-reverse;

      .parent-col {
        padding-top: calc($standardGutter * 2);
        padding-bottom: calc($standardGutter * 2);
      }
    }
  }
}

#kontakt_body {
  background: #fff;
  &:before {
    height: 50%;
  }

  &:after {
    @include absoluteMe();
    height: 50%;
    background-color: $blueTone;
    content: ' ';
    width: 50%;
    bottom: 0;
    top: auto;
    z-index: 1;
  }

  > div.container {
    > div.row {
      position: relative;
      z-index: 2;
    }
  }

  .contact-card {
    display: flex;
    justify-content: center;
    height: 50%;
    text-align: center;
    font-size: 1.3rem;
    padding: calc($standardGutter * 2);

    > div {
      width: 340px;
    }

    img {
      width: 61px;
      margin: auto;
    }
    h2, h3, h4 {
      margin: 20px 0;
      color: $blueTone;
      font-weight: 550;
    }
    a {
      display: inline-block;
    }
    .c-item {
      text-align: left;
      flex-direction: row;
      strong {
        text-align: left;
      }
    }
  }

  .adresse {
    .c-item {
      width: 100%;
      text-align: left;
      span {
        display:block;
      }
    }
  }

  .hotline {
    height: 100%;
    h2, h3, h4 {
      color: #fff;
    }
    h4 {
      margin: 0;
      margin-bottom: 5px;

    }
  }

  #lhr {
    background :none;
  }

  @include media-breakpoint-down(md) {
    // display: none;
    &:before, &:after {
      width: 100%;
      background: none;
    }
    &:after {
      background: none;
    }

    .parent-col {
      padding-bottom: $standardGutter;
    }

    .contact-card {
      padding: 0;
      text-align: center;
    }

    .un-pad-me {
      padding: 0;
    }
    .hotline {
      background: $blueTone;
      padding: calc($standardGutter * 2) 0;
    }
  }

}

</style>
