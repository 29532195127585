export default {
  methods: {
    defaultSelection() {
      // console.log('defaulting selection')
      if (this.offerSelection && this.offerSelection.length > 0) {
        this.offerSelection = []
      }
    },
  },
}
