export default function logger(message, obj = null, type = 'info') {


  if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'local' && process.env.NODE_ENV !== 'staging') {
    // console.log('Logger is disabled in production')
    return
  }


  console.log( 
    `%c${message}`,
    `
        font-size: 1em;
        padding: 0.2em 0.2em;
        margin: 0.1em 0;
        color: ${ type === 'error' ? 'red' : '#007bff'};
        `
  )
  if (obj) {
    console.log(' data -> ', obj)
  }
}
