export const KEY_CODES = {
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,

  Backspace: 8,
  Tab: 9,
  Enter: 13,

  Shift: 16,
  Ctrl: 17,
  Alt: 18,

  Esc: 27,

  PgUp: 33,
  PgDown: 34,
  End: 35,
  Home: 36,

  Insert: 45,
  Delete: 127,

  LeftArrow: 37,
  UpArrow: 38,
  RightArrow: 39,
  DownArrow: 40,

  Num_0: 96,
  Num_1: 97,
  Num_2: 98,
  Num_3: 99,
  Num_4: 100,
  Num_5: 101,
  Num_6: 102,
  Num_7: 103,
  Num_8: 104,
  Num_9: 105
};
