<template>
  <div>
    <div id="vector_roll">
      <div class="row">
        <div class="col-12 columns">
          <h2>Verstehen Sie Ihr persönliches Cyber-Risiko</h2>          
        </div>
        <div class="col-12 col-md-3 columns vector-item"
          :tabindex="index"
          :id="vector.id" v-for="(vector, index) in filteredVectors"
          @click="openVector(vector)"
          :key="index"
          :class="{ disabled: vector.answer === false, visited: vector.visited }">

          <div>
            <img :src="'/static/checkmark.svg'" class="check-mark">
            <img :src="'/static/' + vector.image">
            <h4>{{vector.title}}</h4>
            <p>{{vector.description}}</p>
            <a @click.stop="openVector(vector, true)">Mehr erfahren ></a>
          </div>

        </div>      
      </div>    
    </div> <!-- end vector roll -->

    <transition name="modal">
      <modal v-if="selectedVector">
        <router-view
          class="modal-view"
          :vector="selectedVector"
          :key="$route.params.id"></router-view>
      </modal>
      </transition>

  </div>
</template>

<script>
import _ from 'lodash'
import Modal from '@/components/Modal'
import eventBus from '@/components/shared/EventBus.js'

export default {
  name: 'Vectors',
  props: ['attackVectors'],
  data () {
    return {
      lastScrollPos: 0
    }
  },
  computed: {
    selectedVector: function () {
      const modalId = this.$route.params.id
      if (!modalId) {
        return
      }
      return _.find(this.attackVectors, { id: this.$route.params.id })
    },
    filteredVectors: function () {
      const result = _.filter(this.attackVectors, {answer: true})
        .concat(_.filter(this.attackVectors, {answer: false}))

      return (!_.isEmpty(result)) ? result : this.attackVectors
    }
  },
  mounted: function () {
    const parent = this
    // window.scrollTo(0, 0)
    console.log('did this remounted?')
    eventBus.$on('nextPageHit', function () {
      eventBus.$emit('nextPage', true)
    })

    eventBus.$on('modalClosing', function () {
      console.log('modalClosing firing', parent.lastScrollPos)
      window.scrollTo(0, parent.lastScrollPos)
    })

    const checkedVectors = _.filter(this.attackVectors, {answer: null})
    if (!_.isEmpty(checkedVectors)) {
      _.each(this.attackVectors, _vector => {
        // Let's activate everything since it's probably coming from a refresh
        _vector.answer = true
      })
      return this.attackVectors
    }
  },
  methods: {
    openVector: function (vector, forceOpen) {
      if (!vector.answer && !forceOpen) {
        return false
      }
      vector.visited = true
      this.lastScrollPos = window.scrollY
      return this.$router.push('/beginnen/vectors/' + vector.id + '/tab/1')
    }
  },
  destroyed: function () {
    eventBus.$off('nextPageHit')
    eventBus.$off('modalClosing')
  },
  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

#vector_roll {
  padding-top: $standardGutter;

  .row .columns, .row .column {
      padding-left: 6px;
      padding-right: 6px;
    }

  > div {

    border-radius: $borderRadius;
    background: #f2f2f2;
    
    padding: 1em;
    h2:first-child {
      $padSize: 10px;
      // margin-bottom: $standardGutter;
      padding-top: $padSize;
      padding-bottom: $padSize;
    }
    
    .vector-item {
      text-align: center;
      cursor: pointer;
      margin-bottom: 20px;
      
      > div {
        transition : border .3s ease-out;
        position: relative;
        border-radius: $borderRadius;
        padding: 2rem .4rem;
        border: 1px solid transparent;
        border-width: 4px;
        background: #fff;
        height: 290px;
        box-shadow: 0px 2px 7.6px 0.4px rgba(29, 28, 23, 0.27); 
        text-align: center;
        justify-content: center;

        p, a {
          font-size: 1.1rem;
        }
        h4 {
          color: $blueTone;
          font-size: 1.6rem;          
        }
        svg, img {
          width: 60px;
          margin-bottom: 10px;
        }

        img.check-mark {
          position: absolute;
          right: 5px;
          top: 5px;
          width: 38px;
          opacity: 0;
          transition: opacity 1s ease-in-out;
        }

        a {
          color: $orangeTone;
          // font-size: 1.2rem;
          font-weight: 500;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;

        }

      }
    }
    .vector-item:last-child {
      // flo
    }

    .vector-item.disabled,
     .vector-item.disabled:hover{
      cursor: default;
      > div {
        border-color: transparent;
        background: #fff;
        img.check-mark {
          opacity: 0;
        }
        img, p, h4 {
          opacity: .3;
        }
        
        h4 {
          color: #000;
        }
        a {
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }        
      }
    }

    .vector-item.visited {
      > div {

        img {
        //  opacity: .4; 
        }
        img.check-mark {
          opacity: 1;
        }
        h4, p {
          // color: #6b6a6a;
        }    
      }
    }    
  }  
}

.activeVector {
> div {
        background: #ebf6fc;
        border-color: #33a8dd;

        a {
          text-decoration: underline;
        }
      }
}

body.isDesktop #vector_roll {
  > div {
    .vector-item:hover {
      @extend .activeVector;
    }
  }
}

body.isMobile #vector_roll {
  > div {
    .vector-item {
    }
    .vector-item:focus,
    .vector-item:active {
      @extend .activeVector;
    }
  }
}

#ctas {
  margin-top: $standardGutter;
  margin-bottom: $standardGutter;

  > div:last-child {
    a {
      float: right;
    }
  }
}

.modal-view ::v-deep .modal-container {
    transition: all .3s ease;
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity .3s ease;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter ::v-deep .modal-container,
.modal-leave-active ::v-deep .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
