<template>
  <form v-on:submit.prevent.stop="submitForm()" name="step1" id="step_form" novalidate>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">{{ title }}</h2>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 mb-2">
        <IndustrySelector
        :getFieldClass="getFieldClass"
        :inputs="inputs"
        :isBroker="isBroker"
        :orderedByLabel="orderedByLabel"
        :industries="industries"
        :propIndustryString="industryString"
        :flattenIndustries="flattenIndustries"
        :isWhiteList="isWhiteList"
        @updateIndustryString="updateIndustryString"
        @industryChanged="industryChanged" />
      </div>
      <div class="col-12" v-if="isWWW">
        <div class="form-group" :class="getFieldClass('email')">
          <label for="email" class="cd-label">E-Mail</label>
          <input class="form-control cd-input-primary"
                 type="email"
                 id="email"
                 name="email"
                 v-model="inputs.email"
                 v-validate="{ required: true, email: true }">
          <small>
            Ich erhalte die gesetzlich vorgeschriebene
            <router-link :to="'/erstinformation'" target="_blank">Erstinformation</router-link>
            nach &sect; 15 Versicherungsvermittlungsverordnung an diese E-Mail- Adresse zugesandt.
          </small>
        </div>
      </div>
    </div>
    <!-- checkbox -->
    <div class="row mb-5" v-if="isWWW">
      <div class="col-12 col-sm-10 col-md-8">
        <div class="custom-control custom-checkbox">
          <input type="checkbox"
                 class="custom-control-input"
                 id="legalCheck"
                 v-model="inputs.newsletterCheck">
          <label class="custom-control-label text-muted check-label legal-note" for="legalCheck">
            Ich willige ein, dass diese E-Mail-Adresse sowie meine Nutzungsdaten von der CyberDirekt
            GmbH, K&ouml;penicker Str. 154A, 10997 Berlin verarbeitet werden, um  mich per E-Mail mit
            pers&ouml;nlich auf mich zugeschnittenen Informationen &uuml;ber CyberDirekt Leistungen zu
            kontaktieren. Ich kann diese Einwilligung, die Rechtsgrundlage f&uuml;r die Verarbeitung meiner
            personenbezogenen Daten ist, jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. Weitere
            Informationen erhalte ich in den
            <router-link to="/datenschutzhinweise" target="_blank">Datenschutzhinweisen</router-link>.
          </label>
        </div>
      </div>
    </div>
    <modal v-if="showLeaveModal" @close="showLeaveModal = false" modal-style="mobile">
      <h3 slot="header" class="mobile-method-selector__modal-title">
        Aufgrund Ihrer Branchenauswahl ist eine individuelle Anfrage erforderlich.
      </h3>
      <div slot="body" v-if="get_application_name !== 'portal-2'">
        <div v-if="!modalSuccess">
          <div class="form-group" :class="getFieldClass('modal_name')">
            <label for="modal_name">Name</label>
            <input class="form-control"
                   type="text"
                   id="modal_name"
                   name="modal_name"
                   v-validate="{ required: true }"
                   v-model="contactFormData.name">
          </div>
          <!-- <div class="form-group" :class="getFieldClass('modal_phone')">
            <label for="modal_phone">Telefonnummer</label>
            <phone-resolver class="phone-resolver-container"
              id="modal_phone"
              name="modal_phone"
              v-model="contactFormData.phone"
              required>
            </phone-resolver>
          </div> -->
          <PhoneForm
            name="Mobiltelefon"
            v-model='contactFormData.phone'
            @numberChange="updatePhoneNumber"
            v-validate="{ required: true }"
          />
          <div class="form-group" :class="getFieldClass('modal_mail')">
            <label for="modal_mail">E-Mail</label>
            <input class="form-control"
                   type="text"
                   id="modal_mail"
                   name="modal_mail"
                   v-validate="{ required: true, email: true }"
                   v-model="contactFormData.email">
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox"
                   class="custom-control-input"
                   id="customCheck1"
                   v-model="contactFormData.legalCheck">
            <label class="custom-control-label" for="customCheck1">
              Ich stimme den
              <router-link :to="'/datenschutzhinweise'" target="_blank">Datenschutzhinweisen
              </router-link>
              zu
            </label>
          </div>
        </div>
        <div v-else>
          <p class="text-center">Vielen Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen melden.</p>
        </div>
      </div>
      <div slot="body" v-else>
          <p class="text-center" v-if="isSubmissionSubmitted">Vielen Dank, wir haben Ihre Anfrage erhalten und werden Sie demnächst kontaktieren. hole</p>
      </div>
      <button class="btn btn-block btn-secondary"
              v-if="get_application_name !== 'portal-2'"
              type="button"
              @click="sendSubmission()"
              :disabled="!contactFormData.legalCheck || modalSuccess"
              slot="footer"> Ich möchte ein Angebot erhalten
      </button>
      <button class="btn btn-block btn-secondary"
              v-else
              type="button"
              :disabled="isSubmissionSubmitted"
              @click="isSubmissionSubmitted = true; isRuckRuf = true ; sendSubmission() ;"
              slot="footer"> Ich möchte einen Rückruf erhalten
      </button>
    </modal>
    <input type="submit" id="step_submit">
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/components/shared/EventBus.js'
import orderBy from 'lodash/orderBy'
import config from '../../content/config.json'
import PhoneForm from './shared/PhoneForm'
import StringHelper from '../StringHelper'
import Modal from '../Modal'
import store from '@/store/index'
import IndustrySelector from "./calculator-v2.0/parts/IndustrySelector/index"

export default {
  name: 'Step1a',
  components: {
    Modal,
    PhoneForm,
    IndustrySelector
  },
  data () {
    return {
      telOptions: {
        placeholder: 'Telefonnummer',
        allowedTelCountries: ['DE', 'AT', 'CH']
      },
      industryString: '',
      isExcluded: false,
      showLeaveModal: false,
      urlErstinformation: config.files.erstinformation,
      phoneValid: true,
      contactFormData: {
        name: '',
        phone: '',
        email: '',
        legalCheck: false
      },
      modalSuccess: false,
      isSubmissionSubmitted: false,
      isRuckRuf: false
    }
  },
  computed: {
    ...mapGetters([
      'isWWW',
      'isBroker',
      'style',
      'user',
      'affiliateBroker'
    ]),
    get_application_name () {
      return this.$store.state.application_name
    },
    insurers () {
      return this.isLegal ? this.$store.state.checkout.application.legalInsurers : this.$store.state.checkout.application.insurers
    },
    isLegal () {
      return this.$route.path.includes('rechtsschutzversicherung')
    },
    inputs () {
      return this.$store.state.checkout.application.inputs
    },
    industries () {
      const allIndustries = this.$store.state.checkout.application.industries
      let filterLists
      if (this.user && this.user.brokerAgency) {
        filterLists = {
          whiteList: this.user.brokerAgency.industryWhiteList ? this.user.brokerAgency.industryWhiteList : undefined,
          blackList: this.user.brokerAgency.industryBlackList ? this.user.brokerAgency.industryBlackList : undefined
        }
        return this.filterIndustries(allIndustries, filterLists)
      } else if (this.$store.state.checkout.application.affiliateBroker) {
        const affiliateBroker = this.$store.state.checkout.application.affiliateBroker
        filterLists = {
          whiteList: affiliateBroker.industryWhiteList ? affiliateBroker.industryWhiteList : undefined,
          blackList: affiliateBroker.industryBlackList ? affiliateBroker.industryBlackList : undefined
        }
        return this.filterIndustries(allIndustries, filterLists)
      } else {
        return allIndustries
      }
    },
    flattenIndustries () {
      let flattenIndustries = []

      this.industries.forEach(industryGroup => {
        flattenIndustries = flattenIndustries.concat(industryGroup.c)
      })

      return flattenIndustries
    },
    isWhiteList () {
      const condition1 = this.user && this.user.brokerAgency && !!(this.user.brokerAgency.industryWhiteList && this.user.brokerAgency.industryWhiteList.length)
      const condition2 = this.style === 'affiliate' && this.affiliateBroker && !!(this.affiliateBroker.industryWhiteList && this.affiliateBroker.industryWhiteList.length)
      return condition1 || condition2
    },
    title () {
      return this.isLegal ? 'In 3 Minuten zu Ihrer Rechtsschutzversicherung' : StringHelper.get(this.$store, 'STEP_1_TITLE')
    },
    industryHelpText () {
      return StringHelper.get(this.$store, 'STEP_1_INDUSTRY_HELP_TEXT')
    },
    additionalText () {
      const str = StringHelper.get(this.$store, 'STEP_1_ADDITIONAL_TEXT')
      return str === 'STRING-NOT-FOUND' ? null : str
    }
  },
  props: ['step'],
  watch: {
    'inputs.industry' (selectedIndustry) {
      let self = this
      let update = {
        selectedModules: {}
      }
      this.isExcluded = false

      const isStep1 = this.step.id === '1a' || this.step.id === '1b'

      if (selectedIndustry && selectedIndustry.w && !isStep1) {
        // reset inputs to avoid side effects
        update.insurer = null
        update.insuredSum = 0
        if (!this.isLegal) {
          update.deductible = 1000
        }
        update.paymentPeriod = 'yearly'
        update.answers = {}

        Object.keys(self.inputs.selectedModules)
          .forEach(insurer => {
            update.selectedModules[insurer] = []
          })

        self.$store.dispatch('UPDATE_INPUT_VALUES', update)

        if (selectedIndustry.l && selectedIndustry.l !== 'undefined') {
          self.industryString = selectedIndustry.l ? selectedIndustry.l : ''
        }

        self.isExcluded = self.insurers.filter((insurer) => {
            return !insurer.notAvailable
          }).every((availableInsurer) => {
            if (availableInsurer.excludedIndustries) {
              return availableInsurer.excludedIndustries.indexOf(selectedIndustry.w) > -1
            }
            return availableInsurer.includedIndustries.indexOf(selectedIndustry.w) === -1
          })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    if (to.path.indexOf('rechtsschutzversicherung') > -1) {
      storeInstance
        .dispatch('PREPARE_RESET_INPUTS', {
          insurer: 'roland',
          type: 'legal',
          deductible: 250,
          employees: '1-10'
        })
      next()
    } else {
      storeInstance
        .dispatch('PREPARE_RESET_INPUTS', {
          type: 'cyber'
        })
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    if (to.path.indexOf('rechtsschutzversicherung') > -1) {
      storeInstance
        .dispatch('PREPARE_RESET_INPUTS', {
          insurer: 'roland',
          type: 'legal',
          deductible: 250,
          employees: '1-10'
        })
      next()
    } else {
      storeInstance
        .dispatch('PREPARE_RESET_INPUTS', {
          type: 'cyber'
        })
      next()
    }
  },
  mounted: function () {
    let preSelectedIndustry

    eventBus.$emit('stepChangeCompleted')
    eventBus.$on('submitStep', () => {
      this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs)
      document.getElementById('step_submit')
        .click()
    })

    this.$nextTick(function () {
      if (this.industries && this.industries.length && this.inputs.industry && this.inputs.industry.w) {
        this.industryString = this.inputs.industry.l ? this.inputs.industry.l : ''
      } else {
        this.industryString = ''
      }
    })

    // can be removed or changed to new input
    this.$nextTick(() => {
      // this.$refs.industryField?.focus()
    })

    if (this.$route.query.i && !this.inputs.industry.w) {
      preSelectedIndustry = this.flattenIndustries.find(industry => industry.w === this.$route.query.i)

      if (preSelectedIndustry) {
        this.$set(this.inputs, 'industry', preSelectedIndustry)
      }
    }
  },
  methods: {
    updatePhoneNumber (res) {
      this.contactFormData.phone = res.number;
    },
    submitForm: function () {
      if (this.isExcluded) {
        this.showLeaveModal = true
      } else {
        this.$validator
          .validateAll()
          .then((result) => {
            let type = this.isLegal ? 'legal' : 'cyber'
            if (result) {
              this.step.completed = true

              if (this.isWWW) {
                this.$store
                  .dispatch('INSERT_PREREGISTRATION', {
                    industry: this.inputs.industry.l,
                    email: this.inputs.email,
                    type,
                    newsletterCheck: this.inputs.newsletterCheck
                  })
                  .then(() => {
                    eventBus.$emit('changeStepForward', this.step)
                  })
                  .catch((err) => {
                    const { error } = err.response.data

                    if (error && error.name === 'YouHaveBeenBlocked') {
                      eventBus.$emit('alertUser', {
                        type: 'error',
                        message: 'Sie haben die Anzahl der zulässigen Anfragen erreicht. Bitte wenden Sie sich an unseren Kundenservice. Wir unterstützen Sie gerne. Telefon 030 403660-36. Als Versicherungsmakler können Sie unser Maklerportal mit erweiterten Funktionen und Antragsmodellen nutzen.',
                        customCloseBtnText: 'OK',
                        customCloseBtnClass: 'btn btn-primary'
                      })
                    } else {
                      eventBus.$emit('changeStepForward', this.step)
                    }
                  })
              } else if (this.isBroker) {
                if (this.$analytics) {
                  this.$analytics.track(this.$route.path, {
                    label: 'industry',
                    value: this.inputs.industry.l
                  })
                }

                return eventBus.$emit('changeStepForward', this.step)
              }
            }
          })
      }
    },
    getFieldClass (fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      if (fieldName === 'industry') {
        switch (this.isBroker) {
          case true:
            return {
              'is-danger': this.fields['industry'].validated && !(this.inputs.industry && this.inputs.industry.l && this.inputs.industry.w && this.inputs.industry.w !== -1),
              'touched': false
            }
            break
          default:
            return {
              'is-danger': this.fields['industry'].dirty && !(this.inputs.industry && this.inputs.industry.w),
              'touched': false
            }
        }
      } else {
        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      }
    },
    sendSubmission () {
      // this is used to fill the data when we dont have the contactform data
      // non available industry
      const brokerName = `${this.user.firstname || '' } ${this.user.lastname || ''}`
      const brokerEmail = `${this.user.email || '' }`
      const brokerPhone = `${this.user.mobile || ''}`

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            this
              .$store
              .dispatch('INSERT_SUBMISSION', {
                topic: this.isLegal ? 'industryExcludedLegal' : 'industryExcluded',
                name: this.isRuckRuf ? brokerName : this.contactFormData.name,
                phone: this.isRuckRuf? brokerPhone : this.contactFormData.phone,
                email: this.isRuckRuf? brokerEmail : this.contactFormData.email,
                meta: {
                  industry: this.inputs.industry
                }
              })
              .then(() => {
                this.modalSuccess = true
              })
              .catch(() => {
                eventBus.$emit('alertUser', {
                  type: 'error',
                  message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.',
                  customCloseBtnText: 'OK',
                  customCloseBtnClass: 'btn btn-primary ',
                })
              })
          }
        })
    },
    orderedByLabel: function (indusries) {
      return orderBy(indusries, 'l')
    },
    filterIndustries (array, filter) {
      if ((!filter.whiteList || !filter.whiteList.length) && !filter.blackList) {
        return array
      }
      const industries = JSON.parse(JSON.stringify(array))
      if (filter.whiteList || filter.blackList) {
        const filteredIndustries = []
        industries.forEach(((group) => {
          const filteredGroup = {c: [], l: group.l}
          group.c.forEach((item) => {
            if (filter.whiteList && filter.whiteList.includes(item.w)) {
              filteredGroup.c.push(item)
            }
            if (filter.blackList  && !filter.blackList.includes(item.w)) {
              filteredGroup.c.push(item)
            }
          })
          if (filteredGroup.c.length > 0) {
            filteredIndustries.push(filteredGroup)
          }
        }))
        return filteredIndustries
      } else {
        return
      }
    },
    industryChanged(industry) {
      try {
        this.industryString = industry.l
        this.$store.commit('SET_INDUSTRY', industry)
        // this.inputs.industry = industry
      } catch(err) {
        console.error('industryChanged error: ', err)
      }
    },
    updateIndustryString(string) {
      this.industryString = string
    }
  },
  destroyed: function () {
    eventBus.$off('submitStep')
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/form';
@import '../scss/mixins';
@import '../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.phone-resolver-container {
  border: none;
  padding-top: 0;
  padding-left: 0;
}

h2 {
  margin-bottom: 64px;
  font-weight: 700;

  @include media-breakpoint-down(lg) {
    margin-bottom: 46px;
  }
}

.form-group {
  display: flex;
  flex-direction: column;

  label:not(.cd-label) {
    margin-bottom: 2px;
    font-weight: bold;
  }

  small {
    display: block;
    margin-bottom: 2px;

    &:last-of-type {
      position: relative;
      top: 4px;
    }
  }

  &.is-danger input#industryString {
    border: 0;
  }
  &.is-danger select option,
  &.is-danger select optgroup {
    color: #000;
  }
}

.check-label {
  font-size: 0.85rem;
}

.legal-note {
  text-align: justify;
  font-size: 0.75rem;
}

select {
  option {
    padding: 4px;
    white-space: normal;
    width: 100%;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;

  p {
    font-weight: 800;
    font-size: inherit;
    padding: 0 0 0 10px;
    margin: 0;
  }
}
</style>
