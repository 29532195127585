<template>
  <div class="filter filter--offer">
    <div class="details sticky-col">
      <div>
        <!-- <div class="title">
          Leistungsübersicht
        </div> -->
        <div class="title title--print font-weight-bold">
          Informationen zum Angebot
        </div>
      </div>
      <DetailsInfo
        :inputs="inputs"
        :revenueRange="revenueRange"
        :isOffer="isOffer"
        :allowRatingsSwitch="allowRatingsSwitch"
        :isScoreVisible="isScoreVisible"
        :insuredSumOptionsAllowed="insuredSumOptionsAllowed"
        :showInsuredSumOptions="showInsuredSumOptions"
        :isMobile="isMobile"
        :isPrint="isPrint"
      ></DetailsInfo>
    </div>
  </div>
</template>

<script>
import DetailsInfo from './DetailsInfo.vue'

export default {
  data () {
    return {
      innerSortBy: 'score'
    }
  },
  props: [
    'isWWW',
    'isOffer',
    'isAffiliate',
    // 'downloadLink',
    'inputs',
    'revenueRange',
    'allowRatingsSwitch',
    'sortByOptions',
    'sortBy',
    'insuredSumOptionsAllowed',
    'showInsuredSumOptions',
    'isMobile',
    'isPrint'
  ],
  computed: {
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      }
    }
  },
  mounted() {
    this.innerSortBy = this.sortBy
  },
  components: { DetailsInfo },
  watch:{
    innerSortBy () {
      this.$emit('sortChange', this.innerSortBy)
    },
    sortBy () {
      this.innerSortBy = this.sortBy
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/print/detailsCard.scss';
</style>
